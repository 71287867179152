import React, {useEffect, useState} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {Alert, FormGroup, useAlert} from "./components/FormComps"
import { useIntlEx } from "./components/IntlUtils.js"
import getLogger from "./components/Logging.js"
import {SolvError, useSolv} from "./components/SolvProvider";
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import './App.css'
import {mkHref} from "./components/AccountUtils";

const log = getLogger("CreditTransactionSettleDialog")

export default function CreditTransactionSettleDialog(props)  {

  const { api, setBusy } = useSolv()
  const { intl } = useIntlEx()

  const [alert, setAlert] = useAlert()

  const [refInfo, setRefInfo] = useState(null)
  const [touched, setTouched] = useState(null)

  async function handleSettleClick() {

    try {

      let payload = {
        paymentMethodId: "BANK",
        refInfo: refInfo,
      }

      log.debug("settleCreditTransaction: payload=", payload)

      const res = await api.settleCreditTransaction(props.creditTransaction.tenant.tenantId, props.creditTransaction.creditTransactionId, payload)

      log.debug("settleCreditTransaction: data=", res)

      window.location = mkHref({suffix: "/credits", tenant: props.tenant})
    }
    catch (error) {
      log.debug("Error: error=", error)
      handleError(error)
    }
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
      switch (code) {
        case "NOT_FOUND":
          setAlert({error: intl.msg("credit_transaction_settle_error_not_found")})
          break
        default:
          setAlert({error: intl.msg("error_failed")})
          break
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function handleRefInfoChange(e) {
    setRefInfo(e.target.value)
    setTouched(true)
  }

  function handleOpenDialog() {
    setAlert(null)
  }

  function handleCloseDialog() {
    closeDialog("dlg_credit_transaction_settle")
  }


  return (

    props.tenant &&
      <ModalDialog
        dialogId="dlg_credit_transaction_settle"
        size="lg" keyboard={"true"} backdrop={props.backdrop || "static"}
        onOpen={handleOpenDialog}
        onClose={handleCloseDialog}
      >

        <ModalDialog.Header
          title={intl.msg("credit_transaction_settle_title")} description={intl.msg("credit_transaction_settle_subtitle")}
          alert={alert}
        />

        <ModalDialog.Body>

          <Alert warning={intl.msg("credit_transaction_settle_text", {credits: `${intl.num(props.creditTransaction?.credits)} ${props.creditTransaction?.tenant?.region?.currencyCode || ""}`})}/>

          <FormGroup>
            <FormGroup.Label htmlFor="inp_refInfo" text={intl.msg("credits_ref_info")}/>
            <FormGroup.Controls>
              <input id="inp_refInfo" name="description" type="text" className="form-control" value={refInfo} disabled={false} onChange={handleRefInfoChange} autoComplete="no"/>
            </FormGroup.Controls>
          </FormGroup>

        </ModalDialog.Body>

        <ModalDialog.Footer>
          <ModalDialog.Footer.Controls>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              {intl.msg("cancel")}
            </button>
            {
              !alert && refInfo &&
                <button type="button" className="btn btn-primary" onClick={handleSettleClick}>
                  {intl.msg("settle")}
                </button>
            }
          </ModalDialog.Footer.Controls>
        </ModalDialog.Footer>

      </ModalDialog>
  )
}