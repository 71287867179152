import React, { Fragment, useState, useEffect }  from 'react'
import {useHistory, useParams} from 'react-router-dom'
import { useInfiniteQuery, useQueryClient } from 'react-query'
import useIntersectionObserver from './useIntersectionObserver.js'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {useSolv, useTenant} from "./components/SolvProvider";
import { useIntlEx } from "./components/IntlUtils.js"
import MainMenu from "./MainMenu.js"
import {FormHeader, FormBody, TenantFormTitle, MainContainer} from "./components/FormComps.js"
import { TableHeadColumn } from "./components/Table.js"
import { TagLabel } from './components/TagBox.js'
import {AccountTabs} from "./components/AccountUtils";
import {openDialog} from "./components/DialogUtils";
import TemplatesInstallDialog from "./TemplatesInstallDialog";
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("Templates")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function Templates(props) {

  const {api, session:{user}} = useSolv()
  const {intl} = useIntlEx()

  const history = useHistory()
  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [working, setWorking] = useState(null)
  const [error, setError] = useState(null)

  const [search, setSearch] = useState(null)
  const [sortSpec, setSortSpec] = useState({field: "touchedOn", direction: "DESC"})
  
  const [generatedCount, setGeneratedCount] = useState(0)

  // const [defaultBroadcastTemplateId, setDefaultBroadcastTemplateId] = useState(null)
  // const [tenantBroadcastTemplateIds, setTenantBroadcastTemplateIds] = useState(null)

  const queryClient = useQueryClient()

  // useEffect(() => {
  //   if (tenant) {
  //     setDefaultBroadcastTemplateId(tenant.defaultBroadcastTemplateId)
  //   }
  // }, [tenant])

  const fetchTemplates = async ({ pageParam = null }) => {
    return api.listBroadcastTemplates(tenant.tenantId, search, "BASIC", sortSpec, pageParam, 50)
  }

  const {
    status,
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(['templates', tenant, search, sortSpec], fetchTemplates, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextCursor
    },
    enabled: !!tenant
  })  

  const loadMoreButtonRef = React.useRef()  

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  })

  function handleRowClick(row) {
    window.location = `/${row.tenantId}/templates/${row.broadcastTemplateId}`
  }
  
  function handleSearchKeyDown(e) {
    if (e && e.key === "Enter") {
      setSearch(e.target.value)
    }
  }

  function refreshList() {
    queryClient.invalidateQueries("templates")
  }

  function isProvided(t) {
    // return tenant && tenant.broadcastTemplateIds && tenant.broadcastTemplateIds.map(i => i.replace(/\-/g, "")).includes(t.broadcastTemplateId.replace(/\-/g, ""))
    return t.provided
  }

  function isDefault(t) {
    return t.broadcastTemplateId.replace(/\-/g, "") === tenant?.defaultBroadcastTemplateId?.replace(/\-/g, "")
  }

  function mkNewLink() {
    if (params.tenantId && tenant) {
      return `/${tenant.tenantId.replace(/\-/g, "")}/templates/_new`
    }
    else {
      return "/templates/_new"
    }
  }

  // function MoreButton(props) {
  //   return (
  //     tenant && !tenant.accessingAs("MEMBER") &&
  //       <>
  //         <button className="btn btn-secondary" type="button" id="dropdownMenuButton" title={intl.msg("more_menu")} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  //           <i className="fas fa-ellipsis-v"></i>
  //         </button>
  //         <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
  //           <a className="dropdown-item" href="#" data-toggle="modal" onClick={() => openDialog("dlg_provide_templates")}>
  //             <i className="fas fa-check-circle mr-1"></i>{intl.msg("provide_templates")}
  //           </a>
  //         </div>
  //       </>
  //   )
  // }
  //
  return (
    tenant &&
      <>

        <MainContainer tenant={tenant} menu={MainMenu}>

          <FormHeader>
            <AccountTabs tenant={tenant}/>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("templates")}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                <div className="form-group search mr-2">
                  <i className="fas fa-search form-control-feedback"></i>
                  <input type="text" className="form-control" placeholder={intl.msg("templates_search")} onKeyDown={handleSearchKeyDown}/>
                </div>
                <div>
                  <button className="btn btn-secondary" type="button" title={intl.msg("tooltip_refresh_list")} onClick={refreshList}><i className="fas fa-redo"></i></button>
                </div>
                <div>
                  {
                    tenant && tenant.accessingAs(["SUPERVISOR/MEMBER/*", "ADMIN/MANAGER/*"]) &&
                      <a className="btn btn-primary ml-2" href={mkNewLink()} title={intl.msg("templates_new_tooltip")}>
                        <i className="fas fa-plus mr-1"></i>{intl.msg("add_new")}
                      </a>
                  }
                  {
                    tenant && tenant.accessingAs(["ADMIN/MEMBER/SYSTEM", "ADMIN/MANAGER/*"]) &&
                      <a className="btn btn-secondary ml-2" href="#" title={intl.msg("templates_install_tooltip")} onClick={() => openDialog("dlg_template_install")}>
                        {/*<i className="fas fa-share-alt fa-flip-horizontal mr-1"></i>*/}

                        <span className="fa-layers mr-1">
                          <i className="fas fa-angle-double-down" data-fa-transform="shrink-2 up-2"></i>
                          <i className="fas fa-plus" data-fa-transform="shrink-10 down-6"></i>
                        </span>

                        {/*<i className="fas fa-sign-in-alt fa-rotate-90 mr-1"></i>*/}

                        {intl.msg("install")}
                      </a>
                  }
                </div>
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>

          <FormBody>

            <div className="row">
              <div className="col">
                <table className="table table-dark table-striped table-hover table-bordered dir">
                  <thead className="thead-dark">
                    <tr>
                      <TableHeadColumn clickable label={intl.msg("template_name")} field="broadcastTemplateName" sortSpec={sortSpec} setSortSpec={setSortSpec}/>
                      <TableHeadColumn clickable label={intl.msg("last_updated")} field="touchedOn" sortSpec={sortSpec} setSortSpec={setSortSpec}/>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    status === 'loading' ? (
                      <tr>
                        <td colSpan="3"><p>{intl.msg("loading")}</p></td>
                      </tr>
                    ) : status === 'error' ? (
                      <tr>
                        <td className="text-danger" colSpan="3">{intl.msg("error_failed")}</td>
                      </tr>
                    ) : data ? (
                        // func(data)
                        data.pages.map((group, i) => (
                          <React.Fragment key={i}>
                            {group.data.map(template => (
                              <tr key={template.broadcastTemplateId} onClick={() => handleRowClick(template)}>
                                <td>
                                  <div style={{display: "flex"}}>
                                    <span className="dir-name">
                                      {template.broadcastTemplateName}
                                    </span>
                                    {
                                      isProvided(template) &&
                                        <>
                                          &nbsp;<TagLabel label={intl.msg("template_label_provided")} title={intl.msg("template_label_provided_tooltip")}/>
                                        </>
                                    }
                                    {
                                      isDefault(template) &&
                                        <TagLabel label={intl.msg("template_label_default")} title={intl.msg("template_label_default_tooltip")}/>
                                    }
                                  </div>
                                </td>
                                <td>
                                  <a href="#" className="display-only" title={intl.dateTime(template.touchedOn)}>
                                    {dayjs(template.touchedOn).fromNow()}
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </React.Fragment>
                        ))
                    ) : ""
                  }
                  </tbody>
                </table>
                <div>
                  {
                    hasNextPage ?
                      <button
                        className="btn btn-secondary"
                        ref={loadMoreButtonRef}
                        onClick={() => fetchNextPage()}
                        disabled={!hasNextPage || isFetchingNextPage}>
                        {
                          isFetchingNextPage
                            ? <span>Loading...</span>
                            : <span>Show More</span>
                        }
                      </button>
                      : ""
                  }
                </div>
                <div>
                  {/* {isFetching && !isFetchingNextPage ? 'Background Updating...' : null} */}
                </div>
              </div>
            </div>
          </FormBody>
        </MainContainer>
        <TemplatesInstallDialog
          tenant={tenant}
        />
      </>
  )
}