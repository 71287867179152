import React, {useEffect, useState} from 'react'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {Alert, useAlert} from "./components/FormComps"
import { useIntlEx } from "./components/IntlUtils.js"
import getLogger from "./components/Logging.js"
import './App.css'
import {useSolv} from "./components/SolvProvider";
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {mkHref} from "./components/AccountUtils";

const log = getLogger("CreditsTestPaymentDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function CreditsTestPaymentDialog(props)  {

  const { api, setBusy } = useSolv()
  const { intl } = useIntlEx()

  const [alert, setAlert] = useAlert()

  const [tenant, setTenant] = useState(null)

  useEffect(() => {
    if (props.tenant) {
      setTenant(props.tenant)
    }
  }, [props])

  async function handlePurchaseClick() {

    if (props.tenant) {
      setBusy(intl.msg("saving"))
      try {

        let payload = {
          paymentTransactionType: "PURCHASE",
          paymentMethodId: "TEST",
          tenantId: props.tenant.tenantId,
          amount: props.credits,
          usdMultiplier: props.usdMultiplier,
          reason: "TEST",
        }

        log.debug("createPaymentTransaction: payload=", payload)

        const res = await api.createPaymentTransaction(payload)

        log.debug("createPaymentTransaction: data=", res)

        if (res && res.data) {
          try {
            let payload = {
              paymentTransactionId: res.data.paymentTransactionId,
              purchaseType: "CREDITS",
              description: intl.msg("credit_transaction_description_purchase", {paymentMethod: "Test"}),
            }

            log.debug("purchaseCredits: payload=", payload)

            await api.purchaseCredits(tenant.tenantId, payload)

            window.location.reload()

          }
          catch (error) {
            setAlert({error: intl.msg("error_failed")})
          }
        }
        else {
          setAlert({error: intl.msg("error_failed")})
        }
      }
      finally {
        setBusy(null)
      }
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }

  }

  function handleOpenDialog() {
    log.debug(">>>Z: ", props)
    setAlert(null)
  }

  function handleCloseDialog() {
    closeDialog("dlg_credits_test_payment")
  }


  return (

    tenant &&
    <ModalDialog
      dialogId="dlg_credits_test_payment"
      size="lg" keyboard={"true"} backdrop={props.backdrop || "static"}
      alert={alert}
      onOpen={handleOpenDialog}
      onClose={handleCloseDialog}>

      <ModalDialog.Header
        title={intl.msg("credits_test_payment_title")}
        alert={alert}/>

      <ModalDialog.Body>

        <Alert alert={{warning: intl.msg("credits_test_payment_text", {credits: props.credits})}} align="center"/>

      </ModalDialog.Body>

      <ModalDialog.Footer>
        <ModalDialog.Footer.Controls>
          <button type="button" className="btn btn-secondary" data-dismiss="modal">
            {intl.msg("cancel")}
          </button>
          {
            !alert &&
              <button type="button" className="btn btn-danger" onClick={handlePurchaseClick}>
                {intl.msg("purchase")}
              </button>
          }
        </ModalDialog.Footer.Controls>
      </ModalDialog.Footer>

    </ModalDialog>
  )
}