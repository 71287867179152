import {useIntlEx} from "./IntlUtils";
import {TagBox, TagLabel} from "./TagBox";
import React, {useEffect, useRef, useState} from "react";
import {openDialog} from "./DialogUtils";
import {useSolv} from "./SolvProvider";
import {Widget, WidgetMetric} from "./Widget";
import {mkHref} from "./AccountUtils";
import getLogger from "./Logging.js"

const log = getLogger("CreditTransactions")

export function CreditsExpiring(credits) {
  let expiring = false
  if (credits && credits.amount > 0 && credits.expiresOn) {
    switch (credits.status) {
      case "EXPIRING_3":
        expiring = true
        break
      case "EXPIRING_2":
        expiring = true
        break
      case "EXPIRING_1":
        expiring = true
        break
      default:
        expiring = false
    }
  }
  return expiring
}

export function CreditsExpiringText(props) {
  const {intl} = useIntlEx()
  let {credits} = props
  let cls = ""
  let el = ""
  let expiring = CreditsExpiring(credits)
  if (expiring) {
    switch (credits.status) {
      case "EXPIRING_3":
        cls = "text-danger"
        break
      case "EXPIRING_2":
        cls = "text-warning"
        break
      case "EXPIRING_1":
        cls = "text-warning"
        break
      default:
        cls = ""
    }
    el = <>{intl.msg("credits_expires_days", {expireDays: intl.fmDaysFrom(credits.expiresOn)})}</>
    return (
      <span className={cls} title={intl.dateTime(credits.expiresOn)}>
        {el}
      </span>
    )
  } else if (props.alt) {
    return <span>
      {props.alt}
    </span>
  } else {
    return <></>
  }
}

export function CreditsExtendButton(props) {
  const {intl} = useIntlEx()
  const tenant = props.tenant
  const mode = props.mode || "BUTTON"
  return (
    tenant && CreditsExpiring(tenant.credits) && tenant.accessingAs("ADMIN/*/*")) ? (
    // tenant && (tenant.accessingAs("ADMIN/*/SYSTEM") || (isCreditsExpiring(tenant.credits) && tenant.accessingAs("ADMIN/*/*"))) ? (
    "BUTTON" === mode ? (
      <button className={"btn btn-secondary " + (props.className || "")} href="#"
              title={intl.msg("credit_transaction_extend_expiry_title")}
              onClick={() => openDialog("dlg_credits_extend_expiry")}>
        <i className="fas fa-history mr-1"></i>
        {intl.msg("credit_transaction_extend_expiry")}
      </button>
    ) : (
      <a className={"text-link " + (props.className || "")} href="#"
         onClick={() => openDialog("dlg_credits_extend_expiry")}>
        {intl.msg("credit_transaction_extend_expiry_title")}
      </a>
    )
  ) : (
    <></>
  )
}

export function CreditsWidget(props) {
  const {session} = useSolv()
  const {intl} = useIntlEx()
  const tenant = props.tenant
  return (
    tenant && tenant.credits &&
    <Widget title={intl.msg("credits")}
            subtitle={<CreditsExpiringText credits={tenant.credits} alt={intl.msg("account_balance")}/>}
            onClick={() => window.location = mkHref({suffix: "/credits", tenant: tenant})} size={props.size}>
      <WidgetMetric>
        {intl.num(tenant.credits.amount || 0.0)}
        <small className="pl-1">{tenant.region?.currencyCode || ""}</small>
      </WidgetMetric>
    </Widget>
  )
}

export function canExtendCredits(tenant, user) {
  return (
    tenant && CreditsExpiring(tenant.credits) && (user.isSystem() || tenant.accessingAs("ADMIN/MANAGER/*") || (tenant.accessingAs("SUPERADMIN/MEMBER/*") && !tenant.credits.extendedOn))
  )
}

export function useCreditTransactionStatusOpts() {
  const {intl} = useIntlEx()
  const creditTransactionStatusOpts = [
    {
      value: "ACTIVE",
      label: intl.msg("credit_transaction_status_active"),
    },
    {
      value: "EXPIRED",
      label: intl.msg("credit_transaction_status_expired"),
    },
    {
      value: "PENDING",
      label: intl.msg("credit_transaction_status_pending"),
    },
    {
      value: "REVERSED",
      label: intl.msg("credit_transaction_status_reversed"),
    },
  ]

  function findCreditTransactionStatusOpt(status) {
    const st = status?.toUpperCase()
    if (!st) {
      return null
    }
    return creditTransactionStatusOpts.find(o => o.value === st)
  }

  log.debug(">>>Z: useCreditTransactionStatusOpts: returning=", creditTransactionStatusOpts)
  return ({
    creditTransactionStatusOpts,
    findCreditTransactionStatusOpt,
  })
}

export function CreditTransactionStatus(props) {
  const {intl} = useIntlEx()
  const {findCreditTransactionStatusOpt} = useCreditTransactionStatusOpts()
  const userId = props.userId || props.row?.userId
  const statusOpt = findCreditTransactionStatusOpt(props.status || props.row?.status)
  const userLevel = props.userLevel || props.row?.userLevel
  const size = props.size || "sm"
  return (
    <>
      {
        statusOpt &&
        <TagBox>
          <TagLabel label={statusOpt.label} className={`credit-transaction-status-color-${statusOpt.value.toLowerCase()} tag-label-${size}`} tooltip={""}/>
        </TagBox>
      }
    </>
  )
}

export function useCreditTransactionTypeOpts(p) {
  const {intl} = useIntlEx()
  let creditTransactionTypeOpts = [
    {
      value: "BONUS",
      label: intl.msg("credit_transaction_type_bonus"),
    },
    {
      value: "CORRECTION",
      label: intl.msg("credit_transaction_type_correction"),
    },
    {
      value: "DEDUCTION",
      label: intl.msg("credit_transaction_type_deduction"),
    },
    {
      value: "EXPIRE",
      label: intl.msg("credit_transaction_type_expire"),
    },
    {
      value: "PENALITY",
      label: intl.msg("credit_transaction_type_penality"),
    },
    {
      value: "PURCHASE",
      label: intl.msg("credit_transaction_type_purchase"),
    },
    {
      value: "PUBLISH",
      label: intl.msg("credit_transaction_type_publish"),
    },
    {
      value: "REFUND",
      label: intl.msg("credit_transaction_type_refund"),
    },
    {
      value: "REVIVE",
      label: intl.msg("credit_transaction_type_revive"),
    },
    {
      value: "REVERSE",
      label: intl.msg("credit_transaction_type_reverse"),
    },
    {
      value: "REWARD",
      label: intl.msg("credit_transaction_type_reward"),
    },
    {
      value: "SERVICE",
      label: intl.msg("credit_transaction_type_service"),
    },
    {
      value: "TEST",
      label: intl.msg("credit_transaction_type_test"),
    },
    {
      value: "TRANSFER",
      label: intl.msg("credit_transaction_type_transfer"),
    },
  ]

  if (Array.isArray(p)) {
    creditTransactionTypeOpts = creditTransactionTypeOpts.filter((c) => p.includes(c.value))
  }
  else if ("function" === typeof(p)) {
    creditTransactionTypeOpts = creditTransactionTypeOpts.map(p).filter(Boolean)
  }

  function findCreditTransactionTypeOpt(status) {
    const st = status?.toUpperCase()
    if (!st) {
      return null
    }
    return creditTransactionTypeOpts.find(o => o.value === st)
  }

  return ({
    creditTransactionTypeOpts,
    findCreditTransactionTypeOpt,
  })
}

export function usePaymentMethodOpts(p) {
  const {intl} = useIntlEx()
  let paymentMethodOpts = [
    {
      value: "BANK",
      label: intl.msg("payment_method_bank"),
    },
    {
      value: "CASH",
      label: intl.msg("payment_method_cash"),
    },
    {
      value: "CREDITS",
      label: intl.msg("payment_method_credits"),
    },
    {
      value: "CRYPTO",
      label: intl.msg("payment_method_crypto"),
    },
    {
      value: "OTHER",
      label: intl.msg("payment_method_other"),
    },
    {
      value: "PAYPAL",
      label: intl.msg("payment_method_paypal"),
    },
    {
      value: "PAYPAL_CHECKOUT",
      label: intl.msg("payment_method_paypal_checkout"),
    },
    {
      value: "TEST",
      label: intl.msg("payment_method_test"),
    },
    {
      value: "WISE",
      label: intl.msg("payment_method_wise"),
    },
  ]

  if (Array.isArray(p)) {
    paymentMethodOpts = paymentMethodOpts.filter((c) => p.includes(c.value))
  }
  else if ("function" === typeof(p)) {
    paymentMethodOpts = paymentMethodOpts.map(p).filter(Boolean)
  }

  function findPaymentMethodOpt(v) {
    const st = v?.toUpperCase()
    if (!st) {
      return null
    }
    return paymentMethodOpts.find(o => o.value === st)
  }

  function PaymentMethod(props) {
    const paymentMethod = props.paymentMethod || props.paymentMethodId || props.row?.paymentMethod || props.row?.paymentMethodId
    const opt = findPaymentMethodOpt(paymentMethod)
    return (
      opt && opt.label &&
        <span>{opt.label}</span>
    )
  }

  return ({
    paymentMethodOpts,
    findPaymentMethodOpt,
    PaymentMethod,
  })
}
