import React, { Fragment, Component, useState, useRef, useEffect }  from "react"
import { usePalette } from 'react-palette'
import { Markdown } from './components/MarkdownUtils.js'
import { formatDate } from "./components/CustomFieldUtils.js"
import { mkFieldRowClassName, getStyle } from "./components/BroadcastComps.js"
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("CreativePreview")

export default function CreativePreview(props) {

  const markdown = new Markdown({
    homePage: props.homePage,
    clickUrls: props.clickUrls,
    allowedDomains: props.allowedDomains,
    disallowedDomains: props.disallowedDomains,
    allowLinks: false
  })

  return (
    "IMAGE" === props.creativeType ? (
      <>
        <div id="creativeContainer" className="adcreative-container" style={{borderColor: "#000000", borderWidth: "1px"}}>
          {/*<div className="photo-custom" style={{backgroundColor: data.lightMute}}>*/}
          <div className="photo-custom">
            {
              props.creativeImageUrl && props.creativeImageUrl.trim().length > 0 &&
                props.pageUrl ? (
                  <a className="text-link" href={props.pageUrl} title={props.pageUrl} target="_blank">
                    <img src={props.creativeImageUrl}/>
                  </a>
                ) : (
                  <img src={props.creativeImageUrl}/>
                )
            }
          </div>
        </div>
      </>
    ) : (
      <>
        <div id="creativeContainer" className="adcreative-container" style={{fontFamily: getStyle(props.creativeStyle, "content", "font", "Arial"), color: getStyle(props.creativeStyle, "content", "textColor", "#0000"), backgroundColor: getStyle(props.creativeStyle, "content", "backgroundColor", "#FFFF"), border: `4px solid ${getStyle(props.creativeStyle, "border", "color", "#FF0000")}`}}>
          <div className="header" style={{fontFamily: getStyle(props.creativeStyle, "header", "font", "Arial"),color: getStyle(props.creativeStyle, "header", "textColor", "#FFFFFF"), backgroundColor: getStyle(props.creativeStyle, "header", "backgroundColor", "#FF0000")}}><center>{props.creativeHeader ? props.creativeHeader : ""}</center></div>
          <div className="title" style={{fontFamily: getStyle(props.creativeStyle, "title", "font", "Arial"),color: getStyle(props.creativeStyle, "title", "textColor", "#000000"), backgroundColor: getStyle(props.creativeStyle, "title", "backgroundColor", "#FFFFFF"), borderBottom: `3px solid ${getStyle(props.creativeStyle, "border", "color", "#FF0000")}`}}><center>{props.creativeTitle ? props.creativeTitle : ""}</center></div>
          {
            (props.tenantName || props.logoUrl) &&
              <div className="tenant-info">
                {
                    props.logoUrl && props.logoUrl.trim().length > 0 &&
                    <div className="tenant-logo"><img src={props.logoUrl}/></div>
                }
                <div className="tenant-name">{props.tenantName ? props.tenantName : ""}</div>
              </div>
          }
          <div className="photo">
            {
              props.creativeImageUrl && props.creativeImageUrl.trim().length > 0 &&
                <img src={props.creativeImageUrl}/>
            }
            </div>
            {
               props.creativeFormFields &&
                  <>
                    <div className={`content ${props.tenantName || props.logoUrl ? "" : "no-tenant-info"}`} style={{fontFamily: getStyle(props.creativeStyle, "content", "font", "Arial"), color: getStyle(props.creativeStyle, "content", "textColor", "#000000"), backgroundColor: getStyle(props.creativeStyle, "content", "backgroundColor", "#FFFFFF")}}>
                      <div className="fields">
                        {
                          props.creativeFormFields.map(field =>
                            field.value && field.value != '' &&
                              <div className={mkFieldRowClassName(field.opts)}>
                                <div className="field-label">{field.label}:</div>
                                {
                                  "TEXT_BLOCK" === field.type ? (
                                    <div className="field-value" dangerouslySetInnerHTML={{ __html: field.value ? markdown.toHtml(field.value) : "" }}/>
                                  ) : "DATE" === field.type ? (
                                    <div className="field-value">{formatDate(field.value, props.regionId)}</div>
                                  ) : (
                                    <div className="field-value">{field.value}</div>
                                  )
                                }
                              </div>
                          )
                        }
                      </div>
                    </div>
                  </>
          }
          <div className="footer" style={{fontFamily: getStyle(props.creativeStyle, "footer", "font", "Arial"), color: getStyle(props.creativeStyle, "footer", "textColor", "#FFFFFF"), backgroundColor: getStyle(props.creativeStyle, "footer", "backgroundColor", "#FF0000")}}><center><b>{props.creativeFooter ? props.creativeFooter : ""}</b></center></div>
        </div>
      </>
    )
  )
}
