import React, {useEffect, useState} from 'react'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {FormGroup, useAlert} from "./components/FormComps"
import { useIntlEx } from "./components/IntlUtils.js"
import getLogger from "./components/Logging.js"
import {useSolv} from "./components/SolvProvider";
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {mkHref} from "./components/AccountUtils";
import './App.css'
import {InfoBlock, InfoText} from "./components/ValidationUtils";
import NumericInput from "react-numeric-input";

const log = getLogger("CreditTransactionReverseDialog")

export default function CreditTransactionReverseDialog(props)  {

  const { api, setBusy } = useSolv()
  const { intl } = useIntlEx()

  const [alert, setAlert] = useAlert()

  const [description, setDescription] = useState(null)
  const [creditTransactionType, setCreditTransactionType] = useState({value: "REVERSE", label: "Reverse"})
  const [touched, setTouched] = useState(null)


  function handleReverseClick() {
    log.debug("handleReverseClick: invoked")
    setBusy(intl.msg("working"))
    const payload = {
      creditTransactionId: props.creditTransaction.creditTransactionId,
      creditTransactionType: creditTransactionType.value,
      description: description,
      details: {},
    }
    api.reverseCreditTransaction(props.tenant.tenantId, payload)
      .then((data) => {
        window.location = mkHref({suffix: `/credits`, tenant: props.tenant})
      })
      .catch((error) => {
        handleError(error)
      })
      .finally(() => {
        setBusy(null)
      })
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
      switch (code) {
        case "DUPLICATE_KEY":
          setAlert({error: intl.msg("credit_transaction_reverse_error_duplicate")})
          break
        case "INSUFFICIENT_FUNDS":
          setAlert({error: intl.msg("credit_transaction_reverse_error_insufficient_funds")})
          break
        default:
          setAlert({error: intl.msg("error_failed")})
          break
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function handleDescriptionChange(e) {
    setDescription(e.target.value)
    setTouched(true)
  }

  function handleOpenDialog() {
    setAlert(null)
  }

  function handleCloseDialog() {
    closeDialog("dlg_credit_transaction_reverse")
  }


  return (

    props.tenant &&
      <ModalDialog
        dialogId="dlg_credit_transaction_reverse"
        size="lg" keyboard={"true"} backdrop={props.backdrop || "static"}
        onOpen={handleOpenDialog}
        onClose={handleCloseDialog}
      >

        <ModalDialog.Header
          title={intl.msg("credit_transaction_reverse_title")}
          alert={alert}
        />

        <ModalDialog.Body>

          {intl.msg("credit_transaction_reverse_text", {credits: -(props.creditTransaction?.credits)})}

          <FormGroup>
            <FormGroup.Label htmlFor="inp_description" text={intl.msg("description")}/>
            <FormGroup.Controls>
              <input id="inp_description" name="description" type="text" className="form-control" value={description} disabled={false} onChange={handleDescriptionChange} autoComplete="no"/>
            </FormGroup.Controls>
          </FormGroup>

        </ModalDialog.Body>

        <ModalDialog.Footer>
          <ModalDialog.Footer.Controls>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              {intl.msg("cancel")}
            </button>
            {
              !alert && description &&
                <button type="button" className="btn btn-danger" onClick={handleReverseClick}>
                  {intl.msg("reverse")}
                </button>
            }
          </ModalDialog.Footer.Controls>
        </ModalDialog.Footer>

      </ModalDialog>
  )
}