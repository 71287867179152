import React from "react"
import styles from "./PageDialog.module.css"
import {Alert} from "./FormComps";
import getLogger from "../components/Logging.js"
const log = getLogger("PageDialog")

export function PageDialogContainer(props) {

  return (
      <div className={`${styles.container} ${props.className ? props.className : ""}`}>
        {props.children}
      </div>
  )
}

export function PageDialog(props) {
  const Header = props.header
  const Footer = props.footer
  const Sider = props.sider
  let style = {}
  if (props.style) {
    style = {...props.style}
  }
  let maxWidth =
    "fs" === props.size ? (
      "100%"
    ) : "xl" === props.size ? (
      "2048px"
    ) : "lg" === props.size ? (
      "1024px"
    ) : "md" === props.size ? (
      "800px"
    ) : "sm" === props.size ? (
      "600px"
    ) : props.size ? (
      props.size
    ) : (
      null
    )
  if (maxWidth) {
    style = {...style, maxWidth: maxWidth}
  }
  /*
<img src={props.logoImage} height={getSize(props.logoWidth,1)} height={getSize(props.logoHeight,1)}/>
*/

  return (
      <div className={styles["dialog"]} style={style} data-size={props.size} data-align={props.align}>
        {
          Header &&
            <div className={`${styles["header"]} ${Sider ? styles["responsive"] : ""}`}>
              <Header/>
            </div>
        }
        <div className={styles["body"]}>
          <>
            {
              Sider &&
                <Sider responsive={true}/>
            }
            {props.children}
          </>
        </div>
        {
          Footer &&
            <div className={styles["footer"]}>
              <Footer/>
            </div>
        }
      </div>
  )
}

export function PageDialogSidePanel(props) {
  return (
      <div className={`${styles["side-panel"]} ${props.responsive ? styles["responsive"] : ""} ${props.className}`} style={props.style}>
        <div className={styles["side-panel-body"]}>
          {props.children}
        </div>
      </div>
  )
}

export function PageDialogMainPanel(props) {
  const align = props.align || "center"
  return (
    <div className={styles["main-panel"] + ` ${props.className}`} style={props.style} data-align={align}>
      {
        props.title &&
          <h1>{props.title}</h1>
      }
      {
        props.subtitle &&
        <h2>{props.subtitle}</h2>
      }
      {
        props?.alert &&
          <div className="mt-3 mb-3">
            <Alert alert={props.alert}/>
          </div>
      }
      {props.children}
    </div>
  )
}

export function PageDialogTitle(props) {
  return (
    <>
      {
        props.title &&
            <h1>{props.title}</h1>
      }
      {
        props.alert &&
          <div className="mt-3 mb-3">
            <Alert alert={props.alert}/>
          </div>
      }
    </>
  )
}

function getSize(size, i) {
  if (!size || size === "auto") {
    return "auto"
  }
  const sizes = size.split("|")
  if (sizes.length && sizes.length > 1 && i < sizes.length) {
    return sizes[i]
  }
  else
  {
    return size
  }
}

