import React  from 'react'

export function TagBox(props) {
  return (
    <div className={"tag-box" + (props.className ? ` ${props.className}`: "")}>
      {props.children}
    </div>
  )
}

export function TagLabel(props) {
  const size = (props.size ? props.size : "md")
  return (
    (props.label || props.children) &&
      <div className={`tag-label tag-label-${size}` + (props.className ? ` ${props.className}` : "")} title={props.tooltip || props.title}>
        {
          props.label ? (
            <div>
              {props.label}
            </div>
          ) : (
            <></>
          )
        }
        {
          props.children ? (
            props.children
          ) : (
            <></>
          )
        }
      </div>
  )
}
