import React, { Fragment, useState, useEffect, useRef }  from 'react'
import {useLocation, useParams} from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import { useInfiniteQuery, useQueryClient } from 'react-query'
import { AsyncPaginate } from 'react-select-async-paginate'
import useIntersectionObserver from './useIntersectionObserver.js'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import * as objectSupport from 'dayjs/plugin/objectSupport'
import {useSolv, useTenant} from "./components/SolvProvider"
import { useIntlEx } from "./components/IntlUtils.js"
import useQueryString from 'use-query-string';
import MainMenu from "./MainMenu.js"
import {FormHeader, FormBody, MainContainer} from "./components/FormComps.js"
import SortableColumn from "./components/SortableColumn"
import { openDialog } from "./components/DialogUtils"
import {AccountTabs} from "./components/AccountUtils";
import ExportToFileDialog from "./components/ExportToFileDialog";
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("Responses")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(objectSupport)

const useRefState = initialValue => {
  const [state, setState] = useState(initialValue)
  const stateRef = useRef(state)
  useEffect(
    () => { stateRef.current = state },
    [state]
  )
  return [state, stateRef, setState]
}

export default function Responses(props) {

  const {api, session} = useSolv()

  const {intl} = useIntlEx()

  const location = useLocation()
  const params = useParams()

  const [queryParams, setQueryParams] = useQueryString(location)

  const {tenant} = useTenant(params.tenantId)

  const [working, setWorking] = useState(null)
  const [error, setError] = useState(null)
  const [touched, setTouched] = useState(null)

  const [broadcasts, setBroadcasts] = useState(null)

  const [selectedFeedback, setSelectedFeedback] = useState()

  const [search, setSearch] = useState(null)
  const [sortSpec, setSortSpec] = useState({field: "broadcastName", direction: "ASC"})
  const [refreshId, setRefreshId] = useState(Math.random())

  const queryClient = useQueryClient()

  useEffect(() => {
    log.debug("useEffect: queryParams=", queryParams)
    if (queryParams.broadcasts) {
      loadBroadcasts(queryParams.broadcasts)
          .then(function(data) {
            handleBroadcastsChange(data.options)
          })
    }
  }, [queryParams])

  function loadBroadcasts(search, loadedOptions, additional) { //, loadedOptions, { cursor }) {

    return api.listBroadcasts(tenant.tenantId, search, sortSpec, additional?.cursor, 50)
      .then(({data, nextCursor}) => {
        let opt = data.map(c => {
          return {
            label: c.broadcastName,
            value: c.broadcastId
          }

        })
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug("loadBroadcasts: res=", res)
        return res
      })
    .catch((error) => {
      log.debug("Error loading broadcasts: error=", error)
    })
  }

  function handleBroadcastsChange(v) {
    log.debug("handleBroadcastsChange: v=", v)
    setBroadcasts(v)
  }

  useEffect(
    () => { 
      if (queryParams.broadcast) {
        api.getBroadcast(tenant.tenantId, queryParams.broadcast)
          .then(({data}) => {
            setBroadcasts([{value: data.broadcastId, label: data.broadcastName}])
          })
          .catch((error) => {
            log.debug("Error loading broadcast", error)
          })
      }
    },
    []
  )

  const fetchFeedbacks = async ({ pageParam = null }) => {
    return await api.listResponses(tenant.tenantId, broadcasts, search, sortSpec, pageParam, 50)
  }

  const {
    status,
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(["responses", { search, sortSpec, broadcasts, refreshId }], fetchFeedbacks, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextCursor
    },
    enabled: !!tenant
  })  
  
  const loadMoreButtonRef = React.useRef()  
  
  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  })        

  function doRefresh() {
    queryClient.invalidateQueries("responses")
    setRefreshId(Math.random())
  }

  async function markRead(feedback) {
    if (feedback && feedback.feedbackId && !feedback.readOn) {
      log.debug("Marking read: feedback=", feedback)
      const { data } = await api.markResponseAsRead(tenant.tenantId, feedback.feedbackId)
      log.debug("data=", data)
      feedback.readOn = data
    }
  }
  
  function handleRowClick(feedback) {
    log.debug("handleRowClick: feedback=", feedback)
    markRead(feedback)
    setSelectedFeedback(feedback)
  }

  async function doExport() {

    const payload = {}
    if (broadcasts && broadcasts.length > 0) {
      payload.broadcastIds = broadcasts.map((o) => o.value)
    }

    log.debug("doExport: payload=", payload)

    return await api.exportResponses(tenant.tenantId, payload)
  }

  return (

    tenant &&
    <>

      <MainContainer tenant={tenant} menu={MainMenu}>

        <FormHeader>
          <AccountTabs tenant={tenant}/>
          <FormHeader.Toolbar>          
            <FormHeader.Toolbar.Title>
              {intl.msg("responses")}
            </FormHeader.Toolbar.Title>
            <FormHeader.Toolbar.Controls>
              <div style={{minWidth: "200px", maxWidth: "600px"}}>
                <AsyncPaginate
                    id="inp_broadcasts"
                    className="react-select react-select__absolute"
                    classNamePrefix="react-select"
                    cacheOptions
                    isMulti
                    isClearable
                    value={broadcasts}
                    placeholder={intl.msg("responses_select_broadcasts")}
                    debounceTimeout={800}
                    defaultOptions={true}
                    loadOptions={loadBroadcasts}
                    onChange={handleBroadcastsChange}
                    isDisabled={false}
                />
              </div>
              <button className="btn btn-secondary" type="button" title={intl.msg("tooltip_refresh_list")} onClick={doRefresh}>
                <i className="fas fa-redo"></i>
              </button>
              <button className="btn btn-secondary" type="button" title={intl.msg("responses_export_tooltip")} onClick={() => openDialog("dlg_responses_export")}>
                <i className="fas fa-download"></i>
              </button>
            </FormHeader.Toolbar.Controls>
          </FormHeader.Toolbar>
        </FormHeader>

        <FormBody>

          <div className="form-card">

            <div className="row">
              <div className="col-5">
                <table className="table feedback-table table-striped table-bordered" style={{marginTop: "10px"}}>
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col" className="col-clickable">
                        <SortableColumn label={intl.msg("submitted_on")} field="createdOn" sortSpec={sortSpec} setSortSpec={setSortSpec}/>
                      </th>
                      <th scope="col" className="col-clickable">
                        <SortableColumn label={intl.msg("broadcast")} field="broadcastName" sortSpec={sortSpec} setSortSpec={setSortSpec}/>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    status === 'loading' ? (
                      <tr>
                        <td colSpan="3"><p>{intl.msg("loading")}</p></td>
                      </tr>
                    ) : status === 'error' ? (
                      <tr>
                        <td colSpan="3"><p>Error</p></td>
                      </tr>
                    ) : data ? (
                        data.pages.map((group, i) => (
                          <React.Fragment key={i}>
                            {group.data.map(feedback => (
                              <tr className={feedback.readOn ? "" : "unread"} key={feedback.feedbackId} onClick={() => handleRowClick(feedback)}>
                                <td>
                                  <a href="#" className="display-only" title={dayjs(feedback.createdOn).format("YYYY-MM-DD hh:mm:ss A")}> 
                                    {dayjs(feedback.createdOn).fromNow()}
                                  </a>
                                </td>
                                <td>
                                  {feedback.broadcastName}
                                </td>
                              </tr>                          
                            ))}
                          </React.Fragment>
                        ))
                    ) : ""
                  }
                  </tbody>
                </table>
                <div>
                  { 
                    hasNextPage ?
                      <button
                        className="btn btn-secondary"
                        ref={loadMoreButtonRef}
                        onClick={() => fetchNextPage()}
                        disabled={!hasNextPage || isFetchingNextPage}>
                        { 
                          isFetchingNextPage
                            ? <span>{intl.msg("loading")}</span>
                            : <span>{intl.msg("show_more")}</span>
                        }
                      </button>
                      : ""
                  }
                </div>
                <div>
                  {isFetching && !isFetchingNextPage ? intl.msg("refreshing") : null}
                </div>              
              </div>
              <div className="col-7">
                <div style={{position: "sticky", "top": "110px"}}>
                  <div style={{border: "1px solid #555", marginTop: "10px", padding: "20px", backgroundColor: "var(--form-background-color)"}}>
                    <div className="live-preview-section-divider">
                      {intl.msg("response_details")}
                    </div>
                    {
                      selectedFeedback && 
                        <Fragment>
                          <div className="row">
                            <div className="col-12">
                              <label className="mb-0" style={{color: "var(--form-control-label-color)", fontWeight: "400"}}>
                                {intl.msg("submitted_on")}:
                              </label>
                              <div className="mb-2">{dayjs(selectedFeedback.createdOn).format("YYYY-MM-DD hh:mm:ss A")}</div>
                            </div>
                          </div>

                          {
                            selectedFeedback.feedbackForm && selectedFeedback.feedbackForm.fields && selectedFeedback.feedbackForm.fields.map( field => 
                              field.input !== "READONLY" &&
                                <div className="row">
                                  <div className="col-12 mb-2">
                                    <label className="mb-0" style={{color: "var(--form-control-label-color)", fontWeight: "400"}}>{field.label}:</label>
                                    <div>{field.value}</div>
                                  </div>
                                </div>
                            )
                          }

                        </Fragment>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FormBody>
        
      </MainContainer>

      <ExportToFileDialog
          type="responses"
          fileName={`solv-responses-${intl.date(new Date(), "ISO-COMPACT")}`}
          onExport={doExport}
      />

    </>
  )
}