import React, {useEffect, useState} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {ActionButton, FormGroup, FormGroups, FormText, useAlert} from "./components/FormComps"
import { useIntlEx } from "./components/IntlUtils.js"
import {useSolv} from "./components/SolvProvider";
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {mkHref} from "./components/AccountUtils";
import NumericInput from "react-numeric-input";
import {useCreditTransactionTypeOpts} from "./components/CreditTransactionUtils";
import {default as Select} from "react-select";
import getLogger from "./components/Logging.js"
import './App.css'
import {InfoBlock, InfoText} from "./components/ValidationUtils";

const log = getLogger("CreditsAdjustDialog")

export default function CreditsAdjustDialog(props)  {

  const { api, setBusy } = useSolv()
  const { intl } = useIntlEx()

  const [alert, setAlert] = useAlert()

  const {creditTransactionTypeOpts, findCreditTransactionTypeOpt} = useCreditTransactionTypeOpts(c => {
    switch (c.value) {
      case "BONUS":
        return ({...c, sign: "+"})
      case "CORRECTION":
        return ({...c, sign: "-"})
      case "DEDUCTION":
        return ({...c, sign: "-"})
      case "PENALITY":
        return ({...c, sign: "-"})
      case "PURCHASE":
        return ({...c, label: intl.msg("credit_transaction_type_purchase_pending"), sign: "+"})
      case "REWARD":
        return ({...c, sign: "+"})
      case "SERVICE":
        return ({...c, sign: "-"})
      default:
        return null
    }
  })

  const [credits, setCredits] = useState(0.00)
  const [description, setDescription] = useState(null)
  const [creditTransactionType, setCreditTransactionType] = useState(findCreditTransactionTypeOpt("BONUS"))
  const [touched, setTouched] = useState(null)

  function handleAdjustClick() {
    let c = parseFloat(`${creditTransactionType.sign || "+" }${credits}`)
    setBusy(intl.msg("working"))
    const payload = {
      tenantId: props.tenant.tenantId,
      creditTransactionType: creditTransactionType.value,
      paymentMethod: (creditTransactionType.value !== "PURCHASE" ? "CREDITS" : "BANK"),
      credits: c,
      description: (creditTransactionType.value !== "PURCHASE" ? description : intl.msg("credit_transaction_description_register_purchase_credits")),
      details: {},
    }
    log.debug(">>>Z: handleCreditTransactionTypeChange: payload=", payload)
    api.adjustCreditTransaction(props.tenant.tenantId, payload)
      .then((data) => {
        window.location = mkHref({suffix: `/credits`, tenant: props.tenant})
      })
      .catch((error) => {
        handleError(error)
      })
      .finally(() => {
        setBusy(null)
      })
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
      switch (code) {
        case "DUPLICATE_KEY":
          setAlert({error: intl.msg("credit_transaction_reverse_error_duplicate")})
          break
        case "INSUFFICIENT_FUNDS":
          setAlert({error: intl.msg("credit_transaction_reverse_error_insufficient_funds")})
          break
        default:
          setAlert({error: intl.msg("error_failed")})
          break
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function handleCreditTransactionTypeChange(v) {
    setCreditTransactionType(v)
    setTouched(true)
  }

  function handleCreditsChange(v) {
    setCredits(v)
    setTouched(true)
  }

  function handleDescriptionChange(e) {
    setDescription(e.target.value)
    setTouched(true)
  }

  function handleOpenDialog() {
    setAlert(null)
  }

  function handleCloseDialog() {
    closeDialog("dlg_credits_adjust")
  }


  return (

    props.tenant &&
      <ModalDialog
        dialogId="dlg_credits_adjust"
        size="md" keyboard={"true"} backdrop={props.backdrop || "static"}
        onOpen={handleOpenDialog}
        onClose={handleCloseDialog}
      >

        <ModalDialog.Header
          title={intl.msg("credit_transaction_adjust_title")}
          description={intl.msg("credit_transaction_adjust_subtitle")}
          alert={alert}
        />

        <ModalDialog.Body>

          <FormText className="p-0">
            {intl.msg("credit_transaction_adjust_text")}
          </FormText>

          <FormGroups>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_type" text={intl.msg("credit_transaction_adjust_type")} description={intl.msg("credit_transaction_adjust_type_description")}/>
              <FormGroup.Controls>
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  value={creditTransactionType}
                  options={creditTransactionTypeOpts}
                  // menuPortalTarget={document.body}
                  onChange={handleCreditTransactionTypeChange}
                />
                <InfoBlock>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_credits" text={intl.msg("credit_transaction_adjust_credits")} description={intl.msg("credit_transaction_adjust_credits_description")}/>
              <FormGroup.Controls>
                <div style={{display: 'flex', alignItems: "center", gap: "0.5em"}}>
                  <div>{creditTransactionType?.sign || "+"}</div>
                  <div style={{width: "120px"}}>
                    <NumericInput id="inp_credits" className="form-control" style={false} strict={true} min={+0.00} precision={2} disabled={false} value={credits} onChange={handleCreditsChange}/>
                  </div>
                  <div>{props.currencyCode ? props.currencyCode : ""}</div>
                </div>
                <InfoBlock>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            {
              creditTransactionType?.value !== "PURCHASE" &&
                <FormGroup>
                  <FormGroup.Label htmlFor="inp_description" text={intl.msg("credit_transaction_adjust_description")} description={intl.msg("credit_transaction_adjust_description_description")}/>
                  <FormGroup.Controls>
                    <input id="inp_description" name="description" type="text" className="form-control" value={description} disabled={false} onChange={handleDescriptionChange} autoComplete="no"/>
                    <InfoBlock>
                      <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Controls>
                </FormGroup>
            }

          </FormGroups>

        </ModalDialog.Body>

        <ModalDialog.Footer>
          <ModalDialog.Footer.Controls>
            <ActionButton name="cancel" onClick={handleCloseDialog}/>
            <ActionButton msg="adjust" icon="ok" flavor="primary" disabled={alert || !credits} onClick={handleAdjustClick}/>
          </ModalDialog.Footer.Controls>
        </ModalDialog.Footer>

      </ModalDialog>
  )
}