import React, { Fragment, useState, useEffect }  from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { useSolv } from "./components/SolvProvider";
import { useIntlEx } from "./components/IntlUtils.js"
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {mkAccountDocUrl} from "./components/AccountUtils";
import {Alert} from "./components/FormComps";
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("TemplateDeleteDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function TemplateDeleteDialog(props)  {

  const {api, session, setBusy, setFatal} = useSolv()
  const {intl } = useIntlEx()

  const [tenant, setTenant] = useState(null)
  const [broadcastTemplateId, setBroadcastTemplateId] = useState(null)

  const [alert, setAlert] = useState()

  useEffect(() => {
    if (props) {
      if (props.tenant) {
        setTenant(props.tenant)
      }
      if (props.broadcastTemplateId) {
        setBroadcastTemplateId(props.broadcastTemplateId)
      }
    }
  }, [props])

  function handleDeleteClick() {
    setBusy(intl.msg("deleting"))
    api.deleteBroadcastTemplate(tenant.tenantId, broadcastTemplateId)
      .then(({data}) => {
        log.debug("Delete broadcast Template2: data=", data)
        const url = mkAccountDocUrl(session.user, tenant, "templates")
        log.debug("Delete broadcast Template2: data=, url=", data, url)
        window.location = url
      })
      .catch((error) => {
        handleError(error)
      })
      .finally(() => {
        setBusy(null)
      })
  }

  function handleError(error) {
    if (error) {
      if (error.code) {
        switch (error.code) {
          case "BUSY_BROADCAST_TEMPLATE":
            setAlert({error: intl.msg("template_error_in_use")})
            break
          default:
            setAlert({error: intl.msg("error_failed")})
            break
        }
      }
      else {
        setAlert({error: intl.msg("error_failed")})
      }
    }
  }

  function handleOpenDialog() {
    setAlert({warning: intl.msg("template_delete_text_1", {templateName: props.broadcastTemplateName})})
    setBusy(null)
  }

  function handleCloseDialog() {
    closeDialog("dlg_template_delete")
  }

  return (

    <ModalDialog
      dialogId="dlg_template_delete"
      header={<h1>{intl.msg("template_delete_title")}</h1>}
      footer={
        <>
          <button className="btn btn-secondary" onClick={handleCloseDialog}>{intl.msg("cancel")}</button>
          <button type="button" className="btn btn-primary" onClick={handleDeleteClick}>{intl.msg("delete")}</button>
        </>
      }
      size="md"
      keyboard={"true"}
      backdrop={props.backdrop || "static"}
      onOpen={handleOpenDialog}
      onClose={handleCloseDialog}>
      <center>
        <div className="">
          <Alert alert={alert}/>
        </div>
      </center>
    </ModalDialog>
  )
}