import React, {Fragment, useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {useSolv} from "../components/SolvProvider"
import MainMenu from "../MainMenu.js"
import {FormHeader, FormBody, MainContainer, useAlert, ActionButton} from "../components/FormComps.js"
import {useIntlEx} from "../components/IntlUtils"
import {DataTable, useCols} from "../components/TableComps"
import ExportToFileDialog from "../components/ExportToFileDialog";
import {HumanTimestamp} from "../components/DateUtils";
import getLogger from "../components/Logging.js"
import '../App.css'

const log = getLogger("Versions")

export default function Versions() {

  const {api, session: {user, tenant}, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const [alert, setAlert] = useAlert(null)

  const versionOpts = [
    {
      value: "DRAFT",
      label: "DRAFT",
    },
    {
      value: "RELEASED",
      label: "RELEASED",
    },
  ]

  const {cols} = useCols({
    resourceName: "versions",
    specs: [
      {
        field: "versionCode",
        label: intl.msg("version_code"),
        filter: "text",
        sort: "ASC",
        align: "left",
      },
      {
        field: "status",
        label: intl.msg("status"),
        filter: {
          type: "select",
          multi: true,
          options: () => versionOpts
        },
        sort: "",
        align: "left",
      },
      {
        field: "touchedOn",
        label: intl.msg("last_updated"),
        filter: "select:reportDateRange",
        sort: "DESC",
        align: "left",
      },
    ],
  })

  function handleFetchDataPage({pageParam: cursor}) {
    return api.listVersions({extraParams: cols.stringify(), cursor: cursor})
  }

  function handleRenderDataCol(col, row) {
    let n
    switch (col.field) {
      case "versionCode":
        n = row.versionCode
        break
      case "status":
        n = row.status
        break
      case "touchedOn":
        n = <HumanTimestamp timestamp={row.touchedOn} else={intl.msg("never")}/>
        break
    }
    return (
        n
    )
  }

  function handleRowClick(row) {
    window.location = `/sys/versions/${row.versionId}`
  }

  function handleError(error) {
    if (error) {
      log.debug("handleError: error=", JSON.stringify(error))
      if (error.code) {
        switch (error.code) {
          case "BAD_PAYLOAD":
            if (error.msg) {
              setAlert({error: error.msg})
            }
            else {
              setAlert({error: intl.msg("error_failed")})
            }
            break
          default:
            setAlert({error: intl.msg("error_failed")})
        }
      }
      else {
        setAlert({error: intl.msg("error_failed")})
      }
    }
  }

  return (
    tenant &&
      <>

        <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={"*/*/SYSTEM"}>

          <FormHeader>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("versions")}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                {
                  user.isSystem() && user.isSuperAdminOrAbove() &&
                    <ActionButton name="add_new" flavor="primary"  href={`/sys/versions/_new`}/>
                }

              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>
          <FormBody>
            <DataTable
              tenant={tenant}
                cols={cols}
                resourceName="versions"
                dataKey="versionId"
                onFetchDataPage={handleFetchDataPage}
                onRenderDataCol={handleRenderDataCol}
                onClickDataRow={handleRowClick}
            />
          </FormBody>
        </MainContainer>

      </>
  )
}