import React, {createContext, useState, useEffect, useCallback} from "react"
import axios from "axios"
import axiosRetry from "axios-retry"
import { v4 as uuidv4 } from 'uuid'
import useStateRef from "react-usestateref"
import getLogger from "./Logging"

const log = getLogger("SolvProvider")

// const SESSION_COOKIE_NAME = "SOLV-SESS"

const SolvContext = createContext([])

export const useSolv = () => React.useContext(SolvContext)

export const OWNER_USER_LEVEL = 100
export const SUPERADMIN_USER_LEVEL = 95
export const ADMIN_USER_LEVEL = 90
export const SUPERVISOR_USER_LEVEL = 20
export const BASIC_USER_LEVEL = 10
export const RESTRICTED_USER_LEVEL = 1
export const NO_ACCESS_USER_LEVEL = 0

export const SYSTEM_USER_TYPE = "SYSTEM"
export const GOODWAY_USER_TYPE = "GOODWAY"
export const RESELLER_USER_TYPE = "RESELLER"
export const CLIENT_USER_TYPE = "CLIENT"
export const TRIAL_USER_TYPE = "TRIAL"

export class SolvError extends Error {
  constructor(error) {
    super(error.msg || error.message)
    this.code = error.code
    this.msg = error.msg || error.message
    if (error.details) {
      this.details = error.details
    }
  }
}

const brandId = initBrand()
const env = initEnv(brandId)
const authApiHttp = initApiHttp(brandId, true)
const unauthApiHttp = initApiHttp(brandId, false)
const publicHttp = initPublicHttp()

// log.debug("env=", env)

function initBrand() {
  let brandId = detectBrand()
  // log.debug("initBrand: brandId=", brandId)
  return brandId
}

function detectBrand() {
  let brandId = "SOLV"
  if (window.location) {
    const host = window.location.host
    // log.debug("useEffect: host=", host)
    if (host.startsWith("localhost") || host.startsWith("127.0.0.1")) {
      const port = window.location.port
      // log.debug("useEffect: port=", port)
      switch (port) {
        case "3001":
          brandId = "PETSOLV"
          break
        case "3002":
          brandId = "MEDIASOLV"
          break
        default:
          brandId = "SOLV"
          break
      }
    }
    else if (host.endsWith(".solv.technology") || host.endsWith(".solv-technology.com") || host.endsWith(".solv-manager.com")) {
      const p = host.split(".")
      if (p && p.length >= 3) {
        switch (p[0]) {
          case "app":
          case "console":
          case "platform":
            brandId = "SOLV"
            break
          case "pet":
            brandId = "PETSOLV"
            break
          default:
            brandId = "SOLV"
            break
        }
      }
    }
    else if (host.endsWith(".petsolv.com")) {
      brandId = "PETSOLV"
    }
    else if (host.endsWith(".solvmedia.com")) {
      brandId = "MEDIASOLV"
    }
    else {
      brandId = "SOLV"
    }
  }
  return brandId
}

function initEnv(brandId) {
  let env = {}
  env.STAGE = parseEnv(brandId, process.env.REACT_APP_STAGE)
  env.APP_NAME = parseEnv(brandId, process.env.REACT_APP_NAME)
  env.APP_VERSION = parseEnv(brandId, process.env.REACT_APP_VERSION)
  env.BASE_URL = parseEnv(brandId, process.env.REACT_APP_BASE_URL)
  env.API_ENDPOINT = parseEnv(brandId, process.env.REACT_APP_API_ENDPOINT)
  env.DEMO_ENDPOINT = parseEnv(brandId, process.env.REACT_APP_DEMO_ENDPOINT)
  env.DASSETS_ENDPOINT = parseEnv(brandId, process.env.REACT_APP_DASSETS_ENDPOINT)
  env.UASSETS_ENDPOINT = parseEnv(brandId, process.env.REACT_APP_UASSETS_ENDPOINT)
  env.MEDUSA_ENDPOINT = parseEnv(brandId, process.env.REACT_APP_MEDUSA_ENDPOINT)
  env.HELP_ENDPOINT = parseEnv(brandId, process.env.REACT_APP_HELP_ENDPOINT)
  env.WHATSNEW_RESOURCES_ENDPOINT = parseEnv(brandId, process.env.REACT_APP_WHATSNEW_RESOURCES_ENDPOINT)
  env.GOOGLE_CLIENT_ID = parseEnv(brandId, process.env.REACT_APP_GOOGLE_CLIENT_ID)
  env.GOOGLE_RECAPTCHA_SECRET = parseEnv(brandId, process.env.REACT_APP_GOOGLE_RECAPTCHA_SECRET)
  env.MICROSOFT_CLIENT_ID = parseEnv(brandId, process.env.REACT_APP_MICROSOFT_CLIENT_ID)
  env.MICROSOFT_TENANT_ID = parseEnv(brandId, process.env.REACT_APP_MICROSOFT_TENANT_ID)
  env.LINKEDIN_CLIENT_ID = parseEnv(brandId, process.env.REACT_APP_LINKEDIN_CLIENT_ID)
  env.JWT_SECRET = parseEnv(brandId, process.env.REACT_APP_JWT_SECRET)
  env.PAYPAL_CLIENT_ID = parseEnv(brandId, process.env.REACT_APP_PAYPAL_CLIENT_ID)
  env.PAYPAL_LIVE_CLIENT_ID = parseEnv(brandId, process.env.REACT_APP_PAYPAL_LIVE_CLIENT_ID)
  env.PAYPAL_TEST_CLIENT_ID = parseEnv(brandId, process.env.REACT_APP_PAYPAL_TEST_CLIENT_ID)
  env.SESSION_COOKIE_NAME = parseEnv(brandId, process.env.REACT_APP_SESSION_COOKIE_NAME)
  env.DEFAULT_INDUSTRY_ID = parseEnv(brandId, process.env.REACT_APP_DEFAULT_INDUSTRY_ID)
  // log.debug("env=", env)
  return env
}

function parseEnv(brandId, value) {
  if (value) {
    // log.debug("parseEnv: value=", value)
    const parts = new URLSearchParams(value)
    if (parts.has(brandId)) {
      const v = parts.get(brandId)
      // log.debug("parseEnv: result=", v)
      return v
    }
    else {
      const v = parts.keys().next().value
      // log.debug("parseEnv: result=", v)
      return v
    }
  }
  else {
    // log.debug("parseEnv: result=", value)
    return value
  }
}

function initApiHttp(brandId, checkAuth) {

  // log.debug("initApiHttp: API_ENDPOINT=, auth", env.API_ENDPOINT, checkAuth)

  let http = axios.create({
    baseURL: env.API_ENDPOINT,
    timeout: 30000,
    withCredentials: true
  })

  axiosRetry(http, { retryDelay: axiosRetry.exponentialDelay})

  http.interceptors.request.use(function (config) {
    config.headers["X-SOLV-Brand-Id"] = brandId
    config.headers["X-Requested-With"] = "XMLHttpRequest"
    config.headers["X-Client-Version"] = `${brandId}/2023`
    return config;
  });

  http.interceptors.response.use(
      function(response) {
        const data = response.data
        if (data && data.error) {
          const error = data.error
          if (checkAuth && error.code === "UNAUTHORIZED") {
            window.location = "/signin"
          }
          else if (error.code === "UNAVAILABLE") {
            window.location = "/unavailable"
          }
        }
        return response;
      },
      function(error) {
        // log.debug("axios error", error, error.response)
        if (error.response && error.response.status === 503) {
          window.location = "/unavailable"
        }
        return Promise.reject(error);
      }
  )

  return http
}

function initUnauthApiHttp(brandId) {

  // log.debug("initUnauthApiHtt: API_ENDPOINT=", env.API_ENDPOINT)

  let http = axios.create({
    baseURL: env.API_ENDPOINT,
    timeout: 30000,
    withCredentials: true
  })

  axiosRetry(http, { retryDelay: axiosRetry.exponentialDelay})

  http.interceptors.request.use(function (config) {
    config.headers["X-SOLV-Brand-Id"] = brandId
    config.headers["X-Requested-With"] = "XMLHttpRequest"
    config.headers["X-Client-Version"] = `${brandId}/2023`
    return config;
  });

  http.interceptors.response.use(
    function(response) {
      const data = response.data
      if (data && data.error) {
        const error = data.error
        if (error.code === "UNAVAILABLE") {
          window.location = "/unavailable"
        }
      }
      return response;
    },
    function(error) {
      // log.debug("axios error", error, error.response)
      if (error.response && error.response.status === 503) {
        window.location = "/unavailable"
      }
      return Promise.reject(error);
    }
  )

  return http
}

function initPublicHttp() {

  // log.debug("initPublicHttp")

  let publicHttp = axios.create({
    timeout: 30000,
    withCredentials: true
  })

  axiosRetry(publicHttp, { retryDelay: axiosRetry.exponentialDelay})

  return publicHttp
}

export const SolvProvider = ({children}) => {

  const [loading, setLoading] = useState(true)
  const [session, setSession, sessionRef] = useStateRef(null)
  const [busy, setBusy] = useState(null)
  const [authToken, setAuthToken, authTokenRef] = useStateRef()
  const [fatal, setFatal] = useState(null)

  const [busyQueue, setBusyQueue] = useState([]);
  // const setBusy = useCallback((state) => {
  //   setBusyQueue((prevQueue) => {
  //     if (state !== null) {
  //       return [...prevQueue, state];
  //     }
  //     else {
  //       const updatedQueue = [...prevQueue];
  //       updatedQueue.pop();
  //       return updatedQueue;
  //     }
  //   });
  // }, []);
  //
  // const busy = busyQueue.length > 0 ? busyQueue[busyQueue.length - 1] : null;

  function mkListQueryString(search, sortSpec, cursor, limit = 20, view) {
    if (!limit) {
      limit = 20
    }
    let url = `limit=${limit}`
    if (sortSpec) {
      if (typeof(sortSpec) === "object") {
        sortSpec = `${sortSpec.field.trim()}:${sortSpec.direction}`
      }
      url = url + `&sort=${sortSpec.trim()}`
    }
    if (cursor) {
      url = url + `&cursor=${cursor}`
    }
    if (search) {
      url = url + `&search=${encodeURIComponent(search.trim())}`
    }
    if (view) {
      url = url + `&view=${view}`
    }
    // log.debug(">>>Z: mkListQueryString: url=", url)
    return url
  }

  function mkListQueryString2({extraParams, cursor, limit = 20, view, ...rest}) {
    if (!limit) {
      limit = 20
    }
    let q = `limit=${limit}`
    if (extraParams) {
      q = q + `&${extraParams.trim()}`
    }
    if (cursor) {
      q = q + `&cursor=${cursor}`
    }
    if (view) {
      q = q + `&view=${view}`
    }
    if (Object.keys(rest).length > 0) {
      for (let key in rest) {
        if (rest.hasOwnProperty(key) && rest[key]) {
          q = q + `&${key}=${encodeURIComponent(rest[key])}`
        }
      }
    }
    log.debug("mkListQueryString2: q=", q)
    return q
  }

  const api = {
    get: async(url, opt) => {
      return callApi("get", url, null, opt)
    },
    post: async(url, payload, opt) => {
      return callApi("post", url, payload, opt)
    },
    put: async(url, payload, opt) => {
      return callApi("put", url, payload, opt)
    },
    delete: async(url, opt) => {
      return callApi("delete", url, null, opt)
    },
    getSessionInfo: async() => {
      return callApi("get", `/v1/auth/`)
    },
    getTenantInfo: async(tenantId) => {
      return callApi("get", `/v1/auth/tenants/${tenantId}`)
    },
    updateTenant: async(tenantId, payload) => {
      return callApi("put", `/v1/tenants/${tenantId}`, payload)
    },
    deleteTenant: async(tenantId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/_delete`, payload)
    },
    purchaseTenantPlan: async (payload) => {
      return callApi("post", `/v1/tenants/_purchasePlan`, payload)
    },
    getRegion: async (regionId, view) => {
      return callApi("get", `/v1/regions/${regionId}${view ? `view=${view}` : ""}`)
    },
    listRepresentedRegions: async(industryId, search, cursor) => {
      return callApi("get", `/regions/representives/?industryId=${industryId}&limit=20` + (search ? `&search=${search}` : "") + (cursor ? `&cursor=${cursor}` : ""))
    },
    insertRegion: async(payload) => {
      return callApi("post", `/v1/regions/`, payload)
    },
    updateRegion: async(regionId, payload) => {
      return callApi("put", `/v1/regions/${regionId}`, payload)
    },
    getBroadcast: async (tenantId, broadcastId) => {
      return callApi("get", `/v1/tenants/${tenantId}/broadcasts/${broadcastId}`)
    },
    createBroadcast: async (tenantId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/broadcasts/`, payload)
    },
    listBroadcasts: async ({tenantId, extraParams, cursor, view = "BASIC", limit = 50}) => {
      return callApi("get", `/v1/tenants/${tenantId}/broadcasts/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },
    updateBroadcast: async (tenantId, broadcastId, payload) => {
      return callApi("put", `/v1/tenants/${tenantId}/broadcasts/${broadcastId}`, payload)
    },
    deleteBroadcast: async (tenantId, broadcastId) => {
      return callApi("delete", `/v1/tenants/${tenantId}/broadcasts/${broadcastId}`)
    },
    publishBroadcast: async (tenantId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/broadcasts/_publish`, payload)
    },
    republishBroadcast: async (tenantId, broadcastId) => {
      return callApi("post", `/v1/tenants/${tenantId}/broadcasts/${broadcastId}/_republish`, null)
    },
    cloneBroadcast: async (tenantId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/broadcasts/_clone`, payload)
    },
    stopBroadcast: async (tenantId, broadcastId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/broadcasts/${broadcastId}/_stop`, payload)
    },
    exportBroadcast: async (tenantId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/broadcasts/${payload.broadcastId}/_export`, null, {responseType: 'blob'})
    },
    exportBroadcasts: async ({tenantId, extraParams, cursor, view = "BASIC", limit = 50}) => {
      return callApi("get", `/v1/tenants/${tenantId}/broadcasts/_export?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: -1})}`)
    },
    refundBroadcast: async (tenantId, broadcastId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/broadcasts/${broadcastId}/_refund`, payload)
    },
    getFirstBroadcast: async (tenantId) => {
      return callApi("get", `/v1/tenants/${tenantId}/broadcasts/_first`)
    },
    markBroadcastForDemo: async (tenantId, broadcastId) => {
      return callApi("post", `/v1/tenants/${tenantId}/broadcasts/${broadcastId}/_demo`)
    },

    // -- Credit APOs--
    listCreditTransactions: async ({tenantId, extraParams, view = "BASIC", cursor, limit = 50}) => {
      return callApi("get", `/v1/tenants/${tenantId}/credits/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },
    getCreditTransaction: async (tenantId, creditTransactionId) => {
      return callApi("get", `/v1/tenants/${tenantId}/credits/${creditTransactionId}`)
    },
    updateCreditTransaction: async (tenantId, creditTransactionId, payload) => {
      return callApi("put", `/v1/tenants/${tenantId}/credits/${creditTransactionId}`, payload)
    },
    purchaseCredits: async (tenantId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/credits/_purchase`, payload)
    },
    registerCreditTransaction: async (tenantId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/credits/_register`, payload)
    },
    settleCreditTransaction: async (tenantId, creditTransactionId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/credits/${creditTransactionId}/_settle`, payload)
    },
    deleteCreditTransaction: async (tenantId, creditTransactionId) => {
      return callApi("post", `/v1/tenants/${tenantId}/credits/${creditTransactionId}/_delete`, {})
    },
    adjustCreditTransaction: async (tenantId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/credits/_adjust`, payload)
    },
    reverseCreditTransaction: async (tenantId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/credits/_reverse`, payload)
    },
    extendCreditsExpiry: async (tenantId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/credits/_extendExpiry`, payload)
    },
    exportCreditTransactions: async ({tenantId, extraParams, view = "BASIC"}) => {
      return callApi("get", `/v1/tenants/${tenantId}/credits/_export?${mkListQueryString2({extraParams: extraParams})}`, null, {responseType: 'blob'})
    },

    listBroadcastTemplates: async (tenantId, search, view = "BASIC", sortSpec = "touchedOn:DESC", cursor, limit = 50) => {
      return callApi("get", `/v1/tenants/${tenantId}/broadcastTemplates/?${mkListQueryString(search, sortSpec, cursor, limit, view)}`)
    },
    getAllBroadcastTemplates: async (tenantId) => {
      return callApi("get", `/v1/tenants/${tenantId}/broadcastTemplates/`)
    },
    findOneBroadcastTemplate: async (tenantId) => {
      return callApi("get", `/v1/tenants/${tenantId}/broadcastTemplates/_one`)
    },
    getBroadcastTemplate: async (tenantId, broadcastTemplateId) => {
      return callApi("get", `/v1/tenants/${tenantId}/broadcastTemplates/${broadcastTemplateId}`)
    },
    getBroadcastTemplateDetails: async (tenantId, broadcastTemplateId) => {
      return callApi("get", `/v1/tenants/${tenantId}/broadcastTemplates/${broadcastTemplateId}/_details`)
    },
    listInstallableBroadcastTemplates: async (tenantId, search) => {
      return callApi("get", `/v1/tenants/${tenantId}/broadcastTemplates/_installables/?search=${search ? search.trim() : ""}`)
    },
    createBroadcastTemplate: async (tenantId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/broadcastTemplates/`, payload)
    },
    updateBroadcastTemplate: async (tenantId, broadcastTemplateId, payload) => {
      return callApi("put", `/v1/tenants/${tenantId}/broadcastTemplates/${broadcastTemplateId}`, payload)
    },
    cloneBroadcastTemplate: async (tenantId, broadcastTemplateId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/broadcastTemplates/${broadcastTemplateId}/_clone`, payload)
    },
    deleteBroadcastTemplate: async (tenantId, broadcastTemplateId) => {
      return callApi("delete", `/v1/tenants/${tenantId}/broadcastTemplates/${broadcastTemplateId}`)
    },
    setDefaultBroadcastTemplate: async (tenantId, broadcastTemplateId) => {
      return callApi("post", `/v1/tenants/${tenantId}/broadcastTemplates/${broadcastTemplateId}/_default`, {})
    },
    installBroadcastTemplates: async (tenantId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/broadcastTemplates/_install`, payload)
    },
    distributeBroadcastTemplate: async (tenantId, broadcastTemplateId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/broadcastTemplates/${broadcastTemplateId}/_distribute`, payload)
    },

    createPaymentTransaction: async (payload) => {
      return callApi("post", `/v1/payments/transactions/`, payload)
    },
    inviteClient: async (payload) => {
      return callApi("post", `/v1/clients/_invitation`, payload)
    },
    reinviteClient: async (payload) => {
      return callApi("put", `/v1/clients/_invitation`, payload)
    },
    inviteReseller: async (payload) => {
      return callApi("post", `/v1/resellers/_invitation`, payload)
    },
    reinviteReseller: async (payload) => {
      return callApi("put", `/v1/resellers/_invitation`, payload)
    },

    // -- User/Member APIs --

    listMembers: async ({tenantId, extraParams, view = "BASIC", cursor, limit = 50}) => {
      return callApi("get", `/v1/tenants/${tenantId}/users/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },
    inviteMember: async (tenantId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/users/_invitation`, payload)
    },
    reinviteMember: async (tenantId, userId) => {
      return callApi("put", `/v1/tenants/${tenantId}/users/${userId}/_invitation`)
    },
    deleteMember: async (tenantId, userId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/users/${userId}/_delete`, payload)
    },
    verifyChangeMemberEmail: async (payload) => {
      return callApi("post", `/v1/auth/_verifyChangeEmailAddress`, payload)
    },
    updateSignin: async (payload) => {
      return callApi("post", `/auth/_update`, payload)
    },
    deleteTenantInvitation: async (tenantId) => {
      return callApi("delete", `/v1/tenants/${tenantId}/_invitation`)
    },

    listClients: async ({tenantId, view = "BASIC", extraParams, cursor, limit = 50}) => {
      return callApi("get", `/v1/tenants/${tenantId}/clients/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },
    exportClients: async ({tenantId, extraParams, view = "BASIC"}) => {
      return callApi("get", `/v1/tenants/${tenantId}/clients/_export?${mkListQueryString2({extraParams: extraParams})}`, null, {responseType: 'blob'})
    },

    listResellers: async ({tenantId, view = "BASIC", extraParams, cursor, limit = 50}) => {
      return callApi("get", `/v1/tenants/${tenantId}/resellers/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },
    exportResellers: async ({tenantId, view = "BASIC", extraParams, cursor, limit = 50}) => {
      return callApi("get", `/v1/tenants/${tenantId}/resellers/_export?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },

    listParentResellerCandidates: async ({tenantId, view = "BASIC", extraParams, cursor, limit = 50}) => {
      return callApi("get", `/v1/tenants/${tenantId}/resellers/_parentCandidates/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },

    moveReseller: async (tenantId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/resellers/_move`, payload)
    },

    listRegions: async ({view = "BASIC", extraParams, cursor, limit = 50}) => {
      return callApi("get", `/v1/regions/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },

    getReferralCode: async (tenantId, referralCode) => {
      return callApi("get", `/v1/tenants/${tenantId}/referralCodes/${referralCode}`)
    },
    
    listReferralCodes: async (tenantId, search, sortSpec = "referralCode:ASC", cursor, limit = 50) => {
      return callApi("get", `/v1/tenants/${tenantId}/referralCodes/?${mkListQueryString(search, sortSpec, cursor, limit)}`)
    },

    insertReferralCode: async (tenantId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/referralCodes/`, payload)
    },

    updateReferralCode: async (tenantId, referralCode, payload) => {
      return callApi("put", `/v1/tenants/${tenantId}/referralCodes/${referralCode}`, payload)
    },

    getLocation: async (locationId) => {
      return callApi("get", `/v1/locations/${locationId}`)
    },

    listLocationsByRegion: async (regionId, search, view = "BASIC", sortSpec = "displayName:ASC", cursor, limit = 50) => {
      return callApi("get", `/v1/regions/${regionId}/locations/?${mkListQueryString(search, sortSpec, cursor, limit, view)}`)
    },
    listLocationsByCountry: async (countryCode, search, view = "BASIC", sortSpec = "displayName:ASC", cursor, limit = 50) => {
      return callApi("get", `/v1/countries/${countryCode}/locations/?${mkListQueryString(search, sortSpec, cursor, limit, view)}`)
    },
    listLocations: async ({view = "BASIC", extraParams, cursor, limit = 50}) => {
      return callApi("get", `/v1/locations/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },
    searchLocations: async (regionId, payload) => {
      return callApi("post", `/v1/regions/${regionId}/locations/_search`, payload)
    },
    updateLocation: async (locationId, payload) => {
      return callApi("put", `/v1/locations/${locationId}`, payload)
    },
    insertLocation: async (payload) => {
      return callApi("post", `/v1/locations/`, payload)
    },
    exportLocations: async ({extraParams}) => {
      return callApi("post", `/v1/locations/_export?${mkListQueryString2({extraParams: extraParams, cursor: null, limit: -1})}`)
    },

    listCountries: async ({view = "BASIC", extraParams, cursor, limit = 50}) => {
      return callApi("get", `/v1/countries/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },
    getCountry: async (countryCode) => {
      return callApi("get", `/v1/countries/${countryCode}`)
    },
    updateCountry: async (countryCode, payload) => {
      return callApi("put", `/v1/countries/${countryCode}`, payload)
    },
    insertCountry: async (payload) => {
      return callApi("post", `/v1/countries/`, payload)
    },

    listCurrencies: async ({view = "BASIC", extraParams, cursor, limit = 20}) => {
      return callApi("get", `/v1/currencies/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },
    getCurrency: async (currencyCode, view) => {
      return callApi("get", `/v1/currencies/${currencyCode}`)
    },
    updateCurrency: async (currencyCode, payload) => {
      return callApi("put", `/v1/currencies/${currencyCode}`, payload)
    },
    fetchCurrencies: async () => {
      return callApi("post", `/v1/currencies/_fetch`)
    },

    getTenantRecentActivities: async (tenantId) => {
      return callApi("get", `/v1/tenants/${tenantId}/_recentActivities`)
    },

    getOnboardingInfo: async () => {
      return callApi("get", "/v1/onboarding/")
    },
    updateOnboarded: async (payload) => {
      return callApi("post", `/v1/onboarding/`, payload)
    },
    listResponses: async (tenantId, broadcastIds, search, sortSpec, cursor, limit) => {
      if (broadcastIds && broadcastIds.length > 0) {
        // log.debug("listResponses: broadcastIds=", broadcastIds)
        broadcastIds = broadcastIds.map((i) => i.value)
      }
      else {
        broadcastIds = []
      }
      return callApi("get", `/v1/tenants/${tenantId}/feedbacks/?broadcastIds=${broadcastIds}&${mkListQueryString(search, sortSpec, cursor, limit)}`)
    },
    markResponseAsRead: async (tenantId, feedbackId) => {
      return callApi("put", `/v1/tenants/${tenantId}/feedbacks/${feedbackId}/_markRead`)
    },
    exportResponses: async (tenantId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/feedbacks/_export` + (payload && payload.broadcastIds ? payload.broadcastIds.join(",") : ""), null, {responseType: 'blob'})
    },
    exportBills: async (payload) => {
      return callApi("post", `/v1/bills/_export?startTime=${payload.startTime}&endTime=${payload.endTime}`, null, {responseType: 'blob'})
    },
    exportIncomes: async (payload) => {
      return callApi("post", `/v1/incomes/_export?startTime=${payload.startTime}&endTime=${payload.endTime}`, null, {responseType: 'blob'})
    },
    getUserProfile: async (tenantId, userId) => {
      return callApi("get", `/v1/tenants/${tenantId}/users/${userId}/_profile`)
    },
    updateUserProfile: async (tenantId, userId, payload) => {
      return callApi("put", `/v1/tenants/${tenantId}/users/${userId}/_profile`, payload)
    },
    moveUser: async (tenantId, userId, payload) => {
      return callApi("post", `/v1/tenants/${tenantId}/users/${userId}/_move`, payload)
    },
    // getUserSignupStatus: async (emailAddress) => {
    //   return callApi("get", `/v1/users/${emailAddress}/signUpStatus`)
    // },
    getSignupInfo: async (signupCode) => {
      return callApi("get", `/auth/signup/${signupCode}`)
    },

    listIndustries: async ({view = "BASIC", extraParams, cursor, limit = 50}) => {
      return callApi("get", `/v1/industries/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },
    getIndustry: async(industryId) => {
      return callApi("get", `/v1/industries/${industryId}`)
    },
    updateIndustry: async(industryId, payload) => {
      return callApi("put", `/v1/industries/${industryId}`, payload)
    },

    listIabCategories: async ({view = "BASIC", extraParams, cursor, limit = 50}) => {
      return callApi("get", `/v1/iabCategories/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },
    getIabCategory: async(iabCategoryId) => {
      return callApi("get", `/v1/iabCategories/${iabCategoryId}`)
    },

    listRepresentedIndustries: async ({brandId, lang = "en", view = "BASIC", extraParams, cursor, limit = 50}) => {
      return callApi("get", `/industries/represented/?brandId=${brandId}&lang=${lang}&${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },

    listIncomes: async ({tenantId, refTenantId, extraParams, view = "BASIC", cursor, limit = 50}) => {
      return callApi("get", `/v1/tenants/${tenantId}/incomes/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit, refTenantId: refTenantId})}`)
    },

    getTotalIncome: async({tenantId, startTime, endTime, refTenantId}) => {
      return callApi("get", `/v1/tenants/${tenantId}/incomes/_total?startTime=${startTime}&endTime=${endTime}${refTenantId ? `&refTenantId=${refTenantId}` : ""}`)
    },

    // -- Props APIs --

    getProps: async (propsId) => {
      return callApi("get", `/v1/props/${propsId}`)
    },
    listProps: async ({extraParams, cursor, limit = 50, view}) => {
      return callApi("get", `/v1/props/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },
    insertProps: async (payload) => {
      return callApi("post", `/v1/props/`, payload)
    },
    updateProps: async (propsId, payload) => {
      return callApi("put", `/v1/props/${propsId}`, payload)
    },
    deleteProps: async (propsId) => {
      return callApi("delete", `/v1/props/${propsId}`)
    },
    testProps: async (payload) => {
      return callApi("post", `/v1/props/_test`, payload)
    },

    listFlags: async ({extraParams, cursor, limit = 50, view}) => {
      return callApi("get", `/v1/flags/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },

    ping: async () => {
      return callApi("get", `/ping`)
    },
    createUuid: async () => {
      return callApi("post", `/v1/uuids/`)
    },
    createPresignedUrl: async (payload) => {
      return callApi("post", `/v1/aws/presignedUrls/`, payload)
    },
    geoIp: async () => {
      return callApi("get", `/geoip`)
    },
    listAmobeeBehaviorSegmentSets: async ({extraParams, view = "BASIC", cursor, limit = 50}) => {
      return callApi("get", `/v1/amobee/behaviorSegmentSets/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },
    getAmobeeBehaviorSegmentSet: async (behaviorSegmentSetId) => {
      return callApi("get", `/v1/amobee/behaviorSegmentSets/${behaviorSegmentSetId}`)
    },

    listBankAccounts: async ({view = "BASIC", extraParams, cursor, limit = 50}) => {
      return callApi("get", `/v1/bankAccounts/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },
    getBankAccountById: async (bankId) => {
      return callApi("get", `/v1/bankAccounts/${bankId}`)
    },
    getBankAccountsByRegionId: async (regionId) => {
      return callApi("get", `/v1/regions/${regionId}/bankAccounts/`)
    },
    updateBankAccount: async (bankAccountId, payload) => {
      return callApi("put", `/v1/bankAccounts/${bankAccountId}`, payload)
    },
    insertBankAccount: async (payload) => {
      return callApi("post", `/v1/bankAccounts/`, payload)
    },

    listVersions: async ({view = "BASIC", extraParams, cursor, limit = 50}) => {
      return callApi("get", `/v1/versions/?${mkListQueryString2({extraParams: extraParams, view: view, cursor: cursor, limit: limit})}`)
    },
    getVersion: async (versionCode) => {
      return callApi("get", `/v1/versions/${versionCode}`)
    },
    getLatestVersion: async () => {
      return callApi("get", `/v1/versions/_latest`)
    },
    updateVersion: async (versionId, payload) => {
      return callApi("put", `/v1/versions/${versionId}`, payload)
    },
    insertVersion: async (payload) => {
      return callApi("post", `/v1/versions/`, payload)
    },
    releaseVersion: async (versionId) => {
      return callApi("post", `/v1/versions/${versionId}/_release`)
    },
  }

  useEffect(() => {
    try {
      // log.debug("useEffect: invoked")
      if (loadAuthToken()) {
        setLoading(true)
        // log.debug("useEffect: loading session")
        api.getSessionInfo()
          .then((data) => {
            if (data && data.data) {
              // log.debug("useEffect: session loaded: data=", data.data)
              const ses = decorateSession(data.data)
              // log.debug("useEffect: session=", ses)
              setSession(ses)
            }
            else {
              // log.debug("Fatal error: loading session failed")
              setFatal("OOPS")
            }
          })
          .catch((error) => {
            removeAuthToken()
            window.location = "/signin"
          })
          .finally(() => {
            setLoading(false)
          })
      }
      else {
        // log.debug("No authToken found")
        removeAuthToken()
        setSession(null)
        setLoading(false)
      }
    }
    catch (e) {
      // log.debug("Error!!", e)
    }
  }, [])

  async function signin(payload) {
    // log.debug("signin: payload=", payload)
    const res = await authenticate(`/auth/signin/`, payload)
    // log.debug("signin: rs=", res)
    return (res)
  }

  async function signup(payload) {
    const res = await authenticate(`/auth/signup/`, payload)
    // log.debug("signup: res=", res)
    return (res)
  }

  async function signout() {
    // removeJwt()
    try {
      // log.debug("signout: invoked")
      const res = await api.post("/v1/auth/signout", {})
      // log.debug("signout: res=", res)
      // log.debug("signout: done")
      // // setTenant(null)
      // // setUser(null)
      // // setSessionState(emptySession())
      // setSession(null)
      removeAuthToken()
    }
    catch (err) {
      // log.debug("signout: error=", err)
    }
  }

  async function generateMagicLink (payload) {
    const res = await authApiHttp.post(`/auth/magicLinks/`, payload)
    if (res) {
      if (res.data.error) {
        throw new SolvError(res.data.error)
      }
    }
    else {
      throw new SolvError("SERVER_ERROR")
    }
  }

  async function registerDirectSignup(payload) {
    const res = await authApiHttp.post(`/auth/signup/requests/`, payload)
    if (res?.data) {
      if (res.data.data) {
        return res.data
      }
      else if (res.data.error) {
        throw new SolvError(res.data.error)
      }
      else {
          throw new SolvError("SERVER_ERROR")
      }
    }
    else {
      throw new SolvError("SERVER_ERROR")
    }
  }

  async function refreshSignin() {
    try {
      const data = await api.post(`/v1/auth/_refresh`)
      if (data && data.data && data.data.authToken) {
        // log.debug("refreshSignin: data=", data.data)
        handleAuthTokenChange(data.data.authToken)
        // return createSession(data.data)
        return (data)
      }
      else {
        // setSessionState(emptySession())
        throw new SolvError("UNKNOWN_ERROR")
      }
    }
    catch (e) {
      // setSessionState(emptySession())
    }
  }

  function handleAuthTokenChange(authToken) {
    setAuthToken(authToken)
    setStoredItem(authToken)
  }

  async function authenticate(endpoint, payload) {
    try {
      // log.debug(`authenticate: endpoint=, payload=`, endpoint, payload)
      let res = await unauthApiHttp.post(endpoint, payload)
      // log.debug(`authenticate: payload=, res=`, res)
      let data
      if (res && res.data) {
        if (res.data.data) {
          data = res.data.data
          handleAuthTokenChange(data.authToken)
        }
        else if (res.data.error) {
          throw res.data.error
        }
        else {
          throw new Error({code: "NETWORK_ERROR", message: "Network error"})
        }
      }
      else {
        throw new Error({code: "NETWORK_ERROR", message: "Network error"})
      }
      if (res && res.data && res.data.data && res.data.data.authToken) {
        data = res.data.data
        handleAuthTokenChange(data.authToken)
      }
      return data
    }
    catch (error) {
      setAuthToken(null)
      log.error("authenticate: failed: error=", error, error.response)
      if (error.response) {
        if (error.response.status === 401) {
          throw new SolvError({code: "UNAUTHORIZED", msg: "Unauthorized"})
        }
        else if (error.response.status === 404) {
          // log.debug("authenticate: >>>> 404")
          throw new SolvError({code: "NOT_FOUND", msg: "Unauthorized"})
        }
        else if (error.response.status === 503) {
          throw new SolvError({code: "UNAVAILABLE", msg: "Unavailable"})
        }
      }
      else if (error.code) {
        throw error
      }
      else {
        throw new SolvError({code: "SERVER_ERROR", msg: "Unexpected server/network error occurred. Please try again later."})
      }
    }
  }

  function decorateSession(data) {
    let s = data
    if (s.user) {
      s.user = decorateUser(s.user)
    }
    if (s.tenant) {
      const level = (s.user?.userLevel || 1)
      const type = s.tenant.tenantTypeId
      s.tenant = decorateTenant(s.tenant, {rel: `${level}/MEMBER/${type}`, level: level, userType: type, accessType: "MEMBER"})
    }
    return s
  }

  function loadAuthToken() {
    const t = getStoredItem(env.SESSION_COOKIE_NAME)
    if (t) {
      try {
        if (Date.now() <= parseInt(t.split(".")[1])) {
          // log.debug("loadAuthToken 1=", t)
          return t
        }
        else {
          // log.debug("loadAuthToken 2=", null)
          return null
        }
      }
      catch (err) {
        // log.debug("loadAuthToken 3=", null)
        return null
      }
    }
    // log.debug("loadAuthToken 4=", t)
    return t
  }

  function hasAuthToken() {
    const t = getStoredItem(env.SESSION_COOKIE_NAME)
    // log.debug("hasAuthToken", t !== null)
    return t !== null
  }

  function removeAuthToken() {
    return removeStoredItem(env.SESSION_COOKIE_NAME)
  }

  function getStoredItem() {
    return localStorage.getItem(env.SESSION_COOKIE_NAME)
  }

  function setStoredItem(value) {
    // log.debug("setStoredItem: value=", value)
    return localStorage.setItem(env.SESSION_COOKIE_NAME, value)
  }

  function removeStoredItem() {
    return localStorage.removeItem(env.SESSION_COOKIE_NAME)
  }

  function callApi(method, url, payload, opt) {
    const authToken = loadAuthToken()
    if (authToken) {
      opt = {
        ...opt,
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-SOLV-Request-Id": uuidv4().replace(/[\-]+/g,'')
        }
      }
    }
    log.debug(`callApi: ${method.toUpperCase()} ${url}\n`, payload)

    let res
    switch (method.toUpperCase()) {
      case "GET":
        res = authApiHttp.get(url, opt)
        break;
      case "POST":
        res = authApiHttp.post(url, payload, opt)
        break;
      case "PUT":
        res = authApiHttp.put(url, payload, opt)
        break;
      case "DELETE":
        res = authApiHttp.delete(url, opt)
        break;
    }
    return res
      .then((res) => {
        if (res.data.error) {
          log.debug("callApi: result: failed 1: method=, url=, payload=, opt=, error=", method, url, payload, opt, res.data.error)
          const error = res.data.error
          throw new SolvError({code: error.code, msg: error.message, details: error.details})
        }
        else {
          log.debug("callApi: result: success: method=, url=, payload=, opt=, data=", method, url, payload, opt, res.data)
          return res.data
        }
      }, (error) => {
        log.debug("callApi: result: failed 2: method=, url=, payload=, error=", method, url, payload, JSON.stringify(error))
        if (error.code) {
          throw new SolvError({code: error.code, msg: error.message, details: error.details})
        }
        else {
          throw error
        }
      })
  }

  const auth = {
    signin,
    signup,
    signout,
    refreshSignin,
    generateMagicLink,
    registerDirectSignup,
    session: session,
    get isAuthenticated() {return hasAuthToken()},
  }

  const solvContextValue = {
    isLoading: loading,
    brandId: brandId,
    env,
    api,
    auth,
    publicHttp,
    session: session,
    signin,
    signup,
    signout,
    refreshSignin,
    generateMagicLink,
    registerDirectSignup,
    get isAuthenticated() { return !!loadAuthToken() },
    busy,
    setBusy,
    fatal,
    setFatal,
  }

  return (
      <SolvContext.Provider value={solvContextValue}>
        {children}
      </SolvContext.Provider>
  )
}

export const useTenant = (tenantId) => {

  const {api, session, setFatal} = useSolv()

  const [tenant, setTenant] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // log.debug("useTenant: useEffect: invoked: ", tenantId, session)
    if (session) { // wait till session available
      if (tenantId !== null) { // wait till tenantId is available
        // log.debug("useTenant: useEffect: invoked: tenantId=", tenantId)
        if (tenantId === undefined || tenantId === session.tenant?.tenantId) {
          // log.debug("useTenant: useEffect: return session tenant: tenant=", session.tenant)
          setTenant(session.tenant)
        }
        else {
          // log.debug("useTenant: useEffect: loading from server: tenantId=", tenantId)
          api.getTenantInfo(tenantId)
            .then((data) => {
              // log.debug("useTenant: data=", data)
              if (data && data.data) {
                const t = decorateTenant(data.data.tenant, data.data.accessInfo)
                // log.debug("useTenant: useEffect: returning loaded tenant: tenant=", t)
                setTenant(t)
              }
              else {
                setTenant(session.tenant)
                // log.debug("useTenant: Fatal error: invalid API response: tenantId=", tenantId)
                // setFatal("OOPS")
              }
            })
            .catch((error) => {
              log.error("useTenant: Fatal error: error=", error)
              setTenant(session.tenant)
              setFatal(error)
            })
            .finally(() => {
              setLoading(false)
            })
        }
      }
    }
  }, [session, tenantId])

  return {tenant: tenant, loading: loading}
}

function decorateUser(data) {
  let u = data
  if (u) {
    u.isOfUserType = (...userTypes) => {
      if (u.userTypeId) {
        for (const userType of Array.from(userTypes)) {
          if (u.userTypeId.toUpperCase() === userType.toUpperCase() ) {
            return true
          }
        }
      }
      return false
    }
    u.isAtUserLevel = (userLevel) => {
      if (u.userLevel) {
        if (u.userLevel >= userLevel) {
          return true
        }
      }
      return false
    }
    u.isSystemUser = () => u.isOfUserType(SYSTEM_USER_TYPE)
    u.isSystem = () => u.isOfUserType(SYSTEM_USER_TYPE)
    u.isResellerUser = () => u.isOfUserType(RESELLER_USER_TYPE)
    u.isReseller = () => u.isOfUserType(RESELLER_USER_TYPE)
    u.isManager = () => u.isOfUserType(SYSTEM_USER_TYPE) || u.isOfUserType(RESELLER_USER_TYPE)
    u.isClientUser = () => u.isOfUserType(CLIENT_USER_TYPE)
    u.isClient = () => u.isOfUserType(CLIENT_USER_TYPE)
    u.isOwner = () => u.isAtUserLevel(OWNER_USER_LEVEL)
    u.isAdminOrAbove = () => u.isAtUserLevel(ADMIN_USER_LEVEL)
    u.isSuperAdminOrAbove = () => u.isAtUserLevel(SUPERADMIN_USER_LEVEL)
    u.isSupervisorOrAbove = () => u.isAtUserLevel(SUPERVISOR_USER_LEVEL)
    u.isBasicOrAbove = () => u.isAtUserLevel(BASIC_USER_LEVEL)
    u.isRestrictedOrAbove = () => u.isAtUserLevel(RESTRICTED_USER_LEVEL)
  }
  return u
}

function compareAccessLevel(check, match) {

  const checks = check.split("/")
  const matchs = match.split("/")

  const c_level = cvtAccessLevel(checks[0])

  const c_accessType = checks[1].trim().toUpperCase()

  const c_userType  = checks[2].trim().toUpperCase()

  let m_level = matchs[0].trim()
  if (m_level === "*") {
    m_level = -1
  }
  else {
    m_level = cvtAccessLevel(m_level)
  }

  let m_accessTypes = matchs[1].trim().toUpperCase().split(",")

  let m_userTypes = matchs[2].trim().toUpperCase().split(",")

  if (c_level < m_level) {
    // log.debug(`compareAccessLevel: ${check}=${match}: result=false`)
    return false
  }

  if (!compareAccessLevelTypes(c_accessType, m_accessTypes)) {
    // log.debug(`compareAccessLevel: ${check}=${match}: result=false`)
    return false
  }

  if (!compareAccessLevelTypes(c_userType, m_userTypes)) {
    // log.debug(`compareAccessLevel: ${check}=${match}: result=false`)
    return false
  }

  // log.debug(`compareAccessLevel: ${check}=${match}: result=true`)
  return true

}

function compareAccessLevelTypes(checkType, matchTypes) {
  for (let matchType of matchTypes) {
    if (matchType ===  "*" || matchType === checkType) {
      return true
    }
  }
  return false
}

function decorateTenant(data, accessInfo) {
  const t = data
  if (t) {
    t.accessInfo = accessInfo
    t.isSystem = () => t.tenantTypeId === "SYSTEM"
    t.isReseller = () => t.tenantTypeId === "RESELLER"
    t.isClient = () => t.tenantTypeId === "CLIENT"
    t.isBilling = () => (("SYSTEM" === t.tenantType) || ("STANDARD" === t.tenantPlan) || ("BUSINESS_A" === t.tenantPlan) || ("BUSINESS_B" === t.tenantPlan))
    t.accessingAs = (rel, accessLevel = 0) => {
      if (rel !== undefined) {
        // log.debug("rel=", rel, t.accessInfo.rel)
        if (t.accessInfo && t.accessInfo.rel) {
          const t_rel = t.accessInfo.rel
          if (Array.isArray(rel)) {
            for (let r of rel) {
              if (compareAccessLevel(t_rel, r)) {
                return true
              }
            }
          }
          else {
            if (compareAccessLevel(t_rel, rel)) {
              return true
            }
          }
        }
        return false
      }
      else {
        return t.accessInfo
      }
    }
    t.accessingAt = (accessLevel) => {
      if (t.accessInfo && t.accessInfo.level) {
        return (t.accessInfo.level || 0) >= cvtAccessLevel(accessLevel)
      }
      else {
        return false
      }
    }
    t.hasSubTenants = () => {
      return (t?.maxDepth ? t?.maxDepth > (t?.parentPath ? (t?.parentPath.split(".").length - 1) : 0) : false)
    }
  }
  return t
}

function cvtAccessLevel(accessLevel) {
  if (typeof(accessLevel) === "number") {
    return (accessLevel)
  }
  else if (typeof(accessLevel) === "string") {
    switch (accessLevel) {
      case "OWNER":
      case "100":
        return 100
      case "SUPERADMIN":
      case "SUPER_ADMIN":
      case "95":
        return 95
      case "ADMIN":
      case "90":
        return 90
      case "SUPERVISOR":
      case "20":
        return 20
      case "BASIC":
      case "10":
        return 10
      case "RESTRICTED":
      case "1":
        return 1
      case "NO_ACCESS":
      case "0":
        return 0
      default:
        return 0
    }
  }
  else {
    return 0
  }
}


