import React, { Fragment, useState, useEffect }  from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {ActionButton, FormBody, FormGroup, useAlert} from "./components/FormComps"
import {InfoBlock, InfoText} from "./components/ValidationUtils.js"
import {useSolv} from "./components/SolvProvider"
import {useIntlEx} from "./components/IntlUtils.js"
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {mkHref} from "./components/AccountUtils";
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("BroadcastCloneDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function BroadcastCloneDialog(props)  {

  const {api, session} = useSolv()
  const {intl} = useIntlEx()

  const [alert, setAlert] = useAlert()

  const [tenant, setTenant] = useState(null)
  const [broadcastName, setBroadcastName] = useState(null)

  const [touched, setTouched] = useState(false)
  const [working, setWorking] = useState(null)

  useEffect(() => {
    if (props.tenant) {
      setTenant(props.tenant)
    }
    if (props.broadcastName) {
      setBroadcastName(`Copy of ${props.broadcastName}`)
    }
  }, [props])

  function validate() {
    return (broadcastName && broadcastName.trim().length > 0)
  }

  async function handleCloneClick() {

    try {

      setAlert(null)

      if (validate()) {

        setWorking("Working...")

        let payload = {
          broadcastId: props.broadcastId,
          broadcastName: broadcastName,
          createdBy: session.user.userId
        }

        log.debug("handleCloneClick: payload=", payload)

        const {data} = await api.cloneBroadcast(tenant.tenantId, payload)

        if (data.broadcastId) {
          window.location = mkHref({suffix: `/broadcasts/${data.broadcastId}/_edit`, tenant: tenant})
        }

        setTouched(false)

      }
    }
    catch (error) {
      handleError(error)
    }
    finally {
      setWorking(null)
    }
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug(">>>handleError", error.code)
      const code = error.code
      if (code === "DUPLICATE_KEY") {
        setAlert({error:intl.msg("broadcast_error_duplicate_name")})
      }
      else {
        setAlert({error:intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error:intl.msg("error_failed")})
    }
  }

  function handleOpenDialog() {
    setAlert(null)
  }

  function handleCloseDialog() {
    closeDialog("dlg_broadcast_clone")
  }

  return (

    tenant &&
      <ModalDialog
        dialogId="dlg_broadcast_clone"
        size="md" keyboard={"true"} backdrop={props.backdrop || "static"}
        onOpen={handleOpenDialog}
        onClose={handleCloseDialog}>

        <ModalDialog.Header
          title={intl.msg("broadcast_clone_title")}
          description={intl.msg("broadcast_clone_subtitle")}
          alert={alert}/>

        <ModalDialog.Body>

          <FormGroup>
            <FormGroup.Label htmlFor="inp_emailAddress" description={intl.msg("broadcast_clone_new_broadcast_name_description")}>
              {intl.msg("broadcast_clone_new_broadcast_name")}
            </FormGroup.Label>
            <FormGroup.Control>
              <input id="inp_broadcastName" name="broadcastName" type="text" className="form-control" value={broadcastName} disabled={false} onChange={(e) => {setBroadcastName(e.target.value);setTouched(true)}} autoComplete="no"/>
              <InfoBlock>
                <InfoText>{intl.msg("helptext_name_casing")}</InfoText>
                <InfoText validate="unique">{intl.msg("broadcast_name_helptext_unique")}</InfoText>
                <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
              </InfoBlock>
            </FormGroup.Control>
          </FormGroup>

        </ModalDialog.Body>

        <ModalDialog.Footer>
          <ModalDialog.Footer.Controls>
            <ActionButton name="cancel" flavor="secondary" onClick={handleCloseDialog}/>
            <ActionButton name="clone" flavor="primary" disabled={!validate()} onClick={handleCloneClick}/>
          </ModalDialog.Footer.Controls>
        </ModalDialog.Footer>

      </ModalDialog>
    )
}