import React from "react"
import {useIntlEx} from "../components/IntlUtils";
import {PageDialogSidePanel} from "../components/PageDialog";
import getLogger from "../components/Logging.js"
const log = getLogger("PageDialog")

export function PageDialogHeader(props) {

  const theme = getComputedStyle(document.documentElement).getPropertyValue("--theme") || "dark"
  return (
    <>
      <img src={`https://sassets.solv.technology/brand/solv-logo-with-tagline-color-${theme}-md-c.png`} width="auto" height="56"/>
    </>
  )
}

export function PageDialogFooter(props) {
  const {intl} = useIntlEx()
  return (
      <>
        <div>
          &copy; SOLV Technology PTE LTD
          {/*&copy; <a className="text-link" href="https://www.solv.technology" title="SOLV Home Page" target="_blank">SOLV Technology PTE LTD</a>*/}
        </div>
        <div>
          <a className="text-link mr-2" href="https://www.solv.technology" title="SOLV Home" target="_blank">www</a>
          <a className="text-link mr-2" href="https://www.solv.technology/legal" title="SOLV Policies" target="_blank">legal</a>
          <a className="text-link" href="https://help.solv.technology" title="SOLV Support & Knowledgebase" target="_blank">{intl.msg("help").toLowerCase()}</a>
        </div>
      </>
  )
}

export function PageDialogSider(props) {
  const {intl} = useIntlEx()
  const theme = getComputedStyle(document.documentElement).getPropertyValue("--theme") || "dark"
  return (
      <PageDialogSidePanel {...props}>
        <div className="d-flex flex-column h-100">
          <div className="d-flex justify-content-center w-100 mb-auto">
            <img src={`https://sassets.solv.technology/brand/solv-logo-color-dark-md-c.png`} width="94" height="30"/>
          </div>
          <div>
            <div className="mb-2">
              <img src={`https://sassets.solv.technology/brand/solv-logo-tagline-only-dark-md-c.png`} width="auto" height="14"/>
            </div>
            <p className="m-0 p-0">{intl.msg("solv_pagedialog_sider_text", {a_learn: chunks => <><a className="icon-link" href="https://www.solv.technology" title={chunks} target="_blank"><i className="fas fa-info-circle"></i></a></>})}</p>
          </div>
        </div>
      </PageDialogSidePanel>
  )
}

