import React, {Fragment, useState, useEffect} from "react";
import {ActionButton, Alert, FormGroup, Icon} from "./components/FormComps";
import {capFirst, CurrencySymbol} from "./components/StringUtils";
import CreativePreview from "./CreativePreview";
import {InfoBlock, InfoText} from "./components/ValidationUtils";
import {PayPalButton} from "react-paypal-button-v2";
import {closeDialog, ModalDialog, openDialogCurried} from "./components/DialogUtils";
import {useIntlEx} from "./components/IntlUtils";
import {useSolv} from "./components/SolvProvider";
import getLogger from "./components/Logging.js"
import "./App.css"
import useStateRef from "react-usestateref";
import {mkHref} from "./components/AccountUtils";
import CreditsTestPaymentDialog from "./CreditsTestPaymentDialog";

const log = getLogger("BroadcastPublishDialog")

export function BroadcastPublishDialog(props) {

  const {api, env, session:{user}, setBusy} = useSolv()
  const {intl} = useIntlEx()

  const [alert, setAlert] = useState(null)
  const [touched, setTouched] = useState(null)

  const [publishDialogState, setPublishDialogState] = useState(null)
  const [tenantCredits, setTenantCredits] = useState(null)
  const [topupCredits, setTopupCredits] = useState(null)
  const [paymentTransactionId, setPaymentTransactionId, paymentTransactionIdRef] = useStateRef(null)

  function canUseSandbox() {
    return (("DEV" === env.STAGE) || (["DEMO", "TEST"].includes(props.tenant.tenantPlanId)) || (props.tenant.accessingAs("SUPERADMIN/SYSTEM/*")))
  }

  function canUseTest() {
    return (("DEV" === env.STAGE) || (["DEMO", "TEST"].includes(props.tenant.tenantPlanId)) || (props.tenant.accessingAs("SUPERADMIN/SYSTEM/*")))
  }

  function getPaypalClientId() {
    return (canUseSandbox() ? env.PAYPAL_TEST_CLIENT_ID : env.PAYPAL_LIVE_CLIENT_ID)
  }


  function handleTopupCreditsChange(e) {
    const v = e.target ? e.target.value : e
    setTopupCredits(v)
    setTouched(true)
  }

  function handleTopupCreditsBlur(e) {
    const v = e.target ? e.target.value : e
    if (v < props.targetBudget - props.tenantCredits) {
      setTopupCredits(props.targetBudget - props.tenantCredits)
    }
    setTouched(true)
  }

  async function handlePublishClick() {
    log.debug("handlePublishWClick: invoked", props.tenant)

    setBusy(intl.msg("publishing"))

    try {

      const payload = {
        provider: "SOLV_CREDITS",
        broadcastId: props.broadcastId,
        details: {}
      }

      log.debug("handlePublishWithCredits: payload=", payload)

      const res = await api.publishBroadcast(props.tenant.tenantId, payload)

      log.debug("handlePublishWithCredits: publishhMutation: res=", res)

      setAlert(null)
      setPublishDialogState("THANK_YOU")
      setTouched(false)

      window.location = mkHref({suffix: "/broadcasts", user: user, tenant: props.tenant})

    }
    catch (ex) {
      log.debug("publishedWithCredits failed", JSON.stringify(ex))
      switch (ex.code) {
        case "INSUFFICIENT_FUNDS":
          // setPublishAlert({error: intl.fm("broadcast_publish_error_insufficient_credits")})
          setPublishDialogState("PAY")
          const tc = parseFloat(ex.details.tenantCredits || 0.0)
          setTenantCredits(tc)
          setTopupCredits(props.targetBudget - tc)
          break
        default:
          setAlert({error: intl.msg("error_failed")})
      }
    }
    finally {
      log.debug("Done publishing...")
      setBusy(null)
    }
  }

  async function handlePaypalCreateOrder(orderData, actions) {

    log.debug(">>>Z: handlePaypalCreateOrder: orderData=", orderData, props)

    setBusy(intl.msg("working"))

    try {
      const payload = {
        paymentTransactionType: "PURCHASE",
        paymentMethodId: "PAYPAL_CHECKOUT",
        amount: topupCredits,
        usdMultiplier: props.usdMultiplier,
        details: orderData
      }
      const {data}  = await api.createPaymentTransaction(payload)
      if (data) {
        log.debug("handlePaypalCreateOrder: data=", data)
        setPaymentTransactionId(data.paymentTransactionId)
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                currency_code: `${props.tenant.region.currencyCode ? props.tenant.region.currencyCode : "USD"}`,
                value: topupCredits
              }
            }
          ],
          application_context: {
            brand_name: "SOLV: Publish Broadcast",
            shipping_preference: "NO_SHIPPING"
          }
        })
      }
      else {
        log.debug("handlePaypalCreateOrder: error=", data.error)
        setAlert({error: intl.msg("error_failed_paypal")})
        return false
      }
    }
    finally {
      setBusy(null)
    }
  }

  async function handlePublishWithPaypal(orderData, actions) {

    log.debug("handlePublishWithPaypal: orderData=", orderData)

    setBusy(intl.msg("publishing"))

    try {

      let details = await actions.order.capture() || {}

      details.broadcast = {
        broadcastId: props.broadcastId,
        broadcastName: props.broadcastName
      }

      const payload = {
        broadcastId: props.broadcastId,
        paymentTransactionId: paymentTransactionIdRef.current,
        purchaseType: "CREDITS",
        description: `Publish (broadcast "${props.broadcastName}")`,
        details: details,
      }

      log.debug(`handlePublishWithPaypal: payload=`, payload)

      const res = await api.purchaseCredits(props.tenant.tenantId, payload)

      log.debug("handlePublishWithPaypal: purchaseCredits: res=", res)

      await handlePublishClick()

    }
    catch (ex) {
      setAlert({error: intl.msg("error_failed_paypal")})
    }
    finally {
      setBusy(null)
    }

  }

  async function handlePublishWithTest() {

    log.debug("handlePublishWithTest")

    setBusy(intl.msg("publishing"))

    try {

      await createPaymentTransaction({credits: topupCredits, paymentMethodId: "TEST", details: {}})

      const details = {
        broadcastId: props.broadcastId,
        broadcastName: props.broadcastName
      }

      const payload = {
        broadcastId: props.broadcastId,
        paymentTransactionId: paymentTransactionIdRef.current,
        purchaseType: "CREDITS",
        description: `Publish (broadcast "${props.broadcastName}")`,
        details: details,
      }

      log.debug(`handlePublishWithTest: payload=`, payload)

      const res = await api.purchaseCredits(props.tenant.tenantId, payload)

      log.debug("handlePublishWithTest: purchaseCredits: res=", res)

      await handlePublishClick()

    }
    catch (ex) {
      setAlert({error: intl.msg("error_failed_payment")})
    }
    finally {
      setBusy(null)
    }

  }

  async function createPaymentTransaction({credits, paymentMethodId, details}) {
    const payload = {
      paymentTransactionType: "PURCHASE",
      paymentMethodId: paymentMethodId,
      amount: credits,
      usdMultiplier: props.usdMultiplier,
      details: details
    }
    const {data}  = await api.createPaymentTransaction(payload)
    if (data) {
      setPaymentTransactionId(data.paymentTransactionId)
    }
    else {
      throw new intl.msg("error_failed_payment")
    }
    return data
  }

  function handleOpenDialog() {
    log.debug(">>>Z: handleOpenDialog")
    setAlert(null)
    setPublishDialogState("START")
    setAlert(null)
  }

  function handleCloseDialog() {
    log.debug(">>>Z: handleCloseDialog")
    setAlert(null)
    setPublishDialogState("START")
    setAlert(null)
    closeDialog("dlg_publish")
  }
  function getTargetLocationsAsString() {
    let l = []
    if (props.targetLocations) {
      l = props.targetLocations.map(t => t.label)
    }
    return (l.join(", "))
  }

  return (
    props.tenant &&
      <>
        <ModalDialog
          dialogId="dlg_publish"
          size="md"
          keyboard="true"
          backdrop="static"
          onOpen={handleOpenDialog}
          onClose={handleCloseDialog}>

          <ModalDialog.Header
            title={intl.msg("broadcast_publish_title")}
            alert={alert}/>

          <ModalDialog.Body>
            <section className="main pb-0">
            {
              "START" === publishDialogState ? (
                "TRIAL" === props.tenant.tenantPlanId ? (
                  <>
                    <div>
                      <Alert alert={{error: intl.msg("upgrade_plan_text_1", {tenantPlan: capFirst(props.tenant.tenantPlanId)})}}/>
                    </div>
                    <center className="mt-3">
                      {intl.msg("upgrade_plan_text_2")}
                    </center>
                    <section className="paypal" style={{display: "flex", justifyContent: "end", alignItems: "center", padding: "20px 1px", margin: "0 -2px", borderTop: "1px solid var(--form-divider-color)"}}>
                      <div style={{display: "flex", justifyContent: "end"}}>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" aria-label="Close" onClick={handleCloseDialog}>
                          {intl.msg("cancel")}
                        </button>
                      </div>
                    </section>
                  </>
                ) : (
                  <>
                    <div>
                      <label>{intl.msg("broadcast_publish_text_1")}</label>
                    </div>
                    <div style={{margin: "16px 0 16px 10px"}}>
                      <div style={{display: "table-row"}}>
                        <div style={{display: "table-cell", textAlign: "right", verticalAlign: "top"}}>
                          <label>{intl.msg("broadcast_publish_message")}&nbsp;&nbsp;</label>
                        </div>
                        <div style={{display: "table-cell"}}>
                          <CreativePreview
                            tenantName={props.tenant.displayName}
                            creativeType={props.creativeType}
                            creativeHeader={props.creativeHeader ? props.creativeHeader : ""}
                            creativeTitle={props.creativeTitle}
                            creativeContent={props.creativeContent}
                            creativeFormFields={props.creativeFormFields}
                            creativeFooter={props.creativeFooter}
                            creativeStyle={props.creativeStyle}
                            logoUrl={props.tenant.logoUrl}
                            creativeImageUrl={props.creativeImageUrl}
                            borderColor={props.creativeBorderColor}
                            allowedDomains={props.tenant.allowedDomains}/>
                        </div>
                      </div>
                      <div style={{display: "table-row"}}>
                        <div style={{display: "table-cell", textAlign: "right", paddingTop: "10px"}}>
                          <label>{intl.msg("broadcast_click_action")}&nbsp;&nbsp;</label>
                        </div>
                        <div style={{display: "table-cell", color:"var(--form-control-label-text-color)"}}>
                          {
                            props.clickAction ? (
                              ["EXTERNAL"].includes(props.clickAction.value) ? (
                                <a className="text-link" href={props.pageUrl}>{props.clickAction.displayLabel || ""} ({props.pageUrl})</a>
                              ) : ["HOME"].includes(props.clickAction.value) ? (
                                <a className="text-link" href={props.tenant.homePage}>{props.clickAction.displayLabel || ""} ({props.tenant.homePage})</a>
                              ) : ["WHATSAPP"].includes(props.clickAction?.value) ? (
                                <a className="text-link" href={props.pageUrl}>{props.clickAction?.displayLabel || ""} ({props.whatsAppPhoneNo})</a>
                              ) : (
                                <a className="text-link" href={props.pageUrl}>{props.clickAction.displayLabel || ""}</a>
                              )
                            ) : (
                              ""
                            )
                          }

                        </div>
                      </div>
                      <div style={{display: "table-row"}}>
                        <div style={{display: "table-cell", textAlign: "right"}}>
                          <label>{intl.msg("broadcast_target_locations")}&nbsp;&nbsp;</label>
                        </div>
                        <div style={{display: "table-cell", color:"var(--form-control-label-text-color)"}}>
                          {getTargetLocationsAsString()}
                        </div>
                      </div>
                      <div style={{display: "table-row"}}>
                        <div style={{display: "table-cell", textAlign: "right"}}>
                          <label>
                            {intl.msg("broadcast_target_budget")}&nbsp;&nbsp;
                          </label>
                        </div>
                        <div style={{display: "table-cell", color:"var(--form-control-label-text-color)", marginBottom: "10px"}}>
                          <CurrencySymbol currencyCode={props.tenant.region.currencyCode ? props.tenant.region.currencyCode : "USD"}/>{Number(props.targetBudget).toFixed(2)} {props.tenant.region.currencyCode ? props.tenant.region.currencyCode : "USD"}
                        </div>
                      </div>
                      <div style={{display: "table-row"}}>
                        <div style={{display: "table-cell", textAlign: "right"}}>
                          <label>
                            {intl.msg("broadcast_target_duration")}&nbsp;&nbsp;
                          </label>
                        </div>
                        <div style={{display: "table-cell", color:"var(--form-control-label-text-color)", marginBottom: "10px"}}>
                          {props.targetEndTime.label}
                        </div>
                      </div>
                      <div style={{display: "table-row"}}>
                        <div style={{display: "table-cell", textAlign: "right"}}>
                          <label>
                            {intl.msg("broadcast_estimated_views")}&nbsp;&nbsp;
                          </label>
                        </div>
                        <div style={{display: "table-cell", color:"var(--form-control-label-text-color)", marginBottom: "10px"}}>
                          {intl.int(props.popEstimations.ttlImpressions)}
                        </div>
                      </div>
                    </div>
                    {/*<section className="paypal" style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "20px 1px", margin: "0 -2px", borderTop: "1px solid var(--form-divider-color)"}}>*/}
                    {/*  <small style={{color: "#ccc"}}>*/}
                    {/*    <i className="fas fa-info-circle mr-1"></i>{intl.msg("broadcast_publish_by_clicking")}*/}
                    {/*  </small>*/}
                    {/*  <div style={{display: "flex", justifyContent: "end"}}>*/}
                    {/*    <button type="button" className="btn btn-secondary" data-dismiss="modal" aria-label="Close" onClick={handlePublishDialogCloseClick}>*/}
                    {/*      {intl.msg("cancel")}*/}
                    {/*    </button>*/}
                    {/*    <button className="btn btn-primary ml-2"  onClick={handlePublishClick}>*/}
                    {/*      {intl.msg("publish")}*/}
                    {/*    </button>*/}
                    {/*  </div>*/}
                    {/*</section>*/}
                  </>
                )
              ) : "PAY" === publishDialogState ? (
                <Fragment>
                  <div style={{marginBottom: "20px"}}>
                    <center>
                      <Alert alert={{warning: intl.msg("broadcast_publish_you_dont_have_sufficient_credits", {tenantCredits: `${intl.numFormat.format(tenantCredits)} ${props.tenant.region.currencyCode ? props.tenant.region.currencyCode : "USD"}`, targetBudget: `${intl.numFormat.format(props.targetBudget)} ${props.tenant.region.currencyCode ? props.tenant.region.currencyCode : "USD"}`, topupCredits: `${intl.numFormat.format(topupCredits)} ${props.tenant.region.currencyCode ? props.tenant.region.currencyCode : "USD"}`, a: chunks => <a className="text-link" href="/CreditTransactions.js">{chunks}</a>})}}/></center>
                    <div className="form-card">
                      <FormGroup className="mt-2">
                        <FormGroup.Label htmlFor="inp_topupCredits" description={intl.msg("broadcast_purchase_credits_description", {topupCredits: `${intl.numFormat.format(props.targetBudget - tenantCredits)} ${props.tenant.region.currencyCode ? props.tenant.region.currencyCode : "USD"}`})}>
                          {intl.msg("credits")}
                        </FormGroup.Label>
                        <FormGroup.Controls>
                          <div className="input-group-nowrap">
                            <input id="inp_topupCredits" type="number" className="form-control" style={{maxWidth: "160px"}} min={props.targetBudget - tenantCredits} step={0.01} value={topupCredits} onChange={handleTopupCreditsChange} onBlur={handleTopupCreditsBlur} disabled={false}/>
                            <span className="input-group-text">{props.tenant.region.currencyCode ? props.tenant.region.currencyCode : "USD"}</span>
                          </div>
                          <InfoBlock>
                            <InfoText>{intl.msg("broadcast_purchase_credits_helptext_2")}</InfoText>
                            <InfoText>{intl.msg("broadcast_purchase_credits_helptext_3")}</InfoText>
                            <InfoText validate="min-value">{intl.msg("broadcast_purchase_credits_helptext_min_value", {credits: `${intl.numFormat.format(props.targetBudget - tenantCredits)} ${props.tenant.region.currencyCode ? props.tenant.region.currencyCode : "USD"}`})}</InfoText>
                          </InfoBlock>
                        </FormGroup.Controls>
                      </FormGroup>
                      <FormGroup>
                        <FormGroup.Label text={intl.msg("credits_purchase_payment_method")} description={intl.msg("credits_purchase_payment_method_description")}/>
                        <FormGroup.Controls>
                          <div className="paypal-buttons-container">
                            <PayPalButton
                              options={{
                                clientId: getPaypalClientId(),
                                currency: `${props.tenant.region.currencyCode ? props.tenant.region.currencyCode : "USD"}`,
                              }}
                              createOrder={handlePaypalCreateOrder}
                              onApprove={handlePublishWithPaypal}
                            />
                          </div>
                          {
                            (canUseTest()) &&
                              <div className="credits-payment-container w-100">
                                <button type="button" className="btn btn-primary" onClick={handlePublishWithTest}>
                                  <Icon name="test" width="1.5em" height="1.5em"/>
                                  {intl.msg("test")}
                                </button>
                              </div>
                          }
                        </FormGroup.Controls>
                      </FormGroup>
                    </div>
                  </div>
                </Fragment>
              // ) : "PAY" === publishDialogState ? (
              //   <Fragment>
              //     <div style={{marginBottom: "20px"}}>
              //       <center>
              //         <label>{intl.msg("broadcast_publish_you_dont_have_sufficient_credits", {
              //           tenantCredits: `${intl.numFormat.format(tenantCredits)} ${props.tenant.region.currencyCode ? props.tenant.region.currencyCode : "USD"}`,
              //           targetBudget: `${intl.numFormat.format(props.targetBudget)} ${props.tenant.region.currencyCode ? props.tenant.region.currencyCode : "USD"}`,
              //           topupCredits: `${intl.numFormat.format(props.topupCredits)} ${props.tenant.region.currencyCode ? props.tenant.region.currencyCode : "USD"}`,
              //           a: chunks => <a className="text-link" href="/CreditTransactions.js">{chunks}</a>})}
              //         </label>
              //       </center>
              //       <div className="form-card">
              //         <div className="section-divider" style={{paddingBottom: "0px", margin: "0 0 26px 0"}}></div>
              //         <div className="form-group row m-0" style={{width: "fit-content"}}>
              //           <label className="col-form-label col-sm-3" htmlFor="inp_topupCredits">
              //             {intl.msg("broadcast_purchase_credits")}
              //           </label>
              //           <div className="col-sm-9">
              //             <div className="input-group">
              //               <input id="inp_topupCredits" type="number" className="form-control" style={{maxWidth: "160px"}} min={props.targetBudget - tenantCredits} step={0.01} value={topupCredits} onChange={handleTopupCreditsChange} onBlur={handleTopupCreditsBlur} disabled={false}/>
              //               <span className="input-group-text">{props.tenant.region.currencyCode ? props.tenant.region.currencyCode : "USD"}</span>
              //             </div>
              //             <InfoBlock>
              //               <InfoText>{intl.msg("broadcast_purchase_credits_helptext_1", {topupCredits: `${intl.numFormat.format(props.targetBudget - tenantCredits)} ${props.tenant.region.currencyCode ? props.tenant.region.currencyCode : "USD"}`})}</InfoText>
              //               <InfoText>{intl.msg("broadcast_purchase_credits_helptext_2")}</InfoText>
              //               <InfoText>{intl.msg("broadcast_purchase_credits_helptext_3")}</InfoText>
              //               <InfoText validate="min-value">{intl.msg("broadcast_purchase_credits_helptext_min_value", {topupCredits: `${intl.numFormat.format(props.targetBudget - tenantCredits)} ${props.tenant.region.currencyCode ? props.tenant.region.currencyCode : "USD"}`})}</InfoText>
              //             </InfoBlock>
              //           </div>
              //         </div>
              //         <div className="form-group row">
              //           <label className="col-form-label col-sm-3">
              //             {intl.msg("broadcast_select_payment_method")}
              //           </label>
              //           <div className="col-sm-9">
              //             <section className="paypal" style={{backgroundColor: "white", padding: "10px"}}>
              //               <PayPalButton
              //                 options={{
              //                   clientId: (("SYSTEM" === props.tenant.tenantTypeId) || ["DEMO", "TEST"].includes(props.tenant.tenantPlan) ? env.PAYPAL_TEST_CLIENT_ID : env.PAYPAL_LIVE_CLIENT_ID),
              //                   currency: `${props.tenant.region.currencyCode ? props.tenant.region.currencyCode : "USD"}`
              //                 }}
              //                 createOrder={handlePaypalCreateOrder}
              //                 onApprove={handlePaypalOnApprove}
              //               />
              //             </section>
              //           </div>
              //         </div>
              //       </div>
              //     </div>
                // </Fragment>
              ) : "THANK_YOU" === publishDialogState ? (
                <Fragment>
                  <section className="paypal">
                    <center style={{marginBottom: "10px"}}>
                      <label>{intl.msg("broadcast_publish_thank_you")}</label>
                    </center>
                    <center>
                      <button type="button" className="btn btn-secondary" data-dismiss="modal" aria-label="Close" style={{marginLeft: "8px"}} onClick={handleCloseDialog}>
                        {intl.msg("ok")}
                      </button>
                    </center>
                  </section>
                </Fragment>
              ) : (
                <Fragment>
                  <section className="paypal">
                    <center>
                      <button type="button" className="btn btn-secondary" data-dismiss="modal" aria-label="Close" style={{marginLeft: "8px"}} onClick={handleCloseDialog}>
                        {intl.msg("ok")}
                      </button>
                    </center>
                  </section>
                </Fragment>
              )
            }
          </section>
          </ModalDialog.Body>

          {
            "START" === publishDialogState &&
              <ModalDialog.Footer>
                <small style={{color: "var(--form-control-label-muted-color)"}}>
                  <i className="fas fa-info-circle mr-1"></i>{intl.msg("broadcast_publish_by_clicking")}
                </small>
                <ModalDialog.Footer.Controls>
                  <ActionButton name="cancel" onClick={handleCloseDialog}/>
                  <ActionButton name="publish" flavor="primary" onClick={handlePublishClick}/>
                </ModalDialog.Footer.Controls>
              </ModalDialog.Footer>
          }

        </ModalDialog>
      </>
  )

}
