import React, {useState, useEffect, forwardRef, useRef} from 'react'
import {useIntlEx} from "./components/IntlUtils";
import {Markdown} from "./components/MarkdownUtils";
import getLogger from "./components/Logging.js"
import {shortIdEqual} from "./components/StringUtils";

const log = getLogger("CreditTransactionInvoice")

const CreditTransactionInvoice = forwardRef((props, ref) => {

  const {intl} = useIntlEx()

  const markdown = new Markdown({
    homePage: props.creditTransaction?.tenant?.homePage,
    clickUrls: props.creditTransaction?.tenant?.clickUrls,
    allowedDomains: props.creditTransaction?.tenant?.allowedDomains,
    disallowedDomains: props.creditTransaction?.tenant?.disallowedDomains,
    allowLinks: props.creditTransaction?.tenant?.allowLinks || true,
  })

  // useEffect(() => {
  //   log.debug(">>>Z: bankAccounts=", props.bankAccounts?.filter(ba => !shortIdEqual(ba.regionId, props.tenant?.regionId)))
  // }, [props])

  return (
    <>
      <div className="credits-invoice-wrapper" ref={ref}>
        <div style={{fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif", color: "#000", backgroundColor: "#fff", padding: "40px"}}>

          <div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
              <div style={{fontSize: "30pt", fontWeight: "700", textTransform: "uppercase", letterSpacing: "0.25em"}}>
                {intl.msg("credit_invoice_title")}
              </div>
              <div>
                <img src="https://sassets.solv.technology/brand/solv-logo-with-tagline-color-light-theme.svg" width="140" height="auto"/>
              </div>
            </div>

            <div>

              <table style={{tableLayout: "fixed", fontSize: "12pt", borderCollapse: "separate", borderSpacing: "0", width: "100%",  marginTop: "20px"}}>

                <tr>

                  <td style={{verticalAlign: "top"}}>

                    <table style={{tableLayout: "fixed", fontSize: "12pt", borderCollapse: "separate", borderSpacing: "0", width: "100%"}}>
                      <tr>
                        <td>
                          <div style={{fontWeight: "700", color: "#000", textTransform: "uppercase", padding: "0"}}>
                            {intl.msg("credit_invoice_issued_by")}:
                          </div>
                          <div style={{paddingTop: "6px"}}>
                            SOLV Technology PTE LTD<br/>
                            68 Circular Road, #02-01,<br/>
                            049422<br/>
                            Singapore<br/>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div style={{fontWeight: "700", color: "#000", textTransform: "uppercase", padding: "10px 0 0 0"}}>
                            {intl.msg("credit_invoice_billed_to")}:
                          </div>
                          <div style={{paddingTop: "6px", whiteSpace: "pre-wrap"}}>
                            {props.creditTransaction?.billingInfo?.name || props.billingInfo?.name || props.tenant?.displayName || props.tenant?.tenantName}<br/>
                            {props.creditTransaction?.billingInfo?.address || props.billingInfo?.address || props.tenant?.contactInfo || ""}
                          </div>
                        </td>
                      </tr>

                    </table>

                  </td>

                  <td align="right" style={{verticalAlign: "top"}}>

                    <table style={{tableLayout: "fixed", fontSize: "12pt", borderCollapse: "separate", borderSpacing: "10px"}}>
                      <tr>
                        <td>
                          <div style={{fontWeight: "700", color: "#000", textTransform: "uppercase", padding: "0", textAlign: "right"}}>
                            {intl.msg("credit_transaction_invoice_no")}:
                          </div>
                        </td>
                        <td>
                          <div>
                            {props.creditTransaction?.invoiceNo}
                          </div>
                        </td>
                      </tr>
                      {
                        (props.purchaseOrderNo || props.creditTransaction?.purchaseOrderNo) &&
                          <tr>
                            <td>
                              <div style={{fontWeight: "700", color: "#000", textTransform: "uppercase", padding: "0", textAlign: "right"}}>
                                {intl.msg("credit_transaction_purchase_order_no")}:
                              </div>
                            </td>
                            <td>
                              <div>
                                {props.purchaseOrderNo || props.creditTransaction?.purchaseOrderNo}
                              </div>
                            </td>
                          </tr>
                      }
                      <tr>
                        <td>
                          <div style={{fontWeight: "700", color: "#000", textTransform: "uppercase", padding: "0", textAlign: "right"}}>
                            {intl.msg("issued_on")}:
                          </div>
                        </td>
                        <td>
                          <div>
                            {intl.date(props.creditTransaction?.createdOn)}
                          </div>
                        </td>
                      </tr>
                      {
                        props.creditTransaction?.settledOn &&
                          <>
                            <tr>
                              <td>
                                <div style={{fontWeight: "700", color: "#000", textTransform: "uppercase", padding: "0", textAlign: "right"}}>
                                  {intl.msg("settled_on")}:
                                </div>
                              </td>
                              <td>
                                <div>
                                  {intl.date(props.creditTransaction?.settledOn)}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div style={{fontWeight: "700", color: "#000", textTransform: "uppercase", padding: "0", textAlign: "right"}}>
                                  {intl.msg("payment_method")}:
                                </div>
                              </td>
                              <td>
                                <div>
                                  {intl.msg(`payment_method_${props.creditTransaction?.paymentMethod?.toLowerCase()}`)}
                                </div>
                              </td>
                            </tr>
                            {
                              props.creditTransaction?.details?.payment?.purchase_units?.[0]?.payments?.captures?.[0] &&
                                <tr>
                                  <td>
                                    <div style={{fontWeight: "700", color: "#000", textTransform: "uppercase", padding: "0", textAlign: "right"}}>
                                      {intl.msg("credits_transaction_id")}:
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      ({props.creditTransaction?.details?.payment?.purchase_units?.[0]?.payments?.captures?.[0]?.id})
                                    </div>
                                  </td>
                                </tr>
                            }
                          </>
                      }
                      {
                        props.creditTransaction?.reversedOn &&
                        <>
                          <tr>
                            <td>
                              <div style={{fontWeight: "700", color: "#000", textTransform: "uppercase", padding: "0", textAlign: "right"}}>
                                {intl.msg("canceled_on")}:
                              </div>
                            </td>
                            <td>
                              <div>
                                {intl.date(props.creditTransaction?.reversedOn)}
                              </div>
                            </td>
                          </tr>
                        </>
                      }
                    </table>

                  </td>

                </tr>
              </table>

            </div>

            <table style={{tableLayout: "fixed", fontSize: "12pt", borderCollapse: "separate", borderSpacing: "0", width: "100%", marginTop: "30px"}}>
              <tr>
                <td colSpan="2" style={{width: "80%", padding: "8px", borderTop: "1px solid #ddd", borderBottom: "1px solid #ddd"}}>
                  <div style={{fontWeight: "700", color: "#000", textTransform: "uppercase"}}>
                    {intl.msg("credit_invoice_description")}
                  </div>
                </td>
                <td style={{width: "20%", padding: "8px", borderTop: "1px solid #ddd", borderBottom: "1px solid #ddd"}}>
                  <div style={{fontWeight: "700", color: "#000", textTransform: "uppercase",  textAlign: "right"}}>
                    {intl.msg("credit_invoice_amount")}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="2" style={{width: "80%", padding: "8px"}}>
                  {props.creditTransaction?.description}
                </td>
                <td style={{width: "20%", padding: "8px", textAlign: "right"}}>
                  {intl.num(props.creditTransaction?.credits)} {props.tenant?.region?.currencyCode}
                </td>
              </tr>
              <tr>
                <td style={{width: "60%"}}></td>
                <td style={{width: "20%", padding: "8px", textAlign: "right"}}>
                  <div style={{fontWeight: "700", color: "#000", textTransform: "uppercase"}}>
                    {intl.msg("credit_invoice_subtotal")}:
                  </div>
                </td>
                <td style={{width: "20%", padding: "8px", textAlign: "right"}}>
                  <div style={{fontWeight: "400", color: "#000", textTransform: "uppercase"}}>
                    {intl.num(props.creditTransaction?.credits)} {props.tenant?.region?.currencyCode}
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{width: "60%"}}>&nbsp;</td>
                <td style={{width: "20%", padding: "8px", textAlign: "right"}}>
                  <div style={{fontWeight: "700", color: "#000", textTransform: "uppercase"}}>
                    {intl.msg("credit_invoice_tax")} (0%):
                  </div>
                </td>
                <td style={{width: "20%", padding: "8px", textAlign: "right"}}>
                  <div style={{fontWeight: "400", color: "#000", textTransform: "uppercase"}}>
                    0.00 {props.tenant?.region?.currencyCode}
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{width: "60%", borderTop: "1px solid #ddd", padding: "8px", fontStyle: "italic"}}>We appreciate your business!</td>
                <td style={{width: "20%", padding: "8px", textAlign: "right",  borderTop: "1px solid #ddd"}}>
                  <div style={{fontWeight: "700", color: "#000", textTransform: "uppercase"}}>
                    {intl.msg("credit_invoice_total_due")}:
                  </div>
                </td>
                <td style={{width: "20%", padding: "8px", textAlign: "right", borderTop: "1px solid #ddd", borderBottom: "3px double #ddd"}}>
                  <div style={{fontWeight: "700", color: "#000", textTransform: "uppercase"}}>
                    {intl.num(props.creditTransaction?.credits)} {props.tenant?.region?.currencyCode}
                  </div>
                </td>
              </tr>

              {
                ("REVERSED" === props.creditTransaction?.status) ? (
                  <tr>
                    <td colspan="3" style={{textAlign: "right", width: "100%"}}>
                      <div style={{display: "flex", flexDirection: "row", justifyContent: "end"}}>
                        <div className="text-outline" style={{textTransform: "uppercase", textAlign: "right", fontSize: "24pt", marginTop: "20px", borderTop: "2px solid red", borderBottom: "2px solid red", width: "fit-content", letterSpacing: "0.25em"}}>
                          {intl.msg("canceled")}
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : ("PENDING" !== props.creditTransaction?.status && props.creditTransaction?.settledOn) ? (
                  <tr>
                    <td colspan="3" style={{textAlign: "right", width: "100%"}}>
                      <div style={{display: "flex", flexDirection: "row", justifyContent: "end"}}>
                        <div className="text-outline" style={{textTransform: "uppercase", textAlign: "right", fontSize: "24pt", marginTop: "20px", borderTop: "2px solid red", borderBottom: "2px solid red", width: "fit-content", letterSpacing: "0.25em"}}>
                          {intl.msg("paid")}
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <></>
                )
              }
            </table>
          </div>

          {
            ("PENDING" === props.creditTransaction?.status && !props.creditTransaction?.settledOn && props.bankAccounts) &&
              <>

                <div style={{color: "#000", textTransform: "uppercase", paddingTop: "30px"}}>
                  <b>{intl.msg("credit_offline_payment_instructions")}</b>:
                </div>

                <div style={{fontSize: "12pt", color: "#000", backgroundColor: "#fff"}}>

                  <table style={{tableLayout: "auto", fontSize: "12pt", borderCollapse: "separate", borderSpacing: "2px", width: "100%"}}>

                    {
                      props.bankAccounts?.filter(ba => shortIdEqual(ba.regionId, props.tenant.regionId))?.map(ba =>
                        <>
                          <tr>
                            <td colspan="2">
                              <div style={{fontWeight: "400", color: "#000", paddingTop: "10px"}}>
                                {intl.msg("credit_invoice_payment_domestic_transfer_text", {regionName: props.tenant.region.regionName})}:
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td style={{width: "1%", whiteSpace: "nowrap", textTransform: "uppercase"}}>
                              <b>ACCOUNT NUMBER</b>:
                            </td>
                            <td>
                              {ba.bankAccountNo}
                            </td>
                          </tr>
                          <tr>
                            <td style={{width: "1%", whiteSpace: "nowrap", textTransform: "uppercase"}}>
                              <b>ACCOUNT NAME</b>:
                            </td>
                            <td>
                              {ba.bankAccountName}
                            </td>
                          </tr>
                          {
                            ba.bankAccountEtc.map(bae =>
                              <tr>
                                <td style={{width: "1%", whiteSpace: "nowrap", textTransform: "uppercase"}}>
                                  <b>{bae[0]}</b>:
                                </td>
                                <td>
                                  {bae[1]}
                                </td>
                              </tr>
                            )
                          }
                        </>
                      )
                    }

                    {
                      props.bankAccounts?.filter(ba => !shortIdEqual(ba.regionId, props.tenant.regionId))?.map(ba =>
                        <>
                          <tr>
                            <td colspan="2">
                              <div style={{fontWeight: "400", color: "#000", padding: "10px 0 10px 0"}}>
                                {intl.msg("credit_invoice_payment_international_transfer_text", {regionName: props.tenant.region.regionName})}:
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td style={{width: "1%", whiteSpace: "nowrap", textTransform: "uppercase"}}>
                              <b>ACCOUNT NUMBER</b>:
                            </td>
                            <td>
                              {ba.bankAccountNo}
                            </td>
                          </tr>
                          <tr>
                            <td style={{width: "1%", whiteSpace: "nowrap", textTransform: "uppercase"}}>
                              <b>ACCOUNT NAME</b>:
                            </td>
                            <td>
                              {ba.bankAccountName}
                            </td>
                          </tr>
                          {
                            ba.bankAccountEtc.map(bae =>
                              <tr>
                                <td style={{width: "1%", whiteSpace: "nowrap", textTransform: "uppercase"}}>
                                  <b>{bae[0]}</b>:
                                </td>
                                <td>
                                  {bae[1]}
                                </td>
                              </tr>
                            )
                          }
                        </>
                      )
                    }

                    {/*<tr>*/}
                    {/*  <td colSpan="2">*/}
                    {/*    <div style={{fontWeight: "400", color: "#000", textTransform: "full-width"}}>*/}
                    {/*      {intl.msg("credit_invoice_payment_instructions_other_text")}*/}
                    {/*    </div>*/}
                    {/*  </td>*/}
                    {/*</tr>*/}
                  </table>

                </div>
              </>
          }

          <div style={{fontSize: "12pt", marginTop: "10px", paddingTop: "20px", borderTop: "1px solid #eee"}}>
            {intl.msg("credit_invoice_footer_text")}
          </div>

          {/*<div style={{fontSize: "12pt", marginTop: "10px"}}>*/}
          {/*  For Terms of Service and User Agreement, please visit <a style={{color: "#000"}} href="https://sassets.solv.technology/legal/terms">https://sassets.solv.technology/legal/terms</a>.*/}
          {/*</div>*/}

          <div style={{fontSize: "12pt", marginTop: "10px"}}>
            {intl.msg("credit_invoice_thank_you_text")}
          </div>


          <div style={{fontSize: "8pt", marginTop: "20px"}}>
            <table style={{tableLayout: "fixed", fontSize: "12pt", borderCollapse: "separate", borderSpacing: "2px", marginTop: "20px", width: "100%", borderTop: "1px solid #eee"}}>
              <tr>
                <td style={{width: "80%"}}>
                  <div style={{fontSize: "8pt", fontWeight: "400", color: "#888", paddingTop: "8px"}}>
                    &copy; <a style={{color: "#888", textDecorationColor: "#eee", textUnderlineOffset: "3px"}} href="https://www.solv.technology">SOLV Technology PTE LTD</a>
                  </div>
                </td>
                <td style={{width: "20%"}}>
                  <div style={{display: "flex", justifyContent: "end", gap: "8px", fontSize: "8pt", fontWeight: "400", color: "#888", paddingTop: "8px", textAlign: "right", width: "100%"}}>
                    <a style={{color: "#888", textDecorationColor: "#eee", textUnderlineOffset: "3px"}} href="https://www.solv.technology">www</a>
                    <a style={{color: "#888", textDecorationColor: "#eee", textUnderlineOffset: "3px"}} href="https://assets.solv.technology/legal">legal</a>
                    <a style={{color: "#888", textDecorationColor: "#eee", textUnderlineOffset: "3px"}} href="https://help.solv.technology">help</a>
                  </div>
                </td>
              </tr>
            </table>
          </div>

        </div>

      </div>
    </>
  )

})

export default CreditTransactionInvoice;