import React, {Fragment, useState, useEffect, useRef} from 'react'
import { useParams, useHistory, useLocation }  from 'react-router-dom'
import { useMutation } from 'react-query'
import { default as Select } from "react-select"
import useStateRef from "react-usestateref"
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { useSolv } from "./components/SolvProvider"
import { useIntlEx } from "./components/IntlUtils.js"
import {validateField, validateUrl, validateEmailAddress, showError, clearAllErrors, InfoBlock, InfoText, clearError} from './components/ValidationUtils.js';
import {closeDialog, ModalDialog, openDialog} from "./components/DialogUtils";
import {ActionButton, Alert, FormGroup, FormGroups, useAlert} from "./components/FormComps";
import {ImageDropBox, processImageFile, uploadImage} from "./components/ImageUtils";
import ImageDialog from "./components/ImageDialog";
import NumericInput from "react-numeric-input";
import {rangeMap} from "./components/CommonUtils";
import {AsyncPaginate, reduceGroupedOptions} from "react-select-async-paginate";
import {mkExtraParams} from "./components/TableComps";
import getLogger from "./components/Logging.js"
import './App.css'
import {mkHref, useIndustry} from "./components/AccountUtils";

const log = getLogger("ResellerInviteDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function ResellerInviteDialog(props)  {

  const {api, session:{user}, setBusy, setFatal} = useSolv()

  const {intl} = useIntlEx()

  const tenantTypeOpts = [
    {
      value: "BUSINESS_A",
      label: intl.msg("account_plan_business_a")
    },
    {
      value: "BUSINESS_B",
      label: intl.msg("account_plan_business_b")
    },
    {
      value: "DEMO",
      label: intl.msg("account_plan_demo")
    },
    {
      value: "TEST",
      label: intl.msg("account_plan_test")
    },
    {
      value: "TRIAL",
      label: intl.msg("account_plan_trial")
    },
  ]

  const [tenant, setTenant] = useState(null)

  const {industryOpts, findIndustryOpt} = useIndustry()

  const [region, setRegion] = useState(null)
  const [tenantId, setTenantId] = useState(null)
  const [emailAddress, setEmailAddress] = useState(null)
  const [industry, setIndustry] = useState(findIndustryOpt("PUBLIC_SAFETY"))
  const [tenantName, setTenantName] = useState(null)
  const [contactName, setContactName] = useState(null)
  const [tenantPlan, setTenantPlan] = useState(tenantTypeOpts.find(i => i.value === "TRIAL"))
  const [resellerType, setResellerType] = useState(null)
  const [resellerTypeOpts, setResellerTypeOpts] = useState(null)
  const [maxLevels, setMaxLevels, maxLevelsRef] = useStateRef(2)
  const [commissions, setCommissions, commissionsRef] = useStateRef([5.0])
  const [cpm, setCpm] = useState(5.0)
  const [broadcastTemplates, setBroadcastTemplates] = useState(null)
  const [broadcastTemplateOpts, setBroadcastTemplateOpts] = useState(null)
  const [homePage, setHomePage] = useState(null)
  const [contactInfo, setContactInfo] = useState(null)
  const [logoUrl, setLogoUrl] = useState(null)
  const [customOnboardingPage, setCustomOnboardingPage] = useState(null)
  const [parentReseller, setParentReseller] = useState(null)
  const [representative, setRepresentative] = useState(false)
  const [clipboardOpsAllowed, setClipboardOpsAllowed] = useState(null)
  const [reinviting, setReinviting] = useState(null)

  const [alert, setAlert] = useAlert()

  const [logoImageInfo, setLogoImageInfo] = useState(null)
  const logoImagePickerDialogRef = useRef(null)

  const params = useParams()

  const [touched, setTouched] = useState(null)

  const inpEmailAddressRef = useRef(null)

  useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions.query({name: "clipboard-write"}).then(result => {
        if (result.state == "granted" || result.state == "prompt") {
          setClipboardOpsAllowed(true)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (props) {
      if (props.tenant) {
        const t = props.tenant
        log.debug(">>>Z: tenant=", t)
        setTenant(t)
        if (!props.reinvite) {
          if (props.tenant.isSystem()) {
            const o = [
              {
                value: "MASTER",
                label: intl.msg("reseller_type_master")
              },
              {
                value: "STANDALONE",
                label: intl.msg("reseller_type_standalone")
              }
            ]
            setResellerTypeOpts(o)
            setResellerType(o.find(o => o.value === "STANDALONE"))
            // setIndustry(null)
            // setParentReseller(null)
            // setCommissions(null)
            if (t.clientProps) {
              setCpm(t.clientProps.adSystem?.AMOBEE?.cpms?.IMAGE)
            }
          }
          else if (props.tenant.isReseller()) {
            setIndustry(props.tenant.industryId)
            setParentReseller({
              ...tenant, value: t.tenantId, label: `${t.tenantName} (${intl.msg("self")})`
            })
          }
        }
        else {
          setReinviting(true)
          setTenantId(t.tenantId)
          setTenantName(t.tenantName)
          setContactName(t.displayName)
          setEmailAddress(t.owner?.emailAddress)
          setCommissions(t.commissions)
          setHomePage(t.homePage)
          setContactInfo(t.contactInfo)
          setLogoUrl(t.logoUrl)
          if (t.clientProps) {
            setCpm(t.clientProps.adSystem?.AMOBEE?.cpms?.IMAGE)
          }
        }
      }
    }
  }, [props])

  useEffect(() => {
    if (tenant) {
      setBusy(intl.msg("loading"))
      api.getRegion(tenant.regionId)
        .then(({data}) => {
          log.debug(">>>Z: region=", data)
          setRegion({
            value: data.regionId,
            label: data.regionName,
          })
          loadBroadcastTemplateOpts()
            .catch((error) => {
              setFatal(error)
            })
        })
        .catch((error) => {
          setFatal(error)
        })
        .finally(function () {
          setBusy(null)
        })
    }
  }, [tenant])

  function loadBroadcastTemplateOpts() {
    log.debug("loadBroadcastTemplates")
    return api.getAllBroadcastTemplates(tenant.tenantId)
      .then(({data}) => {
        log.debug("loadBroadcastTemplates: data=", data)
        let opts = []
        opts = data.map(bt => {return {
          value: bt.broadcastTemplateId,
          label: bt.broadcastTemplateName
        }})
        log.debug("loadBroadcastTemplates: opt=", opts)
        setBroadcastTemplateOpts(opts)
        setBroadcastTemplates(opts)
        return opts
      })
      .catch(function (ex) {
        log.debug("Load Region failed: error=", ex)
      })
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_emailAddress", validateEmailAddress(emailAddress), "valid", opt)

    validateField("inp_tenantName", tenantName && (tenantName.trim().length > 0) && (tenantName !== emailAddress), "valid", opt)

    validateField("inp_contactName", contactName && contactName.trim().length > 0, "required", opt)
    validateField("inp_contactInfo", contactInfo && contactInfo.trim().length > 0, "required", opt)

    validateField("inp_homePage", validateUrl(homePage), "valid", opt)

    validateField("inp_logoUrl", logoUrl && logoUrl.trim().length > 0, "required", opt)

    validateField("inp_broadcastTemplates", broadcastTemplates && broadcastTemplates.length > 0, "required", opt)

    if (user?.isSystem()) {

      validateField("inp_region", region, "required", opt)
      validateField("inp_tenantPlan", tenantPlan, "required", opt)

      if (["MASTER"].includes(resellerType?.value)) {
        validateField("inp_maxLevels", parseInt(maxLevels) > 1 && parseInt(maxLevels) <= 4, "range", opt)
      }

      if (commissions === null || commissions.length === 0) {
        log.debug(">>>Z: empty commissions")
        validateField("inp_commission", false, "required", opt)
      }
      else {
        if (["STANDALONE", "SUB"].includes(resellerType?.value)) {
          const cs = parseFloat(commissions[0])
          validateField("inp_commission", !isNaN(cs) && cs > 0.00 && cs <= 20.00, "valid", opt)
        }
        else {
          if (maxLevels > commissions.length) {
            validateField("inp_commission_0", false, "valid", opt)
          }

          const cs = commissions.slice(0, maxLevels)

          if (cs.filter(c => isNaN(parseFloat(c))).length > 0) {
            validateField("inp_commission_0", false, "valid", opt)
          }
          else {
            const ttl = cs.map(c => parseFloat(c)).reduce((acc, curr) => acc + curr)
            log.debug("validate: ttl=", ttl)
            if (isNaN(ttl) || ttl <= 0.00 || ttl > 20.00) {
              validateField("inp_commission_0", false, "valid", opt)
            }
          }
        }
      }
    }

    if (customOnboardingPage && customOnboardingPage.trim().length > 0) {
      validateField("inp_customOnboardingPage", validateUrl(customOnboardingPage), "valid", opt)
    }

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid

  }

  async function handleInviteClick() {

    log.debug("handleInviteClick: 1")

    try {

      if (validate()) {

        setBusy(intl.msg("working"))

        let tid
        if (!tenantId) {
          const {data} = await api.createUuid()
          tid = data.uuid
        }
        else {
          tid = tenantId
        }

        log.debug("handleInviteClick: tenantId=", tid)

        let finalLogoUrl = logoUrl

        if (finalLogoUrl && finalLogoUrl.startsWith("data:")) {
          finalLogoUrl = await uploadImage({
            api: api,
            imageData: finalLogoUrl,
            imagePath: `t/${tid}/logo`,
            setImageUrl: setLogoUrl,
          })
        }

        let payload = {
          emailAddress: emailAddress,
          tenantId: tid,
          tenantName: tenantName,
          tenantPlanId: null,
          broadcastTemplateIds: broadcastTemplates.map(t => t.value),
          homePage: homePage,
          contactInfo: contactInfo,
          logoUrl: finalLogoUrl,
          customOnboardingPage: customOnboardingPage,
          createdBy: user.userId,
        }

        if (props.tenant.isSystem()) {
          payload.parentId = null
          payload.regionId = region.value
          payload.industryId = industry.value
          payload.maxDepth = resellerType.value === "MASTER" ? maxLevels - 1 : 0
          payload.commissions = commissions
        }
        else if (props.tenant.isReseller()) {
          payload.parentId = parentReseller.value
        }
        else {
          setAlert({error: intl.msg("error_failed")})
        }

        log.debug("handleInviteClick: payload=", payload)

        if (reinviting) {
          const {res} = await api.reinviteReseller(payload)
        }
        else {
          const {res} = await api.inviteReseller(payload)
        }

        window.location = mkHref({suffix: `/resellers`, tenant: tenant, user: user})

      }
      else {
        setAlert({error: intl.msg("error_invalid_form")})
      }
    }
    catch (error) {
      log.error("Validation error: ", error)
      handleError(error)
    }
    finally {
      setBusy(null)
    }
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError", error.code)
      const code = error.code
      const details = error.details
      switch (code) {
        case "BANNED_INVITER":
          setAlert({error: intl.msg("error_banned_user", details)})
          break
        case "UNKNOWN_INVITER":
        case "INVALID_INVITER":
          setAlert({error: intl.msg("invite_error_invalid_inviter", details), field: "inp_emailAddresses", constraint: "valid"})
          break
        case "DUPLICATE_INVITEE_USER":
          setAlert({error: intl.msg("invite_error_duplicate_user", details), field: "inp_emailAddresses", constraint: "unique"})
          break
        case "DUPLICATE_INVITEE_TENANT":
          setAlert({error: intl.msg("invite_error_duplicate_tenant", details), field: "inp_tenantName", constraint: "unique"})
          break
        case "BANNED_EMAIL_ADDRESS":
          setAlert({error: intl.msg("error_banned_email_address", details), field: "inp_emailAddresses", constraint: "valid"})
          break
        default:
          setAlert({error: intl.msg("error_failed", details)})
          break
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function loadRegions(input, options, additional) {
    log.debug(">>>Z: loadRegions: ", input, options)
    return api.listRegions({view: "BASIC", extraParams: `${input ? `filter=regionName:${encodeURIComponent(input)}&` : ""}sort=regionName:ASC`, cursor: additional?.cursor, limit: 20})
      .then(({data, nextCursor}) => {
        let opt = data.map(c => {
          return {
            value: c.regionId,
            label: c.regionName,
            pop: c.pop
          };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug("loadRegions: res=", res)
        return res
      });
  }

  function loadIndustries(search, loadedOptions, additional) { //, loadedOptions, { cursor }) {
    return api.listIndustries({extraParams: mkExtraParams({filterParams:{industryName: search}}, {sortParams: {industryName: "ASC"}}), cursor: additional?.cursor, limit: 50})
      .then(({data, nextCursor}) => {
        let opt = data.map(c => {
          return {
            value: c.industryId,
            label: c.industryName,
          };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        return res
      })
      .catch((error) => {
        log.debug("Error loading regions: ", error)
      })
  }

  function handleEmailAddressChange(e) {
    if (e) {
      let v = e.target.value
      if (v) {
        v = v.toLowerCase()
      }
      setEmailAddress(v)
      setTouched(true)
    }
  }

  function handleRegionChange(v) {
    setRegion(v)
    setTouched(true)
  }

  function handleResellerTypeChange(v) {
    setResellerType(v)
    setTouched(true)
  }

  function handleResellerChange(v) {
    setParentReseller(v)
    setTouched(true)
  }

  function handleMaxLevelsChange(v) {
    setMaxLevels(v)
    setTouched(true)
  }

  function handleCommissionsChange(i, v) {
    setCommissions(prev => {
      if (prev === null) {
        prev = []
      }
      if (i >= prev.length - 1) {
        const padLength = Math.max(0, i - prev.length + 1);
        prev = prev.concat(new Array(padLength).fill(0.00))
      }
      prev.splice(i, 1, v);
      log.debug(">>>Z: handleCommissionsChange: prev=", [...prev]);
      return [...prev]
    })
  }

  const ttlCommissions = commissionsRef && commissionsRef.current ? commissionsRef.current.slice(0, maxLevelsRef && maxLevelsRef.current ? maxLevelsRef.current : 0).reduce((acc, cur) => cur ? acc + cur : acc) : 0.0

  function handleCpmChange(v) {
    setCpm(v)
    setTouched(true)
  }

  function handleTenantNameChange(e) {
    setTenantName(e.target.value)
    setTouched(true)
  }

  function handleContactNameChange(e) {
    setContactName(e.target.value)
    setTouched(true)
  }

  function handleIndustryChange(v) {
    log.debug("handleIndustryChange: v=", v)
    setIndustry(v)
    setTouched(true)
  }

  function handleBroadcastTemplateChange(value) {
    log.debug("handleBroadcastTemplateChange: value=", value)
    setBroadcastTemplates(value)
    setTouched(true)
  }

  function handleRepresentative(v) {
    setRepresentative(v)
    setTouched(true)
  }

  function handleHomePageChange(e) {
    const value = e.target.value
    setHomePage(value)
    setTouched(true)
  }

  function handleHomePageBlur() {
    if (homePage && homePage.trim().length > 0) {
      try {
        if (homePage.startsWith("http://")) {
          setHomePage((prev) => `https://${prev.substring(7)}`)
        }
        else if (!homePage.startsWith("https://")) {
          setHomePage((prev) => `https://${prev}`)
        }
      }
      catch (e) {
      }
    }
  }

  function handleContactInfoChange(e) {
    setContactInfo(e.target.value)
    setTouched(true)
  }

  async function handleLogoChange(e) {

    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let result

      setBusy(intl.msg("processing"))

      try {
        result = await processImageFile({
          file: file,
          maxFileSize: 100_000,
          width: 100,
          height: 100,
        })
      }
      catch (ex) {
        setAlert({error: "error_image_process_failed"})
      }
      finally {
        setBusy(null)
      }

      if (result.error) {
      // if (result.warning || result.error) {
        setLogoImageInfo(result)
        const el = document.getElementById("inp_dlg_logoImagePicker")
        el.click()
      }
      else {
        setLogoUrl(result.dataUrl)
        setTouched(true)
      }

      clearError("inp_logoUrl")
    }
  }

  function handleLogoClear() {
    setLogoUrl(null)
    setTouched(true)
  }

  function handleCustomOnboardingPageChange(e) {
    setCustomOnboardingPage(e.target.value)
    setTouched(true)
  }

  function Header(props) {
    return (
      <>
        <h1>{intl.msg("reseller_new_title")}</h1>
      </>
    )
  }

  function handleOpenDialog() {
    setAlert(null)
    if (inpEmailAddressRef.current) {
      log.debug(">>>Z: openDialog: invoked", inpEmailAddressRef)
      inpEmailAddressRef.current.disabled = false
      inpEmailAddressRef.current.focus()
    }
  }

  function handleCloseDialog() {
    closeDialog("dlg_reseller_invite")
  }

  function isEditable() {
    return true
  }
  
  return (

    <>
      <ModalDialog
        dialogId="dlg_reseller_invite"
        size="md"
        keyboard="true"
        backdrop="static"
        onOpen={handleOpenDialog}
        onClose={handleCloseDialog}>

        <ModalDialog.Header
          title={reinviting ? intl.msg("reinvite_reseller_title") : intl.msg("reseller_invite_title")}
          description={reinviting ? intl.msg("reinvite_reseller_description") : intl.msg("reseller_invite_description")}
          alert={alert}
        />

        <ModalDialog.Body>

          <FormGroups>
            <FormGroup>
              <FormGroup.Label text={intl.msg("email_address")} description={intl.msg("account_invite_email_address_description")} htmlFor="inp_emailAddress"/>
              <FormGroup.Control>
                <input ref={inpEmailAddressRef} key="inp_emailAddress" id="inp_emailAddress" className="form-control" type="text" value={emailAddress} onChange={handleEmailAddressChange} isDisabled={!isEditable()}/>
                <InfoBlock>
                  <InfoText validate="valid" disabled={!isEditable()}>{intl.msg("helptext_email_address_valid")}</InfoText>
                  <InfoText validate="unique" disabled={!isEditable()}>{intl.msg("helptext_email_address_unique")}</InfoText>
                  <InfoText validate="required" disabled={!isEditable()}>{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Control>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label text={intl.msg("account_name")} description={intl.msg("account_invite_account_name_description")} htmlFor="inp_tenantName"/>
              <FormGroup.Control>
                <input className="form-control" type="text" key="inp_tenantName" id="inp_tenantName" value={tenantName} onChange={handleTenantNameChange} isDisabled={!isEditable()}/>
                <InfoBlock>
                  <InfoText validate="unique" disabled={!isEditable()}>{intl.msg("account_name_helptext_unique")}</InfoText>
                  <InfoText validate="required" disabled={!isEditable()}>{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Control>
            </FormGroup>
          </FormGroups>

          {
            user?.isSystem() && props.tenant.isSystem() &&

              <FormGroups>

                <FormGroup>
                  <FormGroup.Label text={intl.msg("reseller_type")} description={intl.msg("reseller_type_description")} htmlFor="inp_resellerType"/>
                  <FormGroup.Control>
                    <Select
                      id="inp_resellerType"
                      className="react-select"
                      classNamePrefix="react-select"
                      value={resellerType}
                      options={resellerTypeOpts}
                      onChange={handleResellerTypeChange}
                      isDisabled={user?.isReseller() || !isEditable()}
                    />
                    <InfoBlock>
                      <InfoText validate="required" disabled={!isEditable()}>{intl.msg("helptext_required")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Control>
                </FormGroup>

                {
                  resellerType?.value === "MASTER" ? (
                    <>

                      <FormGroup>
                        <FormGroup.Label text={intl.msg("reseller_max_levels")} description={intl.msg("reseller_max_levels_description")} htmlFor="inp_maxLevels"/>
                        <FormGroup.Control>
                          <div style={{width: "50px"}}>
                            <NumericInput id="inp_maxLevels" className="form-control" style={false} strict={true} min={2} max={5} precision={0} disabled={!isEditable()} value={maxLevels} onChange={handleMaxLevelsChange}/>
                          </div>
                          <InfoBlock>
                            <InfoText validate="range" disabled={!isEditable()}>{intl.msg("reseller_max_levels_helptext_valid", {minValue: tenant.commissions && tenant.commissions.length > 0 ? tenant.commissions.length : 2, maxValue: 5})}</InfoText>
                            <InfoText validate="required" disabled={!isEditable()}>{intl.msg("helptext_required")}</InfoText>
                          </InfoBlock>
                        </FormGroup.Control>
                      </FormGroup>

                      {
                        maxLevels > 0 &&
                        <>
                          <FormGroup>
                            <FormGroup.Label text={intl.msg("reseller_commissions")} description={intl.msg("reseller_commissions_description")} htmlFor="inp_commissio"/>
                            <FormGroup.Control>
                              <table border="0">
                                {
                                  rangeMap(0, maxLevels - 1, (i) => {
                                    return (
                                      <tr>
                                        <td>
                                          <label className="col-form-label pr-2" htmlFor={`inp_commission_${i}`}>
                                            <span>
                                              {
                                                i === 0 ? (
                                                  intl.msg("reseller_type_master")
                                                ) : maxLevels > 2 && i < maxLevels -1 ? (
                                                  intl.msg("reseller_type_super")
                                                ) : (
                                                  intl.msg("reseller_type_sub")
                                                )
                                              }:
                                            </span>
                                          </label>
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center gap-1">
                                            <div style={{width: "80px"}}>
                                              <NumericInput id={`inp_commission_${i}`} className="form-control" style={false} strict={true} min={1.00} max={99.00} precision={2} disabled={!isEditable()} value={commissions && commissions.length > 0 ? commissions[i] : 0.00} onChange={(v) => handleCommissionsChange(i, v)}/>
                                            </div>
                                            <span className="col-form-label">%</span>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                                <tr>
                                  <td>
                                    <label className="col-form-label pr-2" htmlFor="inp_commission">
                                      {intl.msg("total")}:
                                    </label>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-1 align-items-center" style={{borderTop: "1px solid var(--form-divider-color)", borderBottom: "4px double var(--form-divider-color)"}}>
                                      <div style={{width: "80px", textAlign: "right", paddingRight: "25px", color: (ttlCommissions <= 20 ? "inherit" : "var(--color-danger)")}}>
                                        {ttlCommissions.toFixed(2)}
                                      </div>
                                      <div className="col-form-label" style={{paddingBottom: "0"}}>%</div>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                              <InfoBlock>
                                <InfoText validate="valid" disabled={!isEditable()}>{intl.msg("reseller_commissions_helptext_valid")}</InfoText>
                                <InfoText validate="required" disabled={!isEditable()}>{intl.msg("helptext_required")}</InfoText>
                              </InfoBlock>
                            </FormGroup.Control>
                          </FormGroup>
                        </>
                      }
                    </>
                  ) : resellerType?.value === "STANDALONE" ? (
                    <FormGroup>
                      <FormGroup.Label text={intl.msg("reseller_commission")} description={intl.msg("reseller_commission_description")} htmlFor="inp_commission"/>
                      <FormGroup.Control>
                        <div className="d-flex gap-1 align-items-center" style={{width: "100px"}}>
                          <NumericInput id="inp_commission" className="form-control" strict={true} style={false} min={1.00} max={99.00} precision={2} disabled={!isEditable()} value={commissions && commissions.length > 0 ? commissions[0] : 0.00} onChange={(v) => handleCommissionsChange(0, v)}/>
                          <span className="col-form-label">%</span>
                        </div>
                        <InfoBlock>
                          <InfoText validate="valid" disabled={!isEditable()}>{intl.msg("reseller_commission_helptext_valid")}</InfoText>
                          <InfoText validate="required" disabled={!isEditable()}>{intl.msg("helptext_required")}</InfoText>
                        </InfoBlock>
                      </FormGroup.Control>
                    </FormGroup>
                  ) : (
                    <></>
                  )
                }

              </FormGroups>
          }

          <FormGroups>

            <FormGroup>
              <FormGroup.Label text={intl.msg("region")} description={intl.msg("account_invite_region_description")} htmlFor="inp_region"/>
              <FormGroup.Control>
                <AsyncPaginate
                  className="react-select"
                  classNamePrefix="react-select"
                  cacheOptions
                  value={region}
                  defaultOptions={true}
                  loadOptions={loadRegions}
                  reduceOptions={reduceGroupedOptions}
                  // menuPortalTarget={document.body}
                  placeholder=""
                  isDisabled={user?.isResellerUser() || !isEditable()}
                  onChange={handleRegionChange}
                />
                <InfoBlock>
                  <InfoText validate="required" disabled={!isEditable()}>{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Control>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label text={intl.msg("industry")} description={intl.msg("account_invite_industry_description")} htmlFor="inp_industry"/>
              <FormGroup.Control>
                <AsyncPaginate id="inp_industry" className="react-select" classNamePrefix="react-select" cacheOptions isClearable placeholder={""} value={industry} debounceTimeout={800} defaultOptions={true} loadOptions={loadIndustries} reduceOptions={reduceGroupedOptions} onChange={handleIndustryChange} isDisabled={!isEditable()}/><InfoBlock></InfoBlock>
                <InfoBlock>
                  <InfoText validate="required" disabled={!isEditable()}>{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Control>
            </FormGroup>

          </FormGroups>

          <FormGroups>

            <FormGroup>
              <FormGroup.Label text={intl.msg("account_contact_name")} description={intl.msg("account_contact_name_description")} htmlFor="inp_contactName"/>
              <FormGroup.Control>
                <input className="form-control" type="text" key="inp_contactName" id="inp_contactName" value={contactName} onChange={handleContactNameChange} isDisabled={!isEditable()}/>
                <InfoBlock>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Control>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_contactInfo" description={intl.msg("account_contact_info_description")}>
                {intl.msg("account_contact_info")}
              </FormGroup.Label>
              <FormGroup.Control>
                <textarea id="inp_contactInfo" className="form-control" rows="2" autoComplete="no" value={contactInfo} disabled={!isEditable()} onChange={handleContactInfoChange} style={{whiteSpace: "pre-line"}}/>
                <InfoBlock>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Control>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_homePage" description={intl.msg("account_home_page_description")}>
                {intl.msg("account_home_page")}
              </FormGroup.Label>
              <FormGroup.Control>
                <div className="input-group">
                  <input id="inp_homePage" type="text" className="form-control" value={homePage} onChange={handleHomePageChange} onBlur={handleHomePageBlur}/>
                  {
                    validateUrl(homePage) &&
                      <div className="input-group-append">
                        <button className="btn btn-secondary" style={{borderTop: "1px solid #555", borderRight: "1px solid #555", borderBottom: "1px solid #555", width: "41px"}} type="button" title="View page in new browser tab" onClick={(e) => window.open(homePage)}><i className="fas fa-external-link-alt"></i></button>
                      </div>
                  }
                </div>
                <InfoBlock>
                  <InfoText validate="valid">{intl.msg("helptext_web_address_valid")}</InfoText>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Control>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_logoUrl" description={intl.msg("account_logo_description")}>
                {intl.msg("account_logo")}
              </FormGroup.Label>
              <FormGroup.Control>
                <ImageDropBox
                  id="inp_logoUrl"
                  image={logoUrl}
                  imageClassName={"account-logo"}
                  width={100}
                  height={100}
                  onChange={handleLogoChange}
                  onClear={handleLogoClear}
                  disabled={!isEditable()}/>
                <InfoBlock>
                  <InfoText>{intl.msg("account_invite_logo_helptext_2")}</InfoText>
                  <InfoText validate="valid_size">{intl.msg("account_invite_logo_helptext_valid_size")}</InfoText>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Control>
            </FormGroup>

          </FormGroups>

        </ModalDialog.Body>

        <ModalDialog.Footer>
          <ModalDialog.Footer.Controls>
            {
              (reinviting || touched) &&
                <ActionButton name="apply" text={intl.msg("add_new")} flavor="primary" onClick={handleInviteClick}/>
            }
            <ActionButton name="cancel" onClick={handleCloseDialog}/>
          </ModalDialog.Footer.Controls>
        </ModalDialog.Footer>

    </ModalDialog>

    <ImageDialog
      ref={logoImagePickerDialogRef}
      id="dlg_logoImagePicker"
      title="Logo Image"
      imageInfo={logoImageInfo}
      setTargetImageData={setLogoUrl}
      setTouched={setTouched}/>
    </>
  )
}
