import React, {useEffect, useState} from 'react'
import CreatableSelect from 'react-select/creatable'
import {components, MultiValueGenericProps, MultiValueProps, OnChangeValue, Props,} from 'react-select';
import {SortableContainer, SortableContainerProps, SortableElement, SortEndHandler, SortableHandle} from "react-sortable-hoc"
import getLogger from "./Logging.js"

const log = getLogger("TagInput")

function arrayMove(array, from, to) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  );
  return slicedArray;
}

const SortableMultiValue = SortableElement((props) => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const innerProps = { ...props.innerProps, onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
  }
);

const SortableMultiValueLabel = SortableHandle(
  (props) => <components.MultiValueLabel {...props} />
);

const SortableCreatableSelect = SortableContainer(CreatableSelect)

export default function TagInput2(props) {

  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState(null)

  const breakKeys = props.breakKeys || ["Enter"]

  useEffect(() => {
    setValue(props.value)
  }, [props.value]);

  // const notifyChange = useCallback(() => {
  //   props.onChange(value)
  // }, [props.onChange]);

  function notifyChange(r) {
    log.debug("notifyChange: 1: r=", r)
    if (props.onChange) {
      log.debug("notifyChange: 2: r=", r)
      props.onChange(r)
    }
  }

  // useEffect(() => {
  //     if (props.onChange) {
  //       props.onChange(value)
  //     }
  //   }, [value]);

    function createOption(label) {
        return (
            {
                label,
                value: label,
            }
        )
    }

    function setInputValueFromEvent(event, inputValue) {
      if (inputValue) {
        let v = inputValue.trim()
        if (v.length > 0) {
          if (props.lowerCase) {
            v = v.toLowerCase()
          }
          if (v.startsWith("mailto:")) {
            v = v.substring(7)
          }
          // setValue(prevValue => prevValue && prevValue.length ? (!prevValue.some(pv => pv.value == v) ? [...prevValue, createOption(v)] : prevValue) : [createOption(v)])
          setValue(prevValue => {
            let r
            if (prevValue && prevValue.length) {
              r = (!prevValue.some(pv => pv.value == v) ? [...prevValue, createOption(v)] : prevValue)
            }
            else {
              r = [createOption(v)]
            }
            notifyChange(r)
            log.debug("setInputValueFromEvent: r=", r)
            return r
          })
        }
        setInputValue("")
        event.preventDefault();
      }
    }

    function handleKeyDown(event) {
        if (!inputValue) return;
        if (breakKeys.includes(event.key)) {
            setInputValueFromEvent(event, inputValue)
        }
    }

    function handleBlur(event) {
      setInputValueFromEvent(event, inputValue)
    }

    function handlePaste(event) {    
        let paste = (event.clipboardData || window.clipboardData).getData('text');
        for (const inputValue of paste.split(/[\n\t, ]/)) {
            if (inputValue.length > 0) {
                setInputValueFromEvent(event, inputValue)
            }
        }
    }

    function handleSortEnd({ oldIndex, newIndex }) {
      const newValue = arrayMove(value, oldIndex, newIndex);
      setValue(newValue);
      console.log(
        'Values sorted:',
        newValue.map((i) => i.value)
      );
    }
    
    // function styles() {
    //   log.debug("getting styles...")
    //   return {
    //     multiValueLabel: (styles, { data }) => {
    //       log.debug("getting styles 3 ...:data=", styles, data)
    //       return {
    //         ...styles,
    //         color: "hsl(10%, 10%, 20%)",
    //         fontSize: "20%"
    //      }
    //     }
    //   }
    // }

    return (
        <div onPaste={handlePaste} style={{height: '100%', width: '100%' }}>
          <SortableCreatableSelect
              id={props.id}
              className={props.className}
              classNamePrefix={props.classNamePrefix}
              inputValue={inputValue}
              isClearable
              isMulti
              placeholder=""
              menuIsOpen={false}
              onChange={e => props.onChange(e)}
              onInputChange={v => setInputValue(v)}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              onPaste={handlePaste}
              value={value}
              useDragHandle
              axis={"xy"}
              onSortEnd={handleSortEnd}
              distance={4}
              getHelperDimensions={({ node }) => node.getBoundingClientRect()}
              isDisabled={props.isDisabled}
              components={{
                // @ts-ignore We're failing to provide a required index prop to SortableElement
                DropdownIndicator: null,
                MultiValue: SortableMultiValue,
                MultiValueLabel: SortableMultiValueLabel,
              }}
              styles={{
                multiValueLabel: (providedStyles, props) => {
                  return {...providedStyles,
                    color: (props.data.__isError__ ? 'var(--form-control-error-color) !important' : 'var(--form-control-react-select-multivalue-text-color) !important')
                  }
                }
            }}
          />        
        </div>
    )

}