import React, { Fragment, Component, useState, useRef, useEffect }  from "react"
import { Markdown } from './components/MarkdownUtils.js'
import { useIntlEx } from "./components/IntlUtils.js"
import { formatDate } from "./components/CustomFieldUtils.js"
import { mkFieldRowClassName, getStyle } from "./components/BroadcastComps.js"
import getLogger from "./components/Logging.js"
import './App.css'
import './PagePreview.css'

const MEDUSA_ENDPOINT = process.env.REACT_APP_MEDUSA_ENDPOINT

const log = getLogger("PagePreview")

export default function PagePreview(props) {

  const { intl } = useIntlEx()
  const [extPageLoaded, setExtPageLoaded] = useState(false)

  const markdown = new Markdown({
    homePage: props.tenant?.homePage,
    clickUrls: props.tenant?.clickUrls,
    allowedDomains: props.tenant?.allowedDomains,
    disallowedDomains: props.tenant?.disallowedDomains,
    allowLinks: true,
  })

  function handleExtPageLoad() {
    log.debug("handleExtPageLoad")
    setExtPageLoaded(true)
    if (props.onLoad) {
      props.onLoad()
    }
  }

  function handleExtPageError() {
    log.debug("handleExtPageError")
    setExtPageLoaded(false)
    if (props.onError) {
      props.onError()
    }
  }

  return (
    
    "SOLV" === props.pageType ? (
      <div style={{width: props.width ? props.width: "100%", fontFamily: getStyle(props.pageStyle, "body", "font"), color: getStyle(props.pageStyle, "body", "textColor"), backgroundColor: getStyle(props.pageStyle, "body", "backgroundColor")}}>

        {
          "DRAFT" === props.publishStatus &&
            <div className="alert alert-dismissible alert-danger sticky-top">
              <center><i className="fas fa-exclamation-triangle mr-1"></i>This is an <strong>unpublished</strong> broadcast. This is for preview only.</center>
            </div>
        }


        {
          (props.tenantDisplayName || props.logoUrl) &&
            <div className="adpage-container">
              <div className="adpage-page-contact-container">
                <div className="adpage-logo-container">
                  <img src={props.logoUrl} loading="lazy"/>
                </div>
                <div className="adpage-contact-container">
                  <h1>{props.tenantDisplayName}</h1>
                  <p><a href={props.homePage} target="_blank">{props.homePage}</a></p>
                  <p>{props.contactInfo}</p>
                </div>
              </div>
            </div>
        }

        <div className="adpage-container">
          <div className="adpage-page-header" style={{fontFamily: getStyle(props.pageStyle, "header", "font"), color: getStyle(props.pageStyle, "header", "textColor"), backgroundColor: getStyle(props.pageStyle, "header", "backgroundColor")}}>
            {props.pageHeader}
          </div>  
        </div>
        
        <div className="adpage-container">
          <div className="adpage-page-content-container" style={{fontFamily: getStyle(props.pageStyle, "content", "font"), color: getStyle(props.pageStyle, "content", "textColor"), backgroundColor: getStyle(props.pageStyle, "content", "backgroundColor"), border: `1px solid ${getStyle(props.pageStyle, "header", "backgroundColor")}`}}>
            
            <div className="adpage-page-content-img-container">
              {
                props.pageImageUrl && props.pageImageUrl.trim().length > 0 &&
                  <img src={props.pageImageUrl} loading="lazy"/>
              }
            </div>
            
            <div className="adpage-page-content-content-container" style={{fontFamily: getStyle(props.pageStyle, "content", "font"), color: getStyle(props.pageStyle, "content", "textColor"), backgroundColor: getStyle(props.pageStyle, "content", "backgroundColor")}}>
              <h1 style={{fontFamily: getStyle(props.pageStyle, "title", "font"), color: getStyle(props.pageStyle, "title", "textColor")}}>{props.pageTitle}</h1>
              <div className="text">
                {
                  props.pageFormFields &&
                    <>
                      <div className="fields">
                        { 
                          props.pageFormFields.map(field => 
                            field.value && field.value != '' &&
                              <div className={mkFieldRowClassName(field.opts)}>
                                <div className="field-label">{field.label}:</div>
                                {
                                  "TEXT_BLOCK" === field.type ? (
                                    <div className="field-value" dangerouslySetInnerHTML={{ __html: field.value ? markdown.toHtml(field.value) : "" }}/>                            
                                  ) : "DATE" === field.type ? (
                                    <div className="field-value">{formatDate(field.value, props.regionId)}</div>
                                  ) : (
                                    <div className="field-value">{field.value}</div>
                                  )                              
                                }
                              </div>  
                          )
                        }
                      </div>
                    </>
                }                              
              </div>
            </div>
          </div>
        </div>

        {
          true === props.feedbackEnabled &&
            <div className="adpage-container">
              <div className="feedback-container">

              {
                  props.feedbackFormFields &&
                    <>
                      <form name="frm_main" method="POST" onSubmit="return validateForm()" style={{marginTop: "20px"}}>
                        <div className="fields">
                          { 
                            props.feedbackFormFields.map(field => 
                              <div className={mkFieldRowClassName(field.opts)}>
                                <div className="field-label">{field.label}:</div>
                                <div className="field-value">
                                  {
                                    "TEXT_BLOCK" === field.type ? (                                      
                                      "READONLY" === field.input ? (
                                        <div className="field-value" dangerouslySetInnerHTML={{ __html: field.value ? markdown.toHtml(field.value) : "" }}/>                            
                                      ) : (
                                        <>
                                          <textarea name={`F_${field.id}`} cols="80" rows="4" disabled={true}/>
                                          <small>{field.desc}</small>
                                        </>
                                      ) 
                                    ) : "SELECT" == field.type && field.opts && field.opts.selectList ? (
                                      "READONLY" === field.input ? (
                                        field.value
                                      ) : (
                                        <>
                                          <select
                                            name={`F_${field.id}`} disabled={true}>
                                              {
                                                field.opts.selectList.split(",").map(o => <option value={o}>{o}</option>)
                                              }
                                          </select>
                                          <small>{field.desc}</small>
                                        </>
                                      )
                                    ) : (
                                      "READONLY" === field.input ? (
                                        field.value
                                      ) : (
                                        <>
                                          <input name={`F_${field.id}`} disabled={true}/>
                                          <small>{field.desc}</small>
                                        </>
                                      )
                                    )                              
                                  }
                                </div>
                              </div>
                            )
                          }
                          <button type="submit" disabled="disabled" style={{color: getStyle(props.pageStyle, "header", "textColor"), backgroundColor: getStyle(props.pageStyle, "header", "backgroundColor")}}>Submit</button>
                        </div>
                      </form>

                    </>
                }
             </div> 
            </div>
          }

          {
            props.feedbackEnabled && props.feedbackThankyouText &&
              <div className="mt-4" style={{backgroundColor: "var(--form-background-color)"}}>
                <div className="live-preview-section-divider" style={{fontSize: "18px", paddingTop: "40px"}}>{intl.msg("thank_you_text_preview_section")}</div>
                <div style={{backgroundColor: "white"}}>
                  <div className="thank-you">
                    <div dangerouslySetInnerHTML={{ __html: props.feedbackThankyouText ? markdown.toHtml(props.feedbackThankyouText) : "" }}/>
                  </div>
                </div>
              </div>
          }

      </div>
    ) : (
      <>
        {
          // validateUrl(props.pageUrl, true) &&
            <div className="ext-page-preview" style={{width: props.width ? props.width: "100%"}}>
              <img style={{display: extPageLoaded ? "block" : "none"}} src={`${MEDUSA_ENDPOINT}?url=${encodeURI(props.pageUrl)}`} alt="Custom Page" onLoad={handleExtPageLoad} onError={handleExtPageError}/>
            </div>
        }
      </>
    )                         
  )
}