import React, { Fragment, useState, useRef, useEffect }  from 'react'
import { useParams, useHistory, useLocation, Redirect }  from 'react-router-dom'
import { useMutation } from 'react-query'
import NumericInput from 'react-numeric-input'
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {useSolv, useTenant} from "./components/SolvProvider"
import MainMenu from "./MainMenu.js"
import {FormHeader, FormBody, MainContainer, SectionHeader} from "./components/FormComps.js"
import CreativePreview from './CreativePreview.js'
import PagePreview from "./PagePreview.js"
import TemplateCloneDialog from "./TemplateCloneDialog.js"
import TemplateDeleteDialog from "./TemplateDeleteDialog.js"
import TemplateSetDefaultDialog from "./TemplateSetDefaultDialog.js"
import CustomFieldEditDialog from './CustomFieldEditDialog.js'
import CustomFieldDeleteDialog from './CustomFieldDeleteDialog.js'
import CustomFieldCloneDialog from './CustomFieldCloneDialog.js'
import CustomFieldCloneAsDialog from './CustomFieldCloneAsDialog.js'
import TemplateDistributeDialog from "./TemplateDistributeDialog.js"
import ImageDialog from "./components/ImageDialog.js"
import { processImageFile, uploadImage, ImageDropBox } from "./components/ImageUtils.js"
import ToggleButton from './components/ToggleButton.js'
import { TagBox, TagLabel } from "./components/TagBox.js"
import { validateField, showError, clearError, clearAllErrors, InfoBlock, InfoText, validateUrl } from "./components/ValidationUtils.js"
import { parseDate, getDateFormat, getFieldInputName, getFieldLayoutName, getFieldLabelStyleName, getFieldValueStyleName } from "./components/CustomFieldUtils.js"
import {getClickActionOpts, getClickActionOpt, getStyle, getWhatsAppPhoneNoFromPageUrl, getMessageFormatOpt, getMessageFormatOpts} from './components/BroadcastComps.js'
import { useIntlEx } from "./components/IntlUtils.js"
import {useResizeDetector} from "react-resize-detector";
import {default as Select} from "react-select";
import {AccountTabs} from "./components/AccountUtils";
import {openDialog} from "./components/DialogUtils";
import TemplateDetailsDialog from "./TemplateDetailsDialog";
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("Template")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

const DEFAULT_STYLE = {
  border: {
    color: "#FF0000"
  },
  body: {
    font: "Arial",
    textColor: "#000000",
    backgroundColor: "#FFFFFF"
  },
  header: {
    font: "Arial",
    textColor: "#FFFFFF",
    backgroundColor: "#FF0000"
  },
  title: {
    font: "Arial",
    textColor: "#000000",
    backgroundColor: "#FFFFFF"
  },
  content: {
    font: "Arial",
    textColor: "#000000",
    backgroundColor: "#FFFFFF"
  },
  footer: {
    font: "Arial",
    textColor: "#FFFFFF",
    backgroundColor: "#FF0000"
  },
}

export default function Template(props) {

  const {api, session, busy, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const location = useLocation()
  const history = useHistory()

  const params = useParams()

  const [isAddMode, setAddMode] = useState(!params.templateId)
  const [broadcastTemplateId, setBroadcastTemplateId] = useState(params.templateId ? params.templateId : null)

  const {tenant} = useTenant(params.tenantId)

  const [touched, setTouched] = useState(false)
  const [alert, setAlert] = useState(null)

  const [broadcastTemplateName, setBroadcastTemplateName] = useState(null)
  const [broadcastTemplateTenantId, setBroadcastTemplateTenantId] = useState(null)
  const [orgName, setOrgName] = useState(null)
  const [orgHomePage, setOrgHomePage] = useState(null)
  const [orgContactInfo, setOrgContactInfo] = useState("")
  const [orgLogoUrl, setOrgLogoUrl] = useState(null)
  const [creativeType, setCreativeType] = useState("SOLV")
  const [messageFormat, setMessageFormat] = useState(null)
  const [creativeHeader, setCreativeHeader] = useState(null)
  const [creativeTitle, setCreativeTitle] = useState(null)
  const [creativeImageUrl, setCreativeImageUrl] = useState(null)
  const [creativeContent, setCreativeContent] = useState(null)
  const [creativeForm, setCreativeForm] = useState(null)
  const [creativeFormFields, setCreativeFormFields] = useState(null)
  const [creativeFooter, setCreativeFooter] = useState(null)
  const [creativeStyle, setCreativeStyle] = useState(DEFAULT_STYLE)
  const [creativeTypeLocked, setCreativeTypeLocked] = useState(null)
  const [creativeHeaderLocked, setCreativeHeaderLocked] = useState(null)
  const [creativeTitleLocked, setCreativeTitleLocked] = useState(null)
  const [creativeFooterLocked, setCreativeFooterLocked] = useState(null)
  const [creativeImageUrlLocked, setCreativeImageUrlLocked] = useState(null)
  const [pageType, setPageType] = useState("SOLV")
  const [pageUrl, setPageUrl] = useState(null)
  const [clickAction, setClickAction] = useState(null)
  const [whatsAppPhoneNo, setWhatsAppPhoneNo] = useState(null)
  const [pageHeader, setPageHeader] = useState(null)
  const [pageTitle, setPageTitle] = useState(null)
  const [pageContent, setPageContent] = useState(null)
  const [pageForm, setPageForm] = useState(null)
  const [pageFormFields, setPageFormFields] = useState(null)
  const [pageImageUrl, setPageImageUrl] = useState(null)
  const [pageStyle, setPageStyle] = useState(DEFAULT_STYLE)
  const [pageTypeLocked, setPageTypeLocked] = useState(null)
  const [pageHeaderLocked, setPageHeaderLocked] = useState(null)
  const [pageTitleLocked, setPageTitleLocked] = useState(null)
  const [pageUrlLocked, setPageUrlLocked] = useState(null)
  const [pageImageUrlLocked, setPageImageUrlLocked] = useState(null)
  const [extPageLoading, setExtPageLoading] = useState(null)
  const [feedbackEnabled, setFeedbackEnabled] = useState(null)
  const [feedbackForm, setFeedbackForm] = useState(null)
  const [feedbackFormFields, setFeedbackFormFields] = useState(null)
  const [feedbackThankyouText, setFeedbackThankyouText] = useState(null)
  const [createdBy, setCreatedBy] = useState(null)
  const [providedTemplate, setProvidedTemplate] = useState('')
  const [defaultTemplate, setDefaultTemplate] = useState(false)
  const [creativeImageInfo, setCreativeImageInfo] = useState(null)
  const creativeImagePickerDialogRef = useRef(null)
  const [pageImageInfo, setPageImageInfo] = useState(null)
  const pageImagePickerDialogRef = useRef(null)
  const [orgLogoImageInfo, setOrgLogoImageInfo] = useState(null)
  const orgLogoImagePickerDialogRef = useRef(null)

  const { width: pagePreviewWidth, height: pagePreviewHeight, ref: pagePreviewRef } = useResizeDetector()

  const fontOpts = [
    {
      label: "Arial",
      value: "Arial"
    },
    {
      label: "Brush Script MT",
      value: "Brush Script MT"
    },
    {
      label: "Courier New",
      value: "Courier New"
    },
    {
      label: "Garamond",
      value: "Garamond"
    },
    {
      label: "Georgia",
      value: "Georgia"
    },
    {
      label: "Tahoma",
      value: "Tahoma"
    },
    {
      label: "Times New Roma",
      value: "Times New Roma"
    },
    {
      label: "Trebuchet",
      value: "Trebuchet"
    },
    {
      label: "Verdana",
      value: "Verdana"
    },
  ]

  useEffect(() => {
    log.debug("useEffect: invoked", tenant)
    try {
      if (tenant) {
        if (isAddMode) {
          setBusy(intl.msg("loading"))
          return createBroadcastTemplateId()
            .then(function () {
              setBroadcastTemplateTenantId(tenant.tenantId)
              setCreativeType("SOLV")
              setMessageFormat(getMessageFormatOpt("SOLV", tenant, intl))
              setCreativeStyle(DEFAULT_STYLE)
              setPageType("SOLV")
              setPageStyle(DEFAULT_STYLE)
              setClickAction(getClickActionOpt("SOLV", tenant, intl))
              setFeedbackEnabled(true)
              setOrgName(tenant.displayName)
              setOrgLogoUrl(tenant.logoUrl)
              setOrgContactInfo(tenant.contactInfo)
              setOrgHomePage(tenant.homePage)
            })
            .catch((error) => {
              log.debug("Fatal Error: creating broadcastTemplateId: ", error)
              setFatal(error)
            })
            .finally(() => {
              setBusy(null)
            })
        }
        else {
          loadBroadcastTemplate(tenant.tenantId, broadcastTemplateId)
            .catch((error) => {
              log.debug("Fatal Error: creating UUID: ", error)
              setFatal(error)
            })
        }
      }
    }
    catch (e) {
      log.debug("ERROR:", e)
    }
  }, [tenant, isAddMode])

  function createBroadcastTemplateId() {
    return api.createUuid()
        .then(({data}) => {
          log.debug("createBroadcastTemplateId: data=", data)
          setBroadcastTemplateId(data.uuid)
        })
  }

  function loadBroadcastTemplate(tenantId, broadcastTemplateId) {
    return api.getBroadcastTemplate(tenantId, broadcastTemplateId)
        .then(({data}) => {
          log.debug("BroadcastTemplate Loaded: data=", data)
          setBroadcastTemplateName(data.broadcastTemplateName)
          setBroadcastTemplateTenantId(data.tenantId)
          setCreativeType(data.creativeType)
          setMessageFormat(getMessageFormatOpt(data.creativeType, tenant, intl))
          setCreativeTypeLocked(data.creativeTypeLocked)
          setCreativeHeader(data.creativeHeader)
          setCreativeHeaderLocked(data.creativeHeaderLocked)
          setCreativeTitle(data.creativeTitle)
          setCreativeTitleLocked(data.creativeTitleLocked)
          if (data.creativeForm) {
            setCreativeForm(data.creativeForm)
            if (data.creativeForm.fields) {
              setCreativeFormFields(data.creativeForm.fields)
            }
          }
          setCreativeFooter(data.creativeFooter)
          setCreativeFooterLocked(data.creativeFooterLocked)
          let creativeStyle = DEFAULT_STYLE
          if (data.creativeStyle) {
            creativeStyle = {...creativeStyle, ...data.creativeStyle}
          }
          setCreativeStyle(creativeStyle)
          setCreativeImageUrl(data.creativeImageUrl)
          setCreativeImageUrlLocked(data.creativeImageUrlLocked)
          setPageType(data.pageType)
          setClickAction(getClickActionOpt(data.pageType, tenant, intl))
          setPageTypeLocked(data.pageTypeLocked)
          setPageHeader(data.pageHeader)
          setPageHeaderLocked(data.pageHeaderLocked)
          setPageTitle(data.pageTitle)
          setPageTitleLocked(data.pageTitleLocked)
          if (data.pageForm) {
            setPageForm(data.pageForm)
            if (data.pageForm.fields) {
              setPageFormFields(data.pageForm.fields)
            }
          }
          if (data.pageUrl && data.pageUrl.trim().length > 0) {
            setPageUrl(data.pageUrl)
          }
          setPageUrlLocked(data.pageUrlLocked)
          let pageStyle = DEFAULT_STYLE
          if (data.pageStyle) {
            pageStyle = {...pageStyle, ...data.pageStyle}
          }
          setPageStyle(pageStyle)
          if (data.pageImageUrl && data.pageImageUrl.trim().length > 0) {
            setPageImageUrl(data.pageImageUrl)
          }
          setPageImageUrlLocked(data.pageImageUrlLocked)

          switch (data.pageType) {
            case "EXTERNAL":
              if (validateUrl(data.pageUrl, {relaxed: true, allowedDomains: tenant.allowedDomains, disallowedDomains: tenant.disallowedDomains})) {
                setExtPageLoading("LOADING")
              }
              break
            case "WHATSAPP":
              setWhatsAppPhoneNo(getWhatsAppPhoneNoFromPageUrl(data.pageUrl))
              break
          }

          setFeedbackEnabled(data.feedbackEnabled)
          if (data.feedbackForm) {
            setFeedbackForm(data.feedbackForm)
            if (data.feedbackForm.fields) {
              setFeedbackFormFields(data.feedbackForm.fields)
            }
          }
          setFeedbackThankyouText(data.feedbackThankyouText)
          setOrgName(data.orgName)
          setOrgLogoUrl(data.orgLogoUrl)
          setOrgContactInfo(data.orgContactInfo)
          setOrgHomePage(data.orgHomePage)
          setProvidedTemplate(data.provided)
          setDefaultTemplate(data.default)
          setCreatedBy(data.createdBy)
          // log.debug("***data=", data)
          return data
        })
  }

  function handleBroadcastTemplateNameChange(e) {
    setBroadcastTemplateName(e.target.value)
    setTouched(true)
  }

  async function handleOrgNameChange(e) {
    setOrgName(e.target.value)
    setTouched(true)
  }

  async function handleOrgContactInfoChange(e) {
    setOrgContactInfo(e.target.value)
    setTouched(true)
  }

  async function handleOrgLogoChange(e) {

    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let result

      setBusy(intl.msg("processing"))

      try {
        result = await processImageFile({
          file: file,
          maxFileSize: 100_000,
          width: 100,
          height: 100,
        })
      }
      catch (ex) {
        setAlert({error: intl.msg("error_image_process_failed")})
      }
      finally {
        setBusy(null)
      }

      if (result.error) {
        setOrgLogoImageInfo(result)
        const el = document.getElementById("inp_dlg_orgLogoImagePicker")
        el.click()
      }
      else {
        setOrgLogoUrl(result.dataUrl)
        setTouched(true)
      }

      clearError("inp_logoUrl")
    }
  }

  function handleOrgLogoClear() {
    setOrgLogoUrl(null)
    setTouched(true)
  }

  function handleOrgHomePageChange(e) {
    const value = e.target.value
    setOrgHomePage(value)
    setTouched(true)
  }

  function handleOrgHomePageBlur() {
    if (orgHomePage && orgHomePage.trim().length > 0) {
      try {
        if (orgHomePage.startsWith("http://")) {
          setOrgHomePage((prev) => `https://${prev.substring(7)}`)
        }
        else if (!orgHomePage.startsWith("https://")) {
          setOrgHomePage((prev) => `https://${prev}`)
        }
      }
      catch (e) {
      }
    }
  }

  function handleMessageFormatChange(v) {
    setMessageFormat(v)
    setCreativeType(v.value)
    setTouched(true)
  }

  function handleCreativeHeaderChange(e) {
    setCreativeHeader(e.target.value)
    setTouched(true)
  }

  function handleCreativeTitleChange(e) {
    setCreativeTitle(e.target.value)
    setTouched(true)
  }

  function handleCreativeContentChange(v) {
    setCreativeContent(v.target.value)
    setTouched(true)
  }

  function handleCreativeFormFieldChange(e, id) {
    let index = creativeFormFields.findIndex(e => id === e.id)
    let g = creativeFormFields[index]
    let v
    if (e && e.target) {
      v = e.target.value
    }
    else {
      v = e
    }
    if ("DATE" === g.type) {
      try {
        v = dayjs(v).format("YYYY-MM-DD")
        v = "Invalid Date" === v ? "" : v
      }
      catch (e) {
        log.error("Invalid date", e)
      }
    }
    g.value = v
    if (creativeFormFields.length > 1) {
      setCreativeFormFields([
        ...creativeFormFields.slice(0, index),
        g,
        ...creativeFormFields.slice(index + 1),
      ])
    }
    else {
      setCreativeFormFields([g])
    }
    setTouched(true)
  }

  function handleMoveCreativeFieldUpClick(e, id) {
    if (creativeFormFields && creativeFormFields.length && creativeFormFields.length > 1) {
      let index = creativeFormFields.findIndex(e => id === e.id)
      let toIndex
      if (index > 0) {
        toIndex = index - 1
      }
      else {
        toIndex = creativeFormFields.length
      }
      let a = creativeFormFields.slice()
      a.splice(toIndex, 0, a.splice(index, 1)[0])
      setCreativeFormFields(a)
      setTouched(true)
    }
  }

  function handleMoveCreativeFieldDownClick(e, id) {
    if (creativeFormFields && creativeFormFields.length && creativeFormFields.length > 1) {
      let index = creativeFormFields.findIndex(e => id === e.id)
      let toIndex
      if (index < creativeFormFields.length - 1) {
        toIndex = index + 1
      }
      else {
        toIndex = 0
      }
      let a = creativeFormFields.slice()
      a.splice(toIndex, 0, a.splice(index, 1)[0])
      setCreativeFormFields(a)
      setTouched(true)
    }
  }

  function handleCreativeFooterChange(e) {
    setCreativeFooter(e.target.value)
    setTouched(true)
  }

  function handleCreativeStyleChange(e, el, at) {
    let v
    if (e.target) {
      v = e.target.value
    }
    else if (e.value) {
      v = e.value
    }
    else {
      v = v
    }
    let s = {...creativeStyle}
    log.debug(`handleCreativeStyleChange: el=${el}, at=${at}, s=${JSON.stringify(s)}, v=${v}`)
    let elv = s[el]
    if (elv) {
      elv = {...elv, [at]:v}
    }
    else {
      elv = {[at]:v}
    }
    log.debug(`handleCreativeStyleChange: elv=${elv}, s=${JSON.stringify(s)}`)
    setCreativeStyle(s = {...s, [el]:elv})
    setTouched(true)
  }

  async function handleCreativeImageUrlChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let result

      setBusy(intl.msg("processing"))

      try {
        result = await processImageFile({
          file: file,
          maxFileSize: "IMAGE" === creativeType ? 900_000 : 900_000,
          width: "IMAGE" === creativeType ? 300 : 124,
          height: "IMAGE" === creativeType ? 250 : 164,
        })
      }
      catch (ex) {
        setAlert({error: intl.msg("error_image_process_failed")})
      }
      finally {
        setBusy(null)
      }

      if (result.error) {
        // if (result.warning || result.error) {
        setCreativeImageInfo(result)
        const el = document.getElementById("inp_dlg_creativeImagePicker")
        el.click()
      }
      else {
        setCreativeImageUrl(result.dataUrl)
        setTouched(true)
      }

      clearError("inp_creativePhoto")
    }
  }

  function handleCreativePhotoClear() {
    setCreativeImageUrl(null)
    setTouched(true)
  }

  function handlePageHeaderChange(e) {
    setPageHeader(e.target.value)
    setTouched(true)
  }

  function handlePageTitleChange(e) {
    setPageTitle(e.target.value)
    setTouched(true)
  }

  function handlePageContentChange(v) {
    setPageContent(v.target.value)
    setTouched(true)
  }

  function handlePageFormFieldChange(e, id) {
    let index = pageFormFields.findIndex(e => id === e.id)
    let g = pageFormFields[index]
    let v
    if (e && e.target) {
      v = e.target.value
    }
    else {
      v = e
    }
    if ("DATE" === g.type) {
      try {
        v = dayjs(v).format("YYYY-MM-DD")
        v = "Invalid Date" === v ? "" : v
      }
      catch (e) {
        log.error("Invalid date", e)
      }
    }
    g.value = v
    if (pageFormFields.length > 1) {
      setPageFormFields([
        ...pageFormFields.slice(0, index),
        g,
        ...pageFormFields.slice(index + 1),
      ])
    }
    else {
      setPageFormFields([g])
    }
    setTouched(true)
  }

  function handleMovePageFieldUpClick(e, id) {
    if (pageFormFields && pageFormFields.length && pageFormFields.length > 1) {
      let index = pageFormFields.findIndex(e => id === e.id)
      let toIndex
      if (index > 0) {
        toIndex = index - 1
      }
      else {
        toIndex = pageFormFields.length
      }
      let a = pageFormFields.slice()
      a.splice(toIndex, 0, a.splice(index, 1)[0])
      setPageFormFields(a)
      setTouched(true)
    }
  }

  function handleMovePageFieldDownClick(e, id) {
    if (pageFormFields && pageFormFields.length && pageFormFields.length > 1) {
      let index = pageFormFields.findIndex(e => id === e.id)
      let toIndex
      if (index < pageFormFields.length - 1) {
        toIndex = index + 1
      }
      else {
        toIndex = 0
      }
      let a = pageFormFields.slice()
      a.splice(toIndex, 0, a.splice(index, 1)[0])
      setPageFormFields(a)
      setTouched(true)
    }
  }

  function handlePageStyleChange(e, el, at) {
    let v
    if (e.target) {
      v = e.target.value
    }
    else if (e.value) {
      v = e.value
    }
    else {
      v = v
    }
    let s = {...pageStyle}
    log.debug(`handlePageStyleChange: el=${el}, at=${at}, s=${JSON.stringify(s)}, v=${v}`)
    let elv = s[el]
    if (elv) {
      elv = {...elv, [at]:v}
    }
    else {
      elv = {[at]:v}
    }
    log.debug(`handlePageStyleChange: elv=${elv}, s=${JSON.stringify(s)}`)
    setPageStyle(s = {...s, [el]:elv})
    setTouched(true)
  }

  async function handlePageImageUrlChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let result

      setBusy(intl.msg("processing"))

      try {
        result = await processImageFile({
          file: file,
          maxFileSize: 2_000_000,
          width: 300,
          height: 400,
        })
      }
      catch (ex) {
        setAlert({error: intl.msg("error_image_process_failed")})
      }
      finally {
        setBusy(null)
      }

      if (result.error) {
        // if (result.warning || result.error) {
        setPageImageInfo(result)
        const el = document.getElementById("inp_dlg_pageImagePicker")
        el.click()
      }
      else {
        setPageImageUrl(result.dataUrl)
        setTouched(true)
      }

      clearError("inp_pagePhoto")
    }
  }

  function handlePageImageClear() {
    setPageImageUrl(null)
    setTouched(true)
  }

  function handleClickActionChange(v) {
    setClickAction(v)
    setPageType(v.value)
    setWhatsAppPhoneNo(null)
    setPageUrl(null)
    setTouched(true)
  }

  function handleWatsAppPhoneNoChange(e) {
    let value = e?.target?.value
    if (value) {
      value = value.replace(/[^0-9]/g, "")
    }
    setWhatsAppPhoneNo(value)
    setPageUrl(`https://wa.me/${encodeURIComponent(value)}`)
  }

  function handleExtPageUrlChange(e) {
    const url = e.target.value
    setPageUrl(url)
    setTouched(true)
    if (validateUrl(url, {relaxed: true, allowedDomains: tenant.allowedDomains, disallowedDomains: tenant.disallowedDomains})) {
      setExtPageLoading("LOADING")
    }
  }

  function handleExtPageUrlLoad() {
    setExtPageLoading("LOADED")
  }

  function handleExtPageUrlError() {
    setExtPageLoading("FAILED")
  }

  function addExtPageUrlHttps() {
    if (pageUrl && pageUrl.trim().length > 0) {
      try {
        if (pageUrl.startsWith("http://")) {
          setPageUrl((prev) => `https://${prev.substring(7)}`)
        }
        else if (!pageUrl.startsWith("https://")) {
          setPageUrl((prev) => `https://${prev}`)
        }
      }
      catch (e) {
      }
    }
  }

  function handleExternalPageUrlBlur() {
    addExtPageUrlHttps()
  }

  function handleExternalPageUrlKeyDown(e) {
    if (e.key === "Enter") {
      addExtPageUrlHttps()
    }
  }

  function handleExternalPageUrlBlur() {
    addExtPageUrlHttps()
  }

  function handleFeedbackFormFieldChange(e, id) {
    let index = feedbackFormFields.findIndex(e => id === e.id)
    let g = feedbackFormFields[index]
    let v
    if (e && e.target) {
      v = e.target.value
    }
    else {
      v = e
    }
    if ("DATE" === g.type) {
      try {
        v = dayjs(v).format("YYYY-MM-DD")
        v = "Invalid Date" === v ? "" : v
      }
      catch (e) {
        log.error("Invalid date", e)
      }
    }
    g.value = v
    if (feedbackFormFields.length > 1) {
      setFeedbackFormFields([
        ...feedbackFormFields.slice(0, index),
        g,
        ...feedbackFormFields.slice(index + 1),
      ])
    }
    else {
      setFeedbackFormFields([g])
    }
    setTouched(true)
  }

  function handleMoveFeedbackFieldUpClick(e, id) {
    if (feedbackFormFields && feedbackFormFields.length && feedbackFormFields.length > 1) {
      let index = feedbackFormFields.findIndex(e => id === e.id)
      let toIndex
      if (index > 0) {
        toIndex = index - 1
      }
      else {
        toIndex = feedbackFormFields.length
      }
      let a = feedbackFormFields.slice()
      a.splice(toIndex, 0, a.splice(index, 1)[0])
      setFeedbackFormFields(a)
      setTouched(true)
    }
  }

  function handleMoveFeedbackFieldDownClick(e, id) {
    if (feedbackFormFields && feedbackFormFields.length && feedbackFormFields.length > 1) {
      let index = feedbackFormFields.findIndex(e => id === e.id)
      let toIndex
      if (index < feedbackFormFields.length - 1) {
        toIndex = index + 1
      }
      else {
        toIndex = 0
      }
      let a = feedbackFormFields.slice()
      a.splice(toIndex, 0, a.splice(index, 1)[0])
      setFeedbackFormFields(a)
      setTouched(true)
    }
  }

  function handleFeedbackThankyouTextChange(e) {
    setFeedbackThankyouText(e.target.value)
    setTouched(true)
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_broadcastTemplateName", broadcastTemplateName && broadcastTemplateName.trim().length > 0, "required", opt)

    log.debug(">>creativeFormFields", creativeFormFields)

    if (creativeType == "SOLV") {
      if (!creativeFormFields || creativeFormFields.length == 0) {
        validateField("inp_add_creativeFormField", false, "required", opt)
      }
    }

    switch (pageType) {
      case "SOLV":
        if (!pageFormFields || pageFormFields.length == 0) {
          validateField("inp_add_pageFormField", false, "required", opt)
        }
        if (feedbackEnabled && (!feedbackFormFields || feedbackFormFields.length == 0)) {
          validateField("inp_add_feedbackFormField", false, "required", opt)
        }
        break
      case "EXTERNAL":
        if (pageUrl) {
          try {
            const url = new URL(pageUrl)
            if (tenant.allowedDomains && tenant.allowedDomains.length > 0) {
              const domain = url.hostname.trim().toUpperCase()
              if (!tenant.allowedDomains.find(u => domain.endsWith(u.trim().toUpperCase()))) {
                validateField("inp_pageUrl", false, "whitelist", opt)
              }
            }
            if (tenant.disallowedDomains && tenant.disallowedDomains.length > 0) {
              const domain = url.hostname.trim().toUpperCase()
              if (tenant.disallowedDomains.find(u => domain.endsWith(u.trim().toUpperCase()))) {
                validateField("inp_pageUrl", false, "whitelist", opt)
              }
            }
          }
          catch (ex) {
            validateField("inp_pageUrl", false, "valid", opt)
          }
        }
        break
      case "WHATSAPP":
        if (whatsAppPhoneNo) {
          validateField("inp_whatsAppPhoneNo", validateUrl(pageUrl), "valid", opt)
        }
        break

    }

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid
  }

  function saveBroadcastTemplate(tenantId, payload) {
    if (isAddMode) {
      return api.createBroadcastTemplate(tenantId, payload)
    }
    else {
      return api.updateBroadcastTemplate(tenantId, broadcastTemplateId, payload)
    }
  }

  const saveMutation = useMutation(payload => saveBroadcastTemplate(broadcastTemplateTenantId, payload))

  async function handleSaveClick() {

    setBusy(intl.msg("saving"))

    try {

      if (validate()) {

        let finalCreativeImageUrl = creativeImageUrl

        if (creativeImageUrl && creativeImageUrl.startsWith("data:")) {
          finalCreativeImageUrl = await uploadImage({
            api: api,
            imageData: creativeImageUrl,
            imagePath: `t/${tenant.tenantId}/bt/${broadcastTemplateId}/m/0`,
            setImageUrl: setCreativeImageUrl,
          })
        }

        let finalPageImageUrl = pageImageUrl

        if (pageImageUrl && pageImageUrl.startsWith("data:")) {
          finalPageImageUrl = await uploadImage({
            api: api,
            imageData: pageImageUrl,
            imagePath: `t/${tenant.tenantId}/bt/${broadcastTemplateId}/p/0`,
            setImageUrl: setPageImageUrl,
          })
        }

        let finalOrgLogoUrl = orgLogoUrl

        if (finalOrgLogoUrl && finalOrgLogoUrl.startsWith("data:")) {
          finalOrgLogoUrl = await uploadImage({
            api: api,
            imageData: finalOrgLogoUrl,
            imagePath: `t/${tenant.tenantId}/bt/${broadcastTemplateId}/logo`,
            setImageUrl: setOrgLogoUrl,
          })
        }

        let cf, pf, ff
        if (creativeFormFields) {
          cf = {...creativeForm}
          cf.fields = creativeFormFields
        }

        if (pageFormFields) {
          pf = {...pageForm}
          pf.fields = pageFormFields
        }

        if (feedbackFormFields) {
          ff = {...feedbackForm}
          ff.fields = feedbackFormFields
        }

        const payload = {
          broadcastTemplateId: broadcastTemplateId,
          tenantId: broadcastTemplateTenantId,
          broadcastTemplateName: broadcastTemplateName,
          creativeHeader: creativeHeader ? creativeHeader : "",
          creativeTitle: creativeTitle ? creativeTitle : "",
          creativeContent: creativeContent,
          creativeForm: cf,
          creativeFooter: creativeFooter ? creativeFooter : "",
          creativeStyle: creativeStyle,
          creativeImageUrl: finalCreativeImageUrl,
          creativeType: creativeType ? creativeType : "SOLV",
          creativeTypeLocked: creativeTypeLocked,
          creativeHeaderLocked: creativeHeaderLocked,
          creativeTitleLocked: creativeTitleLocked,
          creativeFooterLocked: creativeFooterLocked,
          creativeImageUrlLocked: creativeImageUrlLocked,
          pageHeader: pageHeader ? pageHeader : "",
          pageTitle: pageTitle ? pageTitle : "",
          pageContent: pageContent,
          pageForm: pf,
          pageStyle: pageStyle,
          pageImageUrl: finalPageImageUrl,
          pageType: pageType ? pageType : "SOLV",
          pageUrl: pageUrl,
          pageTypeLocked: pageTypeLocked,
          pageHeaderLocked: pageHeaderLocked,
          pageTitleLocked: pageTitleLocked,
          pageUrlLocked: pageUrlLocked,
          pageImageUrlLocked: pageImageUrlLocked,
          feedbackEnabled: feedbackEnabled,
          feedbackForm: ff,
          feedbackThankyouText: feedbackThankyouText,
          orgName: orgName,
          orgContactInfo: orgContactInfo,
          orgHomePage: orgHomePage,
          orgLogoUrl: finalOrgLogoUrl,
          protected: tenant.tenantTypeId,
          createdBy: session.user.userId,
          status: "ACTIVE"
        }

        const {data} = await saveMutation.mutateAsync(payload)

        setAlert(null)
        setTouched(false)
      }
      else {
        log.debug("Validation error")
        setAlert({error: intl.msg("error_invalid_form")})
      }
    }
    catch (ex) {
      setTouched(false)
      log.debug("Save broadcast failed: ex=", JSON.stringify(ex))
      handleError(ex)
    }
    finally {
      setBusy(null)
    }
  }

  function handleCancelClick() {
    window.location = `/templates`
  }

  function handleBackClick()  {
    if (broadcastTemplateId) {
      window.location = `/templates/${broadcastTemplateId}`
    }
    else {
      window.location = `/templates`
    }
  }

  function getDate(v) {
    let d
    try {
      log.debug("getDate: v=, t=", v, typeof(v))
      if (v && v.trim().length > 0) {
        d = dayjs(v, "yyyy-MM-dd").toDate()
      }
      log.debug("getDate: v=, d=", v, d)
    }
    catch (e) {
      //
    }
    return d
  }
  function isEditable(locked) {
    try {
      if (isAddMode) {
        return true
      }
      else {
        if (createdBy === session.user.userId.replace(/\-/g, "")) {
          return true
        }
        else if (tenant) {
          if (broadcastTemplateTenantId === tenant.tenantId) {
            if (tenant.accessingAs(["SUPERVISOR/MEMBER/*", "ADMIN/MANAGER/*"])) {
              return true
            }
          }
          else if (tenant.accessingAs("ADMIN/MANAGER/*")) {
            return true
          }
        }
      }
      return false
    }
    catch (e) {
      log.debug("Error", e)
    }
  }

  function isDefault() {
    return defaultTemplate === true
  }

  function isProvided() {
    return providedTemplate === true
  }


  function canChangePageType() {
    return (isEditable())
  }

  const Rd = (props) => {
    window.location = props.to
  }

  function testUrl() {
    try {
      const u = new URL(pageUrl)
      return true
    }
    catch (ex) {
      return false
    }
  }

  function mkDomainListText(tenantAllowedDomains) {
    if (tenantAllowedDomains) {
      if (tenantAllowedDomains.length > 1) {
        return ` one of (${tenantAllowedDomains.join(", ")})`
      }
      else {
        return tenantAllowedDomains[0]
      }
    }
    else {
      return ""
    }
  }

  function MoreButton(props) {
    log.debug("MoreButton: ", tenant.tenantTypeId)
    return (
        <>
          <button className="btn btn-secondary" type="button" id="dropdownMenuButton" title={intl.msg("more_menu")} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fas fa-ellipsis-v"></i>
          </button>
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            {
              !touched &&
                <>
                  {
                    tenant.accessingAs(["SUPERVISOR/*/*"]) && !defaultTemplate &&
                      <a className="dropdown-item" href="#" onClick={() => openDialog("dlg_template_set_default")}>
                        <i className="fas fa-check-circle mr-1"></i>{intl.msg("template_set_as_default")}
                      </a>
                  }

                  {
                    tenant.accessingAs(["SUPERVISOR/*/*"]) &&
                      <a className="dropdown-item" href="#" onClick={() => openDialog("dlg_template_clone")}>
                        <i className="fas fa-clone mr-1"></i>{intl.msg("clone")}
                      </a>
                  }
                  {
                    tenant && tenant.accessingAs("ADMIN/MANAGER/*") &&
                      <>
                        <a className="dropdown-item" href="#" onClick={() => openDialog("dlg_template_distribute")}>
                          <span className="fa-layers mr-1">
                            <i className="fas fa-plus" data-fa-transform="shrink-10 down-6"></i>
                            <i className="fas fa-angle-double-up" data-fa-transform="shrink-1 up-2"></i>
                          </span>

                          {intl.msg("distribute")}
                        </a>
                      </>
                  }
                  {
                    tenant && tenant.accessingAs("ADMIN/*/*") &&
                      <>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#" onClick={() => openDialog("dlg_template_delete")}>
                          <i className="fas fa-trash-alt mr-1"></i>{intl.msg("delete")}
                        </a>
                      </>
                  }
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="#" onClick={() => openDialog("dlg_template_details")}>
                    <i className="fas fa-info-circle"></i>&nbsp;
                    {intl.msg("details")}
                  </a>
                </>
            }
          </div>
        </>
    )
  }

  function handleError(ex) {
    if (ex.code) {
      switch (ex.code) {
        case "DUPLICATE_KEY":
          log.debug("DUPLICATE_ERROR")
          setAlert({error: intl.msg("error_invalid_form")})
          showError("inp_broadcastTemplateName", "unique")
          break
        default:
          setAlert({error: intl.msg("error_failed")})
          break
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  return (
    tenant &&
      <>

        <MainContainer tenant={tenant} menu={MainMenu}>

          <FormHeader>
            <AccountTabs tenant={tenant}/>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("template")}
                <span style={{paddingInline: "8px"}}>-</span>
                {isAddMode ? "(new)" : broadcastTemplateName}
                <TagBox className="mt-1 ml-2">
                  {
                    isProvided() &&
                      <TagLabel className="tag-label" label={intl.msg("template_label_provided")} title={intl.msg("template_label_provided_tooltip")}/>
                  }
                  {
                    isDefault() &&
                      <TagLabel className="tag-label" label={intl.msg("template_label_default")} title={intl.msg("template_label_default_tooltip")}/>
                  }
                </TagBox>
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                {
                  isAddMode ? (
                      <>
                        <button key="key_btn_save" className="btn btn-primary" disabled={busy} onClick={handleSaveClick}>
                          <i className="fas fa-plus mr-1"></i>{intl.msg("create")}
                        </button>
                        <button key="key_btn_reset" className="btn btn-secondary" disabled={busy} onClick={handleCancelClick}>
                          <i className="fas fa-times mr-1"></i>{intl.msg("cancel")}
                        </button>
                      </>
                  ) : touched ? (
                      <>
                        <button key="key_btn_save" className="btn btn-primary" onClick={handleSaveClick}>
                          <i className="fas fa-check mr-1"></i>{intl.msg("save")}
                        </button>
                        <button key="key_btn_reset" className="btn btn-secondary" onClick={handleBackClick}>
                          <i className="fas fa-times mr-1"></i>{intl.msg("cancel")}
                        </button>
                      </>
                  ) : (
                      <>
                        <MoreButton/>
                      </>
                  )
                }
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>

          <FormBody>

            <div className="row no-gutters">

              <div className="col">

                <form id="frmMain" autoComplete="off">

                  <input type="hidden" value="prayer"/>

                  <div className="form-card">

                    {/*<SectionHeader title={intl.msg("main_info")}/>*/}

                    <div className="row">

                      <div className="col-sm-7">

                        <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                          <label className="col-sm-3 col-form-label" htmlFor="inp_broadcastTemplateName">
                            {intl.msg("template_name")}:
                          </label>
                          <div className="col-sm-9">
                            <input id="inp_broadcastTemplateName" name="broadcastTemplateName" type="text" className="form-control" value={broadcastTemplateName} maxlength="200" disabled={!isEditable()} onChange={handleBroadcastTemplateNameChange} autoComplete="no"/>
                            <InfoBlock>
                              <InfoText>{intl.msg("template_name_helptext_1")}</InfoText>
                              <InfoText disabled={!touched} validate="unique">{intl.msg("helptext_name_casing")}</InfoText>
                              <InfoText disabled={!touched} validate="unique">{intl.msg("template_name_helptext_unique")}</InfoText>
                              <InfoText disabled={!touched} validate="required">{intl.msg("helptext_required")}</InfoText>
                            </InfoBlock>
                          </div>
                        </div>

                      </div>

                    </div>

                    <SectionHeader title={intl.msg("broadcast_message_section_title")} text={intl.msg("broadcast_message_section_text")}/>

                    <div className="row">

                      <div className="col-sm-7">

                        <div className={`form-group row  ${isEditable(creativeTypeLocked) ? "" : "readonly"}`}>
                          <label className="col-sm-3 col-form-label" htmlFor="inp_creativeType">
                            {intl.msg("broadcast_message_type")}:
                            {
                              isEditable() &&
                                <div>
                                  <btn key={`key_creativeTypeLocked_${creativeTypeLocked}`} href="#" className="btn btn-secondary btn-tiny" title={creativeTypeLocked ? intl.msg("template_field_unlock") : intl.msg("template_field_lock")} onClick={e => {setCreativeTypeLocked(!creativeTypeLocked); setTouched(true)}}>
                                    <i className={creativeTypeLocked ? "fas fa-lock" : "fas fa-lock-open"}></i>
                                  </btn>
                                </div>
                            }
                          </label>
                          <div className="col-sm-9">
                            <Select
                                id="inp_creativeType"
                                className="react-select react-select__absolute"
                                classNamePrefix="react-select"
                                value={messageFormat}
                                options={getMessageFormatOpts(tenant, intl)}
                                onChange={handleMessageFormatChange}
                                isDisabled={!isEditable()}
                            />
                            <InfoBlock>
                              <InfoText>{intl.msg("broadcast_message_type_helptext_1")}</InfoText>
                            </InfoBlock>
                          </div>
                        </div>

                        {/*<div className={`form-group row ${isEditable() ? "" : "readonly"}`}>*/}
                        {/*  <label className="col-sm-3 col-form-label" htmlFor="inp_creativeType">*/}
                        {/*    {intl.msg("broadcast_message_type")}:*/}
                        {/*    {*/}
                        {/*      isEditable() &&*/}
                        {/*        <div>*/}
                        {/*          <btn key={`key_creativeTypeLocked_${creativeTypeLocked}`} href="#" className="btn btn-secondary btn-tiny" title={creativeTypeLocked ? intl.msg("template_field_unlock") : intl.msg("template_field_lock")} onClick={e => {setCreativeTypeLocked(!creativeTypeLocked); setTouched(true)}}>*/}
                        {/*            <i className={creativeTypeLocked ? "fas fa-lock" : "fas fa-lock-open"}></i>*/}
                        {/*          </btn>*/}
                        {/*        </div>*/}
                        {/*    }*/}
                        {/*  </label>*/}
                        {/*  <div className="col-sm-9">*/}
                        {/*    <label className="radio-inline">*/}
                        {/*      <input type="radio" name="creativeType" id="inp_creativeType_std" checked={creativeType === "SOLV"} value="SOLV" disabled={!isEditable()} onClick={() => {setCreativeType("SOLV"); setTouched(true)}}/>{intl.msg("broadcast_message_type_solv_message")}*/}
                        {/*      <span className="checkmark"></span>*/}
                        {/*    </label>*/}
                        {/*    <label className="radio-inline ml-3">*/}
                        {/*      <input type="radio" name="creativeType" id="inp_creativeType_userdef" checked={creativeType === "IMAGE"} value="IMAGE" disabled={!isEditable()} onClick={() => {setCreativeType("IMAGE"); setTouched(true)}}/>{intl.msg("broadcast_message_type_custom_image")}*/}
                        {/*      <span className="checkmark"></span>*/}
                        {/*    </label>*/}
                        {/*    <InfoBlock>*/}
                        {/*      <InfoText>{intl.msg("broadcast_message_type_helptext_1")}</InfoText>*/}
                        {/*    </InfoBlock>*/}
                        {/*  </div>*/}
                        {/*</div>*/}


                        {
                          "IMAGE" === creativeType ? (
                              <>
                                <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                  <label className="col-sm-3 col-form-label" htmlFor="inp_creativePhoto">{intl.msg("broadcast_message_custom_image_message_type_photo")}:
                                    {
                                        isEditable() &&
                                        <div>
                                          <btn key={`key_creativeImageUrlLocked_${creativeImageUrlLocked}`} href="#" className="btn btn-secondary btn-tiny" title={creativeImageUrlLocked ? intl.msg("template_field_unlock") : intl.msg("template_field_lock")} onClick={e => {setCreativeImageUrlLocked(!creativeImageUrlLocked); setTouched(true)}}><i className={creativeImageUrlLocked ? "fas fa-lock" : "fas fa-lock-open"}></i></btn>
                                        </div>
                                    }
                                  </label>
                                  <div className="col-sm-9">
                                    <ImageDropBox
                                        id="inp_creativePhoto"
                                        image={creativeImageUrl}
                                        imageClassName={"primary-photo-custom"}
                                        width={300}
                                        height={250}
                                        onChange={handleCreativeImageUrlChange}
                                        onClear={handleCreativePhotoClear}
                                        disabled={!isEditable()}/>
                                    <InfoBlock>
                                      <InfoText>{intl.msg("broadcast_message_custom_image_message_type_photo_helptext_1")}</InfoText>
                                      <InfoText disabled={!touched}>{intl.msg("broadcast_message_custom_image_message_type_photo_helptext_valid")}</InfoText>
                                    </InfoBlock>
                                  </div>
                                </div>
                              </>
                          ) : (
                              <>
                                <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                  <label className="col-sm-3 col-form-label" htmlFor="inp-creativeHeader">
                                    {intl.msg("broadcast_message_header")}:
                                    {
                                      isEditable() &&
                                        <div>
                                          <btn key={`key_creativeHeaderLocked_${creativeHeaderLocked}`} href="#" className="btn btn-secondary btn-tiny" title={creativeHeaderLocked ? intl.msg("template_field_unlock") : intl.msg("template_field_lock")} onClick={e => {log.debug("!!!J"); setCreativeHeaderLocked(!creativeHeaderLocked); setTouched(true)}}><i className={creativeHeaderLocked ? "fas fa-lock" : "fas fa-lock-open"}></i></btn>
                                        </div>
                                    }
                                  </label>
                                  <div className="col-sm-9">
                                    <div className="row">
                                      <div className="col-sm">
                                        <label className="col-form-label">{intl.msg("text")}:</label>
                                        <input id="inp_creativeHeader" type="text" className="form-control" value={creativeHeader} disabled={!isEditable()} onChange={handleCreativeHeaderChange} autoComplete="no"/>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-sm">
                                        <label className="col-form-label">{intl.msg("font")}:</label>
                                        <Select
                                            id="inp_creativeHeaderFont"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            value={{label:getStyle(creativeStyle, "header", "font"), value:getStyle(creativeStyle, "header", "font")}}
                                            options={fontOpts}
                                            onChange={e => handleCreativeStyleChange(e, "header", "font")}
                                            isDisabled={!isEditable()}
                                        />
                                      </div>
                                      <div className="col-sm">
                                        <label className="col-form-label text-nowrap">{intl.msg("text_color")}:</label>
                                        <input id="inp_creativeHeaderTextColor" type="color" className="form-control" style={{width: "80px"}} value={getStyle(creativeStyle, "header", "textColor")} onChange={e => handleCreativeStyleChange(e, "header", "textColor")} disabled={!isEditable()} autoComplete="no"/>
                                      </div>
                                      <div className="col-sm">
                                        <label className="col-form-label text-nowrap">{intl.msg("background_color")}:</label>
                                        <input id="inp_creativeHeaderBackgroundColor" type="color" className="form-control" style={{width: "80px"}} value={getStyle(creativeStyle, "header", "backgroundColor")} onChange={e => handleCreativeStyleChange(e, "header", "backgroundColor")} disabled={!isEditable()} autoComplete="no"/>
                                      </div>
                                    </div>
                                    <InfoBlock>
                                      <InfoText>{intl.msg("broadcast_message_header_helptext_1")}</InfoText>
                                    </InfoBlock>
                                  </div>
                                </div>

                                <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                  <label className="col-sm-3 col-form-label" htmlFor="inp_creativeTitle">
                                    {intl.msg("broadcast_message_title")}:
                                    {
                                        isEditable() &&
                                        <div>
                                          <btn key={`key_creativeTitleLocked_${creativeTitleLocked}`} href="#" className="btn btn-secondary btn-tiny" title={creativeTitleLocked ? intl.msg("template_field_unlock") : intl.msg("template_field_lock")} onClick={e => {setCreativeTitleLocked(!creativeTitleLocked); setTouched(true)}}><i className={creativeTitleLocked ? "fas fa-lock" : "fas fa-lock-open"}></i></btn>
                                        </div>
                                    }
                                  </label>
                                  <div className="col-sm-9">
                                    <div className="row">
                                      <div className="col-sm">
                                        <label className="col-form-label">{intl.msg("text")}:</label>
                                        <input id="inp_creativeTitle" type="text" className="form-control" value={creativeTitle} disabled={!isEditable()} autoComplete="no" onChange={handleCreativeTitleChange}/>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-sm">
                                        <label className="col-form-label">{intl.msg("font")}:</label>
                                        <Select
                                            id="inp_creativeTitleFont"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            value={{label:getStyle(creativeStyle, "title", "font"), value:getStyle(creativeStyle, "title", "font")}}
                                            options={fontOpts}
                                            onChange={e => handleCreativeStyleChange(e, "title", "font")}
                                            isDisabled={!isEditable()}
                                        />
                                      </div>
                                      <div className="col-sm">
                                        <label className="col-form-label text-nowrap">{intl.msg("text_color")}:</label>
                                        <input id="inp_creativeTitleTextColor" type="color" className="form-control" style={{width: "80px"}} value={getStyle(creativeStyle, "title", "textColor")} onChange={e => handleCreativeStyleChange(e, "title", "textColor")} disabled={!isEditable()} autoComplete="no"/>
                                      </div>
                                      <div className="col-sm">
                                        <label className="col-form-label text-nowrap">{intl.msg("background_color")}:</label>
                                        <input id="inp_creativeTitleBackgroundColor" type="color" className="form-control" style={{width: "80px"}} value={getStyle(creativeStyle, "title", "backgroundColor")} onChange={e => handleCreativeStyleChange(e, "title", "backgroundColor")} disabled={!isEditable()} autoComplete="no"/>
                                      </div>
                                    </div>
                                    <InfoBlock>
                                      <InfoText>{intl.msg("broadcast_message_title_helptext_1")}</InfoText>
                                    </InfoBlock>
                                  </div>
                                </div>

                                <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                  <label className="col-sm-3 col-form-label" htmlFor="inp_creativePhoto">
                                    {intl.msg("broadcast_message_solv_message_type_photo")}:
                                    {
                                        isEditable() &&
                                        <div>
                                          <btn key={`key_creativeImageUrlLocked_${creativeImageUrlLocked}`} href="#" className="btn btn-secondary btn-tiny" title={creativeImageUrlLocked ? intl.msg("template_field_unlock") : intl.msg("template_field_lock")} onClick={e => {setCreativeImageUrlLocked(!creativeImageUrlLocked); setTouched(true)}}><i className={creativeImageUrlLocked ? "fas fa-lock" : "fas fa-lock-open"}></i></btn>
                                        </div>
                                    }
                                  </label>

                                  <div className="col-sm-9">
                                    <ImageDropBox
                                        id="inp_creativePhoto"
                                        image={creativeImageUrl}
                                        imageClassName={"primary-photo-custom"}
                                        width={120}
                                        height={160}
                                        onChange={handleCreativeImageUrlChange}
                                        onClear={handleCreativePhotoClear}
                                        disabled={!isEditable()}/>
                                    <InfoBlock>
                                      <InfoText>{intl.msg("broadcast_message_solv_message_type_photo_helptext_1")}</InfoText>
                                      <InfoText disabled={!touched}>{intl.msg("broadcast_message_solv_message_type_photo_helptext_valid")}</InfoText>
                                    </InfoBlock>
                                  </div>
                                </div>

                                {
                                    creativeFormFields  && creativeFormFields.map( (field, index) =>
                                        <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                          <label className="col-sm-3 col-form-label" style={{whiteSpace: "nowrap"}} htmlFor={`inp_edit_creativeForm_${field.id}`}>
                                            {field.label}:
                                            <div>
                                              {
                                                  isEditable() &&
                                                  <>
                                                    <btn href="#" className="btn btn-secondary btn-tiny" title={intl.msg("template_edit_field_tooltip")} data-toggle="modal" data-target={`#dlg_edit_creativeFormField_${field.id}`} data-backdrop="static">
                                                      <i className="fas fa-pen"></i>
                                                    </btn>
                                                    {
                                                        creativeFormFields && creativeFormFields.length && creativeFormFields.length > 1 &&
                                                        <>
                                                          <btn href="#" className="btn btn-secondary btn-tiny" title={intl.msg("template_move_field_up")} onClick={e => handleMoveCreativeFieldUpClick(e, field.id)}>
                                                            <i className="fas fa-arrow-up"></i>
                                                          </btn>
                                                          <btn href="#" className="btn btn-secondary btn-tiny" title={intl.msg("template_move_field_down")} onClick={e => handleMoveCreativeFieldDownClick(e, field.id)}>
                                                            <i className="fas fa-arrow-down"></i>
                                                          </btn>
                                                        </>
                                                    }
                                                    <>
                                                      <button className="btn btn-secondary btn-tiny" type="button" title={intl.msg("more_menu")} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fas fa-ellipsis-v"></i>
                                                      </button>
                                                      <div className="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
                                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target={`#dlg_clone_creativeFormField_${field.id}`} data-backdrop="static">
                                                          <i className="fas fa-clone mr-1" style={{fontSize: "14px"}}></i>{intl.msg("template_clone_field")}
                                                        </a>
                                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target={`#dlg_cloneAs_creativeFormField_${field.id}`} data-backdrop="static">
                                                          <i className="fas fa-clone mr-1" style={{fontSize: "14px"}}></i>{intl.msg("template_clone_as_page_field")}
                                                        </a>
                                                        <div className="dropdown-divider"></div>
                                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target={`#dlg_delete_creativeFormField_${field.id}`} data-backdrop="static">
                                                          <i className="fas fa-trash-alt mr-1" style={{fontSize: "14px"}}></i>{intl.msg("template_delete_field")}
                                                        </a>
                                                      </div>
                                                    </>
                                                  </>
                                              }
                                            </div>
                                            <CustomFieldEditDialog
                                                key={['dlg_edit_creativeFormField-', index].join('_')}
                                                id={`dlg_edit_creativeFormField_${field.id}`}
                                                dialogType="Message"
                                                field={field}
                                                fields={creativeFormFields}
                                                setFields={setCreativeFormFields}
                                                setTouched={setTouched}/>
                                            <CustomFieldDeleteDialog
                                                key={['dlg_delete_creativeFormField-', index].join('_')}
                                                id={`dlg_delete_creativeFormField_${field.id}`}
                                                dialogType="Message"
                                                field={field}
                                                fields={creativeFormFields}
                                                setFields={setCreativeFormFields}
                                                setTouched={setTouched}/>
                                            <CustomFieldCloneDialog
                                                key={['dlg_clone_creativeFormField-', index].join('_')}
                                                id={`dlg_clone_creativeFormField_${field.id}`}
                                                dialogType="Message"
                                                field={field}
                                                fields={creativeFormFields}
                                                setFields={setCreativeFormFields}
                                                setTouched={setTouched}/>
                                            <CustomFieldCloneAsDialog
                                                key={['dlg_cloneAs_creativeFormField-', index].join('_')}
                                                id={`dlg_cloneAs_creativeFormField_${field.id}`}
                                                dialogType="Message"
                                                targetType="Page"
                                                field={field}
                                                fields={creativeFormFields}
                                                targetFields={pageFormFields}
                                                setTargetFields={setPageFormFields}
                                                setTouched={setTouched}/>
                                          </label>
                                          <div className="col-9">
                                            {
                                              ["TEXT_LINE"].includes(field.type) ? (
                                                  <input
                                                      key={['edit_creativeFormField-', index].join('_')}
                                                      id={`inp_edit_creativeForm_${field.id}`}
                                                      type="text"
                                                      className="form-control"
                                                      value={field.value}
                                                      disabled={!isEditable()}
                                                      onChange={e => handleCreativeFormFieldChange(e, field.id)}
                                                      autoComplete="no"/>
                                              ) : ["TEXT_BLOCK"].includes(field.type) ? (
                                                  <textarea
                                                      key={['edit_creativeFormField-', index].join('_')}
                                                      id={`inp_edit_creativeForm_${field.id}`}
                                                      className="form-control"
                                                      rows="5"
                                                      autoComplete="no"
                                                      disabled={!isEditable()}
                                                      value={field.value}
                                                      onChange={e => handleCreativeFormFieldChange(e, field.id)}
                                                  />
                                              ) : ["NUM_INT"].includes(field.type) ? (
                                                  <NumericInput
                                                      id={`inp_edit_creativeForm_${field.id}`}
                                                      className="form-control"
                                                      style={{wrap: {width: "200px"}}}
                                                      min={0}
                                                      precision={0}
                                                      disabled={!isEditable()}
                                                      value={field.value}
                                                      onChange={e => handleCreativeFormFieldChange(e, field.id)} />
                                              ) : ["NUM_REAL"].includes(field.type) ? (
                                                  <NumericInput
                                                      id={`inp_edit_creativeForm_${field.id}`}
                                                      className="form-control"
                                                      style={{wrap: {width: "200px"}}}
                                                      min={0}
                                                      precision={2}
                                                      disabled={!isEditable()}
                                                      value={field.value}
                                                      onChange={e => handleCreativeFormFieldChange(e, field.id)} />
                                              ) : ["DATE"].includes(field.type) ? (
                                                  <DatePicker
                                                      key={['edit_creativeFormField-', index].join('_')}
                                                      id={`inp_edit_creativeForm_${field.id}`}
                                                      selected={parseDate(creativeFormFields[index].value, tenant?.region.regionId)}
                                                      dateFormat={getDateFormat(tenant?.region.regionId, "date-picker")}
                                                      onChange={e => handleCreativeFormFieldChange(e, field.id)} />
                                              ) : ["SELECT"].includes(field.type) && field.opts && field.opts.selectList ? (
                                                  <select
                                                      key={['edit_creativeFormField-', index].join('_')}
                                                      id={`inp_edit_creativeForm_${field.id}`}
                                                      value={field.value}
                                                      onChange={e => handleCreativeFormFieldChange(e, field.id)}
                                                      className="form-control">
                                                    {
                                                      ("OPTIONAL" === field.input ? "," + field.opts.selectList : field.opts.selectList).split(",").map(o => <option value={o.trim()}>{o.trim()}</option>)
                                                    }
                                                  </select>
                                              ) : (
                                                  <>
                                                  </>
                                              )
                                            }
                                            {
                                              <InfoBlock>
                                                <InfoText>
                                                  <span>{getFieldInputName(field)}</span>&nbsp;|&nbsp;
                                                  <span>{getFieldLayoutName(field)}</span>&nbsp;|&nbsp;
                                                  <span>Label:&nbsp;{getFieldLabelStyleName(field)}</span>&nbsp;|&nbsp;
                                                  <span>Value:&nbsp;{getFieldValueStyleName(field)}</span>
                                                </InfoText>
                                                {
                                                    field.desc &&
                                                    <InfoText><span>{intl.msg("template_field_description")}</span> {field.desc}</InfoText>
                                                }
                                              </InfoBlock>
                                            }
                                          </div>
                                        </div>
                                    )
                                }

                                {
                                  isEditable() &&
                                    <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                      <label className="col-sm-3 col-form-label">
                                        <btn id="inp_add_creativeFormField" href="#" className="btn btn-secondary" data-toggle="modal" data-target="#dlg_add_creativeFormField" data-backdrop="static">
                                          <i className="fas fa-plus" style={{marginRight: "4px"}}></i>{intl.msg("template_add_message_field")}
                                        </btn>
                                      </label>
                                      <div className="col-sm-9">
                                        <InfoBlock>
                                          <InfoText>{intl.msg("template_add_message_field_helptext_1")}</InfoText>
                                          <InfoText validate="required">{intl.msg("template_add_message_field_helptext_required")}</InfoText>
                                        </InfoBlock>
                                      </div>
                                    </div>
                                }

                                <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                  <label className="col-sm-3 col-form-label">{intl.msg("content_style")}:</label>
                                  <div className="col-sm-9">
                                    <div className="row">
                                      <div className="col-sm">
                                        <label className="col-form-label">{intl.msg("font")}:</label>
                                        <Select
                                            id="inp_creativeContentFont"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            value={{label:getStyle(creativeStyle, "content", "font"), value:getStyle(creativeStyle, "content", "font")}}
                                            options={fontOpts}
                                            onChange={e => handleCreativeStyleChange(e, "content", "font")}
                                            isDisabled={!isEditable()}
                                        />
                                      </div>
                                      <div className="col-sm">
                                        <label className="col-form-label text-nowrap">{intl.msg("text_color")}:</label>
                                        <input id="inp_creativeContentTextColor" type="color" className="form-control" style={{width: "80px"}} value={getStyle(creativeStyle, "content", "textColor")} onChange={e => handleCreativeStyleChange(e, "content", "textColor")} disabled={!isEditable()} autoComplete="no"/>
                                      </div>
                                      <div className="col-sm">
                                        <label className="col-form-label text-nowrap">{intl.msg("background_color")}:</label>
                                        <input id="inp_creativeContentBackgroundColor" type="color" className="form-control" style={{width: "80px"}} value={getStyle(creativeStyle, "content", "backgroundColor")} onChange={e => handleCreativeStyleChange(e, "content", "backgroundColor")} disabled={!isEditable()} autoComplete="no"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                  <label className="col-sm-3 col-form-label" htmlFor="inp-creativeFooter">
                                    {intl.msg("broadcast_message_footer")}
                                    {
                                      isEditable() &&
                                        <div>
                                          <btn key={`key_creativeFooterLocked_${creativeFooterLocked}`} href="#" className="btn btn-secondary btn-tiny" title={creativeImageUrlLocked ? intl.msg("template_field_unlock") : intl.msg("template_field_lock")} onClick={e => {setCreativeFooterLocked(!creativeFooterLocked); setTouched(true)}}><i className={creativeFooterLocked ? "fas fa-lock" : "fas fa-lock-open"}></i></btn>
                                        </div>
                                    }
                                  </label>
                                  <div className="col-sm-9">
                                    <div className="row">
                                      <div className="col-sm">
                                        <label className="col-form-label">{intl.msg("template_text")}:</label>
                                        <input id="inp_creativeFooter" type="text" className="form-control" value={creativeFooter} onChange={handleCreativeFooterChange} disabled={!isEditable()} autoComplete="no"/>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-sm">
                                        <label className="col-form-label">{intl.msg("font")}:</label>
                                        <Select
                                            id="inp_creativeFooterFont"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            value={{label:getStyle(creativeStyle, "footer", "font"), value:getStyle(creativeStyle, "footer", "font")}}
                                            options={fontOpts}
                                            onChange={e => handleCreativeStyleChange(e, "footer", "font")}
                                            isDisabled={!isEditable()}
                                        />
                                      </div>
                                      <div className="col-sm">
                                        <label className="col-form-label text-nowrap">{intl.msg("text_color")}:</label>
                                        <input id="inp_creativeFooterTextColor" type="color" className="form-control" style={{width: "80px"}} value={getStyle(creativeStyle, "footer", "textColor")} onChange={e => handleCreativeStyleChange(e, "footer", "textColor")} disabled={!isEditable()} autoComplete="no"/>
                                      </div>
                                      <div className="col-sm">
                                        <label className="col-form-label text-nowrap">{intl.msg("background_color")}:</label>
                                        <input id="inp_creativeFooterBackgroundColor" type="color" className="form-control" style={{width: "80px"}} value={getStyle(creativeStyle, "footer", "backgroundColor")} onChange={e => handleCreativeStyleChange(e, "footer", "backgroundColor")} disabled={!isEditable()} autoComplete="no"/>
                                      </div>
                                    </div>
                                    <InfoBlock>
                                      <InfoText>{intl.msg("broadcast_message_footer_helptext_1")}</InfoText>
                                    </InfoBlock>
                                  </div>
                                </div>

                                <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                  <label className="col-sm-3 col-form-label">{intl.msg("border_style")}:</label>
                                  <div className="col-sm-9">
                                    <div className="row">
                                      <div className="col-sm">
                                        <label className="col-form-label">{intl.msg("text_color")}:</label>
                                        <input id="inp_creativeBorderColor" type="color" className="form-control" style={{width: "80px"}} value={getStyle(creativeStyle, "border", "color")} onChange={e => handleCreativeStyleChange(e, "border", "color")} disabled={!isEditable()} autoComplete="no"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </>
                          )
                        }

                        <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                          <label className="col-sm-3 col-form-label col-form-label" htmlFor="inp_clickAction">
                            {intl.msg("broadcast_click_action")}:
                            {
                              isEditable() &&
                                <div>
                                  <btn key={`key_pageTypeLocked_${pageTypeLocked}`} href="#" className="btn btn-secondary btn-tiny" title={pageTypeLocked ? intl.msg("template_field_unlock") : intl.msg("template_field_lock")} onClick={e => {setPageTypeLocked(!pageTypeLocked); setTouched(true)}}><i className={pageTypeLocked ? "fas fa-lock" : "fas fa-lock-open"}></i></btn>
                                </div>
                            }
                          </label>
                          <div className="col-sm-9">
                            <Select
                                id="inp_clickAction"
                                className="react-select react-select__absolute"
                                classNamePrefix="react-select"
                                value={clickAction}
                                options={getClickActionOpts(tenant, intl)}
                                onChange={handleClickActionChange}
                                isDisabled={!isEditable()}
                            />
                            <InfoBlock>
                              <InfoText>{intl.msg("broadcast_click_action_helptext_1")}</InfoText>
                              {/*<InfoText disabled={!touched}>{intl.msg("broadcast_message_solv_message_type_photo_helptext_valid")}</InfoText>*/}
                            </InfoBlock>
                          </div>
                        </div>

                        {
                          "EXTERNAL" === pageType ? (
                              <>
                                <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                  <label className="col-sm-3 col-form-label" htmlFor="inp-pageUrl">
                                    {intl.msg("broadcast_page_solv_page_page_type_page_url")}:
                                    {
                                      isEditable() &&
                                        <div>
                                          <btn key={`key_pageUrlLocked_${pageUrlLocked}`} href="#" className="btn btn-secondary btn-tiny" title={pageUrlLocked ? intl.msg("template_field_unlock") : intl.msg("template_field_lock")} onClick={e => {setPageUrlLocked(!pageUrlLocked); setTouched(true)}}><i className={pageUrlLocked ? "fas fa-lock" : "fas fa-lock-open"}></i></btn>
                                        </div>
                                    }
                                  </label>
                                  <div className="col-sm-9">
                                    <div className="input-group">
                                      <input id="inp_pageUrl" name="pageUrl" type="text" className="form-control" value={pageUrl} disabled={!isEditable()} onChange={handleExtPageUrlChange} onBlur={handleExternalPageUrlBlur} onKeyDown={handleExternalPageUrlKeyDown} autoComplete="no"/>
                                      <div key="key_div_externalUrl" className="input-group-append">
                                        <button key="key_btn_externalUrl" className="btn btn-secondary" style={{borderTop: "1px solid #555", borderRight: "1px solid #555", borderBottom: "1px solid #555", width: "41px"}} type="button" title="Click to view this page" disabled={!validateUrl(pageUrl, {relaxed: true})} onClick={(e) => window.open(pageUrl)}><i className="fas fa-external-link-alt"></i></button>
                                      </div>
                                    </div>
                                    <InfoBlock>
                                      <InfoText>{intl.msg("broadcast_page_solv_page_page_type_page_url_helptext_1")}</InfoText>
                                      <InfoText validate="valid">{intl.msg("broadcast_page_solv_page_page_type_page_url_helptext_valid")}</InfoText>
                                      {
                                        (tenant.allowedDomains && tenant.allowedDomains.length > 0) &&
                                          <InfoText validate="whitelist">{intl.msg("broadcast_page_solv_page_page_type_page_url_helptext_match", {allowedDomains: mkDomainListText(tenant.allowedDomains)})}</InfoText>
                                      }
                                    </InfoBlock>
                                  </div>
                                </div>
                              </>
                          ) : "WHATSAPP" === pageType ? (
                              <>
                                <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                  <label className="col-sm-3 col-form-label" htmlFor="inp_whatsappPhoneNo">
                                    {intl.msg("broadcast_whatsapp_phone_no")}:
                                    {
                                      isEditable() &&
                                        <div>
                                          <btn key={`key_pageUrlLocked_${pageUrlLocked}`} href="#" className="btn btn-secondary btn-tiny" title={pageUrlLocked ? intl.msg("template_field_unlock") : intl.msg("template_field_lock")} onClick={e => {setPageUrlLocked(!pageUrlLocked); setTouched(true)}}><i className={pageUrlLocked ? "fas fa-lock" : "fas fa-lock-open"}></i></btn>
                                        </div>
                                    }
                                  </label>
                                  <div className="col-sm-9">
                                    <div className="input-group">
                                      <div style={{width: "200px"}}>
                                        <input id="inp_whatsappPhoneNo" type="text" className="form-control" maxLength={15} disabled={!isEditable()} value={whatsAppPhoneNo} onChange={handleWatsAppPhoneNoChange}/>
                                      </div>
                                      <div key="key_div_whatsAppUrl" className="input-group-append">
                                        <button key="key_btn_whatsAppUrl" className="btn btn-secondary" style={{borderTop: "1px solid #555", borderRight: "1px solid #555", borderBottom: "1px solid #555", width: "41px"}} type="button" title="Click to test chatting with this number" disabled={!validateUrl(pageUrl, {relaxed: true})} onClick={(e) => window.open(pageUrl)}><i className="fas fa-external-link-alt"></i></button>
                                      </div>
                                    </div>
                                    <InfoBlock>
                                      <InfoText>{intl.msg("broadcast_whatsapp_phone_no_helptext_1")}</InfoText>
                                      <InfoText validate="valid">{intl.msg("broadcast_whatsapp_phone_no_helptext_valid")}</InfoText>
                                    </InfoBlock>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )
                        }
                      </div>

                      <div className="col-sm-4">
                        <div style={{position: "sticky", top: "158px", overflow: "auto"}}>
                          <div className="live-preview-section-divider">{intl.msg("broadcast_message_preview_section")}</div>
                          <CreativePreview
                              tenantName={tenant.displayName || tenant.tenantName}
                              creativeType={creativeType}
                              creativeHeader={creativeHeader}
                              creativeTitle={creativeTitle}
                              creativeContent={creativeContent}
                              creativeFormFields={creativeFormFields}
                              creativeFooter={creativeFooter}
                              creativeStyle={creativeStyle}
                              creativeImageUrl={creativeImageUrl}
                              logoUrl={tenant.logoUrl}
                              regionCode={tenant?.region.regionId}
                          />
                          <div style={{maxWidth: "300px", marginTop: "10px"}}>
                            <small><span  style={{color: "#fff"}}><i className="fas fa-info-circle mr-1"></i></span>{intl.msg("broadcast_message_preview_helptext_1")}</small>
                          </div>
                        </div>
                      </div>
                    </div>

                    {
                      "SOLV" === pageType &&
                        <>
                          <SectionHeader title={intl.msg("broadcast_page_section_title")} text={intl.msg("broadcast_page_section_text")}/>

                          <div className="row">

                            <div className="col-sm-7">

                              <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                <label className="col-sm-3 col-form-label">{intl.msg("body_style")}:</label>
                                <div className="col-sm-9">
                                  <div className="row">
                                    <div className="col-sm">
                                      <label className="col-form-label">{intl.msg("font")}:</label>
                                      <Select
                                          id="inp_pageBodyFont"
                                          className="react-select"
                                          classNamePrefix="react-select"
                                          value={{label:getStyle(pageStyle, "body", "font"), value:getStyle(pageStyle, "body", "font")}}
                                          options={fontOpts}
                                          onChange={e => handlePageStyleChange(e, "body", "font")}
                                          isDisabled={!isEditable()}
                                      />
                                    </div>
                                    <div className="col-sm">
                                      <label className="col-form-label text-nowrap">{intl.msg("text_color")}:</label>
                                      <input id="inp_pageBodyTextColor" type="color" className="form-control" style={{width: "80px"}} value={getStyle(pageStyle, "body", "textColor")} onChange={e => handlePageStyleChange(e, "body", "textColor")} disabled={!isEditable()} autoComplete="no"/>
                                    </div>
                                    <div className="col-sm">
                                      <label className="col-form-label text-nowrap">{intl.msg("background_color")}:</label>
                                      <input id="inp_pageBodyBackgroundColor" type="color" className="form-control" style={{width: "80px"}} value={getStyle(pageStyle, "body", "backgroundColor")} onChange={e => handlePageStyleChange(e, "body", "backgroundColor")} disabled={!isEditable()} autoComplete="no"/>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                <label className="col-sm-3 col-form-label" htmlFor="inp_pageHeader">
                                  {intl.msg("broadcast_page_header")}:
                                  {
                                      isEditable() &&
                                      <div>
                                        <btn key={`key_pageHeaderLocked_${pageHeaderLocked}`} href="#" className="btn btn-secondary btn-tiny" title={pageHeaderLocked ? intl.msg("template_field_unlock") : intl.msg("template_field_lock")} onClick={e => {setPageHeaderLocked(!pageHeaderLocked); setTouched(true)}}><i className={pageHeaderLocked ? "fas fa-lock" : "fas fa-lock-open"}></i></btn>
                                      </div>
                                  }
                                </label>
                                <div className="col-sm-9">
                                  <div className="row">
                                    <div className="col-sm">
                                      <label className="col-form-label">{intl.msg("text")}:</label>
                                      <input id="inp_pageHeader" type="text" className="form-control" value={pageHeader} onChange={handlePageHeaderChange} disabled={!isEditable()} autoComplete="no"/>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm">
                                      <label className="col-form-label">{intl.msg("font")}:</label>
                                      <Select
                                          id="inp_pageHeaderFont"
                                          className="react-select"
                                          classNamePrefix="react-select"
                                          value={{label:getStyle(pageStyle, "header", "font"), value:getStyle(pageStyle, "header", "font")}}
                                          options={fontOpts}
                                          onChange={e => handlePageStyleChange(e, "header", "font")}
                                          isDisabled={!isEditable()}
                                      />
                                    </div>
                                    <div className="col-sm">
                                      <label className="col-form-label text-nowrap">{intl.msg("text_color")}:</label>
                                      <input id="inp_pageHeaderTextColor" type="color" className="form-control" style={{width: "80px"}} value={getStyle(pageStyle, "header", "textColor")} onChange={e => handlePageStyleChange(e, "header", "textColor")} disabled={!isEditable()} autoComplete="no"/>
                                    </div>
                                    <div className="col-sm">
                                      <label className="col-form-label text-nowrap">{intl.msg("background_color")}:</label>
                                      <input id="inp_pageHeaderBackgroundColor" type="color" className="form-control" style={{width: "80px"}} value={getStyle(pageStyle, "header", "backgroundColor")} onChange={e => handlePageStyleChange(e, "header", "backgroundColor")} disabled={!isEditable()} autoComplete="no"/>
                                    </div>
                                  </div>
                                  <InfoBlock>
                                    <InfoText>{intl.msg("broadcast_page_header_helptext_1")}</InfoText>
                                  </InfoBlock>
                                </div>
                              </div>

                              <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                <label className="col-sm-3 col-form-label" htmlFor="inp_pageTitle">
                                  {intl.msg("broadcast_page_title")}:
                                  {
                                      isEditable() &&
                                      <div>
                                        <btn key={`key_pageTitleLocked_${pageTitleLocked}`} href="#" className="btn btn-secondary btn-tiny" title={pageTitleLocked ? intl.msg("template_field_unlock") : intl.msg("template_field_lock")} onClick={e => {setPageTitleLocked(!pageTitleLocked); setTouched(true)}}><i className={pageTitleLocked ? "fas fa-lock" : "fas fa-lock-open"}></i></btn>
                                      </div>
                                  }
                                </label>
                                <div className="col-sm-9">
                                  <div className="row">
                                    <div className="col-sm">
                                      <label className="col-form-label">{intl.msg("text")}:</label>
                                      <input id="inp_pageTitle" type="text" className="form-control" value={pageTitle} onChange={handlePageTitleChange} disabled={!isEditable()} autoComplete="no"/>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm">
                                      <label className="col-form-label">{intl.msg("font")}:</label>
                                      <Select
                                          id="inp_pageTitleFont"
                                          className="react-select"
                                          classNamePrefix="react-select"
                                          value={{label:getStyle(pageStyle, "title", "font"), value:getStyle(pageStyle, "title", "font")}}
                                          options={fontOpts}
                                          onChange={e => handlePageStyleChange(e, "title", "font")}
                                          isDisabled={!isEditable()}
                                      />
                                    </div>
                                    <div className="col-sm">
                                      <label className="col-form-label text-nowrap">{intl.msg("text_color")}:</label>
                                      <input id="inp_pageTitleTextColor" type="color" className="form-control" style={{width: "80px"}} value={getStyle(pageStyle, "title", "textColor")} onChange={e => handlePageStyleChange(e, "title", "textColor")} disabled={!isEditable()} autoComplete="no"/>
                                    </div>
                                  </div>
                                  <InfoBlock>
                                    <InfoText>{intl.msg("broadcast_page_title_helptext_1")}</InfoText>
                                  </InfoBlock>
                                </div>
                              </div>

                              <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                <label className="col-sm-3 col-form-label" htmlFor="inp_pagePhoto">
                                  {intl.msg("broadcast_page_photo")}
                                  {
                                      isEditable() &&
                                      <div>
                                        <btn key={`key_pageImageUrlLocked_${pageImageUrlLocked}`} href="#" className="btn btn-secondary btn-tiny" title={pageImageUrlLocked ? intl.msg("template_field_unlock") : intl.msg("template_field_lock")} onClick={e => {setPageImageUrlLocked(!pageImageUrlLocked); setTouched(true)}}><i className={pageImageUrlLocked ? "fas fa-lock" : "fas fa-lock-open"}></i></btn>
                                      </div>
                                  }
                                </label>
                                <div className="col-sm-9">
                                  <ImageDropBox
                                      id="inp_pagePhoto"
                                      image={pageImageUrl}
                                      imageClassName={"secondary-photo"}
                                      width={300}
                                      height={400}
                                      onChange={handlePageImageUrlChange}
                                      onClear={handlePageImageClear}
                                      disabled={!isEditable()}/>
                                  <InfoBlock>
                                    <InfoText>{intl.msg("broadcast_page_photo_helptext_1")}</InfoText>
                                    <InfoText>{intl.msg("broadcast_page_photo_helptext_valid")}</InfoText>
                                  </InfoBlock>
                                </div>
                              </div>

                              {
                                  pageFormFields  && pageFormFields.map( (field, index) =>
                                      <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                        <label className="col-3 col-form-label" style={{whiteSpace: "nowrap"}} htmlFor={`inp_edit_pageForm_${field.id}`}>
                                          {field.label}:
                                          <div>
                                            {
                                                isEditable() &&
                                                <>
                                                  <a href="#" className="btn btn-secondary btn-tiny" title={intl.msg("template_edit_field_tooltip")} data-toggle="modal" data-target={`#dlg_edit_pageFormField_${field.id}`} data-backdrop="static">
                                                    <i className="fas fa-pen"></i>
                                                  </a>
                                                  {
                                                      pageFormFields && pageFormFields.length && pageFormFields.length > 1 &&
                                                      <>
                                                        <btn href="#" className="btn btn-secondary btn-tiny" title={intl.msg("template_move_field_up")} onClick={e => handleMovePageFieldUpClick(e, field.id)}>
                                                          <i className="fas fa-arrow-up"></i>
                                                        </btn>
                                                        <btn href="#" className="btn btn-secondary btn-tiny" title={intl.msg("template_move_field_down")} onClick={e => handleMovePageFieldDownClick(e, field.id)}>
                                                          <i className="fas fa-arrow-down"></i>
                                                        </btn>
                                                      </>
                                                  }
                                                  <>
                                                    <button className="btn btn-secondary btn-tiny" type="button" title={intl.msg("more_menu")} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                      <i className="fas fa-ellipsis-v"></i>
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
                                                      <a className="dropdown-item" href="#" data-toggle="modal" data-target={`#dlg_clone_pageFormField_${field.id}`} data-backdrop="static">
                                                        <i className="fas fa-clone mr-1" style={{fontSize: "14px"}}></i>{intl.msg("template_clone_field")}
                                                      </a>
                                                      <a className="dropdown-item" href="#" data-toggle="modal" data-target={`#dlg_cloneAs_pageFormField_${field.id}`} data-backdrop="static">
                                                        <i className="fas fa-clone mr-1" style={{fontSize: "14px"}}></i>{intl.msg("template_clone_as_message_field")}
                                                      </a>
                                                      <div className="dropdown-divider"></div>
                                                      <a className="dropdown-item" href="#" data-toggle="modal" data-target={`#dlg_delete_pageFormField_${field.id}`} data-backdrop="static">
                                                        <i className="fas fa-trash-alt mr-1" style={{fontSize: "14px"}}></i>Delete
                                                      </a>
                                                    </div>
                                                  </>
                                                </>
                                            }
                                          </div>
                                          <CustomFieldEditDialog
                                              id={`dlg_edit_pageFormField_${field.id}`}
                                              dialogType="Page"
                                              fields={pageFormFields}
                                              field={field}
                                              setFields={setPageFormFields}
                                              setTouched={setTouched}/>
                                          <CustomFieldDeleteDialog
                                              id={`dlg_delete_pageFormField_${field.id}`}
                                              dialogType="Page"
                                              fields={pageFormFields}
                                              field={field}
                                              setFields={setPageFormFields}
                                              setTouched={setTouched}/>
                                          <CustomFieldCloneDialog
                                              key={['dlg_clone_pageFormField-', index].join('_')}
                                              id={`dlg_clone_pageFormField_${field.id}`}
                                              dialogType="Page"
                                              field={field}
                                              fields={pageFormFields}
                                              setFields={setPageFormFields}
                                              setTouched={setTouched}/>
                                          <CustomFieldCloneAsDialog
                                              key={['dlg_cloneAs_pageFormField-', index].join('_')}
                                              id={`dlg_cloneAs_pageFormField_${field.id}`}
                                              dialogType="Page"
                                              targetType="Message"
                                              field={field}
                                              fields={pageFormFields}
                                              targetFields={creativeFormFields}
                                              setTargetFields={setCreativeFormFields}
                                              setTouched={setTouched}/>
                                        </label>
                                        <div className="col-sm-9">
                                          {
                                            ["TEXT_LINE"].includes(field.type) ? (
                                                <input
                                                    key={['edit_pageFormField_', index].join('_')}
                                                    id={`inp_edit_pageForm_${field.id}`}
                                                    type="text"
                                                    className="form-control"
                                                    value={field.value}
                                                    disabled={!isEditable()}
                                                    onChange={e => handlePageFormFieldChange(e, field.id)}
                                                    autoComplete="no"/>
                                            ) : ["TEXT_BLOCK"].includes(field.type) ? (
                                                <textarea
                                                    key={['edit_pageFormField-', index].join('_')}
                                                    id={`inp_edit_pageForm_${field.id}`}
                                                    className="form-control"
                                                    rows="5"
                                                    autoComplete="no"
                                                    disabled={!isEditable()}
                                                    value={field.value}
                                                    onChange={e => handlePageFormFieldChange(e, field.id)}
                                                />
                                            ) : ["NUM_INT"].includes(field.type) ? (
                                                <NumericInput
                                                    id={`inp_edit_pageForm_${field.id}`}
                                                    className="form-control"
                                                    style={{wrap: {width: "200px"}}}
                                                    min={0}
                                                    precision={0}
                                                    disabled={!isEditable()}
                                                    value={field.value}
                                                    onChange={e => handlePageFormFieldChange(e, field.id)} />
                                            ) : ["NUM_REAL"].includes(field.type) ? (
                                                <NumericInput
                                                    id={`inp_edit_pageForm_${field.id}`}
                                                    className="form-control"
                                                    style={{wrap: {width: "200px"}}}
                                                    min={0}
                                                    precision={2}
                                                    disabled={!isEditable()}
                                                    value={field.value}
                                                    onChange={e => handlePageFormFieldChange(e, field.id)} />
                                            ) : ["DATE"].includes(field.type) ? (
                                                <DatePicker
                                                    key={['edit_pageFormField-', index].join('_')}
                                                    id={`inp_edit_pageForm_${field.id}`}
                                                    selected={getDate(field.value)}
                                                    dateFormat={getDateFormat(tenant?.region.regionId, "date-picker")}
                                                    onChange={e => handlePageFormFieldChange(e, field.id)} />
                                            ) : ["SELECT"].includes(field.type) && field.opts && field.opts.selectList? (
                                                <select
                                                    key={['edit_pageFormField-', index].join('_')}
                                                    id={`inp_edit_pageForm_${field.id}`}
                                                    value={field.value}
                                                    onChange={e => handlePageFormFieldChange(e,field.id)}
                                                    className="form-control">
                                                  {
                                                    field.opts.selectList.split(",").map(o => <option value={o}>{o}</option>)
                                                  }
                                                </select>
                                            ) : (
                                                <>
                                                </>
                                            )
                                          }

                                          <InfoBlock>
                                            <InfoText>
                                              <span>{getFieldInputName(field)}</span>&nbsp;|&nbsp;
                                              <span>{getFieldLayoutName(field)}</span>&nbsp;|&nbsp;
                                              <span>Label:&nbsp;{getFieldLabelStyleName(field)}</span>&nbsp;|&nbsp;
                                              <span>Value:&nbsp;{getFieldValueStyleName(field)}</span>
                                            </InfoText>
                                            {
                                                field.desc &&
                                                <InfoText><span>{intl.msg("template_field_description")}</span>{field.desc}</InfoText>
                                            }
                                          </InfoBlock>

                                        </div>
                                      </div>
                                  )
                              }

                              {
                                isEditable() &&
                                  <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                    <label className="col-sm-3 col-form-label">
                                      <btn id="inp_add_pageFormField" href="#" className="btn btn-secondary" data-toggle="modal" data-target="#dlg_add_pageFormField" data-backdrop="static">
                                        <i className="fas fa-plus" style={{marginRight: "4px"}}></i>{intl.msg("template_add_page_field")}
                                      </btn>
                                    </label>
                                    <div className="col-sm-9">
                                      <InfoBlock>
                                        <InfoText>{intl.msg("template_add_page_field_helptext_1")}</InfoText>
                                        <InfoText validate="required">{intl.msg("template_add_page_field_helptext_required")}</InfoText>
                                      </InfoBlock>
                                    </div>
                                  </div>
                              }

                              <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                <label className="col-sm-3 col-form-label">{intl.msg("content_style")}:</label>
                                <div className="col-sm-9">
                                  <div className="row">
                                    <div className="col-sm">
                                      <label className="col-form-label">{intl.msg("font")}:</label>
                                      <Select
                                          id="inp_pageContentFont"
                                          className="react-select"
                                          classNamePrefix="react-select"
                                          value={{label:getStyle(pageStyle, "content", "font"), value:getStyle(pageStyle, "content", "font")}}
                                          options={fontOpts}
                                          onChange={e => handlePageStyleChange(e, "content", "font")}
                                          isDisabled={!isEditable()}
                                      />
                                    </div>
                                    <div className="col-sm">
                                      <label className="col-form-label text-nowrap">{intl.msg("text_color")}:</label>
                                      <input id="inp_pageContentTextColor" type="color" className="form-control" style={{width: "80px"}} value={getStyle(pageStyle, "content", "textColor")} onChange={e => handlePageStyleChange(e, "content", "textColor")} disabled={!isEditable()} autoComplete="no"/>
                                    </div>
                                    <div className="col-sm">
                                      <label className="col-form-label text-nowrap">{intl.msg("background_color")}:</label>
                                      <input id="inp_pageContentBackgroundColor" type="color" className="form-control" style={{width: "80px"}} value={getStyle(pageStyle, "content", "backgroundColor")} onChange={e => handlePageStyleChange(e, "content", "backgroundColor")} disabled={!isEditable()} autoComplete="no"/>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                <label className="col-sm-3 col-form-label" htmlFor="inp_feedbackEnabled">{intl.msg("broadcast_response")}:</label>
                                <div className="col-sm-9">
                                  <ToggleButton
                                      id="inp_feedbackEnabled"
                                      checked={feedbackEnabled}
                                      readonly={!isEditable()}
                                      onChange={(v) => {setFeedbackEnabled(v); setTouched(true)}}
                                      onLabel={intl.msg("yes")}
                                      offLabel={intl.msg("no")}
                                  />
                                  <InfoBlock>
                                    <InfoText>{intl.msg("broadcast_response_helptext_1")}</InfoText>
                                  </InfoBlock>
                                </div>
                              </div>

                              {
                                feedbackEnabled &&
                                  <>
                                    {
                                      feedbackFormFields  && feedbackFormFields.map( (field, index) =>
                                        <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                          <label className="col-3 col-form-label" style={{whiteSpace: "nowrap"}} htmlFor={`inp_feedback_pageForm_${field.id}`}>
                                            {field.label}:
                                            <div>
                                              {
                                                  isEditable() &&
                                                  <>
                                                    <a href="#" className="btn btn-secondary btn-tiny" title={intl.msg("template_edit_field_tooltip")} data-toggle="modal" data-target={`#dlg_edit_feedbackFormField_${field.id}`} data-backdrop="static">
                                                      <i className="fas fa-pen"></i>
                                                    </a>
                                                    {
                                                        feedbackFormFields && feedbackFormFields.length && feedbackFormFields.length > 1 &&
                                                        <>
                                                          <btn href="#" className="btn btn-secondary btn-tiny" title={intl.msg("template_move_field_up")} onClick={e => handleMoveFeedbackFieldUpClick(e, field.id)}>
                                                            <i className="fas fa-arrow-up"></i>
                                                          </btn>
                                                          <btn href="#" className="btn btn-secondary btn-tiny" title={intl.msg("template_move_field_down")} onClick={e => handleMoveFeedbackFieldDownClick(e, field.id)}>
                                                            <i className="fas fa-arrow-down"></i>
                                                          </btn>
                                                        </>
                                                    }
                                                    <>
                                                      <button className="btn btn-secondary btn-tiny" type="button" title={intl.msg("more_menu")} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fas fa-ellipsis-v"></i>
                                                      </button>
                                                      <div className="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
                                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target={`#dlg_clone_feedbackFormField_${field.id}`} data-backdrop="static">
                                                          <i className="fas fa-clone mr-1" style={{fontSize: "14px"}}></i>{intl.msg("template_clone_field")}
                                                        </a>
                                                        <div className="dropdown-divider"></div>
                                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target={`#dlg_delete_feedbackFormField_${field.id}`} data-backdrop="static">
                                                          <i className="fas fa-trash-alt mr-1" style={{fontSize: "14px"}}></i>{intl.msg("template_delete_field")}
                                                        </a>
                                                      </div>
                                                    </>
                                                  </>
                                              }
                                            </div>
                                            <CustomFieldEditDialog
                                                id={`dlg_edit_feedbackFormField_${field.id}`}
                                                dialogType="Response"
                                                fields={feedbackFormFields}
                                                field={field}
                                                setFields={setFeedbackFormFields}
                                                setTouched={setTouched}
                                                allowedFieldTypes={["TEXT_LINE", "TEXT_BLOCK", "SELECT"]}
                                                allowedFieldLayouts={["NEWLINE", "LCELL"]}/>
                                            <CustomFieldDeleteDialog
                                                id={`dlg_delete_feedbackFormField_${field.id}`}
                                                dialogType="Response"
                                                fields={feedbackFormFields}
                                                field={field}
                                                setFields={setFeedbackFormFields}
                                                setTouched={setTouched}
                                                allowedFieldTypes={["TEXT_LINE", "TEXT_BLOCK", "SELECT"]}/>
                                            <CustomFieldCloneDialog
                                                key={['dlg_clone_feedbackFormField_', index].join('_')}
                                                id={`dlg_clone_feedbackFormField_${field.id}`}
                                                dialogType="Response"
                                                field={field}
                                                fields={feedbackFormFields}
                                                setFields={setFeedbackFormFields}
                                                setTouched={setTouched}/>
                                          </label>
                                          <div className="col-sm-9">
                                            {
                                              ["TEXT_LINE"].includes(field.type) ? (
                                                  <input
                                                      key={['edit_feedbackFormField-', index].join('_')}
                                                      id={`inp_edit_feedbackForm_${field.id}`}
                                                      type="text"
                                                      className="form-control"
                                                      value={field.value}
                                                      disabled={!isEditable()}
                                                      onChange={e => handleFeedbackFormFieldChange(e, field.id)}
                                                      autoComplete="no"/>
                                              ) : ["TEXT_BLOCK"].includes(field.type) ? (
                                                  <textarea
                                                      key={['edit_feedbackFormField-', index].join('_')}
                                                      id={`inp_edit_feedbackForm_${field.id}`}
                                                      className="form-control"
                                                      rows="5"
                                                      autoComplete="no"
                                                      disabled={!isEditable()}
                                                      value={field.value}
                                                      onChange={e => handleFeedbackFormFieldChange(e, field.id)}
                                                  />
                                              ) : ["SELECT"].includes(field.type) && field.opts && field.opts.selectList? (
                                                  <select
                                                      key={['edit_feedbackFormField-', index].join('_')}
                                                      id={`inp_edit_feedbackForm_${field.id}`}
                                                      value={field.value}
                                                      onChange={e => handleFeedbackFormFieldChange(e,field.id)}
                                                      className="form-control">
                                                    {
                                                      field.opts.selectList.split(",").map(o => <option value={o}>{o}</option>)
                                                    }
                                                  </select>
                                              ) : (
                                                  <>
                                                  </>
                                              )
                                            }

                                            <InfoBlock>
                                              <InfoText>
                                                <span>{getFieldInputName(field)}</span>&nbsp;|&nbsp;
                                                <span>{getFieldLayoutName(field)}</span>&nbsp;|&nbsp;
                                                <span>Label:&nbsp;{getFieldLabelStyleName(field)}</span>&nbsp;|&nbsp;
                                                <span>Value:&nbsp;{getFieldValueStyleName(field)}</span>
                                              </InfoText>
                                              {
                                                  field.desc &&
                                                  <InfoText><span>{intl.msg("template_field_description")}</span> {field.desc}</InfoText>
                                              }
                                            </InfoBlock>

                                          </div>
                                        </div>
                                      )
                                    }

                                    {
                                      isEditable() &&
                                        <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                          <label className="col-sm-3 col-form-label">
                                            <btn id="inp_add_feedbackFormField" href="#" className="btn btn-secondary" data-toggle="modal" data-target="#dlg_add_feedbackFormField" data-backdrop="static">
                                              <i className="fas fa-plus" style={{marginRight: "4px"}}></i>{intl.msg("template_add_response_field")}
                                            </btn>
                                          </label>
                                          <div className="col-sm-9">
                                            <InfoBlock>
                                              <InfoText>{intl.msg("template_add_response_field_helptext_1")}</InfoText>
                                              <InfoText validate="required">{intl.msg("template_add_response_field_helptext_required")}</InfoText>
                                            </InfoBlock>
                                          </div>
                                        </div>
                                    }

                                    <div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                                      <label className="col-sm-3 col-form-label" htmlFor="inp_feedbackThankyouText">{intl.msg("template_thank_you")}</label>
                                      <div className="col-sm-9">
                                        <textarea id="inp_feedbackThankyouText" className="form-control" value={feedbackThankyouText} disabled={!isEditable()} autoComplete="no" onChange={handleFeedbackThankyouTextChange} rows="5"/>
                                        <InfoBlock>
                                          <InfoText>{intl.msg("template_thank_you_helptext_1")}</InfoText>
                                          <InfoText>{intl.msg("helptext_you_can_use_markdown")}</InfoText>
                                        </InfoBlock>
                                      </div>
                                    </div>
                                  </>
                              }

                            </div>

                            <div className="col-sm-5" style={{backgroundColor: "var(--form-background-color"}}>

                              {
                                <div style={{position: "sticky", "top": "240px"}}>
                                  <div className="live-preview-section-divider">
                                    {
                                      "SOLV" === pageType ? (
                                          <>
                                            {intl.msg("broadcast_page_preview_section")}
                                            {
                                                !touched &&
                                                <a className="icon-link pl-2" href={pageUrl} title={intl.msg("broadcast_page_preview_tooltip")} target="_blank"><i className="fas fa-external-link-alt"></i></a>
                                            }
                                          </>
                                      ) : (
                                          "LOADING" === extPageLoading ? (
                                              <>
                                                {intl.msg("broadcast_page_preview_section")}
                                                <div className="live-preview-section-divider-spinner"></div>
                                              </>
                                          ) : "LOADED" === extPageLoading ? (
                                              <>
                                                {intl.msg("broadcast_page_preview_section")}
                                                <a className="icon-link pl-2" href={pageUrl} title={intl.msg("broadcast_page_preview_tooltip")} target="_blank"><i className="fas fa-external-link-alt"></i></a>
                                              </>
                                          ) : (
                                              <></>
                                          )
                                      )
                                    }
                                  </div>
                                  <div className={`${pageType ? pageType.toLowerCase() : "solv"}-page-preview-container`}>
                                    <div ref={pagePreviewRef}>
                                      {
                                          (("SOLV" === pageType) || (["LOADING", "LOADED"].includes(extPageLoading))) &&
                                          <PagePreview
                                              pageType={pageType}
                                              pageUrl={pageUrl}
                                              publishStatus={"PUBLISHED"}
                                              logoUrl={tenant.logoUrl}
                                              tenantName={tenant.displayName || tenant.tenantName}
                                              tenantDisplayName={tenant.displayName}
                                              homePage={tenant.homePage}
                                              contactInfo={tenant.contactInfo}
                                              pageTitle={pageTitle}
                                              pageHeader={pageHeader}
                                              pageContent={pageContent}
                                              pageFormFields={pageFormFields}
                                              pageStyle={pageStyle}
                                              pageImageUrl={pageImageUrl}
                                              feedbackEnabled={feedbackEnabled}
                                              feedbackFormFields={feedbackFormFields}
                                              feedbackThankyouText={feedbackThankyouText}
                                              allowedDomains={tenant.allowedDomains}
                                              disallowedDomains={tenant.disallowedDomains}
                                              preview={true}
                                              width={pagePreviewWidth}
                                              onLoad={handleExtPageUrlLoad}
                                              onError={handleExtPageUrlError}
                                          />
                                      }
                                    </div>
                                  </div>

                                  {
                                      (("SOLV" === pageType && !touched) || ("LOADED" == extPageLoading)) &&
                                      <div style={{marginTop: "10px"}}>
                                        <small>
                                          <span><i className="fas fa-info-circle"></i></span>&nbsp;
                                          {intl.msg("broadcast_page_preview_helptext_1", {a: chunks => <a className="text-link" href={pageUrl} target="_blank">{chunks}</a>})}
                                        </small>
                                      </div>
                                  }

                                </div>
                              }

                            </div>

                          </div>
                        </>
                    }

                  </div>

                </form>
              </div>
            </div>
          </FormBody>

        </MainContainer>

        <ImageDialog
            ref={creativeImagePickerDialogRef}
            id="dlg_creativeImagePicker"
            title="Message Photo"
            imageInfo={creativeImageInfo}
            setTargetImageData={setCreativeImageUrl}
            setTouched={setTouched}
        />

        <ImageDialog
            ref={pageImagePickerDialogRef}
            id="dlg_pageImagePicker"
            title="Page Photo"
            imageInfo={pageImageInfo}
            setTargetImageData={setPageImageUrl}
            setTouched={setTouched}
        />

        <ImageDialog
            ref={orgLogoImagePickerDialogRef}
            id="dlg_orgLogoImagePicker"
            title="Organization Logo Image"
            imageInfo={orgLogoImageInfo}
            setTargetImageData={setOrgLogoUrl}
            setTouched={setTouched}
        />

        <CustomFieldEditDialog
            id="dlg_add_creativeFormField"
            dialogType="Message"
            fields={creativeFormFields}
            setFields={setCreativeFormFields}
            setTouched={setTouched}
            addMode={true}
        />

        <CustomFieldEditDialog
            id="dlg_add_pageFormField"
            dialogType="Page"
            fields={pageFormFields}
            setFields={setPageFormFields}
            setTouched={setTouched}
            addMode={true}
        />

        <CustomFieldEditDialog
            id="dlg_add_feedbackFormField"
            dialogType="Response"
            fields={feedbackFormFields}
            setFields={setFeedbackFormFields}
            setTouched={setTouched}
            addMode={true}
            allowedFieldTypes={["TEXT_LINE", "TEXT_BLOCK", "SELECT"]}
            allowedFieldLayouts={["NEWLINE", "LCELL"]}
        />

        <TemplateCloneDialog
            isAddMode={isAddMode}
            tenant={tenant}
            broadcastTemplateId={broadcastTemplateId}
            broadcastTemplateName={broadcastTemplateName}
        />

        <TemplateDeleteDialog
            isAddMode={isAddMode}
            tenant={tenant}
            broadcastTemplateId={broadcastTemplateId}
            broadcastTemplateName={broadcastTemplateName}
        />

        <TemplateSetDefaultDialog
            isAddMode={isAddMode}
            tenant={tenant}
            broadcastTemplateId={broadcastTemplateId}
            broadcastTemplateName={broadcastTemplateName}
        />

        <TemplateDistributeDialog
            tenant={tenant}
            broadcastTemplateId={broadcastTemplateId}
            broadcastTemplateName={broadcastTemplateName}
        />

        <TemplateDetailsDialog
            tenant={tenant}
            broadcastTemplateId={broadcastTemplateId}
            broadcastTemplateName={broadcastTemplateName}
        />

      </>
  )
}

{/*{*/}
{/*  ("SOLV" === creativeType || "SOLV" === pageType) &&*/}

{/*    <>*/}
{/*      <div className="section-divider">*/}
{/*        {intl.msg("organization_info_section")}*/}
{/*      </div>*/}

{/*      <div className="form-group row">*/}
{/*        <label className="col-2 col-form-label" htmlFor="inp_orgName">{intl.msg("organization_name")}</label>*/}
{/*        <div className="col-10">*/}
{/*          <input id="inp_displayName" type="text" className="form-control" value={orgName} disabled={!isEditable()} onChange={handleOrgNameChange} autoComplete="no"/>*/}
{/*          <InfoBlock>*/}
{/*            <InfoText>{intl.msg("organization_name_helptext_1")}</InfoText>*/}
{/*            /!*<InfoText validate="required">{intl.msg("helptext_required")}</InfoText>*!/*/}
{/*          </InfoBlock>*/}
{/*        </div>*/}
{/*      </div>*/}

{/*      <div className="form-group row">*/}
{/*        <label className="col-2 col-form-label" htmlFor="inp_logoUrl">{intl.msg("organization_logo")}</label>*/}
{/*        <div className="col-10">*/}
{/*          <ImageDropBox*/}
{/*              id="inp_logoUrl"*/}
{/*              image={orgLogoUrl}*/}
{/*              imageClassName={"account-logo"}*/}
{/*              width={200}*/}
{/*              height={200}*/}
{/*              onChange={handleOrgLogoChange}*/}
{/*              onClear={handleOrgLogoClear}*/}
{/*              disabled={!isEditable()}/>*/}
{/*          <InfoBlock>*/}
{/*            <InfoText>{intl.msg("organization_logo_helptext_1")}</InfoText>*/}
{/*            <InfoText>{intl.msg("organization_logo_helptext_2")}</InfoText>*/}
{/*            <InfoText validate="required">{intl.msg("helptext_optional")}</InfoText>*/}
{/*          </InfoBlock>*/}
{/*        </div>*/}
{/*      </div>*/}

{/*      {*/}
{/*        "SOLV" === pageType &&*/}
{/*          <div className="form-group row">*/}
{/*            <label className="col-2 col-form-label" htmlFor="inp_contactInfo">{intl.msg("organization_contact")}</label>*/}
{/*            <div className="col-10">*/}
{/*              <textarea id="inp_contactInfo" className="form-control" rows="2" autoComplete="no" value={orgContactInfo} disabled={!isEditable()} onChange={handleOrgContactInfoChange} style={{whiteSpace: "pre-line"}}/>*/}
{/*              <InfoBlock>*/}
{/*                <InfoText>{intl.msg("organization_contact_helptext_1")}</InfoText>*/}
{/*                <InfoText validate="optional">{intl.msg("helptext_optional")}</InfoText>*/}
{/*              </InfoBlock>*/}
{/*            </div>*/}
{/*          </div>*/}
{/*      }*/}

{/*      {*/}
{/*        "SOLV" === pageType &&*/}
{/*          <div className="form-group row">*/}
{/*            <label className="col-2 col-form-label" htmlFor="inp_homePage">{intl.msg("organization_home_page")}</label>*/}
{/*            <div className="col-10">*/}
{/*              <div className="input-group">*/}
{/*                <input id="inp_homePage" type="text" className="form-control" value={orgHomePage} onChange={handleOrgHomePageChange} onBlur={handleOrgHomePageBlur}/>*/}
{/*                {*/}
{/*                  validateUrl(orgHomePage) &&*/}
{/*                    <div className="input-group-append">*/}
{/*                      <button className="btn btn-secondary" style={{borderTop: "1px solid #555", borderRight: "1px solid #555", borderBottom: "1px solid #555", width: "41px"}} type="button" title="View page in new browser tab" onClick={(e) => window.open(orgHomePage)}><i className="fas fa-external-link-alt"></i></button>*/}
{/*                    </div>*/}
{/*                }*/}
{/*              </div>*/}
{/*              <InfoBlock>*/}
{/*                <InfoText>{intl.msg("organization_home_page_helptext_1")}</InfoText>*/}
{/*                <InfoText validate="optional">{intl.msg("helptext_optional")}</InfoText>*/}
{/*                <InfoText validate="valid">{intl.msg("organization_home_page_helptext_valid")}</InfoText>*/}
{/*              </InfoBlock>*/}
{/*            </div>*/}
{/*          </div>*/}
{/*      }*/}

{/*    </>*/}
{/*}*/}
