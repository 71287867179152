import React, {useState, useEffect, useReducer} from 'react'
import { useParams}  from 'react-router-dom'
import { default as Select } from "react-select"
import {useSolv} from "./components/SolvProvider"
import {useIntlEx} from "./components/IntlUtils"
import {FormHeader, FormBody, MainContainer, useAlert} from "./components/FormComps.js"
import {validateField, clearAllErrors, InfoBlock, InfoText} from './components/ValidationUtils.js';
import MainMenu from "./MainMenu"
import {useTenant} from "./components/SolvProvider";
import {AccountTabs} from "./components/AccountUtils";
import DatePicker from "react-datepicker";
import {mkHref} from "./components/AccountUtils";
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("ReferralCode")

export default function ReferralCode(props) {

  const {api, session: {user}, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useAlert()

  const [referralCode, setReferralCode] = useState(null)
  const [modifyReferralCode, setModifyReferralCode] = useState(null)
  const [isAdding, setAdding] = useState(null)

  const [touched, setTouched] = useState(false)

  const [description, setDescription] = useState(null)
  const [discount, setDiscount] = useState(null)
  const [startsOn, setStartsOn] = useState(null)
  const [expiresOn, setExpiresOn] = useState(null)
  const [currencyCode, setCurrencyCode] = useState(null)
  const [status, setStatus] = useState(null)

  const statusOpts = [
    {
      value: "ACTIVE",
      label: intl.msg("status_active")
    },
    {
      value: "INACTIVE",
      label: intl.msg("status_inactive")
    },
    {
      value: "EXPIRED",
      label: intl.msg("status_expired")
    },
  ]

  function getStatusOpts() {
    return (statusOpts)
  }

  function getStatusOpt(status) {
    return (statusOpts.find(i => i.value === status))
  }

  useEffect(() => {
    if (params.referralCode) {
      if (params.referralCode.trim().toLowerCase() === "_new") {
        setReferralCode(null)
        setModifyReferralCode(null)
        setDiscount(null)
        setStatus(getStatusOpt("ACTIVE"))
        setAdding(true)
      }
      else {
        setReferralCode(params.referralCode)
        setDiscount(null)
        setAdding(false)
      }
    }
  }, [])

  useEffect(() => {

    if (tenant && referralCode) {
      log.debug("useEffect: tenant=, referralCode", tenant, referralCode)
      setBusy(intl.msg("loading"))
      api.getReferralCode(tenant.tenantId, referralCode)
        .then(({data}) => {
          log.debug("getReferralCode: data=", data)
          setModifyReferralCode(data.referralCode)
          setDescription(data.description)
          setStartsOn(data.startsOn ? new Date(data.startsOn) : null)
          setExpiresOn(data.expiresOn ? new Date(data.expiresOn) : null)
          setDiscount(data.referralActions?.discount ? data.referralActions?.discount * 100 : null)
          setStatus(getStatusOpt(data.status))
        })
        .catch((error) => {
          setFatal(error)
        })
        .finally(() => {
          setBusy(null)
        })
    }

  }, [tenant, referralCode]);

  // function handleFullNameChange(e) {
  //   setFullName(e.target.value)
  //   setTouched(true)
  // }

  function handleReferralCodeChange(e) {
    const v = e.target?.value
    setModifyReferralCode(v)
    setTouched(true)
  }

  function handleDescriptionChange(e) {
    const v = e.target?.value
    setDescription(v)
    setTouched(true)
  }

  function handleStartsOnChange(v) {
    setStartsOn(v)
    setTouched(true)
  }

  function handleExpiresOnChange(v) {
    setExpiresOn(v)
    setTouched(true)
  }

  function handleStatusChange(v) {
    setStatus(v)
    setTouched(true)
  }

  function handleDiscountChange(e) {
    const v = e.target?.value
    setDiscount(v)
    setTouched(true)
  }

  // function handleTtdTypeChange(v) {

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_referralCode", modifyReferralCode && modifyReferralCode.trim().length > 0 , "required", opt)
    validateField("inp_description", description && description.trim().length > 0, "required", opt)

    log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid

  }

  async function handleSaveClick() {

    log.debug("handleSaveClick: invoked" )

    if (validate()) {

      setBusy(intl.msg("saving"))

      try {

        let referralActions = null
        if (discount) {
          referralActions = {discount: discount}
        }

        const payload = {
          referralCode: modifyReferralCode,
          referrerId: tenant.tenantId,
          description: description,
          referralActions: referralActions,
          startsOn: (startsOn ? startsOn.getTime() : null),
          expiresOn: (expiresOn ? expiresOn.getTime() : null),
          status: (status ? status.value : null),
        }

        if (isAdding) {
          const {data} = await api.insertReferralCode(tenant.tenantId, payload)
        }
        else {
          const {data} = await api.updateReferralCode(tenant.tenantId, referralCode, payload)
        }

        window.location = mkHref({suffix: `/refcodes`, tenant: tenant})

      }
      catch (error) {
        handleError(error)
      }
      finally {
        setBusy(null)
      }
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }

  }

  function handleCancelClick() {
    window.location.reload()
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
      if (code === "DUPLICATE_KEY") {
        setAlert({error: intl.msg("refcode_error_duplicate"), field: "inp_referralCode", constraint: "unique"})
      }
      else {
        setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function isEditable() {
    return (status && ["ACTIVE", "INACTIVE"].includes(status.value))
  }

  return (
    user &&
    <>
      <MainContainer menu={MainMenu}>

        <FormHeader>
          <AccountTabs tenant={tenant}/>
          <FormHeader.Toolbar>
            <FormHeader.Toolbar.Title>
              {intl.msg("refcode")} {" - " + (isAdding ? "(new)" : referralCode)}
            </FormHeader.Toolbar.Title>
            <FormHeader.Toolbar.Controls>
              {
                touched &&
                <>
                  <button key="key_btn_save" className="btn btn-primary" onClick={handleSaveClick}>
                    <i className="fas fa-check"></i>{intl.msg("save")}
                  </button>
                  <button key="key_btn_reset" className="btn btn-secondary" onClick={handleCancelClick}>
                    <i className="fas fa-times"></i>{intl.msg("cancel")}
                  </button>
                </>
              }
            </FormHeader.Toolbar.Controls>
          </FormHeader.Toolbar>
          <FormHeader.Alert alert={alert}/>
        </FormHeader>

        <FormBody>

          <div className="row no-gutters">

            <div className="col">

              <form id="frmMain" autoComplete="off">

                <div className="form-card">

                  <div className="row">

                    <div className="col-9">

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label" htmlFor="inp_referralCode">{intl.msg("refcode")}:</label>
                        <div className="col-sm-9">
                          <input id="inp_referralCode" type="text" className="form-control" value={modifyReferralCode} disabled={!isEditable()} onChange={handleReferralCodeChange}/>
                          <InfoBlock>
                            <InfoText>{intl.msg("refcode_helptext_1")}</InfoText>
                            <InfoText validate="unique">{intl.msg("refcode_helptext_unique")}</InfoText>
                            <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                          </InfoBlock>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label" htmlFor="inp_description">{intl.msg("description")}:</label>
                        <div className="col-sm-9">
                          <input id="inp_description" type="text" className="form-control" value={description} disabled={!isEditable()} onChange={handleDescriptionChange}/>
                          <InfoBlock>
                            <InfoText>{intl.msg("refcode_description_helptext_1")}</InfoText>
                            <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                          </InfoBlock>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label" htmlFor="inp_startsOn">{intl.msg("starts_on")}:</label>
                        <div className="col-sm-9">
                          <div style={{width: "200px"}}>
                            <DatePicker
                              id="inp_startsOn"
                              selected={startsOn}
                              onChange={handleStartsOnChange}
                              dateFormat="yyyy-MM-dd"
                              disabled={!isEditable()}
                            />
                          </div>
                          <InfoBlock>
                            <InfoText>{intl.msg("refcode_starts_on_helptext_1")}</InfoText>
                            <InfoText>{intl.msg("helptext_optional")}</InfoText>
                          </InfoBlock>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label" htmlFor="inp_expiresOn">{intl.msg("expires_on")}:</label>
                        <div className="col-sm-9">
                          <div style={{width: "200px"}}>
                            <DatePicker
                              id="inp_expiresOn"
                              selected={expiresOn}
                              onChange={handleExpiresOnChange}
                              dateFormat="yyyy-MM-dd"
                              disabled={!isEditable()}
                            />
                          </div>
                          <InfoBlock>
                            <InfoText>{intl.msg("refcode_expires_on_helptext_1")}</InfoText>
                            <InfoText>{intl.msg("helptext_optional")}</InfoText>
                          </InfoBlock>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label" htmlFor="inp_status">{intl.msg("status")}:</label>
                        <div className="col-sm-9">
                          <div style={{width: "200px"}}>
                            <Select
                              id="inp_status"
                              className="react-select"
                              classNamePrefix="react-select"
                              value={status}
                              options={getStatusOpts(intl)}
                              onChange={handleStatusChange}
                              isDisabled={!isEditable()}/>
                          </div>
                          <InfoBlock>
                            <InfoText>{intl.msg("refcode_status_helptext_1")}</InfoText>
                            <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                          </InfoBlock>
                        </div>
                      </div>

                    </div>

                  </div>

                </div>

              </form>

            </div>

          </div>
        </FormBody>
      </MainContainer>

    </>

  )
}
