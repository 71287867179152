import React, { Fragment, useState, useEffect }  from 'react'
import { useParams, useHistory, useLocation }  from 'react-router-dom'
import { useQueryClient } from 'react-query'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { useIntlEx } from "./components/IntlUtils.js"
import getLogger from "./components/Logging.js"
import './App.css'
import {ModalDialog} from "./components/DialogUtils";
import {useSolv} from "./components/SolvProvider";
import {TagLabel} from "./components/TagBox";
import {UserLink} from "./components/UserUtils";

const log = getLogger("TemplateDetailsDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function TemplateDetailsDialog(props)  {

  const {api, session, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const [tenant, setTenant] = useState(null)

  const [broadcastTemplateId, setBroadcastTemplateId] = useState(null)
  const [broadcastTemplateName, setBroadcastTemplateName] = useState(null)
  const [isDefault, setDefault] = useState(null)
  const [isProvided, setProvided] = useState(null)
  const [status, setStatus] = useState(null)
  const [createdOn, setCreatedOn] = useState(null)
  const [createdBy, setCreatedBy] = useState(null)
  const [modifiedOn, setModifiedOn] = useState(null)
  const [modifiedBy, setModifiedBy] = useState(null)
  const [usedBy,setUsedBy] = useState(null)

  const params = useParams()

  const [alert, setAlert] = useState(null)

  useEffect(() => {
    if (props) {
      if (props.tenant) {
        setTenant(props.tenant)
        log.debug("useEffect: tenant=", tenant)
      }
      if (props.broadcastTemplateId) {
        setBroadcastTemplateId(props.broadcastTemplateId)
      }
    }
  }, [props])

  function handleOpenDialog() {
    if (!broadcastTemplateId) {
      return
    }
    setBusy(intl.msg("loading"))
    log.debug("TemplateDetailsDialog: loading...", broadcastTemplateId)
    api.getBroadcastTemplateDetails(tenant.tenantId, broadcastTemplateId)
      .then(({data}) => {
          log.debug("TemplateDetailsDialog: data=", data)
          setBroadcastTemplateName(data.broadcastTemplateName)
          setStatus(data.status)
          setDefault(data.default)
          setProvided(data.provided)
          setCreatedOn(data.createdOn)
          setCreatedBy(data.createdBy)
          setModifiedOn(data.modifiedOn)
          setModifiedBy(data.modifiedBy)
          setUsedBy(data.usedBy)
        })
        .catch((error) => {
          setAlert({error: "broadcast_error_load_failed"})
        })
        .finally(function() {
          setBusy(null)
        })
  }

  function handleCloseDialog() {
    setAlert(null)
    setBusy(null)
  }


  return (

      <ModalDialog
          dialogId="dlg_template_details"
          header={<h1>{intl.msg("template_details_title")}</h1>}
          size="lg"
          keyboard={"true"}
          onOpen={handleOpenDialog}
          onClose={handleCloseDialog}
      >
        <div className="row">
          <div className="col-3 talign-right">
            <label style={{color: "#bbb"}}>{intl.msg("template_name")}:</label>
          </div>
          <div className="col-9">
            {broadcastTemplateName}
            <div className="ml-2 d-inline-block">
              <div className="d-flex flex-row" style={{gap: "4px"}}>
                {
                  isDefault &&
                    <TagLabel label={intl.msg("template_label_default")} tooltip={intl.msg("template_label_default_tooltip")}/>
                }
                {
                  isProvided &&
                    <TagLabel label={intl.msg("template_label_provided")} tooltip={intl.msg("template_label_provided_tooltip")}/>
                }
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-3 talign-right">
            <label style={{color: "#bbb"}}>{intl.msg("created")}:</label>
          </div>
          <div className="col-9">
            <span>
              on: {intl.dateTime(createdOn)}
            </span>
            <span className="ml-2">
              by: <UserLink session={session} tenant={tenant} user={createdBy}/>
            </span>
          </div>
        </div>
        {
          modifiedOn &&
            <div className="row mt-3">
              <div className="col-3 talign-right">
                <label style={{color: "#bbb"}}>{intl.msg("modified")}:</label>
              </div>
              <div className="col-9">
                <span>
                  on: {intl.dateTime(modifiedOn)}
                </span>
                <span className="ml-2">
                  by: <UserLink session={session} tenant={tenant} user={modifiedBy}/>
                </span>
              </div>
            </div>
        }
        {
          session?.user && session.user.isSystem() && usedBy &&
            <div className="row mt-3">
              <div className="col-3 talign-right">
                <label style={{color: "#bbb"}}>{intl.msg("also_used_in")}:</label>
              </div>
              <div className="col-9">
                {
                  usedBy.map((u, i) => {
                    return (
                      <>
                        {i > 0 ? ", " : ""}
                        <a className="text-link" href={`/${u.tenantId}/`}>{u.tenantName}</a>
                      </>
                    )
                  })
                }
              </div>
            </div>
        }
      </ModalDialog>
  )
}