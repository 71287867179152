import React, {useState, useEffect, useReducer, useRef} from 'react'
import { useParams}  from 'react-router-dom'
import { default as Select } from "react-select"
import {useSolv, useTenant} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils"
import {FormHeader, FormBody, MainContainer, useAlert, FormGroup, ActionButton, FormGroups, Icon} from "../components/FormComps.js"
import {validateField, showError, clearAllErrors, InfoBlock, InfoText, clearError} from '../components/ValidationUtils.js';
import MainMenu from "../MainMenu"
import {AsyncPaginate} from "react-select-async-paginate";
import {simpleReducer} from "../components/ReactUtils";
import ToggleButton from "../components/ToggleButton";
import {useAccountTypesOpts} from "../components/AccountUtils";
import {MarkdownTextArea} from "../components/MarkdownTextArea";
import {ImageDropBox, processImageFile, uploadImage} from "../components/ImageUtils";
import ImageDialog from "../components/ImageDialog";
import getLogger from "../components/Logging.js"
import "../App.css"
import styles from "./Version.module.css"
import TagInput from "../components/TagInput";
import TagInput2 from "../components/TagInput2";
import {concatUrlComponents} from "../components/UrlUtils";
import CreatableSelect from "react-select/creatable";

const log = getLogger("Version")

export default function Version(props) {

  const {api, env, session: {user}, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useAlert()

  const [isAdding, setAdding] = useState(null)

  const [versionId, setVersionId] = useState(params.versionId ? params.versionId : null)
  const [versionCode, setVersionCode] = useState(null)
  const [summary, setSummary] = useState(null)
  const [releaseNotes, setReleaseNotes] = useState([])
  const [status, setStatus] = useState(null)

  const [touched, setTouched] = useState(false)

  const {accountTypeOpts, findAccountTypeOpt} = useAccountTypesOpts()

  const [releaseNoteFeaturedImageInfo, setReleaseNoteFeaturedImageInfo] = useState(null)

  const releaseNoteFeaturedImagePickerDialogRef = useRef(null)

  const targetLevelOpts = [
    {
      value: "CLIENT",
      label: "Client",
    },
    {
      value: "RESELLER",
      label: "Reseller",
    },
    {
      value: "SYSTEM",
      label: "System",
    }
  ]

  function findTargetLevelOpt(value, defaultValue) {
    return targetLevelOpts.find(o => o.value === value) ?? defaultValue
  }

  const changeTypeOpts = [
    {
      value: "NEW",
      label: "New Feature",
    },
    {
      value: "IMPROVED",
      label: "Improvement",
    },
    {
      value: "FIXED",
      label: "Bug Fix",
    }
  ]

  function findChangeTypeOpt(value, defaultValue) {
    return changeTypeOpts.find(o => o.value === value) ?? defaultValue
  }

  const categoryOpts = [
    {
      value: "ADMIN",
      label: "Administration",
    },
    {
      value: "BROADCAST",
      label: "Broadcast",
    },
    {
      value: "CLIENT",
      label: "Client",
    },
    {
      value: "CORE",
      label: "Core",
    },
    {
      value: "PLATFORM",
      label: "Platform",
    },
    {
      value: "RESELLER",
      label: "Reseller",
    },
    {
      value: "CREDITS",
      label: "Credits",
    },
    {
      value: "PAYMENT",
      label: "Payment",
    },
    {
      value: "MEMBER",
      label: "Member",
    },
    {
      value: "UI",
      label: "UI",
    }
  ]

  function findCategoryOpt(value) {
    return categoryOpts.find(o => o.value === value) ?? {value: value, label: value}
  }

  useEffect(() => {
    if (params.versionId) {
      if (params.versionId.trim().toLowerCase() === "_new") {
        setReleaseNotes([
          {
            title: "",
            // userTypes: [findAccountTypeOpt("CLIENT")],
            changeType: "NEW",
            targetLevel: "CLIENT",
            categories: [],
            featuredImage: "",
            text: "",
          }
        ])
        setAdding(true)
      }
      else {
        setBusy(intl.msg("working"))
        api.getVersion(params.versionId)
          .then(({data: b}) => {
            log.debug(">>>Z: getVersionById: data=", b)
            setVersionCode(b.versionCode)
            setSummary(b.summary || "")
            setReleaseNotes(b.releaseNotes?.map((rn, i) => {
              return (
                {
                  ...rn,
                  // userTypes: rn.userTypes?.map(t => findAccountTypeOpt(t) || [findAccountTypeOpt("CLIENT")]),
                  changeType: findChangeTypeOpt(rn.changeType, "NEW"),
                  targetLevel: findTargetLevelOpt(rn.targetLevel, "SYSTEM"),
                  categories: rn.categories?.map(t => {return ({label: t, value: t})}),
                  // module: findCategoryOpt(rn.module, "CORE"),
                })
            }))
            setStatus(b.status)
          })
          .catch((err) => {
            setFatal(err)
          })
          .finally(() => {
            setBusy(null)
          })
      }
    }
  }, [])

  function handleVersionCodeChange(e) {
    const v = e.target?.value
    setVersionCode(v)
    setTouched(true)
  }

  function handleSummaryChange(e) {
    const v = e.target?.value
    setSummary(v)
    setTouched(true)
  }

  function handleReleaseNoteChangeTypeChange(v, index) {
    setReleaseNotes(prev => {
        return prev.map((item, i) =>
          i === index ? {...item, changeType: v} : item
        )
      }
    )
    setTouched(true)
  }

  function handleReleaseNoteTargetLevelChange(v, index) {
    setReleaseNotes(prev => {
        return prev.map((item, i) =>
          i === index ? {...item, targetLevel: v} : item
        )
      }
    )
    setTouched(true)
  }

  // function handleReleaseNoteUserTypesChange(v, index) {
  //   setReleaseNotes(prev => {
  //       return prev.map((item, i) =>
  //         i === index ? {...item, userTypes: v} : item
  //       )
  //     }
  //   )
  //   setTouched(true)
  // }
  //
  function handleReleaseNoteCategoriesChange(v, index) {
    setReleaseNotes(prev => {
        return prev.map((item, i) =>
          i === index ? {...item, categories: v} : item
        )
      }
    )
    setTouched(true)
  }

  async function handleReleaseNoteFeaturedImageChange(e, index) {
    const v = e.target.value
    setReleaseNoteFeaturedImage(v, index)
    setTouched(true)
  }

  function setReleaseNoteFeaturedImage(v, index) {
    setReleaseNotes(prev => {
        return prev.map((item, i) =>
          i === index ? {...item, featuredImage: v} : item
        )
      }
    )
  }

  function handleReleaseNoteFeaturedImageClear(index) {
    setReleaseNotes(prev => {
        return prev.map((item, i) =>
          i === index ? {...item, featuredImage: null} : item
        )
      }
    )
    setTouched(true)
  }

  function handleReleaseNoteTitleChange(e, index) {
    const v = e.target?.value
    const slug = genTitleSlug(v)
    setReleaseNotes(prev => {
        return prev.map((item, i) =>
          i === index ? {...item, title: v, slug: slug} : item
        )
      }
    )
    setTouched(true)
  }

  function handleReleaseNoteSlugChange(e, index) {
    const v = e.target?.value
    setReleaseNotes(prev => {
        return prev.map((item, i) =>
          i === index ? {...item, slug: v} : item
        )
      }
    )
    setTouched(true)
  }

  function handleReleaseNoteTextChange(e, index) {
    const v = e.target?.value
    setReleaseNotes(prev => {
        return prev.map((item, i) =>
          i === index ? {...item, text: v} : item
        )
      }
    )
    setTouched(true)
  }

  function handleReleaseNoteAdd(e) {
    setReleaseNotes(prev => {
      if (prev?.length > 0) {
        return ([...prev, {userTypes: [findAccountTypeOpt("CLIENT")], title: "", text: "", featuredImage: ""}])
      }
      else {
        return ([{userTypes: [findAccountTypeOpt("CLIENT")], title: "", text: "", featuredImage: ""}])
      }
    })
    setTouched(true)
  }

  function handleReleaseNoteDelete(index) {
    setReleaseNotes(prev => {
      return prev.filter((_, i) => i !== index)
    })
    setTouched(true)
  }

  function handleReleaseNoteMoveUp(index) {
    if (index <= 0) return;
    setReleaseNotes((prevItems) => {
      const newItems = [...prevItems];
      [newItems[index], newItems[index - 1]] = [newItems[index - 1], newItems[index]]; // Swap
      return newItems;
    });
    setTouched(true)
  }

  function handleReleaseNoteMoveDown(index) {
    if (index >= releaseNotes?.length - 1) return; // Can't move the last item down
    setReleaseNotes((prevItems) => {
      const newItems = [...prevItems];
      [newItems[index], newItems[index + 1]] = [newItems[index + 1], newItems[index]]; // Swap
      return newItems;
    });
    setTouched(true)
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_versionCode", versionCode && versionCode.trim().length > 0 , "required", opt)

    for (let i = 0; i < releaseNotes.length; i++)

    // validateField("inp_bankAccountName", version?.bankAccountName && version.bankAccountName.trim().length > 0 , "required", opt)

    log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid

  }

  async function handleSaveClick() {

    log.debug("handleSaveClick: invoked" )

    if (validate()) {

      setBusy(intl.msg("saving"))

      try {

        let payload = {
          versionCode: versionCode,
          summary: summary,
          releaseNotes: releaseNotes.map((rn, i) => {
            // log.debug(">>>Z: rn.userTypes=", rn.userTypes)
            return (
              {
                ...rn,
                targetLevel: rn.targetLevel?.value ?? "SYSTEM",
                changeType: rn.changeType?.value ?? "NEW",
                // userTypes: rn.userTypes?.map(t => t.value) || [findAccountTypeOpt("CLIENT")],
                categories: rn.categories?.map(t => t.value) || [],
              })
          }),
        }

        if (isAdding) {
          const {data} = await api.insertVersion(payload)
        }
        else {
          const {data} = await api.updateVersion(params.versionId, payload)
        }

        window.location.reload()

      }
      catch (error) {
        handleError(error)
      }
      finally {
        setBusy(null)
      }
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }

  }

  async function handleReleaseClick() {

    log.debug("handleReleaseClick: invoked: versionId=", versionId)

    if (validate()) {

      setBusy(intl.msg("saving"))

      try {

        const {data} = await api.releaseVersion(versionId)

        window.location = `/sys/versions`

      }
      catch (error) {
        handleError(error)
      }
      finally {
        setBusy(null)
      }
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }

  }

  function handleCancelClick() {
    // window.location = `/sys/bankaccounts`
    window.location.reload()
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
      if (code === "DUPLICATE_KEY") {
        setAlert({error: intl.msg("country_error_duplicate"), field: "inp_regionId", constraint: "unique"})
      }
      else {
        setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function canEdit() {
    return user.isSystem() && user.isSuperAdminOrAbove()
  }

  function canRelease() {
    return user.isSystem() && user.isSuperAdminOrAbove() && ("DRAFT" === status)
  }

  function canRerelease() {
    return user.isSystem() && user.isSuperAdminOrAbove() && ("RELEASED" === status)
  }

  function genTitleSlug(title) {
    return title.toString().toLowerCase().trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-z0-9\s-]/g, "").replace(/\s+/g, "-").replace(/-+/g, "-")
  }

  return (
    tenant &&
      <>
        <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={"SUPERADMIN/*/SYSTEM"}>

          <FormHeader>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("version")} {" - " + (isAdding ? "(new)" : versionCode)}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                {
                  touched ? (
                    <>
                      <ActionButton name="save" flavor="primary" disabled={!touched} onClick={handleSaveClick}/>
                      <ActionButton name="cancel" onClick={handleCancelClick}/>
                    </>
                  ) : canRelease() ? (
                    <ActionButton name="release" flavor="primary" onClick={handleReleaseClick}/>
                  ) : canRerelease() ? (
                    <ActionButton msg="rerelease" icon="release" flavor="primary" onClick={handleReleaseClick}/>
                  ) : (
                    <></>
                  )
                }
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>

          <FormBody>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_versionCode" text={intl.msg("version_code")}/>
              <FormGroup.Controls>
                <input id="inp_versionCode" type="text" className="form-control" value={versionCode} disabled={!canEdit()} onChange={handleVersionCodeChange}/>
                <InfoBlock>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_summary" text={intl.msg("version_summary")}/>
              <FormGroup.Controls>
                <input id="inp_summary" type="text" className="form-control" value={summary} disabled={!canEdit()} onChange={handleSummaryChange}/>
                <InfoBlock>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            {/*<FormGroups text={intl.msg("version_release_notes")}>*/}

              {
                releaseNotes && releaseNotes.length > 0 &&
                  releaseNotes?.map((e, i) =>
                    <FormGroups>

                      <FormGroups.Header>
                        <FormGroups.Header.Title text={intl.msg("version_release_note")}/>
                        <FormGroups.Header.Controls>
                          <ActionButton className="p-0" icon="delete" flavor="tertiary" onClick={(e) => handleReleaseNoteDelete(i)}/>
                          <ActionButton className="p-0" icon="move_up" flavor="tertiary" disabled={releaseNotes?.length === 0 || i === 0} onClick={(e) => handleReleaseNoteMoveUp(i)}/>
                          <ActionButton className="p-0" icon="move_down" flavor="tertiary" disabled={releaseNotes?.length === 0 || i >= releaseNotes?.length - 1} onClick={(e) => handleReleaseNoteMoveDown(i)}/>
                        </FormGroups.Header.Controls>
                      </FormGroups.Header>

                      <FormGroup key={`inp_releaseNoteTitle_${i}`}>
                        <FormGroup.Label htmlFor={`inp_releaseNoteTitle_${i}`} text={intl.msg("version_release_note_title")}/>
                        <FormGroup.Controls>
                          <input id={`inp_releaseNoteTitle_${i}`} key={`inp_releaseNoteTitle_${i}`} className="form-control" type="text" value={e.title} disabled={!canEdit()} onChange={(e) => handleReleaseNoteTitleChange(e, i)}/>
                          <InfoBlock>
                            <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                          </InfoBlock>
                        </FormGroup.Controls>
                      </FormGroup>

                      <FormGroup key={`inp_releaseNoteSlug_${i}`}>
                        <FormGroup.Label htmlFor={`inp_releaseNoteSlug_${i}`} text={intl.msg("version_release_note_slug")}/>
                        <FormGroup.Controls>
                          <input id={`inp_releaseNoteSlug_${i}`} key={`inp_releaseNoteSlug_${i}`} className="form-control" type="text" value={e.slug} disabled={!canEdit()} onChange={(e) => handleReleaseNoteSlugChange(e, i)}/>
                          <InfoBlock>
                            <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                          </InfoBlock>
                        </FormGroup.Controls>
                      </FormGroup>

                      <FormGroup>
                        <FormGroup.Label htmlFor={`inp_releaseNoteChangeType_${i}`} text={intl.msg("version_release_note_change_type")}/>
                        <FormGroup.Control>
                          <Select
                            id={`inp_releaseNoteChangeType_${i}`}
                            key={`inp_releaseNoteChangeType_${i}`}
                            className="react-select"
                            classNamePrefix="react-select"
                            value={e.changeType}
                            isClearable={false}
                            options={changeTypeOpts}
                            onChange={(e) => handleReleaseNoteChangeTypeChange(e, i)}/>
                          <InfoBlock>
                            <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                          </InfoBlock>
                        </FormGroup.Control>
                      </FormGroup>

                      <FormGroup>
                        <FormGroup.Label htmlFor={`inp_releaseNoteTargetLevel_${i}`} text={intl.msg("version_release_note_target_level")}/>
                        <FormGroup.Control>
                          <Select
                            id={`inp_releaseNoteTargetLevel_${i}`}
                            key={`inp_releaseNoteTargetLevel_${i}`}
                            className="react-select"
                            classNamePrefix="react-select"
                            value={e.targetLevel}
                            options={targetLevelOpts}
                            onChange={(e) => handleReleaseNoteTargetLevelChange(e, i)}/>
                          <InfoBlock>
                            <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                          </InfoBlock>
                        </FormGroup.Control>
                      </FormGroup>

                      {/*<FormGroup>*/}
                      {/*  <FormGroup.Label htmlFor={`inp_releaseNoteUserTypes_${i}`} text={intl.msg("version_release_note_user_types")}/>*/}
                      {/*  <FormGroup.Control>*/}
                      {/*    <Select*/}
                      {/*      id={`inp_releaseNoteUserTypes_${i}`}*/}
                      {/*      key={`inp_releaseNoteUserTypes_${i}`}*/}
                      {/*      className="react-select"*/}
                      {/*      classNamePrefix="react-select"*/}
                      {/*      value={e.userTypes}*/}
                      {/*      isMulti*/}
                      {/*      isClearable={false}*/}
                      {/*      options={accountTypeOpts}*/}
                      {/*      onChange={(e) => handleReleaseNoteUserTypesChange(e, i)}/>*/}
                      {/*    <InfoBlock>*/}
                      {/*      <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>*/}
                      {/*    </InfoBlock>*/}
                      {/*  </FormGroup.Control>*/}
                      {/*</FormGroup>*/}

                      {/*<FormGroup>*/}
                      {/*  <FormGroup.Label htmlFor={`inp_releaseNoteModule_${i}`} text={intl.msg("version_release_note_module")}/>*/}
                      {/*  <FormGroup.Control>*/}
                      {/*    <CreatableSelect*/}
                      {/*      id={`inp_releaseNoteModule_${i}`}*/}
                      {/*      key={`inp_releaseNoteModule_${i}`}*/}
                      {/*      className="react-select"*/}
                      {/*      classNamePrefix="react-select"*/}
                      {/*      value={e.module}*/}
                      {/*      isClearable={true}*/}
                      {/*      options={categoryOpts}*/}
                      {/*      onChange={(e) => handleReleaseNoteModuleChange(e, i)}/>*/}
                      {/*    <InfoBlock>*/}
                      {/*      <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>*/}
                      {/*    </InfoBlock>*/}
                      {/*  </FormGroup.Control>*/}
                      {/*</FormGroup>*/}

                      <FormGroup>
                        <FormGroup.Label htmlFor={`inp_releaseNoteCategories_${i}`} text={intl.msg("version_release_note_categories")}/>
                        <FormGroup.Control>
                          <TagInput
                            id={`inp_releaseNoteCategories_${i}`}
                            className="react-select w-100"
                            classNamePrefix="react-select"
                            value={e.categories}
                            breakKeys={["Enter", " ", ","]}
                            lowerCase={true}
                            onChange={(e) => handleReleaseNoteCategoriesChange(e, i)}/>
                          <InfoBlock>
                            <InfoText validate="optional">{intl.msg("helptext_optional")}</InfoText>
                          </InfoBlock>
                        </FormGroup.Control>
                      </FormGroup>

                      <FormGroup key={`inp_releaseNoteText_${i}`}>
                        <FormGroup.Label htmlFor={`inp_releaseNoteText_${i}`} text={intl.msg("version_release_note_text")}/>
                        <FormGroup.Controls>
                          <MarkdownTextArea id={`inp_releaseNoteText_${i}`} className="form-control" value={e.text} disabled={!canEdit()} onChange={(e) => handleReleaseNoteTextChange(e, i)}/>
                          <InfoBlock>
                            <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                          </InfoBlock>
                        </FormGroup.Controls>
                      </FormGroup>

                      <FormGroup>
                        <FormGroup.Label htmlFor={`inp_releaseNoteFeaturedImage_${i}`}  text={intl.msg("version_release_note_featured_image")}/>
                        <FormGroup.Controls>
                          <div className={styles["featured-image-container"]}>
                            <div className={styles["input-container"]}>
                              <input id={`inp_releaseNoteFeaturedImage_${i}`} key={`inp_releaseNoteFeaturedImage_${i}`} className="form-control" type="text" value={e.featuredImage} disabled={!canEdit()} onChange={(e) => handleReleaseNoteFeaturedImageChange(e, i)}/>
                            </div>
                            <div className={styles["image-container"]} style={{border: "1px solid var(--form-control-border-color)"}}>
                              <VersionFeaturedImage versionCode={versionCode} featuredImage={e.featuredImage}/>
                            </div>
                          </div>
                          <InfoBlock>
                            <InfoText validate="optional">{intl.msg("helptext_optional")}</InfoText>
                          </InfoBlock>
                        </FormGroup.Controls>
                      </FormGroup>

                    </FormGroups>
                  )
              }
              <FormGroup className="pt-3">
                <FormGroup.Controls>
                  <ActionButton icon="add_new" text={intl.msg("version_release_note_add")} onClick={handleReleaseNoteAdd}/>
                </FormGroup.Controls>
              </FormGroup>

            {/*</FormGroups>*/}

          </FormBody>
        </MainContainer>

      </>

  )
}

export function VersionFeaturedImage(props) {
  const {env} = useSolv()
  const {intl} = useIntlEx()
  return (
    props.featuredImage &&
      <img src={concatUrlComponents(env.WHATSNEW_RESOURCES_ENDPOINT, props.versionCode ?? "", props.featuredImage)} width={500} height={"auto"}/>
  )
}
