import React, {Fragment, useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {useSolv} from "../components/SolvProvider"
import MainMenu from "../MainMenu.js"
import {FormHeader, FormBody, MainContainer, useAlert, ActionButton} from "../components/FormComps.js"
import {useIntlEx} from "../components/IntlUtils"
import {DataTable, useCols} from "../components/TableComps"
import ExportToFileDialog from "../components/ExportToFileDialog";
import getLogger from "../components/Logging.js"
import '../App.css'

const log = getLogger("BankAccounts")

export default function BankAccounts() {

  const {api, session: {user, tenant}, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const [alert, setAlert] = useAlert(null)

  const {cols} = useCols({
    resourceName: "bankAccounts",
    specs: [
      {
        field: "bankAccountNo",
        label: intl.msg("bank_account_no"),
        filter: "text",
        sort: "ASC",
        align: "left",
      },
      {
        field: "bankAccountName",
        label: intl.msg("bank_account_name"),
        filter: "text",
        sort: "ASC",
        align: "left",
      },
      {
        field: "regionId",
        label: intl.msg("region"),
        filter: {
          type: "asyncselect",
          loader: (input, loadedOptions, additional) => {
            log.debug("listRegions: invoked")
            return api.listRegions({view: "BASIC", extraParams: `${input ? `filter=regionName:${encodeURIComponent(input)}&` : ""}sort=regionName:ASC`, cursor: additional?.cursor, limit: 20})
              .then(({data, nextCursor}) => {
                let opt = data.map(c => {
                  return {
                    value: c.regionId,
                    label: c.regionName,
                    pop: c.pop
                  };
                });
                let res = {
                  options: opt,
                }
                if (nextCursor) {
                  res = {
                    ...res,
                    hasMore: true,
                    additional: {
                      cursor: nextCursor
                    }
                  }
                }
                log.debug("loadRegions: res=", res)
                return res
              });
          }
        },
        sort: "",
        align: "left",
      },
    ],
  })

  function handleFetchDataPage({pageParam: cursor}) {
    return api.listBankAccounts({extraParams: cols.stringify(), cursor: cursor})
  }

  function handleRenderDataCol(col, row) {
    let n
    switch (col.field) {
      case "bankAccountNo":
        n = row.bankAccountNo
        break
      case "bankAccountName":
        n = row.bankAccountName
        break
      case "regionId":
        n = <a className="text-link" href={`/sys/regions?filter=regionName:=${row.regionName}`}>{row.regionName}</a>
        break
    }
    return (
        n
    )
  }

  function handleRowClick(row) {
    window.location = `/sys/bankaccounts/${row.bankAccountId}`
  }

  function handleError(error) {
    if (error) {
      log.debug("handleError: error=", JSON.stringify(error))
      if (error.code) {
        switch (error.code) {
          case "BAD_PAYLOAD":
            if (error.msg) {
              setAlert({error: error.msg})
            }
            else {
              setAlert({error: intl.msg("error_failed")})
            }
            break
          case "UNKNOWN_LOCATION":
            log.debug("ERROR: ", error)
            if (error.msg) {
              setAlert({error: error.msg})
            }
            else {
              setAlert({error: intl.msg("error_failed")})
            }
            break
          default:
            setAlert({error: intl.msg("error_failed")})
        }
      }
      else {
        setAlert({error: intl.msg("error_failed")})
      }
    }
  }

  async function handleExport() {
    return await api.exportLocations({extraParams: cols.stringify()})
  }

  return (
    tenant &&
      <>

        <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={"*/*/SYSTEM"}>

          <FormHeader>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("bank_accounts_title")}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                {
                  user.isSystem() && user.isAdminOrAbove() &&
                    <ActionButton name="add_new" flavor="primary"  href={`/sys/bankaccounts/_new`}/>
                }

              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>
          <FormBody>
            <DataTable
              tenant={tenant}
                cols={cols}
                resourceName="bankAccounts"
                dataKey="bankAccountId"
                onFetchDataPage={handleFetchDataPage}
                onRenderDataCol={handleRenderDataCol}
                onClickDataRow={handleRowClick}
            />
          </FormBody>
        </MainContainer>

      </>
  )
}