import React, {Fragment, useState, useEffect, useRef} from 'react'
import {useParams, useHistory, useLocation, Redirect}  from 'react-router-dom'
import {useQueryClient, useMutation} from 'react-query'
import {default as Select} from "react-select";
import {useSolv, useTenant} from "./components/SolvProvider"
import {getAllLangOpts, getLangOpt, useIntlEx} from "./components/IntlUtils.js"
import MainMenu from "./MainMenu.js"
import {FormHeader, FormBody, MainContainer, FormGroup, useAlert, ActionButton, DropdownMenu, DropdownMenuItem} from "./components/FormComps.js"
import {validateField, validateEmailAddress, clearAllErrors, InfoBlock, InfoText, clearError} from './components/ValidationUtils.js';
import {openDialog} from "./components/DialogUtils.js"
import {AccountTabs} from "./components/AccountUtils";
import {useAccessLevelOpts, UserStatus, useThemeOpts} from "./components/UserUtils";
import {usePrefs} from "./components/PrefUtils";
import {ImageDropBox, processImageFile, uploadImage} from "./components/ImageUtils";
import ImageDialog from "./components/ImageDialog";
import MemberDeleteDialog from "./MemberDeleteDialog";
import {shortIdEqual} from "./components/StringUtils";
import {mkHref} from "./components/AccountUtils";
import MemberReinviteDialog from "./MemberReinviteDialog";
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("Member")

export default function Member(props) {

  const {api, session: {user}, refreshSignin, setFatal, setBusy} = useSolv()
  const {intl} = useIntlEx()
  const {savePrefs} = usePrefs()

  const history = useHistory()

  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [userId, setUserId] = useState(params.userId)

  const [editMode, setEditMode] = useState(params.userId === "_new" ? "ADD" : params.userId === "_edit" ? "EDIT" : null)
  const [touched, setTouched] = useState(false)
  const [alert, setAlert] = useAlert()

  const {findAccessLevelOpt, findAvailableAccessLevelOpts} = useAccessLevelOpts()
  const {themeOpts, findThemeOpt} = useThemeOpts()

  const [row, setRow] = useState(null)
  const [fullName, setFullName] = useState(null)
  const [emailAddress, setEmailAddress] = useState(null)
  const [displayName, setDisplayName] = useState(null)
  const [userLevel, setUserLevel] = useState(null)
  const [oldUserLevel, setOldUserLevel] = useState(null)
  const [oldEmailAddress, setOldEmailAddress] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [lang, setLang] = useState(null)
  const [themeOpt, setThemeOpt] = useState(null)
  const [timezone, setTimezone] = useState(null)
  const [idProvider, setIdProvider] = useState(null)
  const [signedInOn, setSignedInOn] = useState(null)
  const [status, setStatus] = useState(null)
  const [createdById, setCreatedById] = useState(null)

  const [accessLevelOpts, setAccessLevelOpts] = useState(null)

  const imagePickerDialogRef = useRef(null)
  const [imageInfo, setImageInfo] = useState(null)

  const queryClient = useQueryClient()

  useEffect(() => {

    // log.debug("useEffect: invoked")

    try {
      if ("ADD" === editMode) {
        if (tenant) {
          setAccessLevelOpts(findAvailableAccessLevelOpts(tenant, user.userLevel))
        }
      } else {
        if (!userId) {
          setUserId(user.userId)
        }
        if (tenant) {
          setBusy(intl.msg("loading"))
          // log.debug(">>>Z: accessLevelOpts=", findAvailableAccessLevelOpts(tenant, user.userLevel))
          setAccessLevelOpts(findAvailableAccessLevelOpts(tenant, user.userLevel))
          loadMember()
            // .then(() => {
            //   api.listMembers({tenantId: tenant.tenantId, view: "BASIC", limit: -1})
            //     .then(({data}) => {
            //       log.debug(">>>Z: listMembers: data=", data)
            //     })
            //     .catch((error) => {
            //       log.debug(">>>Z: listMembers: error: ", error)
            //       setFatal(error)
            //     })
            // })
            .catch((error) => {
              setFatal(error)
            })
            .finally(function () {
              setBusy(null)
            })
        }
      }
    } catch (error) {
      log.debug("FATAL", error)
      setFatal(error)
    } finally {
      setBusy(null)
    }

  }, [tenant]);

  function loadMember() {
    // log.debug("Loading member")
    return api.getUserProfile(tenant.tenantId, userId)
      .then(({data}) => {
        log.debug("User loaded: data=", data, findThemeOpt(data.theme || "system"))
        setRow(data)
        setFullName(data.fullName)
        setDisplayName(data.displayName)
        setEmailAddress(data.emailAddress)
        setOldEmailAddress(data.emailAddress)
        setUserLevel(findAccessLevelOpt(data.userLevel))
        setOldUserLevel(findAccessLevelOpt(data.userLevel))
        setImageUrl(data.imageUrl)
        setLang(getLangOpt(data.lang ? data.lang : "en"))
        if (!data.theme.startsWith("http")) {
          setThemeOpt(findThemeOpt(data.theme || "system"))
        }
        else {
          setThemeOpt({value: data.theme, label: data.theme})
        }
        setIdProvider(data.idProvider)
        setStatus(data.status)
        setCreatedById(data.createdById?.replace(/\-/g, ""))
        return data
      })
      .catch((error) => {
        log.debug("Load User failed: error=", error)
        setFatal(error)
      })
  }

  function handleFullNameChange(e) {
    setFullName(e.target.value)
    setTouched(true)
  }

  function handleEmailAddressChange(e) {
    setEmailAddress(e.target.value)
    setTouched(true)
  }

  function handleUserLevelChange(v) {
    setUserLevel(v)
    setTouched(true)
  }

  function handleLangChange(v) {
    setLang(v)
    setTouched(true)
  }

  function handleThemeOptChange(v) {
    setThemeOpt(v)
    setTouched(true)
  }

  async function handleImageUrlChange(e) {

    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let result

      setBusy(intl.msg("processing"))

      try {
        result = await processImageFile({
          file: file,
          maxFileSize: 100_000,
          width: 100,
          height: 100,
        })
      }
      catch (ex) {
        setAlert({error: "error_image_process_failed"})
      }
      finally {
        setBusy(null)
      }

      if (result.warning || result.error) {
        setImageInfo(result)
        const el = document.getElementById("inp_dlg_logoImagePicker")
        el.click()
      }
      else {
        setImageUrl(result.dataUrl)
        setTouched(true)
      }

      clearError("inp_logoUrl")
    }
  }

  function handleImageUrlClear() {
    setImageUrl(null)
    setTouched(true)
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_emailAddress", emailAddress && validateEmailAddress(emailAddress), "valid", opt)

    if (!isAdding()) {
      validateField("inp_fullName", fullName && fullName.trim().length > 0, "required", opt)
    }

    validateField("inp_accessLevel", userLevel, "required", opt)

    // log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid

  }

  function emailAddressChanged() {
    return (emailAddress.trim().toLowerCase() !== oldEmailAddress.trim().toLowerCase())
  }

  async function handleSaveClick() {

    // log.debug("handleSaveClick: invoked" )

    if (validate()) {

      setBusy(intl.msg("saving"))

      try {

        if (isAdding()) {

          const payload = {
            emailAddress: emailAddress,
            accessLevel: userLevel.value,
            invokedById: tenant.accessingAs("*/MEMBER/*") ? user.userId : tenant.owner.userId
          }

          // log.debug("handleSaveClick: payload=", payload)

          const {data} = await api.inviteMember(tenant.tenantId, payload)

          // log.debug("handleSaveClick: data=", data)

          window.location = mkHref({suffix: `/members`, tenant: tenant, user: user})

        }
        else {

          let finalImageUrl = imageUrl

          if (finalImageUrl && finalImageUrl.startsWith("data:")) {
            finalImageUrl = await uploadImage({
              api: api,
              imageData: finalImageUrl,
              imagePath: `u/${userId}/avatar`,
              setImageUrl: setImageUrl,
            })
          }

          const payload = {
            emailAddress: emailAddress,
            fullName: fullName,
            userLevel: userLevel.value,
            imageUrl: finalImageUrl,
            lang: lang.value,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            theme: themeOpt.value,
          }

          const {data} = await api.updateUserProfile(tenant.tenantId, row.userId, payload)

          if (isMe()) {

            let prefs = {
              lang: null,
              theme: themeOpt.value
            }

            if (lang.value !== "system") {
              prefs.lang = lang.value
            }

            savePrefs(prefs)

            resetEditMode()

            await refreshSignin()

          }

          window.location.reload()
        }

      }
      catch (error) {
        handleError(error)
      }
      finally {
        setBusy(null)
      }
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }

  }

  function handleEditClick() {
    if (canEdit()) {
      setEditMode("EDIT")
      const fc = document.querySelector(".form-control")
      // log.debug(">>>Z: fc=", fc, fc.focus())
      if (fc) {
        fc.disabled = false
        fc.focus()
      }
    }
  }

  function resetEditMode() {
    if (canEdit()) {
      setEditMode(null)
      setTouched(false)
    }
  }

  function handleCancelClick() {
    // resetEditMode()
    // window.location.reload()
    window.location = mkHref({suffix: `/members`, tenant: tenant, user: user})
  }

  function handleError(error) {
    if (error && error.code) {
      // log.debug(">>>Z: handleError", error.code)
      const code = error.code
      const details = error.details
      switch (code) {
        case "DUPLICATE_USER":
        case "DUPLICATE_KEY":
          setAlert({error: intl.msg("invite_error_duplicate_user"), field: "inp_emailAddresses", constraint: "valid"})
          break
        case "BANNED_INVITER":
          setAlert({error: intl.msg("error_banned_user", details)})
          break
        case "UNKNOWN_INVITER":
        case "INVALID_INVITER":
          setAlert({error: intl.msg("invite_error_invalid_inviter", details), field: "inp_emailAddress", constraint: "valid"})
          break
        case "DUPLICATE_INVITEE_USER":
          setAlert({error: intl.msg("invite_error_duplicate_user", details), field: "inp_emailAddress", constraint: "unique"})
          break
        case "DUPLICATE_INVITEE_TENANT":
          setAlert({error: intl.msg("invite_error_duplicate_tenant", details), field: "inp_tenantName", constraint: "unique"})
          break
        case "BANNED_EMAIL_ADDRESS":
          setAlert({error: intl.msg("error_banned_email_address", details), field: "inp_emailAddress", constraint: "valid"})
          break
        default:
          setAlert({error: intl.msg("error_failed", details)})
          break
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function canEdit(canChange) {
    if (!["undefined", "null"].includes(typeof(canChange)) && !canChange) {
      return false
    }
    if (isInvitation()) {
      return tenant.accessingAs("ADMIN/MEMBER,MANAGER/*") || shortIdEqual(createdById, user.userId)
    }
    if (isMe()) {
      return true
    }
    if (row) {
      return (tenant.accessingAt(row.userLevel))
    }
    else {
      return false
    }
  }

  function isEditing(canChange) {
    return (("ADD" === editMode) || ("EDIT" === editMode && canEdit(canChange)))
  }

  function isAdding() {
    return ("ADD" === editMode)
  }

  function isInvitation() {
    return (status === "INVITING")
  }

  function isMe() {
    return user.userId.replace(/\-/g, "") === row?.userId?.replace(/\-/g, "")
  }

  function isOwner() {
    return (row?.userLevel === 100)
  }

  function handleBackClick() {
    history.push("/members")
  }

  function canReinvite() {
    if (isInvitation()) {
      return (tenant.accessingAs("ADMIN/MEMBER,MANAGER/*") || shortIdEqual(createdById, user.userId))
    }
    else {
      return false
    }
  }

  function canDelete() {
    if (isMe()) {
      return false
    }
    if (isOwner()) {
      return false
    }
    if (tenant.accessingAs("ADMIN/MEMBER/*")) {
      return (user.userLevel > row?.userLevel)
    }
    else {
      return (tenant.accessingAs(["ADMIN/MANAGER/*"]))
    }
  }

  function canChangeEmailAddress() {
    return (isMe() || tenant.accessingAs("ADMIN/MEMBER,MANAGER/*"))
  }

  function canChangeFullName() {
    return (isMe() || tenant.accessingAs("ADMIN/MEMBER,MANAGER/*"))
  }

  function canChangeUserLevel() {
    return (!isOwner() && tenant.accessingAs("ADMIN/MEMBER,MANAGER/*"))
  }

  function handleResendClick() {
    openDialog("dlg_member_reinvite")
  }

  function handleDeleteClick() {
    openDialog("dlg_member_delete")
  }

  function handleDetailsClick() {
    openDialog("dlg_member_details")
  }

  function MoreMenu(props) {

    const comps = []

    if (!touched) {

      if (canDelete()) {
        if (comps.length > 0) {
          comps.push(<div className="dropdown-divider"></div>)
        }
        comps.push(
          <DropdownMenuItem name="delete" onClick={handleDeleteClick}/>
        )
      }

    }

    return (
      <>
        {
          comps.length > 0 &&
            <DropdownMenu>
              {comps}
            </DropdownMenu>
        }
      </>
    )
  }

  function FormControls(props) {
    const cmps = []

    if (isEditing()) {
      cmps.push(
        <ActionButton name="save" flavor="primary" disabled={!touched} onClick={handleSaveClick}/>
      )
      cmps.push(
        <ActionButton name="cancel" onClick={handleCancelClick}/>
      )
    }
    else {
      if (canEdit()) {
        cmps.push(
          <ActionButton name="edit" onClick={handleEditClick}/>
        )
      }
      if (canReinvite()) {
        cmps.push(
          <ActionButton name="reinvite" onClick={handleResendClick}/>
        )
      }
      cmps.push(
        <MoreMenu/>
      )
    }
    return cmps
  }

return (
  tenant &&
      <>

        <MainContainer tenant={tenant} menu={MainMenu}>

          <FormHeader>
            <AccountTabs tenant={tenant}/>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {
                  isAdding() ? (
                    <>
                      <div>{intl.msg("member")}</div>
                      <div className="text-muted">-</div>
                      <div>(new)</div>
                    </>
                  ) : (
                    <>
                      <div>{intl.msg("member")}</div>
                      <div className="text-muted">-</div>
                      <div>{displayName}</div>
                      <UserStatus row={row} size="lg" showYou={true}/>
                    </>
                  )
                }
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                <FormControls/>
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>

          <FormBody>

            <div className="row">
              <div className="col-sm-8">

                <FormGroup disabled={!isEditing(canChangeEmailAddress())}>
                  <FormGroup.Label text={intl.msg(`member_${isAdding() ? "invite_" : ""}email_address`)} description={intl.msg(`member_${isAdding() ? "invite_" : ""}email_address_description`)} htmlFor="inp_emailAddress"/>
                  <FormGroup.Control>
                    <div style={{maxWidth: "500px"}}>
                      <input id="inp_emailAddress" type="text" className="form-control" maxLength={150} value={emailAddress} disabled={!isEditing(canChangeEmailAddress())} onChange={handleEmailAddressChange}/>
                    </div>
                    <InfoBlock>
                      <InfoText validate="unique" disabled={!isEditing(canChangeEmailAddress())}>{intl.msg("member_email_address_helptext_unique")}</InfoText>
                      <InfoText validate="valid" disabled={!isEditing(canChangeEmailAddress())}>{intl.msg("helptext_email_address_valid")}</InfoText>
                      <InfoText validate="required" disabled={!isEditing(canChangeEmailAddress())}>{intl.msg("helptext_required")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Control>
                </FormGroup>

                {
                  !isInvitation() && !isAdding() &&
                    <FormGroup disabled={!isEditing(canChangeFullName())}>
                      <FormGroup.Label text={intl.msg("member_name")} description={intl.msg("member_name_description")} htmlFor="inp_fullName"/>
                      <FormGroup.Control>
                        <div style={{maxWidth: "500px"}}>
                          <input id="inp_fullName" type="text" className="form-control" maxLength={150} value={fullName} disabled={!isEditing(canChangeFullName())} onChange={handleFullNameChange}/>
                        </div>
                        <InfoBlock>
                          <InfoText validate="required" disabled={!isEditing(canChangeFullName())}>{intl.msg("helptext_required")}</InfoText>
                        </InfoBlock>
                      </FormGroup.Control>
                    </FormGroup>
                }

                <FormGroup disabled={!isEditing(canChangeUserLevel())}>
                  <FormGroup.Label text={intl.msg("member_access_level")} description={intl.msg("member_access_level_description")} htmlFor="inp_accessLevel"/>
                  <FormGroup.Control>
                    {/*<div style={{maxWidth: "400px"}}>*/}
                      <Select
                        id="inp_accessLevel"
                        className="react-select"
                        classNamePrefix="react-select"
                        cacheOptions
                        value={userLevel}
                        options={accessLevelOpts}
                        onChange={handleUserLevelChange}
                        isDisabled={!isEditing(canChangeUserLevel())}
                      />
                    {/*</div>*/}
                    <InfoBlock>
                      {
                        isEditing() && isOwner() && !isInvitation() &&
                          <InfoText warning={true} >{intl.msg("member_access_level_helptext_owner")}</InfoText>
                      }
                      <InfoText validate="required" disabled={!isEditing(canChangeUserLevel())}>{intl.msg("helptext_required")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Control>
                </FormGroup>

                {
                  (!isInvitation() && !isAdding() && (isOwner() || isMe() || tenant.accessingAs("ADMIN/*/SYSTEM"))) ? (
                    canEdit() ? (
                      <FormGroup disabled={!isEditing()}>
                        <FormGroup.Label text={intl.msg("member_lang")} description={intl.msg("member_lang_description")} htmlFor="inp_lang"/>
                        <FormGroup.Control>
                          <div style={{maxWidth: "400px"}}>
                            <Select
                              id="inp_lang"
                              className="react-select"
                              classNamePrefix="react-select"
                              cacheOptions
                              value={lang}
                              options={getAllLangOpts()}
                              hideSelectedOptions={true}
                              onChange={handleLangChange}
                              isDisabled={!isEditing()}
                            />
                          </div>
                          <InfoBlock>
                            <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                          </InfoBlock>
                        </FormGroup.Control>
                      </FormGroup>
                    ) : (
                      <FormGroup disabled={!isEditing()}>
                        <FormGroup.Label text={intl.msg("member_lang")} description={intl.msg("member_lang_description")} htmlFor="inp_lang"/>
                        <FormGroup.Control>
                          <div style={{maxWidth: "400px"}}>
                            <input id="inp_lang" type="text" className="form-control" value={lang?.label} disabled={true}/>
                            <InfoBlock>
                              <InfoText validate="required">{intl.msg("helptext_optional")}</InfoText>
                            </InfoBlock>
                          </div>
                        </FormGroup.Control>
                      </FormGroup>
                    )
                  ) : (
                    <></>
                  )
                }

                {
                  !isInvitation() && !isAdding() && (!themeOpt?.value?.startsWith("http")) &&
                    <FormGroup disabled={!isEditing()}>
                      <FormGroup.Label text={intl.msg("member_theme")} description={intl.msg("member_theme_description")} htmlFor="inp_theme"/>
                      <FormGroup.Control>
                        <Select
                          id="inp_theme"
                          className="react-select"
                          classNamePrefix="react-select"
                          cacheOptions
                          value={themeOpt}
                          options={themeOpts}
                          hideSelectedOptions={true}
                          onChange={handleThemeOptChange}
                          isDisabled={!isEditing()}
                        />
                        {/*</div>*/}
                        <InfoBlock>
                          <InfoText disabled={!isEditing()}>{intl.msg("helptext_optional")}</InfoText>
                        </InfoBlock>
                      </FormGroup.Control>
                    </FormGroup>
                }

              </div>

              <div className="col-sm-4">

                {
                  !isInvitation() && !isAdding() &&
                    <FormGroup>
                      <FormGroup.Label text={intl.msg("member_avatar")} description={intl.msg("member_avatar_description")} htmlFor="inp_avatar"/>
                      <FormGroup.Control>
                        <ImageDropBox
                          id="inp_avatar"
                          image={imageUrl}
                          imageClassName={"avatar"}
                          width={150}
                          height={150}
                          onChange={handleImageUrlChange}
                          onClear={handleImageUrlClear}
                          disabled={!isEditing()}/>
                        <InfoBlock>
                          <InfoText validate="res" disabled={!isEditing()}>{intl.msg("member_avatar_helptext_res")}</InfoText>
                          <InfoText validate="size" disabled={!isEditing()}>{intl.msg("member_avatar_helptext_size")}</InfoText>
                          <InfoText disabled={!isEditing()}>{intl.msg("helptext_optional")}</InfoText>
                        </InfoBlock>
                      </FormGroup.Control>
                    </FormGroup>
                }

              </div>

            </div>

          </FormBody>

        </MainContainer>

        {/*<MemberMoveDialog tenant={tenant} row={row}/>*/}
        <MemberReinviteDialog tenant={tenant} row={row}/>
        <MemberDeleteDialog tenant={tenant} row={row}/>
        {/*<MemberDetailsDialog tenant={tenant} row={row}/>*/}

        <ImageDialog
          ref={imagePickerDialogRef}
          id="dlg_logoImagePicker"
          title="Avatar Image"
          imageInfo={imageInfo}
          setTargetImageData={setImageUrl}
          setTouched={setTouched}/>

      </>

  )
}
