import React, { Fragment, useState, useEffect }  from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useSolv } from "./SolvProvider"
import { useIntlEx } from "./IntlUtils.js"
import getLogger from "./Logging.js"
import { ModalDialog, closeDialog } from "./DialogUtils"
import { fileExport } from "./FileUtils";
import {ActionButton, Alert, FormGroup} from "./FormComps"
import '../App.css'
import {getAdSystemName} from "./BroadcastComps";
import {InfoBlock, InfoText} from "./ValidationUtils";

const log = getLogger("ExportToFileDialogs")

export default function ExportToFileDialog(props)  {

  const {setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const [touched, setTouched] = useState(false)

  const [fileName, setFileName] = useState(null)

  const [alert, setAlert] = useState(null)

  useEffect(() => {
    if (props.fileName) {
      setFileName(props.fileName)
    }
  }, [props])

  async function handleExportClick() {
    setBusy(intl.msg("exporting"))
    try {
      const data = await props.onExport()
      fileExport(data, `${fileName}.${props.fileExt}`)
      closeDialog(`dlg_${props.type}_export`)
    }
    catch (ex) {
      setAlert({error: intl.msg("error_failed")})
    }
    finally {
      setBusy(null)
    }
  }

  function handleOpenDialog() {
  }

  function handleCloseDialog() {
    closeDialog(props.id || `dlg_${props.type}_export`)
  }

  return (
      <>
        <ModalDialog
          dialogId={props.id || `dlg_${props.type}_export`}
          size={props.size || "md"}
          keyboard={"true"}
          backdrop={props.backdrop || "static"}
          onOpen={handleOpenDialog}
          onClose={handleCloseDialog}>

          <ModalDialog.Header
            title={intl.msg(`${props.type}_export_title`)}
            description={intl.msg(`${props.type}_export_subtitle`)}
            alert={alert}/>

          <ModalDialog.Body>

            {/*<p className="">{intl.msg(`${props.type}_export_text`)}</p>*/}

            <FormGroup>
              <FormGroup.Label text={intl.msg(`export_file_name`)} description={intl.msg(`export_file_name_description`, {fileExtName: intl.msg(`file_type_name_${props.fileExt?.toLowerCase()}`)})}/>
              <FormGroup.Controls>
                <input id="inp_exportFileName" name="exportFileName" type="text" className="form-control" value={fileName} disabled={false} onChange={(e) => {setFileName(e.target.value);setTouched(true)}} autoComplete="no"/>
                <InfoBlock>
                  <InfoText>{intl.msg("export_file_name_helptext_1", {fileExt: props.fileExt})}</InfoText>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

          </ModalDialog.Body>

          <ModalDialog.Footer>
            <ModalDialog.Footer.Controls>
              <ActionButton name="cancel" flavor="secondary" onClick={handleCloseDialog}/>
              <ActionButton name="export" flavor="primary" disabled={fileName?.trim()?.length == 0} onClick={handleExportClick}/>
            </ModalDialog.Footer.Controls>
          </ModalDialog.Footer>

        </ModalDialog>
      </>
  )
}