import React, {Fragment, useState, useEffect, useImperativeHandle, forwardRef, createRef, useLayoutEffect} from 'react'
import {useInfiniteQuery} from 'react-query'
import {AsyncPaginate, reduceGroupedOptions} from "react-select-async-paginate";
import useIntersectionObserver from '../useIntersectionObserver.js'
import {useSolv} from "./SolvProvider";
import {useIntlEx} from "./IntlUtils";
import {SearchInput} from "./SearchInput";
import {default as Select} from "react-select";
import {useBroadcastStatusOpts} from "./BroadcastComps";
import {useReportDateRangeOpts} from "../ReportDateRangeSelect";
import {useAccountStatusOpts} from "./AccountUtils";
import {useAccessLevelOpts, useUserStatusOpts} from "./UserUtils";
import getLogger from "../components/Logging.js"
import "../App.css"
import styles from "./TableComps.module.css"
import {useSearchParams} from "./UrlUtils";

const log = getLogger("TableComps")

function getOptions(options) {
  if (typeof(options) !== "function") {
    return options
  }
  else {
    return options()
  }
}

export function useCols({specs, multiSort, onFilterChange, onSortChange, storageItemName}) {

  const {accessLevelOpts} = useAccessLevelOpts()
  const {accountStatusOpts} = useAccountStatusOpts()
  const {userStatusOpts} = useUserStatusOpts()
  const {broadcastStatusOpts} = useBroadcastStatusOpts()
  const {dateRangeOpts} = useReportDateRangeOpts()

  const _specs = [...specs]
  const cols = initCols()

  const [filterValues, setFilterValues] = useState({})
  const [sortValues, setSortValues] = useState(initSortValues(specs))

  cols.getSortValue = function(field) {
    return (sortValues[field])
  }
  cols.updateSortValue = function(field, value) {
    if (multiSort) {
      setSortValues(prev => {return {...prev, [field]: value}})
    }
    else {
      setSortValues({[field]: value})
    }
    if (onSortChange) {
      onSortChange(field, value)
    }
  }

  cols.getFilterValue = function(field) {
    return filterValues[field]
  }
  cols.updateFilterValue = function (field, value) {
    setFilterValues({...filterValues, [field]: value})
    if (onFilterChange) {
      onFilterChange(field, value)
    }
  }

  function initCols() {
    for (let col of _specs) {
      if (col.filter) {
        let filter = {}
        if ("string" === typeof(col.filter)) {
          filter = {
            type: col.filter,
            required: false,
            default: null,
          }
        }
        else {
          filter = col.filter
        }
        switch (filter.type) {
          case "select:accessLevel":
            filter.options = () => accessLevelOpts
            break
          case "select:accountStatus":
            filter.options = () => accountStatusOpts
            break
          case "select:broadcastStatus":
            filter.options = () => broadcastStatusOpts
            break
          case "select:reportDateRange":
            filter.options = () => dateRangeOpts
            break
          case "select:userStatus":
            filter.options = () => userStatusOpts
            break
          default:
        }
        col.filter = filter
      }
      if (typeof(col.visible) === "undefined") {
        col.visible = function () {
          return true
        }
      }
      col.getFilterValue = function() {
        return filterValues[col.field]
      }
      col.updateFilterValue = function (value) {
        const f = {...filterValues, [col.field]: value}
        // log.debug("filter: updateFilterValue: ", f)
        setFilterValues(prev => { return {...prev, [col.field]: value}})
        if (onFilterChange) {
          onFilterChange(col.field, value)
        }
      }
      col.parseFilterValue = function (value) {
        switch (col.filter.type) {
          case "select":
          case "select:accountStatus":
          case "select:userStatus":
          case "select:broadcastStatus":
          case "select:accessLevel": {
            const options = getOptions(col.filter.options)
            if (options) {
              if (col.filter.multi) {
                const opts = value.split("\t").map(value => {
                  return options.find(o => o.value === value)
                }).filter(opt => opt !== undefined && opt !== null)
                // log.debug(">>>Z: parseFilterValue: col, return=", col, opts)
                return Promise.resolve({field: col.field, value: opts})
              }
              else {
                const opt = options.find(o => o.value === value)
                // log.debug(">>>Z: parseFilterValue: col, return=", col, opt)
                return Promise.resolve({field: col.field, value: opt})
              }
            }
            break
          }
          case "asyncselect": {
            if (col.filter.loader) {
              // log.debug(">>>Z: parseFilterValue: col, return=", col, col.filter.loader)
              return col.filter.loader(value).then((opts) => {
                // log.debug(">>>Z: parseFilterValue: loader: opts=", opts)
                const opt = opts.options.find(o => o.value === value)
                // log.debug(">>>Z: parseFilterValue: loader: opt=", opt)
                return Promise.resolve({field: col.field, value: opt})
              })
            }
          }
          case "select:reportDateRange": {
            const options = getOptions(col.filter.options)
            if (options) {
              const values = value.split("\t")
              if (values.length === 2) {
                const startTime = parseInt(values[0].trim())
                const endTime = parseInt(values[1].trim())
                const opt = options.find(o => (o.startTime === startTime && (o.endTime === endTime)))
                // log.debug(">>>Z: parseFilterValue: col, return=", col, opt)
                return Promise.resolve({field: col.field, value: opt})
              }
            }
            break
          }
          default:
            // log.debug(">>>Z: parseFilterValue: col, return=", col, value)
            return Promise.resolve({field: col.field, value: value})
        }
      }
      col.stringifyFilterValue = function(value) {
        let result = ""
        if (typeof (col.filter.type) === "function") {
          result = value.toString()
        }
        else {
          switch (col.filter.type) {
            case "select":
            case "select:accountStatus":
            case "select:userStatus":
            case "select:broadcastStatus":
            case "select:accessLevel":
            case "asyncselect": {
              if (col.filter.multi) {
                result = value.map(v => v.value).join("\t")
              }
              else {
                result = value.value
              }
              break
            }
            case "select:reportDateRange": {
              result = `${value.startTime}\tvalue.endTime}`
              break
            }
            default:
              if (typeof(filterValues[col.field]) === "string") {
                if (filterValues[col.field].trim().length > 0) {
                  result = value.trim()
                }
              }
              else {
                result = value
              }
              break
          }
        }
        return `${col.field}:${encodeURIComponent(result)}`
      }
    }
    const cols = {
      get: function(field) {
        let s = _specs.find(i => i.field === field)
        return s
      },
      getAll: function() {
        return _specs
      },
      parse: function (search) {
        let searchParams
        switch (typeof(search)) {
          case "object":
            searchParams = search
            break
          case "string":
            searchParams = new URLSearchParams(search)
            if (!searchParams) {
              return
            }
            break
          default:
            return null
        }
        parseSortParams(searchParams.getAll("sort"))
        parseFilterParams(searchParams.getAll("filter"))
      },
      stringify: function() {
        let result = ""
        for (const col of _specs) {
          if (filterValues[col.field]) {
            let value = ""
            if (typeof (col.filter.type) === "function") {
              value = col.filter.type(col.field, filterValues[col.field])
            }
            else {
              switch (col.filter.type) {
                case "select":
                case "select:accountStatus":
                case "select:userStatus":
                case "select:broadcastStatus":
                case "select:accessLevel":
                case "asyncselect": {
                  if (col.filter.multi && filterValues[col.field].length > 0) {
                    value = filterValues[col.field].map(v => v.value).join("\t")
                  }
                  else {
                    value = filterValues[col.field].value
                  }
                  break
                }
                case "select:reportDateRange": {
                  value = `${filterValues[col.field].startTime}\t${filterValues[col.field].endTime}`
                  break
                }
                default:
                  if (typeof(filterValues[col.field]) === "string") {
                    if (filterValues[col.field].trim().length > 0) {
                      value = filterValues[col.field].trim()
                    }
                  }
                  else {
                    value = filterValues[col.field]
                  }
                  break
              }
            }
            if (value) {
              result += ((result.length > 0) ? "&" : "") + `filter=${col.field}:${encodeURIComponent(value)}`
            }
          }
        }
        for (const spec of _specs) {
          if (sortValues[spec.field]) {
            result += ((result.length > 0) ? "&" : "") + `sort=${spec.field}:${sortValues[spec.field]}`
          }
        }
        return result
      },
      stringify2: function(opts) {
        let result = ""
        for (const col of _specs) {
          let value
          if (opts?.filter?.field === col.field) {
            value = opts.filter.value
          }
          else if (filterValues[col.field]) {
            value = filterValues[col.field]
          }
          if (value) {
            if (typeof (col.filter.type) === "function") {
              value = col.filter.type(col.field, value)
            } else {
              switch (col.filter.type) {
                case "select":
                case "select:accountStatus":
                case "select:userStatus":
                case "select:broadcastStatus":
                case "select:accessLevel":
                case "asyncselect": {
                  if (col.filter.multi && value.length > 0) {
                    value = value.map(v => v.value).join("\t")
                  } else {
                    value = value.value
                  }
                  break
                }
                case "select:reportDateRange": {
                  value = `${value.startTime}\t${value.endTime}`
                  break
                }
                default:
                  if (typeof (value) === "string") {
                    if (value.trim().length > 0) {
                      value = value.trim()
                    }
                  }
                  // else {
                  //   value = value
                  // }
                  break
              }
            }
            if (value) {
              result += ((result.length > 0) ? "&" : "") + `filter=${col.field}:${encodeURIComponent(value)}`
            }
          }
        }
        if (opts?.sort?.field && opts?.sort?.value) {
          result += ((result.length > 0) ? "&" : "") + `sort=${opts.sort.field}:${opts.sort.value}`
        }
        else {
          for (const spec of _specs) {
            let value = sortValues[spec.field]
            if (value) {
              result += ((result.length > 0) ? "&" : "") + `sort=${spec.field}:${value}`
            }
          }
        }
        return result
      },
      // load: function() {
      //   if (storageItemName) {
      //     const s = localStorage.getItem(storageItemName)
      //     if (s) {
      //       this.parse(s)
      //     }
      //   }
      // }
    }
    return cols
  }

  function initSortValues(specs) {
    const result = {}
    for (const spec of specs) {
      // log.debug(`initSortValues: spec=`, spec)
      if (spec.sort && spec.sort.trim().length > 0) {
        result[spec.field] = spec.sort
      }
    }
    return result
  }

  function updateSortValue(field, value){
    // log.debug("parse: updateSortValue: ", field, value)
    if (multiSort) {
      setSortValues(prev => {return {...prev, [field]: value}})
    }
    else {
      // log.debug("parse: updateSortValue: ", {[field]: value})
      setSortValues({[field]: value})
    }
    if (onSortChange) {
      onSortChange(field, value)
    }
  }

  const nvPat = /([^:]+):(.*)/

  function parseFilterParams(params) {
    // log.debug("parse: parseFilterParams: params=", params)
    for (const param of params) {
      const match = param.match(nvPat);
      // log.debug("parse: parseFilterParams: param=, match", param, match)
      if (match) {
        const field = match[1]
        const value = match[2].trim()
        // log.debug("parse: parseFilterParams: field=, value=", field, value)
        const spec = specs.find(i => i.field === field)
        if (spec) {
          switch (spec.filter.type) {
            case "select":
            case "select:accountStatus":
            case "select:userStatus":
            case "select:broadcastStatus":
            case "select:accessLevel":
            case "asyncselect": {
              const options = getOptions(spec.filter.options)
              if (options) {
                if (spec.filter.multi) {
                  const opts = value.split("\t").map(value => {
                    return options.find(o => o.value === value)
                  }).filter(opt => opt !== undefined && opt !== null)
                  spec.updateFilterValue(opts)
                }
                else {
                  spec.updateFilterValue(options.find(o => o.value === value))
                }
              }
              break
            }
            case "select:reportDateRange": {
              const options = getOptions(spec.filter.options)
              if (options) {
                const values = value.split("\t")
                if (values.length === 2) {
                  const startTime = parseInt(values[0].trim())
                  const endTime = parseInt(values[1].trim())
                  spec.updateFilterValue(options.find(o => (o.startTime === startTime && (o.endTime === endTime))))
                }
              }
              break
            }
            default:
              spec.updateFilterValue(value)
          }
        }
      }
    }
  }

  function parseSortParams(params) {
    for (const param of params) {
      const match = param.match(nvPat);
      // log.debug("parse: parseSortParams: match=", match)
      if (match) {
        const field = match[1]
        const value = match[2].trim()
        const spec = specs.find(i => i.field === field)
        // log.debug("parse: parseSortParams: ", spec?.sort !== null && spec?.sort !== undefined)
        if (spec?.sort !== null && spec?.sort !== undefined) {
          // log.debug("parse: updateSortValue...")
          updateSortValue(field, value)
        }
      }
    }
  }

  useEffect(() => {
   if (cols) {
     for (const col of cols.getAll()) {
       if (col.filter && col.filter.default) {
         const opts = getOptions(col.filter.options)
         if (opts) {
           const opt = opts.find(o => o.value === col.filter.default)
           if (opt) {
             col.updateFilterValue(opt)
           }
         }
       }
     }

   }
  }, [])

  return ({
    cols,
  })
}

export const FilterInput = forwardRef(({col, onChange}, ref) => {

  const [value, setValue] = useState(col.getFilterValue())

  const inputRef = React.useRef()

  function handleChange(v, opts) {
    setValue(v)
    col.updateFilterValue(v)
    if (onChange) {
      onChange(col, v, opts)
    }
  }

  useImperativeHandle(ref, () => ({
    updateValue(value, opts) {
      handleChange(value, opts)
      inputRef.current.setValue(value)
    }
  }));

  return (
    col && col.filter &&
      <SearchInput ref={inputRef} value={value} onChange={handleChange}/>
  )
})

export const FilterSelect = forwardRef(({col, onChange}, ref) => {
  const [value, setValue] = useState(col.getFilterValue())
  const selectRef = React.useRef()

  // log.debug(">>>Z: col=", col.filter)

  useEffect(() => {
    if (col && col.filter && col.filter.required) {
      if (onChange) {
        onChange(col, value)
      }
    }
  }, [])

  function handleChange(v, selectOpts, opts) {
    setValue(v)
    col.updateFilterValue(v)
    if (onChange) {
      onChange(col, v, opts)
    }
  }

  useImperativeHandle(ref, () => ({
    updateValue(value, opts) {
      handleChange(value, null, opts)
    }
  }));

  return (
    col && col.filter &&
      <Select
        ref={selectRef}
        className="react-select"
        classNamePrefix="react-select"
        isMulti={col.filter.multi || false}
        value={value}
        placeholder={col.filter.placeholder || ""}
        options={getOptions(col.filter.options)}
        menuPortalTarget={col.filter.menuPortalTarget || document.body}
        menuPosition={col.filter.menuPosition || "fixed"}
        isClearable={!col.filter.required}
        isDisabled={false}
        onChange={handleChange}/>
  )
})

export const FilterAsyncSelect = forwardRef(({col, onChange}, ref) => {
// export function FilterAsyncSelect({col, onChange}) {

  const [value, setValue] = useState(col.getFilterValue())
  const selectRef = React.useRef()

  useEffect(() => {
    if (col && col.filter && col.filter.required) {
      if (onChange) {
        onChange(col, value)
      }
    }
  }, [])

  function handleChange(v, selectOpts, opts) {
    setValue(v)
    col.updateFilterValue(v)
    if (onChange) {
      onChange(col, v, opts)
    }
  }

  useImperativeHandle(ref, () => ({
    updateValue(value, opts) {
      handleChange(value, null, opts)
    }
  }));

  return (
    col && col.filter &&
      <AsyncPaginate
        selectRef={selectRef}
        className="react-select"
        classNamePrefix="react-select"
        cacheOptions
        value={value}
        debounceTimeout={col.filter.debounceTimeout || 800}
        defaultOptions={col.filter.defaultOptions || true}
        loadOptions={col.filter.loader}
        reduceOptions={reduceGroupedOptions}
        placeholder={col.filter.placeholder || ""}
        menuPortalTarget={col.filter.menuPortalTarget || document.body}
        menuPosition={col.filter.menuPosition || "fixed"}
        isMulti={col.filter.multi || false}
        isClearable={!col.filter.required}
        isDisabled={false}
        onChange={handleChange}
      />
  )
})

function colWidthClass(width) {
  switch (width) {
    case "sm":
    case "md":
    case "lg":
    case "xl":
      return styles[`col-width-${width}`]
    default:
      return ""
  }
}

export function Table({cols, resourceName, selectable, clickable, autoSaveSettings, configurable, onFilterChange, onSortChange, children}) {

  const {intl} = useIntlEx()

  const [_cols, _] = useState(cols?.getAll())
  const [searchParams, setSearchParams] = useSearchParams();

  const filterRefs = React.useRef(
    cols?.getAll().reduce((acc, curr) => {
      acc[curr.field] = React.createRef()
      return acc;
    }, {})
  )

  useLayoutEffect(() => {
    if (searchParams && searchParams.size > 0) {
      // log.debug(">>>Z: searchParams=", searchParams)
      loadFromSearch(searchParams, {transient: true})
    }
    else {
      loadFromSearch(localStorage.getItem(`SOLV-TABL-${resourceName}`))
    }
  },[])

  function loadFromSearch(searchParams, opts) {
    // log.debug(">>>Z: loadFromSearch: searchParams=", `SOLV-TABL-${resourceName}`, searchParams)
    if (searchParams) {
      if (typeof(searchParams) === "string") {
        searchParams = new URLSearchParams(searchParams)
      }
      // log.debug(">>>Z: searchParams=", searchParams)
      const promises = []
      for (const param of searchParams.getAll("filter")) {
        const match = param.match(nvPat);
        if (match) {
          const field = match[1]
          const value = match[2].trim()
          const col = _cols.find(i => i.field === field)
          if (col) {
            const parsedValue = col.parseFilterValue(value)
            promises.push(
              parsedValue
            )
          }
        }
        // log.debug(">>>Z: promises=", promises)
        Promise.all(promises)
          .then((values) => {
            // log.debug(">>>Z: promises: values=", values)
            values.map(v => filterRefs.current[v.field].current.updateValue(v.value, opts))
          })
      }
      // log.debug(">>>Z: sort=", searchParams.getAll("sort"))
      for (const param of searchParams.getAll("sort")) {
        const match = param.match(nvPat);
        if (match) {
          const field = match[1]
          const value = match[2].trim()
          cols.updateSortValue(field, value)
        }
      }
    }
  }

  function saveSettings(opts) {
    localStorage.setItem(`SOLV-TABL-${resourceName}`, cols.stringify2(opts))
  }

  const nvPat = /([^:]+):(.*)/

  function handleFilterChange(col, value, opts) {
    // log.debug(">>>Z: handleFilterChange: opts=", opts)
    if (!opts || opts.transient === false) {
      // log.debug(">>>Z: handleFilterChange: saveToStorage", opts)
      if (autoSaveSettings) {
        saveSettings({filter: {field: col.field, value: value}})
      }
    }
    if (onFilterChange) {
      onFilterChange(col, value)
    }
  }

  function handleColumnClick(col) {
    let v = null
    switch (cols?.getSortValue(col.field)) {
      case "ASC":
        v = "DESC"
        break
      case "DESC":
        v = null
        break
      default:
        v = "ASC"
        break
    }

    // log.debug(">>>Z: sort: ", cols.stringify())
    saveSettings({sort: {field: col.field, value: v}})

    cols.updateSortValue(col.field, v)
    if (onSortChange) {
      onSortChange(col, v)
    }
  }

  return (
    _cols &&
      <table className={`${styles["table"]} ${styles["table-striped"]} ${styles["table-bordered"]} ${selectable ? styles["table-selectable"] : ""}  ${clickable ? styles["table-clickable"] : ""}`}>
        <thead>
        <tr>
          {
            _cols.map((col) => {
              const sty = {}
              if (col.width) {
                sty.width = col.width
              }
              if (col.align) {
                sty.textAlign = col.align
              }
              return (
                col.visible && col.visible() &&
                  <th key={col.label} scope="col" className={`${("string" === typeof(col.sort) ? styles["col-clickable"] : "")} ${colWidthClass(col.width)}`} style={sty}>
                    {
                      "string" === typeof(col.sort) ? (
                        <label className={styles["sortable-column"]} onClick={(e) => handleColumnClick(col)}>
                          <>
                            <span className="mr-1">{col.label}</span>
                            {
                              cols?.getSortValue(col.field) === "ASC" ? (
                                // <span key="up"><i className="fas fa-long-arrow-alt-up"></i></span>
                                <span key="sort-up"><i className="fas fa-sort-alpha-up"></i></span>
                              ) : cols?.getSortValue(col.field) === "DESC" ? (
                                <span key="sort-down" ><i className="fas fa-sort-alpha-down-alt"></i></span>
                                // <span key="sort-down" ><i className="fas fa-long-arrow-alt-down"></i></span>
                              ) : (
                                <span key="updown-up"></span>
                              )
                            }
                          </>
                        </label>
                      ) : (
                        <label>{col.label}</label>
                      )
                    }
                  </th>
              )
            })
          }
          {
            configurable &&
              <th>
              </th>
          }
        </tr>
        {
          _cols.find(col => col.filter) &&
            <tr>
              {
                _cols.map((col, i) => {
                    const sty = {}
                    return (
                      col.visible && col.visible() &&
                        <th key={col.field} scope="col" style={sty} className={colWidthClass(col.wdith)}>
                          {
                            col.filter ? (
                              "text" === col.filter.type ? (
                                <FilterInput key={i} ref={filterRefs.current[col.field]} col={col} onChange={handleFilterChange}/>
                              ) : ["select",  "select:accessLevel", "select:accountStatus", "select:broadcastStatus", "select:reportDateRange", "select:userStatus"].includes(col.filter.type) ? (
                                <FilterSelect key={i} ref={filterRefs.current[col.field]} col={col} onChange={handleFilterChange}/>
                              ) : ["asyncselect"].includes(col.filter.type) ? (
                                <FilterAsyncSelect key={i} ref={filterRefs.current[col.field]} col={col} onChange={handleFilterChange}/>
                              ) : (
                                <></>
                              )
                            ) : (
                              <></>
                            )
                          }
                        </th>
                    )
                })
              }
              {
                configurable &&
                  <th key="more_menu">
                    <button className="btn btn-secondary" style={{padding: "6px 4px 6px 6px"}} type="button" id="dropdownMenuButton" title={intl.msg("more_menu")} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fas fa-ellipsis-v"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                      <a className="dropdown-item" href="#">
                        <i className="fas fa-save mr-2"></i>
                        {intl.msg("table_state_save")}
                      </a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="#">
                        <i className="fas fa-times mr-2"></i>
                        {intl.msg("table_state_clear")}
                      </a>
                    </div>
                  </th>
              }
            </tr>
        }
        </thead>
        <>
          {children}
        </>
      </table>
  )
}

export function DataTable(props) {
// export const DataTable = forwardRef((props, ref) => {

  const {intl} = useIntlEx()

  const [tenant, setTenant] = useState(null)
  const [cols, setCols] = useState(null)

  const loadOnStart = props.loadOnStart === undefined || props.loadOnStart === null || props.loadOnStart === true

  const [refreshId, setRefreshId] = useState(loadOnStart ? Math.random : null)

  const [selectedRow, setSelectedRow] = useState(null)

  const {status, data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage,} = useInfiniteQuery(
    [props.resourceName, props.tenant, refreshId],
    props.onFetchDataPage,
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.nextCursor
      },
      enabled: !!props.tenant && !!refreshId
    }
  )

  const loadMoreButtonRef = React.useRef()
  // const tableRef = React.useRef()

  useEffect(() => {
    if (props) {
      // log.debug("useEffect: props=", props)
      if (props.tenant) {
        setTenant(tenant)
      }
      if (props.cols) {
        setCols(cols)
      }
    }
  }, [])

  useEffect(() => {
    if (status) {
      if (props.onFetchStatusChange) {
        props.onFetchStatusChange(status)
      }
    }
  }, [status])

  // useImperativeHandle(ref, () => ({
  //   updateFiltersFromSearch(search) {
  //     log.debug("DataTable: updateFiltersFromSearch invoked")
  //     tableRef.current.updateFiltersFromSearch(search)
  //   }
  // }));

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  })

  function handleRowClick(row) {
    if (props.onClickDataRow) {
      setSelectedRow(row)
      props.onClickDataRow(row)
    }
  }

  function handleFilterChange(col, value) {
    refresh()
    if (props.onFilterChange) {
      props.onFilterChange(col, value)
    }
  }

  function handleSortChange(col, value) {
    refresh()
    if (props.onSortChange) {
      props.onSortChange(col, value)
    }
  }
  function refresh() {
    setRefreshId(Math.random())
  }

  const _cols = props.cols
  const _colsAll = _cols?.getAll()

  function DataRow(p) {
    let className = ""
    if (selectedRow) {
      if (props.selectable) {
        if (selectedRow[props.dataKey] === p.row[props.dataKey]) {
          className = styles["selected"]
        } else {

        }
      }
    }
    return (
      <>
        <tr className={className} key={p.row[props.dataKey]} onClick={() => handleRowClick(p.row)}>
          {p.children}
        </tr>
      </>
    )
  }

  function renderTotalsRow() {
    if (props.onRenderColTotal) {
      return () => {
        const c = _cols.getAll()
        return c.map(c => {
          if (c.visible?.()) {
            if (c.showTotal) {
              return <th>{props.onRenderColTotal(c, data)}</th>
            } else {
              return <th></th>
            }
          } else {
            return <></>
          }
        })
      }
    }
    else {
      return null
    }

  }

  return (
    _cols &&
      <>
      <div style={{overflowX: "auto"}}>
        <Table cols={_cols} resourceName={props.resourceName} location={props.location} onFilterChange={handleFilterChange} onSortChange={handleSortChange} selectable={props.selectable} clickable={typeof(props.onClickDataRow) === "function"}>
          <tbody>
          {
            status === 'loading' ? (
              <tr>
                <td colSpan="3"><p>{intl.msg("loading")}</p></td>
              </tr>
            ) : status === 'error' ? (
              <tr>
                <td className="text-danger" colSpan="3">{intl.msg("error_failed")}</td>
              </tr>
            ) : data ? (
              data.pages.map((group, i) => (
                <React.Fragment key={i}>
                  {
                    group.data.map((row, j) => (
                      <DataRow key={`datarow-${j}`} row={row}>
                        {
                          _colsAll.map((col, k) => {
                            return (
                              col.visible() &&
                                <td key={k} className={`${col.align ? styles["align-" + col.align] : ""} ${colWidthClass(col.width)}`}>
                                  {
                                    col.visible() && props.onRenderDataCol &&
                                      props.onRenderDataCol(col, row)
                                  }
                                </td>
                            )
                          })
                        }
                      </DataRow>
                    ))}
                </React.Fragment>
              ))
            ) : (
              <></>
            )
          }
          </tbody>
          {
            data && props.onRenderFooterCol && //_colsAll.find(col => !!col.footer) &&
              <tfoot>
              {
                _colsAll.map(col => {
                  return (
                    col.visible() &&
                      <th className={ `${col.align ? styles["align-" + col.align] : ""} ${colWidthClass(col.width)}`}>
                      {
                        col.visible() &&
                          props.onRenderFooterCol(col, data)
                      }
                      </th>
                  )
                })
              }
              </tfoot>
          }
        </Table>
      </div>
      <div>
        {
          hasNextPage ?
            <button
              className="btn btn-secondary"
              ref={loadMoreButtonRef}
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}>
              {
                isFetchingNextPage
                  ? <span>{intl.msg("loading")}</span>
                  : <span>{intl.msg("show_more")}</span>
              }
            </button>
            : ""
        }
      </div>
    </>
  )

}

export function mkExtraParams(params) {

  let q = ""

  if (params.filterParams) {
    // log.debug("mkExtraParams: filterParams=", params.filterParams)
    for (const [key, value] of Object.entries(params.filterParams)) {
      q += (q.length > 0 ? "&" : "") + "filter=" + key + ":" + encodeURIComponent(value)
    }
  }

  if (params.sortParams) {
    for (const [key, value] of Object.entries(params.sortParams)) {
      q += (q.length > 0 ? "&" : "") + "sort=" + key + ":" + encodeURIComponent(value)
    }
  }

  return q

}
