import React, { Fragment, useState, useEffect }  from 'react'
import {default as Select} from "react-select";
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {SolvError, useSolv} from "./components/SolvProvider"
import {useIntlEx} from "./components/IntlUtils"
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {clearAllErrors, InfoBlock, InfoText, showError, validateField} from "./components/ValidationUtils";
import getLogger from "./components/Logging.js"
import './App.css'
import NumericInput from "react-numeric-input";
import {PayPalButton} from "react-paypal-button-v2";
import useStateRef from "react-usestateref";
import {FormGroup} from "./components/FormComps";

const log = getLogger("CreditsExtendExpiryDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function CreditsExtendExpiryDialog(props)  {

  const {api, session: {user}, refreshSignin, env, setFatal, setBusy} = useSolv()

  const {intl} = useIntlEx()

  const [touched, setTouched] = useState(false)
  const [alert, setAlert] = useState(null)

  const [tenant, setTenant] = useState(null)

  const [extendDays, setExtendDays] = useState(90)

  useEffect(() => {
    if (props.tenant) {
      log.debug("useEffect: tenant=", props.tenant)
      setTenant(props.tenant)
    }
  }, [props.tenant])

  function handleOpenDialog() {
  }

  function handleCloseDialog() {
    closeDialog("dlg_credits_extend_expiry")
  }

  async function handleExtendClick() {

    if (tenant) {
      setBusy(intl.msg("processing"))
      try {

        const payload = {
          extendDays: extendDays,
        }
        const {data} = await api.extendCreditsExpiry(tenant.tenantId, payload)

        log.debug("extendCreditsExpiry: data=", data)

        window.location.reload()
      }
      catch (error) {
        setAlert({error: intl("error_failed")})
      }
      finally {
        setBusy(null)
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }

  }

  function getErrorMsg(error) {
    log.debug("getErrorMsg: 1")
    let res = {}
    if (error && error.code) {
      const code = error.code
      const details = error.details
      res.code = code
      switch (code) {
        case "INSUFFICIENT_FUNDS":
          res.msg = intl.msg("error_failed")
          break
        default:
          res.msg = intl.msg("error_failed")
          break
      }
    }
    else {
      res.msg = intl.msg("error_failed")
    }
    log.debug("getErrorMsg: >>>>>res=", res)
    return res
  }

  function handleError(error) {
    log.debug("handleError: >>>error=", error)
    if (error && error.code) {
      switch (error.code) {
        case "TENANT_NOT_FOUND":
          setAlert({error: intl.msg("error_failed")})
          break
        default:
          setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function getErrorMsg(error) {
    let res = null
    if (error && error.code) {
      log.debug("1 getErrorMsg: error=", error)
      const code = error.code
      const details = error.details
      log.debug("2 getErrorMsg: details=", details)
      res = {
        code: code
      }
      switch (code) {
        default:
          res.msg = intl.msg("error_failed", details)
          break
      }
    }
    else {
      return intl.msg("error_failed")
    }
    return res
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_extendDays", extendDays >= 90, "min-value", opt)

    log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid
  }

  function handleExtendDaysChange(v) {
    setExtendDays(v)
  }

  function isEditable() {
    return true
  }

  return (

    <ModalDialog
      dialogId="dlg_credits_extend_expiry"
      size={props.size || "md"}
      keyboard={"true"}
      backdrop={props.backdrop || "static"}
      onOpen={handleOpenDialog}
      onClose={handleCloseDialog}>

      <ModalDialog.Header title={intl.msg("credits_extend_expiry_title")} alert={alert}/>
        {
          tenant &&
            <>
              <ModalDialog.Body>
                {
                  tenant.accessingAs("*/*/SYSTEM") ? (
                    <FormGroup>
                      <FormGroup.Label htmlFor="inp_extendDays" text={intl.msg("credits_extend_expiry_days")} description={intl.msg("credits_extend_expiry_days_description")}/>
                      <FormGroup.Controls>
                        <div style={{display: 'flex', alignItems: "center", width: "120px"}}>
                          <NumericInput id="inp_extendDays" className="form-control" style={false} min={90} precision={0} disabled={!isEditable()} value={extendDays} onChange={handleExtendDaysChange}/>
                        </div>
                        <InfoBlock>
                          <InfoText validate="min-value" disabled={!isEditable()}>{intl.msg("credits_extend_expiry_days_helptext_min_value", {minDays: 90})}</InfoText>
                        </InfoBlock>
                      </FormGroup.Controls>
                    </FormGroup>
                  ) : (
                    intl.msg("credits_extend_expiry_text_1", {broadcastName: props.broadcastName})
                  )
                }
              </ModalDialog.Body>
              <ModalDialog.Footer>
                <ModalDialog.Footer.Controls>
                  <button className="btn btn-secondary" onClick={() => closeDialog("dlg_credits_extend_expiry")}>{intl.msg("cancel")}</button>
                  <button className="btn btn-primary mr-2" onClick={handleExtendClick}>{intl.msg("extend_expiry")}</button>
                </ModalDialog.Footer.Controls>
              </ModalDialog.Footer>
            </>
        }
      </ModalDialog>
  )
}