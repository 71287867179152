import React, { Fragment, useState }  from 'react'
import {useHistory, useParams} from 'react-router-dom'
import { useInfiniteQuery, useQueryClient } from 'react-query'
import {useSolv, useTenant} from "./components/SolvProvider"
import MainMenu from "./MainMenu.js"
import {FormHeader, FormBody, MainContainer} from "./components/FormComps.js"
import SortableColumn from "./components/SortableColumn"
import { useIntlEx } from "./components/IntlUtils.js"
import useIntersectionObserver from './useIntersectionObserver.js'
import {AccountTabs} from "./components/AccountUtils";
import {SearchInput} from "./components/SearchInput";
import {mkHref} from "./components/AccountUtils";
import {HumanTimestamp} from "./components/DateUtils";
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("ReferralCodes")

export default function ReferralCodes() {

  const {api, session: {user}, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const history = useHistory()
  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [search, setSearch] = useState(null)
  const [sortSpec, setSortSpec] = useState({field: "emailAddress", direction: "ASC"})

  const [alert, setAlert] = useState(null)

  const [reloadId, setReloadId] = useState(null)

  const queryClient = useQueryClient()

  const fetchReferralCodes = async ({ pageParam = null }) => {
    return api.listReferralCodes(tenant.tenantId, search, sortSpec, pageParam, 50)
  }

  const {
    status,
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(['referralCodes', tenant, search, sortSpec, reloadId], fetchReferralCodes, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextCursor
    },
    enabled: !!tenant
  })

  const loadMoreButtonRef = React.useRef()

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  })

  function handleSearchClick(v) {
    setSearch(v)
  }

  function handleRowClick(row) {
    let url = ""
    if (tenant && params.tenantId) {
      url = `/${params.tenantId}/refcodes/${row.referralCode}`
    }
    else {
      url = `/refcodes/${row.referralCode}`
    }
    window.location = url
  }

  function handleRefreshClick() {
    setReloadId(Math.random())
    queryClient.invalidateQueries("members")
  }

  return (

    tenant &&
    <>

      <MainContainer tenant={tenant} menu={MainMenu}>

        <FormHeader>
          <AccountTabs tenant={tenant}/>
          <FormHeader.Toolbar>
            <FormHeader.Toolbar.Title>
              {intl.msg("refcodes")}
            </FormHeader.Toolbar.Title>
            <FormHeader.Toolbar.Controls>

              <div className="form-group">
                <SearchInput value={search} onSearch={handleSearchClick}/>
              </div>

              <button className="btn btn-secondary" type="button" title={intl.msg("tooltip_refresh_list")} onClick={handleRefreshClick}>
                <i className="fas fa-redo"></i>
              </button>

              {
                tenant && tenant.accessingAs("ADMIN/*/SYSTEM") &&
                  <a className="btn btn-primary ml-2" href={mkHref({suffix: `/refcodes/_new`, tenant: tenant})}>
                    <i className="fas fa-plus mr-1"></i>
                    {intl.msg("new")}
                  </a>
              }


            </FormHeader.Toolbar.Controls>
          </FormHeader.Toolbar>
          <FormHeader.Alert alert={alert}/>
        </FormHeader>

        <FormBody>

          <div className="row">
            <div className="col">
              <table className="table table-dark table-striped table-bordered table-hover dir">
                <thead>
                <tr>
                  <th scope="col" className="col-clickable">
                    <SortableColumn label={intl.msg("refcode")} field="referralCode" sortSpec={sortSpec} setSortSpec={setSortSpec}/>
                  </th>
                  <th scope="col">
                    {intl.msg("description")}
                  </th>
                  <th scope="col">
                    {intl.msg("created")}
                  </th>
                  <th scope="col">
                    {intl.msg("starts")}
                  </th>
                  <th scope="col">
                    {intl.msg("expires")}
                  </th>
                  <th scope="col">
                    {intl.msg("status")}
                  </th>
                </tr>
                </thead>
                <tbody>
                {
                  status === 'loading' ? (
                    <tr>
                      <td colSpan="3">{intl.msg("loading")}</td>
                    </tr>
                  ) : status === 'error' ? (
                    <tr>
                      <td className="text-danger" colSpan="3">{intl.msg("error_failed")}</td>
                    </tr>
                  ) : data ? (
                    // func(data)
                    data.pages.map((group, i) => (
                      <React.Fragment key={i}>
                        {group.data.map(row => (
                          <tr key={row.userId} onClick={() => handleRowClick(row)}>
                            <td>
                              {row.referralCode}
                            </td>
                            <td>
                              {row.description}
                            </td>
                            <td>
                              <HumanTimestamp timestamp={row.createdOn}/>
                            </td>
                            <td>
                              <HumanTimestamp timestamp={row.startsOn} else={intl.msg("immediately")}/>
                            </td>
                            <td>
                              <HumanTimestamp timestamp={row.expiresOn} else={intl.msg("never")}/>
                            </td>
                            <td>
                              {intl.msg(`status_${row.status.toLowerCase()}`)}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))
                  ) : ""
                }
                </tbody>
              </table>
              <div>
                {
                  hasNextPage ?
                    <button
                      className="btn btn-secondary"
                      ref={loadMoreButtonRef}
                      onClick={() => fetchNextPage()}
                      disabled={!hasNextPage || isFetchingNextPage}>
                      {
                        isFetchingNextPage
                          ? <span>{intl.msg("loading")}</span>
                          : <span>{intl.msg("load_more")}</span>
                      }
                    </button>
                    : ""
                }
              </div>
              <div>
                {/* {isFetching && !isFetchingNextPage ? intl.fm("refreshing") : null} */}
              </div>
            </div>
          </div>
        </FormBody>
      </MainContainer>

    </>
  )
}