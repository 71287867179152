import React, {useState, useEffect, useRef} from "react"
import { useIntlEx } from "./IntlUtils.js"
import useDebounce from "./Debounce";
import getLogger from "./Logging.js"
import styles from "./SearchInput.module.css"
import {Markdown} from "./MarkdownUtils";
import {InfoBlock, InfoText} from "./ValidationUtils";
import {useResizeDetector} from "react-resize-detector";
import {he} from "date-fns/locale";

const log = getLogger("MarkdownTextArea")

export function MarkdownTextArea(props) {

  const {intl} = useIntlEx()
  const { height, ref: refTextarea} = useResizeDetector()
  const refPreview = useRef()

  const markdown = new Markdown({
    homePage: props.tenant?.homePage,
    clickUrls: props.tenant?.clickUrls,
    allowedDomains: props.tenant?.allowedDomains,
    disallowedDomains: props.tenant?.disallowedDomains,
    allowLinks: props.tenant?.allowLinks || true,
  })

  const [value, setValue] = useState(props.value)

  useEffect(() => {
    // log.debug("height=", height, refPreview.current.style.height)
    refPreview.current.style.height = `${height + 14}px`;
  }, [height]);

  function handleValueChange(e) {
    const v = e.target.value
    setValue(v)
    if (props.onChange) {
      props.onChange(e)
    }
  }

  return (
    <div className="d-flex flex-row gap-2">
      <div style={{flex: "1", height: "100%"}}>
        <textarea ref={refTextarea} id={props.id} name={props.name} className="form-control" style={{width: "100%"}} value={value} disabled={props.disabled} onChange={handleValueChange} autoComplete="no" cols={props.cols || "100%"} rows={props.rows || "8"}/>
        {props.children}
      </div>
      <div style={{flex: "1", height: "100%"}}>
        <div ref={refPreview} style={{padding: "10px", border: "1px solid var(--form-control-border-color)", overflow: "scroll"}}>
          {
            value ? (
              <div style={{height: "100%", fontSize: "var(--form-control-font-size)", color: "var(--form-control-text-color)", backgroundColor: "transparent !important"}} dangerouslySetInnerHTML={{__html: markdown.toHtml(value)}}/>
            ) : (
              <div style={{height: "100%", fontSize: "var(--form-control-font-size)", color: "var(--form-control-text-color)", backgroundColor: "transparent !important"}}>
                &nbsp;
              </div>
            )
          }
        </div>
        <InfoBlock>
          <InfoText>{intl.msg("helptext_markdown_preview")}</InfoText>
        </InfoBlock>
      </div>

    </div>
  )

}