 import React, {Fragment, useState, useEffect, useReducer, useRef} from 'react'
import { useParams, useHistory, useLocation, Redirect }  from 'react-router-dom'
import { useMutation } from 'react-query'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {v4 as uuidv4} from 'uuid'
import {useSolv, useTenant} from "./components/SolvProvider"
import MainMenu from "./MainMenu.js"
import {FormHeader, FormBody, MainContainer, RegionLink, useAlert, ExternalLink, FormGroup, FormGroups, ExternalLinkButton, ActionButton, DropdownMenu, DropdownMenuItem, Section, Label, Text} from "./components/FormComps.js"
import TagInput from "./components/TagInput.js"
import {ImageDropBox, uploadImage, processImageFile} from "./components/ImageUtils.js"
import {validateField, showError, clearError, clearAllErrors, validateUrl, InfoBlock, InfoText, validateDomainName} from "./components/ValidationUtils.js"
import {useIntlEx} from "./components/IntlUtils.js"
import {AccountTabs} from "./components/AccountUtils";
import {pathReducer, simpleReducer} from "./components/ReactUtils";
import {openDialog, openDialogCurried} from "./components/DialogUtils.js"
import getLogger from "./components/Logging.js"
import ImageDialog from "./components/ImageDialog";
import {AccountSummaryWidgets} from "./components/Widget";
import {AccountHeader, mkHref, useIndustry, useResellerType} from "./components/AccountUtils"
 import AccountDeleteDialog from "./AccountDeleteDialog"
import ResellerInviteDialog from "./ResellerInviteDialog"
import ClientInviteDialog from "./ClientInviteDialog";
import {BroadcastStatusTagLabel} from "./components/BroadcastComps"
import CreditTransactionTopupDialog from "./CreditTransactionTopupDialog"
import ToggleButton from "./components/ToggleButton"
import {UserLink} from "./components/UserUtils"
import CreditsExtendExpiryDialog from "./CreditsExtendExpiryDialog"
import {AsyncPaginate} from "react-select-async-paginate"
import {mkExtraParams} from "./components/TableComps"
import {default as Select} from "react-select"
import NumericInput from "react-numeric-input"
import {rangeMap} from "./components/CommonUtils"
import ResellerMoveDialog from "./ResellerMoveDialog";
import {shortIdEqual} from "./components/StringUtils";
import {canExtendCredits, CreditsExpiring} from "./components/CreditTransactionUtils";
import CreditsAdjustDialog from "./CreditsAdjustDialog";
import "./App.css"
import styles from "./Account.module.css"

 const log = getLogger("Account")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

const DefaultCPMs = {"SOLV": 5.0, "IMAGE": 5.0, "VIDEO": 10.0}

export default function Account(props) {

  const {api, session, setFatal, setBusy} = useSolv()
  const {intl} = useIntlEx()

  const location = useLocation()
  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useAlert()

  const [editMode, setEditMode] = useState(location.pathname.endsWith("_edit") ? "EDIT" : null)
  const [touched, setTouched] = useState(null)

  const {industryOpts, findIndustryOpt} = useIndustry()
  const {getResellerType, getAvailableResellerTypeOpts, findResellerTypeOpt} = useResellerType()

  const [industry, setIndustry] = useState(findIndustryOpt("PUBLIC_SAFETY"))

  const [modifyTenant, updateModifyTenant] = useReducer(pathReducer, {})
  const [ownerEmailAddress, setOwnerEmailAddress] = useState(null)
  const [clickUrls, setClickUrls] = useState(null)
  const [restrictDomains, setRestrictDomains] = useState(null)
  const [allowedDomains, setAllowedDomains] = useState(null)
  const [disallowedDomains, setDisallowedDomains] = useState(null)
  const [useContactInfo, setUseContactInfo] = useState(null)
  const [overrideAdSystemProps, setOverrideAdSystemProps] = useState(null)
  const [resellerType, setResellerType] = useState(null)
  const [commissions, setCommissions] = useState(null)
  const [commission, setCommission] = useState(null)
  const [ttlCommissions, setTtlCommissions] = useState(null)
  const [cpms, setCpms] = useState(DefaultCPMs)
  const [maxLevels, setMaxLevels] = useState(null)
  const [flags, setFlags] = useState(null)
  const [logoImageInfo, setLogoImageInfo] = useState(null)
  const [recentActivities, setRecentActivities] = useState(null)
  const [amobeeBehaviorSegmentSet, setAmobeeBehaviorSegmentSet] = useState(null)

  const logoImagePickerDialogRef = useRef(null)
  const ownerEmailAddressRef = useRef(null)

  const [clipboardOpsAllowed, setClipboardOpsAllowed] = useState(null)

  const [availableResellerTypeOpts, setAvailableResellerTypeOpts] = useState(null)

  useEffect(() => {
    if (tenant) {
      log.debug("useEffect: tenant=", tenant)
      if ("ONBOARDING" === tenant.status) {
        setAlert({attention: intl.msg("account_onboarding_notice")})
        setEditMode("EDIT")
      }
      updateModifyTenant({path: ".", value: tenant})
      setClickUrls(tenant.clickUrls)
      const _allowedDomains = tenant.allowedDomains?.length > 0 ? tenant.allowedDomains.map(d => {
        return {label: d.trim(), value: d.trim()}
      }) : null
      setAllowedDomains(_allowedDomains)
      const _disallowedDomains = tenant.disallowedDomains?.length > 0 ? tenant.disallowedDomains.map(d => {
        return {label: d.trim(), value: d.trim()}
      }) : null
      setDisallowedDomains(_disallowedDomains)
      setRestrictDomains(_allowedDomains?.length > 0 || _disallowedDomains?.length > 0)
      setAvailableResellerTypeOpts(getAvailableResellerTypeOpts(tenant))
      setResellerType(findResellerTypeOpt(getResellerType(tenant)))
      setCommissions(tenant.commissions)
      setMaxLevels(tenant.maxDepth + 1)
      setIndustry(tenant.industryId)
      setFlags(tenant.flags?.map(d => {
        return {label: d.trim(), value: d.trim()}
      }))
      setUseContactInfo(["null", "undefined"].includes(typeof(tenant.billingInfo?.name)))
      setOverrideAdSystemProps(!!tenant.props)
      if (tenant.props && tenant.props.adSystem && tenant.props.adSystem.AMOBEE) {
        if (tenant.props.adSystem.AMOBEE.cpms) {
          log.debug(">>>Z: tenant.props.adSystem.AMOBEE.cpms=", tenant.props.adSystem.AMOBEE.cpms)
          setCpms(tenant.props.adSystem.AMOBEE.cpms)
        }
        if (tenant.props.adSystem.AMOBEE.behavioralSegmentSetId) {
          api.getAmobeeBehaviorSegmentSet(tenant.props.adSystem.AMOBEE.behavioralSegmentSetId)
            .then(({data}) => {
              log.debug("getAmobeeBehaviorSegmentSet: ", data)
              setAmobeeBehaviorSegmentSet({
                value: data.behaviorSegmentSetId,
                label: data.behaviorSegmentSetName,
                popDampFactor: data.popDampFactor,
              })
            })
            .catch((error) => {
              log.debug("Error", error)
            })
        }
      }
      api.getTenantRecentActivities(tenant.tenantId)
        .then(({data}) => {
          if (data.broadcasts || data.responses) {
            setRecentActivities(data)
          }
          else {
            setRecentActivities(null)
          }
          log.debug("tenantRecentActivities: data=", data)
        })
        .catch((error) => {
          log.debug("Error", error)
        })

      if (tenant.industryId) {
        api.getIndustry(tenant.industryId)
          .then(({data}) => {
            log.debug("getIndustry: data=", data)
            setIndustry({
              value: data.industryId,
              label: data.industryName._,
              description: data.industryDescription._,
            })
          })
          .catch((error) => {
            log.error("getIndustry: error=", error)
          })
      }

      if (CreditsExpiring(tenant.credits)) {
        setAlert({warning: intl.msg("credits_alert_expiring", {accessType: tenant.accessInfo?.accessType, canExtend: canExtendCredits(tenant, session.user).toString(), expireDays: intl.fmDaysFrom(tenant.credits.expiresOn), a: chunk => <a className="text-link" href="#" onClick={openDialogCurried("dlg_credits_extend_expiry")}>{chunk}</a>})})
      }

    }
  }, [tenant])

  useEffect(() => {
    setTtlCommissions(commissions ? commissions.slice(0, maxLevels).reduce((acc, cur) => cur ? acc + cur : acc) : 0.0)
  }, [commissions, maxLevels])

  async function handleOwnerEmailAddressChange(e) {
    setOwnerEmailAddress(e.target.value)
    setTouched(true)
  }

  async function handleTenantNameChange(e) {
    updateModifyTenant({path: "tenantName", value: e.target.value})
    setTouched(true)
  }

  async function handleResellerTypeChange(v) {
    setResellerType(v)
    setMaxLevels((prev) => (prev && prev) < 2 ? 2 : prev)
    setTouched(true)
  }

  function handleMaxLevelsChange(v) {
    setMaxLevels(v)
    setTouched(true)
  }

  function handleCommissionsChange(i, v) {
    setCommissions(prev => {
      if (i >= prev.length - 1) {
        const padLength = Math.max(0, i - prev.length + 1);
        prev = prev.concat(new Array(padLength).fill(0.00))
      }
      prev.splice(i, 1, v);
      return [...prev]
    })
  }

  async function handleIndustryChange(v) {
    setIndustry(v)
    setTouched(true)
  }

  async function handleDisplayNameChange(e) {
    updateModifyTenant({path: "displayName", value: e.target.value})
    setTouched(true)
  }

  function setLogoUrl(v) {
    updateModifyTenant({path: "logoUrl", value: v})
  }

  async function handleLogoChange(e) {

    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let result

      setBusy(intl.msg("processing"))

      try {
        result = await processImageFile({
          file: file,
          maxFileSize: 100_000,
          width: 100,
          height: 100,
        })
      }
      catch (ex) {
        setAlert({error: "error_image_process_failed"})
      }
      finally {
        setBusy(null)
      }

      if (result.warning || result.error) {
        setLogoImageInfo(result)
        const el = document.getElementById("inp_dlg_logoImagePicker")
        el.click()
      }
      else {
        setLogoUrl(result.dataUrl)
        setTouched(true)
      }

      clearError("inp_logoUrl")
    }
  }

  function handleLogoClear() {
    setLogoUrl(null)
    setTouched(true)
  }

  function handleHomePageChange(e) {
    const value = e.target.value
    updateModifyTenant({path: "homePage", value: value})
    setTouched(true)
  }

  function handleHomePageBlur() {
    if (modifyTenant.homePage && modifyTenant.homePage.trim().length > 0) {
      try {
        if (modifyTenant.homePage.startsWith("http://")) {
          updateModifyTenant({path: "homePage", value: `https://${modifyTenant.homePage.substring(7)}`})
        }
        else if (!modifyTenant.homePage.startsWith("https://")) {
          updateModifyTenant({path: "homePage", value: `https://${modifyTenant.homePage}`})
        }
      }
      catch (e) {
      }
    }
  }

  function handleContactInfoChange(e) {
    updateModifyTenant({path: "contactInfo", value: e.target.value})
    setTouched(true)
  }

  function handleUseContactInfoChange(v) {
    setUseContactInfo(v)
    if (v) {
      updateModifyTenant({path: "billingInfo", value: null})
    }
    // else {
    //   if (!["undefined", "null"].includes(modifyTenant.billingInfo)) {
    //     updateModifyTenant({path: "billingInfo", value: {
    //       name: modifyTenant.displayName,
    //       address: modifyTenant.contactInfo,
    //     }})
    //   }
    // }
  }

  function handleBillingNameChange(e) {
    updateModifyTenant({path: "billingInfo.name", value: e.target.value})
    setTouched(true)
  }

  function handleBillingAddressChange(e) {
    updateModifyTenant({path: "billingInfo.address", value: e.target.value})
    setTouched(true)
  }

  function loadFlags(search, loadedOptions, additional) {

    log.debug("loadFlags:", search, loadedOptions, additional)

    return api.listFlags({extraParams: mkExtraParams({filterParams:{flagId: search}}, {sortParams: {flagId: "ASC"}}), cursor: additional?.cursor})
      .then(({data, nextCursor}) => {
        log.debug("loadFlags: data=", data)
          let opt = data.map(c => {
            return {
              value: c.flagId,
              label: c.flagId,
            };
          });
          let res = {
            options: opt,
          }
          if (nextCursor) {
            res = {
              ...res,
              hasMore: true,
              additional: {
                cursor: nextCursor
              }
            }
          }
          log.debug("loadFlags: res=", res)
          return res
        });
  }

  function loadAmobeeBehaviorSegmentSets(search, loadedOptions, additional) {

    log.debug("loadAmobeeBehaviorSegmentSets:", search, loadedOptions, additional)

    return api.listAmobeeBehaviorSegmentSets({extraParams: mkExtraParams({filterParams:{behaviorSegmentSetName: search}}, {sortParams: {behaviorSegmentSetName: "ASC"}}), cursor: additional?.cursor})
      .then(({data, nextCursor}) => {
        log.debug("loadAmobeeBehaviorSegmentSets: data=", data)
        let opt = data.map(c => {
          return {
            value: c.behaviorSegmentSetId,
            label: c.behaviorSegmentSetName,
            popDampFactor: c.popDampFactor,
          };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug("loadAmobeeBehaviorSegmentSets: res=", res)
        return res
      });
  }


  function handleClickUrlNameChange(e, index) {
    e.persist()
    setClickUrls(prev => {
      return ([
        ...prev.slice(0, index),
        {...prev[index], name: e.target.value},
        ...prev.slice(index + 1),
      ])
    })
    setTouched(true)
  }

  function handleClickUrlUrlChange(e, index) {
    setClickUrls(prev => {
      return ([
        ...prev.slice(0, index),
        {...prev[index], url: e.target.value.trim()},
        ...prev.slice(index + 1),
      ])
    })
    setTouched(true)
  }

  function handleClickUrlDelete(e, index) {
    log.debug("handleClickUrlDelete: ", index)
    setClickUrls(prev => {
      return ([
        ...prev.slice(0, index),
        ...prev.slice(index + 1),
      ])
    })
    setTouched(true)
  }

  // function handleClickUrlUrlBlur(e, index) {
  //   let clickUrl = clickUrls[index]
  //   let url = clickUrl?.url?.trim()
  //   log.debug("handleClickUrlUrlBlur: url1=", url)
  //   if (url?.length > 0) {
  //     try {
  //       if (url.startsWith("http://")) {
  //         url = `https://${url.substring(7)}`
  //       }
  //       else if (!url.startsWith("https://")) {
  //         url = `https://${url}`
  //       }
  //       log.debug("handleClickUrlUrlBlur: url2=", url)
  //       setClickUrls(prev => {
  //         return ([
  //           ...prev.slice(0, index),
  //           {...prev[index], url: url},
  //           ...prev.slice(index + 1),
  //         ])
  //       })
  //     }
  //     catch (e) {
  //     }
  //   }
  // }


  function handleAddClickUrlClick() {
    setClickUrls(prev => {
      if (prev?.length > 0) {
        return ([...prev, {id: "PREDEF_" + uuidv4().replace("-",''), name: "", url: null}])
      }
      else {
        return ([{id: "PREDEF_" + uuidv4().replace("-",''), name: "", url: null}])
      }

    })
    setTouched(true)
  }

  function handleFlagsChange(v) {
    setFlags(v)
    setTouched(true)
  }

  function handleAllowedDomainsChange(v) {
    log.debug("handleAllowedDomainsChange: ", v)
    try {
      // const u = new URL(v)
      setAllowedDomains(v)
      setTouched(true)
    }
    catch (e) {
      log.debug("Invalid domain name=", e)
    }
  }

  function handleDisallowedDomainsChange(v) {
    log.debug("handleDisallowedDomainsChange: ", v)
    try {
      // const u = new URL(v)
      setDisallowedDomains(v)
      setTouched(true)
    }
    catch (e) {
      log.debug("Invalid domain name=", e)
    }
  }

  function handleRestrictDomainsChange(v) {
    setRestrictDomains(v)
    if (!v) {
      setAllowedDomains(null)
      setDisallowedDomains(null)
    }
    setTouched(true)
  }

  async function handleCopyClick(signUpLink) {
    try {
      const res = await navigator.clipboard.writeText(signUpLink)
    }
    catch (e) {
      setClipboardOpsAllowed(false)
    }
  }

  function handleOverrideAdSystemPropsChange(v) {
    setOverrideAdSystemProps(v)
    setTouched(true)
  }

  function handleCpmsChange(cpmType, v) {
    setCpms(prev => {return {...prev, [cpmType]: v}})
    setTouched(true)
  }

  function handleAmobeeBehaviorSegmentSetChange(v) {
    setAmobeeBehaviorSegmentSet(v)
    setTouched(true)
  }

  useEffect(() => {
    log.debug("cpms=", cpms)
  }, [cpms])

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_tenantName", modifyTenant.tenantName && modifyTenant.tenantName.trim().length > 0, "required", opt)

    if (modifyTenant.homePage) {
      validateField("inp_homePage", validateUrl(modifyTenant.homePage), "valid", opt)
    }

    if (clickUrls?.length > 0) {
      for (let i = 0; i < clickUrls.length; i++) {
        const clickUrl = clickUrls[i]
        if (!clickUrl || !clickUrl.id || !clickUrl.name || clickUrl.name.trim().length === 0 || !clickUrl.url || clickUrl.url.trim().length === 0 || !validateUrl(clickUrl.url)) {
          validateField(`#inp_clickUrls_name_${i}`, false, "valid", opt)
          break
        }
      }
    }

    if (restrictDomains) {
      if ((!allowedDomains?.length > 0) && (!disallowedDomains?.length > 0)) {
        validateField("inp_restrictDomains",  false, "valid", opt)
      }
      if (allowedDomains?.length > 0) {
        validateField("inp_allowedDomains",  allowedDomains.map(v => v.value ? v.value.trim() : "").every(v => validateDomainName(v)), "valid", opt)
      }
      if (disallowedDomains?.length > 0) {
        validateField("inp_disallowedDomains",  disallowedDomains.map(v => v.value ? v.value.trim() : "").every(v => validateDomainName(v)), "valid", opt)
      }
    }

    if (tenant.isReseller() && session.user.isSystem()) {

      if (["MASTER"].includes(resellerType?.value)) {
        validateField("inp_maxLevels", parseInt(maxLevels) > 1 && parseInt(maxLevels) <= 4, "valid", opt)
      }

      if (!commissions || commissions.length === 0) {
        validateField("inp_commissions", false, "required", opt)
      }
      else {
        if (["STANDALONE", "SUB"].includes(resellerType?.value)) {

          const cs = parseFloat(commissions[0])
          validateField("inp_commission", !isNaN(cs) && cs > 0.00 && cs <= 20.00, "valid", opt)

        }
        else {

          if (maxLevels > commissions.length) {
            validateField("inp_commission_0", false, "valid", opt)
          }

          const cs = commissions.slice(0, maxLevels)

          if (cs.filter(c => isNaN(parseFloat(c))).length > 0) {
            validateField("inp_commission_0", false, "valid", opt)
          }
          else {
            const ttl = cs.map(c => parseFloat(c)).reduce((acc, curr) => acc + curr)
            log.debug("validate: ttl=", ttl)
            if (isNaN(ttl) || ttl <= 0.00 || ttl > 20.00) {
              validateField("inp_commission_0", false, "valid", opt)
            }
          }
        }
      }

    }

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    log.debug("validate: opt=", opt)

    return opt.valid
  }

  const saveMutation = useMutation(payload => api.updateTenant(modifyTenant.tenantId, payload))

  async function handleEditClick() {
    // const ctls = document.getElementsByClassName("form-control")
    // if (ctls && ctls.length > 0 && ctls[0]) {
    //   log.debug("handleEditClick: frm=", ctls[0])
    //   ctls[0].focus()
    // }
    // setEditMode("EDIT")
    window.location = mkHref({suffix: "/_edit", user: session.user, tenant: tenant})
  }

  async function handleSaveClick() {

    setBusy(intl.msg("saving"))

    try {

      if (validate()) {

        let finalLogoUrl = modifyTenant.logoUrl

        if (finalLogoUrl && finalLogoUrl.startsWith("data:")) {
          finalLogoUrl = await uploadImage({
            api: api,
            imageData: finalLogoUrl,
            imagePath: `t/${modifyTenant.tenantId}/logo`,
            setImageUrl: setLogoUrl,
          })
        }

        const payload = {
          brandId: modifyTenant.brandId,
          tenantName: modifyTenant.tenantName,
          displayName: modifyTenant.displayName,
          homePage: modifyTenant.homePage,
          contactInfo: modifyTenant.contactInfo,
          billingInfo: modifyTenant.billingInfo,
          logoUrl: finalLogoUrl,
          clickUrls: null,
          // clickUrls: clickUrls,
          allowedDomains: allowedDomains && allowedDomains.length > 0 ? allowedDomains.map(d => d.value.trim()) : [],
          dissallowedDomains: disallowedDomains && disallowedDomains.length > 0 ? disallowedDomains.map(d => d.value.trim()) : [],
          flags: flags && flags.length > 0 ? flags.map(d => d.value.trim()) : null,
        }

        if (tenant.isReseller()) {
          payload.resellerType = resellerType.value
          if (["STANDALONE", "SUB"].includes(payload.resellerType)) {
            payload.commissions = commissions.slice(0, 1)
          }
          else {
            payload.commissions = commissions
          }
          payload.maxDepth = maxLevels - 1
          payload.industryId = industry.value
        }
        else if (tenant.isClient()) {
          payload.industryId = industry.value
          payload.maxDepth = 0
        }
        else {
          payload.maxDepth = 0
        }

        if (session.user?.isSystem()) {
          if (overrideAdSystemProps) {
            payload.props = {"adSystem": {"AMOBEE": {"cpms": cpms, "behavioralSegmentSetId": amobeeBehaviorSegmentSet?.value || null}}}
          }
          else {
            payload.props = {}
          }
        }

        const res = await saveMutation.mutateAsync(payload)

        window.location = mkHref({suffix: "/", user: session.user, tenant: tenant})

      }
      else {
        log.debug("Validation error")
        setAlert({error: intl.msg("error_invalid_form")})
        setTouched(true)
      }
    }
    catch (ex) {
      log.debug("Save tenant failed: ex=", ex)
      handleError(ex)
    }
    finally {
      setBusy(null)
    }
  }

  function prepareObject(target, defaults) {
    for (const key in defaults) {
      if (defaults.hasOwnProperty(key)) {
        if (typeof defaults[key] === 'object' && !Array.isArray(defaults[key])) {
          // If the value is an object (but not an array), recurse into it
          target[key] = prepareObject(target[key] || {}, defaults[key]);
        }
        else {
          // Otherwise, update the value
          target[key] = target[key] !== undefined ? target[key] : defaults[key];
        }
      }
    }
    return target;
  }

  function handleCancelClick() {
    window.location = mkHref({suffix: "/", user: session.user, tenant: tenant})
  }

  function handleReinviteClick() {
    if (tenant) {
      openDialog(`dlg_${tenant.tenantTypeId?.toLowerCase()}_invite`)
    }
  }

  function getErrorMsg(error) {
    let res = null
    if (error && error.code) {
      log.debug("getErrorMsg: error=", error)
      const code = error.code
      res = {
        code: code
      }
      switch (code) {
        case "DUPLICATE_KEY":
        case "DUPLICATE_TENANT":
          res.msg = intl.msg("error_invalid_form", {tenantName: error.tenantName})
          res.field = "inp_tenantName"
          res.constraint = "valid"
          break
        default:
          res.msg = intl.msg("error_failed", {emailAddress: error.emailAddress})
          break
      }
    }
    else {
      return intl.msg("error_failed")
    }
    return res
  }

  function handleError(error) {
    const msg = getErrorMsg(error)
    if (msg) {
      setAlert({error: msg.msg})
      if (msg.field) {
        showError(msg.field, msg.constraint)
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function ClientQuickAccess(props) {
    return (
      <ul className={styles["plain-list"]}>
        <li>
          <a className="text-link" href={mkHref({
            suffix: "/broadcasts/_new",
            tenant: tenant
          })}>{intl.msg("home_quick_access_create_a_broadcast")}</a>
        </li>
        {
          tenant.accessingAs(["ADMIN/MEMBER/*"]) ? (
            <li>
              <a className="text-link" href="#"
                 onClick={openDialogCurried("dlg_credits_add")}>{intl.msg("home_quick_access_add_credits")}</a>
            </li>
          ) : tenant.accessingAs(["*/MANAGER/SYSTEM"]) ? (
            <li>
              <a className="text-link" href="#"
                 onClick={openDialogCurried("dlg_credits_adjust")}>{intl.msg("home_quick_access_adjust_credits")}</a>
            </li>

          ) : (
            <></>
          )
        }
      </ul>
    )
  }

  function ResellerQuickAccess(props) {
    return (
      <ul className={styles["plain-list"]}>
        {
          tenant.accessingAs(["BASIC/MEMBER/RESELLER", "ADMIN/MANAGER/*"]) &&
          <li>
            <a className="text-link" href="#" data-toggle="modal" onClick={openDialogCurried("dlg_client_invite")}>{intl.msg("home_quick_access_invite_a_client")}</a>
            </li>
        }
        {
          tenant.accessingAs(["ADMIN/MANAGER/RESELLER", "ADMIN/*/SYSTEM"]) &&
          // (tenant?.maxDepth ? tenant?.maxDepth > (tenant?.parentPath ? (tenant?.parentPath.split(".").length - 1) : 0) : false) &&
          // (tenant.accessingAs("ADMIN/MANAGER/RESELLER")) &&
            <li>
              <a className="text-link" href="#" onClick={openDialogCurried("dlg_reseller_invite")}>{intl.msg("home_quick_access_invite_a_reseller")}</a>
            </li>
        }
        {/*<li>*/}
        {/*  <a className="text-link" href={mkHref({suffix: "/broadcasts/_new", tenant: tenant})}>{intl.msg("home_quick_access_create_a_broadcast")}</a>*/}
        {/*</li>*/}
        {
          <li>
            {/*{tenant && <ContactSupportLink user={session.user} tenant={tenant}/>}*/}
          </li>
        }
      </ul>
    )
  }

  function SystemQuickAccess(props) {
    return (
      <ul className={styles["plain-list"]}>
        <li>
          <a className="text-link" href="#" data-toggle="modal" onClick={openDialogCurried("dlg_client_invite")}>{intl.msg("home_quick_access_invite_a_client")}</a>
        </li>
        <li>
          <a className="text-link" href={mkHref({suffix: `/resellers/_new`, tenant: tenant, user: session.user})}>{intl.msg("home_quick_access_invite_a_reseller")}</a>
          {/*<a className="text-link" href="#" onClick={openDialogCurried("dlg_reseller_invite")}>{intl.msg("home_quick_access_invite_a_reseller")}</a>*/}
        </li>
      </ul>
    )
  }

  function QuickAccess(props) {
    log.debug("QuickAccess: tenant=", tenant)
    return (
      tenant.isClient() ? (
        ClientQuickAccess(props)
      ) : tenant.isReseller() ? (
        ResellerQuickAccess(props)
      ) : tenant.isSystem() ? (
        SystemQuickAccess(props)
      ) : (
        <></>
      )
    )
  }

  function Recent(props) {
    log.debug("Recent: recentActivities=", recentActivities)
    return (
      recentActivities?.broadcasts ? (
        <>
          <div className="mb-2">
            {intl.msg("broadcasts")}:
          </div>
          <ul className={styles["bullet-list"]}>
            {
              recentActivities.broadcasts?.map(function(broadcast) {
                return (
                  <li>
                    <div className="d-flex" style={{gap: "4px"}}>
                      <a className="text-link" href={`/${tenant.tenantId}/broadcasts/${broadcast.broadcastId}`}>{broadcast.broadcastName}</a>
                      <BroadcastStatusTagLabel broadcast={broadcast} tenant={tenant}/>
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </>
      ) : (
        <></>
      )
    )
  }

  function canReinvite() {
    if (!touched && tenant) {
      if (["INVITING", "ONBOARDING"].includes(tenant.status)) {
        if (tenant.createdBy === session.user?.userId) {
          return true
        }
        else if (tenant.accessingAs("ADMIN/*/*")) {
          return true
        }
      }
    }
    return false
  }

  function canEdit() {
    if (tenant) {
      if (["INVITING"].includes(tenant.status) && shortIdEqual(tenant?.createdById, session.user?.userId)) {
        return true
      }
      return tenant.accessingAs("ADMIN/*/*") && !["DELETED", "BANNED"].includes(tenant.status)
    }
    return false
  }

  function canMove() {
    if (!tenant) {
      return false
    }
    if (["DELETED", "BANNED"].includes(tenant.status)) {
      return false
    }
    if (!tenant.isReseller) {
      return false
    }
    if (!tenant.accessingAs(["SUPERADMIN/MANAGER/*"])) {
      return false
    }
    if (!(tenant.hasSubTenants() || session.user.isSystem())) {
      return false
    }
    return true
  }

  function canDelete() {
    if (tenant) {
      if (["INVITING", "ONBOARDING"].includes(tenant.status)) {
        if (tenant.accessingAs("ADMIN/*/*")) {
          return true
        }
      }
      else if (tenant.tenantId !== session.user.tenantId && tenant.accessingAs("SUPERADMIN/MANAGER/*")) {
        return true
      }
    }
    return false
  }

  function isEditing() {
    return ["ADD", "EDIT"].includes(editMode)
  }

  function isAdding() {
    return "ADD" === editMode
  }

  function MoreMenu(props) {
    const comps = []
    if (tenant && !touched) {
      if (canMove()) {
        if (comps.length > 0) {
          comps.push(<div className="dropdown-divider"></div>)
        }
        comps.push(
          <DropdownMenuItem name="move" onClick={() => openDialog(`dlg_${tenant.tenantTypeId.toLowerCase()}_move`)}/>
        )
      }
      if (canDelete()) {
        if (comps.length > 0) {
          comps.push(<div className="dropdown-divider"></div>)
        }
        comps.push(
          <DropdownMenuItem name="delete"  onClick={() => openDialog("dlg_account_delete")}/>
        )
      }
    }
    return (
      <>
        {
          comps.length > 0 &&
            <DropdownMenu>
              {comps}
            </DropdownMenu>
        }
      </>
    )
  }

  function AccountInfoSection(props) {
    return (
      <Section title={intl.msg("account_info")}>

        <div className="d-table p-2">

          <div className="d-table-row">
            <div className="d-table-cell"></div>
            <div className="d-table-cell talign-right">
              <Label msg="account_name"/>
            </div>
            <div className="d-table-cell p-2">
              <Text text={modifyTenant.tenantName}/>
            </div>
          </div>

          {
            !tenant.accessingAs("*/MEMBER/*") &&
              <div className="d-table-row">
                <div className="d-table-cell pt-2">&nbsp;</div>
                <div className="d-table-cell talign-right">
                  <Label msg="account_type"/>
                </div>
                <div className="d-table-cell p-2">
                  <Text msg={`account_type_${tenant.tenantTypeId.toLowerCase()}`}/>
                </div>
              </div>
          }

          {
            tenant.tenantTypeId === "CLIENT" &&
              <div className="d-table-row">
                <div className="d-table-cell pt-2">&nbsp;</div>
                <div className="d-table-cell talign-right">
                  <Label msg="account_plan"/>
                </div>
                <div className="d-table-cell p-2">
                  <Text msg={`account_plan_solv_${modifyTenant.tenantPlanId}`}/>
                </div>
              </div>
          }

          {
            tenant.tenantTypeId === "RESELLER" &&
              <div className="d-table-row">
                <div className="d-table-cell pt-2">&nbsp;</div>
                <div className="d-table-cell talign-right">
                  <Label msg="reseller_type"/>
                </div>
                <div className="d-table-cell p-2">
                  <Text msg={`reseller_type_${getResellerType(tenant).toLowerCase()}`}/>
                </div>
              </div>
          }

          {
            tenant.reseller &&
              <div className="d-table-row">
                <div className="d-table-cell pt-2">&nbsp;</div>
                <div className="d-table-cell talign-right">
                  <Label msg={tenant.isReseller() ? "account_reseller_reseller" : "account_client_reseller"}/>
                </div>
                <div className="d-table-cell p-2">
                  <a className="text-link" href={`/${tenant.reseller.tenantId}`}>{tenant.reseller.tenantName}</a>
                </div>
              </div>
          }

          {
            tenant && tenant.isReseller() && tenant.accessingAs("ADMIN/*/SYSTEM") &&
              <div className="d-table-row">
                <div className="d-table-cell pt-2">&nbsp;</div>
                <div className="d-table-cell talign-right">
                  <Label text={(resellerType?.value === "MASTER" ? intl.msg("reseller_commissions") : intl.msg("reseller_commission"))}/>
                </div>
                <div className="d-table-cell p-2">
                  {/*<Text>*/}
                  {/*  {*/}
                  {/*    !["MASTER"].includes(resellerType?.value) ? (*/}
                  {/*      <span>{tenant.commissions[0].toFixed(2)}%</span>*/}
                  {/*    ) : (*/}
                  {/*      tenant.commissions?.map((c, i) => {*/}
                  {/*        return (*/}
                  {/*          i === 0 ? (*/}
                  {/*            <>*/}
                  {/*              <span className="text-muted pr-1">{intl.msg("reseller_type_master_self")}:</span>*/}
                  {/*              <span className="">{c.toFixed(2)}%</span>*/}
                  {/*            </>*/}
                  {/*          ) : tenant.maxDepth > 1 ? (*/}
                  {/*            <>*/}
                  {/*              <span className="text-muted pr-1">, {intl.msg("reseller_type_sub_l", {subLevel: i})}:</span>*/}
                  {/*              <span className="">{c}%</span>*/}
                  {/*            </>*/}
                  {/*          ) : (*/}
                  {/*            <>*/}
                  {/*              <span className="text-muted pr-1">, {intl.msg("reseller_type_sub", {subLevel: i})}:</span>*/}
                  {/*              <span className="">{c.toFixed(2)}%</span>*/}
                  {/*            </>*/}
                  {/*          )*/}
                  {/*        )*/}
                  {/*      })*/}
                  {/*    )*/}
                  {/*  }*/}
                  {/*</Text>*/}
                </div>
              </div>
          }

          {
            tenant?.tenantTypeId != "SYSTEM" &&
              <div className="d-table-row">
                <div className="d-table-cell pt-2">&nbsp;</div>
                <div className="d-table-cell talign-right text-muted">
                  <Label msg="industry"/>
                </div>
                <div className="d-table-cell p-2">
                  <Text text={industry?.label}/>
                </div>
              </div>
          }

          {
            tenant.owner &&
              <div className="d-table-row">
                <div className="d-table-cell pt-2">&nbsp;</div>
                <div className="d-table-cell talign-right text-muted align-top pt-2">
                  <Label msg="owner"/>
                </div>
                <div className="d-table-cell pt-2 p-2">
                  <UserLink user={tenant.owner} tenant={tenant} session={session}/>
                </div>
              </div>
          }

          <div className="d-table-row">
            <div className="d-table-cell pt-2">&nbsp;</div>
            <div className="d-table-cell talign-right text-muted">
              <Label msg="region"/>
            </div>
            <div className="d-table-cell p-2">
              <RegionLink session={session} region={modifyTenant.region}/>
            </div>
          </div>

          <div className="d-table-row">
            <div className="d-table-cell pt-2">&nbsp;</div>
            <div className="d-table-cell talign-right text-muted">
              <Label msg="currency"/>
            </div>
            <div className="d-table-cell p-2">
              {tenant.region.currencyCode}
            </div>
          </div>

          {
            tenant.logoUrl &&
              <div className="d-table-row">
                <div className="d-table-cell pt-2">&nbsp;</div>
                <div className="d-table-cell talign-right text-muted align-top pt-2">
                  <Label msg="account_logo"/>
                </div>
                <div className="d-table-cell pt-2 p-2">
                  <div  style={{width: "100px", height: "100px", overflow: "hidden", position: "relative"}}>
                    <img src={tenant.logoUrl} style={{width: "100%", height: "100%", objectFit: "contain", position: "absolute", top: "0", left: "0"}}/>
                  </div>
                </div>
              </div>
          }

          {
            tenant.contactInfo &&
              <div className="d-table-row">
                <div className="d-table-cell pt-2">&nbsp;</div>
                <div className="d-table-cell talign-right text-muted align-top pt-2">
                  <Label msg="account_contact_info"/>
                </div>
                <div className="d-table-cell pt-2 p-2">
                  <div style={{whiteSpace: "pre-wrap"}}>{modifyTenant.contactInfo}</div>
                </div>
              </div>
          }

          {
            tenant.homePage &&
              <div className="d-table-row">
                <div className="d-table-cell pt-2">&nbsp;</div>
                <div className="d-table-cell talign-right text-muted align-top pt-2">
                  <Label msg="account_home_page"/>
                </div>
                <div className="d-table-cell pt-2 p-2">
                  <ExternalLink href={modifyTenant.homePage} target="_blank">{modifyTenant.homePage}</ExternalLink>
                </div>
              </div>
          }

          {
            tenant && tenant.accessingAs("*/*/SYSTEM") &&
              <div className="d-table-row">
                <div className="d-table-cell pt-2">&nbsp;</div>
                <div className="d-table-cell talign-right text-muted">
                  <Label msg="flags"/>
                </div>
                <div className="d-table-cell p-2">
                  {tenant.flags?.join(", ")}
                </div>
              </div>
          }

          {
            session.user?.isSystem() &&
              <div className="d-table-row ">
                <div className="d-table-cell pt-2">&nbsp;</div>
                <div className="d-table-cell talign-right text-muted">
                  <Label msg="props"/>
                </div>
                <div className="d-table-cell p-2 w-100" style={{verticalAlign: "top"}}>
                  <div className="form-group" style={{backgroundColor: "transparent", border: "none", padding: "0"}}>
                    {
                      tenant.props &&
                        <textarea rows="10" style={{backgroundColor: "transparent", width: "100%", overflow: "scroll", whiteSpace: "pre"}} resize="true" disabled="true" value={JSON.stringify(tenant.props, null, 2)}/>
                    }
                  </div>
                </div>
              </div>
          }

        </div>
      </Section>
    )
  }

  function QuickAccessSection(props) {
    if (!["INVITING", "ONBOARDING"].includes(tenant.status)) {
      return (
        <Section title={intl.msg("quick_access")}>
          <div>
            <QuickAccess/>
          </div>
        </Section>
      )
    }
    else {
      return <></>
    }
  }

  function LearnSection(props) {
    if (!["INVITING", "ONBOARDING"].includes(tenant.status)) {
      return (
        <Section title={intl.msg("learn")}>
          <ul className={styles["plain-list"]}>
            <li>
              <a className="text-link" href="/whatsnew">What's New</a>
            </li>
            <li>
              <a className="text-link" href="https://help.solv.technology/category/using-solv" target="_blank">{intl.msg("hc_using_solv")}</a>
            </li>
            <li>
              <a className="text-link" href="https://help.solv.technology/category/faq" target="_blank">{intl.msg("hc_faq")}</a>
            </li>
            <li>
              <a className="text-link" href="https://help.solv.technology" target="_blank">Help Center</a>
            </li>
          </ul>
        </Section>
      )
    }
    else {
      return <></>
    }
  }

  function RecentSection(props) {
    if (!["INVITING", "ONBOARDING"].includes(tenant.status)) {
      return (
        <>
          <div className="section-divider">
            {intl.msg("learn")}
          </div>
          <div>
            <Recent/>
          </div>
        </>
      )
    }
    else {
      return <></>
    }
  }

  function mkSignupLink(userId) {
    return `https://console.solv.technology/signup/${userId.replace(/-/g,"")}`
  }

  // function InvitingSection(props) {
  //   log.debug("InvitingSection: ", tenant)
  //   return (
  //     <>
  //       <div className="section-divider">
  //         {intl.msg("invitation")}
  //       </div>
  //       <div>
  //         <div className="d-table">
  //
  //           <div className="d-table-row">
  //             <div className="d-table-cell"></div>
  //             <div className="d-table-cell talign-right text-muted">
  //               {intl.msg("created")}:
  //             </div>
  //             <div className="d-table-cell p-2">
  //               <UserAndTimeLink tenant={tenant} session={session} user={tenant.owner} timestamp={tenant.owner.createdOn}/>
  //             </div>
  //           </div>
  //
  //           <div className="d-table-row">
  //             <div className="d-table-cell pt-2"></div>
  //             <div className="d-table-cell talign-right text-muted pt-2">
  //               {intl.msg("signup_link")}:
  //             </div>
  //             <div className="d-table-cell p-2 d-flex flex-wrap">
  //               <div>{mkSignupLink(tenant.owner.userId)}</div>
  //               <button id="btn-clipboard" type="button" className="btn btn-secondary ml-2" style={{width: "20px", height: "22px", fontSize: "10pt !important", margin: "0", padding: "0 4px 0 4px"}} title={intl.msg("tooltip_copy_to_clipboard")} onClick={() => handleCopyClick(mkSignupLink(tenant.owner.userId))}>
  //                 <i className="fas fa-paste mr-2"></i>
  //               </button>
  //             </div>
  //           </div>
  //
  //         </div>
  //       </div>
  //     </>
  //   )
  // }

  function Greeting() {
    const curHr = (new Date()).getHours()
    const userLink = (chunk) => <a className="text-link" href={mkHref({suffix: `/members/${session.user.userId}`, tenant: tenant})}>{chunk}</a>
    return (
      tenant.accessingAs().accessType === "MEMBER" ? (
        <div className="greeting">
          {
            curHr < 12 ? (
              <span>{intl.msg("greeting_morning", {member: session.user.fullName, userLink: userLink})}</span>
            ) : curHr < 18 ? (
              <span>{intl.msg("greeting_afternoon", {member: session.user.fullName, userLink: userLink})}</span>
            ) : (
              <span>{intl.msg("greeting_evening", {member: session.user.fullName, userLink: userLink})}</span>
            )
          }
      </div>
      ) : (
        <></>
      )
    )
  }

  return (
    tenant &&
      <>

        <MainContainer tenant={tenant} menu={MainMenu}>

          <FormHeader>
            <AccountTabs tenant={tenant}/>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {
                  tenant.accessingAs("*/MEMBER/*") ? (
                    <AccountHeader tenant={tenant}/>
                  ) : (
                    <div>
                      {intl.msg("account")}
                    </div>
                  )
                }
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                {
                  isEditing() ? (
                    "ONBOARDING" === tenant.status ? (
                      <>
                        <ActionButton action="save" flavor="primary" onClick={handleSaveClick}/>
                      </>
                    ) : (
                      <>
                        <ActionButton action="save" flavor="primary" onClick={handleSaveClick}/>
                        <ActionButton action="cancel" onClick={handleCancelClick}/>
                      </>
                    )
                  ) : "TRIAL" === modifyTenant.tenantPlanId ? (
                    <ActionButton action="upgrade" flavor="primary" onClick={() => openDialog("dlg_account_upgrade")}/>
                  ) : (
                    <>
                      {
                        canEdit() ? (
                          <ActionButton action="edit" onClick={handleEditClick}/>
                        ) : (
                          <></>
                        )
                      }
                      <MoreMenu/>
                    </>

                  )
                }
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>

          <FormBody>

            {
                tenant && modifyTenant && !isEditing() ? (
                  <>

                    <Greeting/>

                    <div className={`${!["INVITING", "ONBOARDING"].includes(tenant.status) ? styles.container : ""}`}>

                      {
                        tenant.accessingAs("BASIC/MEMBER,MANAGER/*") && !["INVITING","ONBOARDING"].includes(tenant.status) &&
                          <div className={styles.widgets_container}>
                            <AccountSummaryWidgets tenant={tenant}/>
                          </div>
                      }

                      {
                        tenant.accessingAs("BASIC/MEMBER,MANAGER/*") &&
                          <div className={styles.quick_access_container}>
                            <QuickAccessSection/>
                          </div>
                      }

                      <div className={styles.account_info_container}>
                        <AccountInfoSection/>
                      </div>

                      {
                        tenant.accessingAs("BASIC/MEMBER,MANAGER/*") &&
                          <div className={styles.learn_container}>
                            <LearnSection/>
                          </div>
                      }

                      {
                        tenant.accessingAs("BASIC/MEMBER,MANAGER/*") &&
                          <div className={styles.recent_container}>
                            <RecentSection/>
                          </div>
                      }

                    </div>
                  </>
              ) : isEditing() ? (
                <>

                  <FormGroups>

                    {
                      isAdding() &&
                        <>
                          <FormGroup>
                            <FormGroup.Label text={intl.msg("email_address")} description={intl.msg("account_invite_email_address_description")} htmlFor="inp_emailAddress" autoFocus={isEditing() && isAdding()}/>
                            <FormGroup.Control>
                              <input ref={ownerEmailAddressRef} key="inp_emailAddress" id="inp_emailAddress" className="form-control" type="text" value={ownerEmailAddress} onChange={handleOwnerEmailAddressChange}/>
                              <InfoBlock>
                                <InfoText validate="valid" disabled={!isEditing()}>{intl.msg("helptext_email_address_valid")}</InfoText>
                                <InfoText validate="unique" disabled={!isEditing()}>{intl.msg("helptext_email_address_unique")}</InfoText>
                                <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                              </InfoBlock>
                            </FormGroup.Control>
                          </FormGroup>
                        </>
                    }

                    <FormGroup>
                      <FormGroup.Label htmlFor="inp_tenantName" text={intl.msg("account_name")} description={intl.msg("account_name_description")}/>
                      <FormGroup.Controls>
                        <input id="inp_tenantName" type="text" className="form-control" value={modifyTenant.tenantName} maxLength="200" disabled={!canEdit()} onChange={handleTenantNameChange} autoComplete="no" autoFocus={isEditing() && !isAdding()}/>
                        <InfoBlock>
                          <InfoText disabled={!isEditing()}>{intl.msg("helptext_name_casing")}</InfoText>
                          <InfoText validate="unique" disabled={!isEditing()}>{intl.msg("account_name_helptext_unique")}</InfoText>
                          <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                        </InfoBlock>
                      </FormGroup.Controls>
                    </FormGroup>

                  </FormGroups>

                  {
                    tenant.isReseller() ? (

                        tenant.accessingAs("*/*/SYSTEM") ? (
                          <FormGroups title={intl.msg("account_reseller_settings")}>

                            <FormGroup>
                              <FormGroup.Label htmlFor="inp_resellerType" text={intl.msg("reseller_type")} description={intl.msg("reseller_type_description")}/>
                              <FormGroup.Controls>
                                <div style={{width: "200px"}}>
                                  <Select
                                    id="inp_resellerType"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    value={resellerType}
                                    options={availableResellerTypeOpts}
                                    onChange={handleResellerTypeChange}
                                    isDisabled={!canEdit()}
                                  />
                                </div>
                                <InfoBlock>
                                  <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                                  {
                                    resellerType?.value === "STANDALONE" && getResellerType(tenant) !== "STANDALONE" &&
                                    <InfoText warning={true} disabled={!isEditing()}>{intl.msg(`reseller_type_helptext_${getResellerType(tenant).toLowerCase()}_to_${resellerType?.value.toLowerCase()}_warning`)}</InfoText>
                                  }
                                </InfoBlock>
                              </FormGroup.Controls>
                            </FormGroup>

                            {
                              resellerType?.value === "MASTER" ? (
                                <>

                                  <FormGroup>
                                    <FormGroup.Label htmlFor="inp_maxLevels" text={intl.msg("reseller_max_levels")} description={intl.msg("reseller_max_levels_description")}/>
                                    <FormGroup.Controls>
                                      <div style={{width: "50px"}}>
                                        <NumericInput id="inp_maxLevels" className="form-control" strict={true} min={2} max={5} precision={0} disabled={!canEdit()} value={maxLevels} onChange={handleMaxLevelsChange}/>
                                      </div>
                                      <InfoBlock>
                                        <InfoText validate="valid" disabled={!isEditing()}>{intl.msg("reseller_max_levels_helptext_valid", {minValue: tenant.commissions && tenant.commissions.length > 0 ? tenant.commissions.length : 2, maxValue: 5})}</InfoText>
                                        {
                                          tenant?.commissions && maxLevels < tenant?.commissions.length &&
                                          <InfoText warning={true} disabled={!isEditing()}>{intl.msg("reseller_max_levels_helptext_reduce_warning", {minValue: tenant.commissions && tenant.commissions.length > 0 ? tenant.commissions.length : 2, maxValue: 5})}</InfoText>
                                        }
                                        <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                                      </InfoBlock>
                                    </FormGroup.Controls>
                                  </FormGroup>

                                  {
                                    maxLevels > 0 &&
                                    <>
                                      <FormGroup>
                                        <FormGroup.Label htmlFor="inp_commissions" text={intl.msg("reseller_commissions")} description={intl.msg("reseller_commissions_description")}/>
                                        <FormGroup.Controls>
                                          <table border="0">
                                            {
                                              rangeMap(0, maxLevels - 1, (i) => {
                                                return (
                                                  <tr>
                                                    <td>
                                                      <label className="col-form-label pr-2" htmlFor={`inp_commission_${i}`}>
                                                        <span>
                                                          {
                                                            i === 0 ? (
                                                              intl.msg("reseller_type_master_self")
                                                            ) : maxLevels > 2 ? (
                                                              intl.msg("reseller_type_sub_l", {subLevel: i})
                                                            ) : (
                                                              intl.msg("reseller_type_sub")
                                                            )
                                                          }:
                                                        </span>
                                                      </label>
                                                    </td>
                                                    <td>
                                                      <div className="d-flex align-items-center gap-1">
                                                        <div style={{width: "80px"}}>
                                                          <NumericInput id={`inp_commission_${i}`} className="form-control" min={1.00} max={99.00} precision={2} disabled={!canEdit()} value={commissions && commissions.length > 0 ? commissions[i] : 0.00} onChange={(v) => handleCommissionsChange(i, v)}/>
                                                        </div>
                                                        <span className="col-form-label">%</span>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              })
                                            }

                                            <tr>
                                              <td>
                                                <label className="col-form-label pr-2" htmlFor="inp_commission_standalone">
                                                  {intl.msg("total")}:
                                                </label>
                                              </td>
                                              <td>
                                                <div className="d-flex gap-1 align-items-center" style={{borderTop: "1px solid var(--form-divider-color)", borderBottom: "4px double var(--form-divider-color)"}}>
                                                  <div style={{width: "80px", textAlign: "right", paddingRight: "25px", color: (ttlCommissions <= 20 ? "inherit" : "var(--color-danger)")}}>
                                                    {ttlCommissions.toFixed(2)}
                                                  </div>
                                                  <div className="col-form-label" style={{paddingBottom: "0"}}>%</div>
                                                </div>
                                              </td>
                                            </tr>

                                            {
                                              tenant?.commissions && maxLevels < tenant?.commissions.length &&
                                              <>
                                                <tr>
                                                  <td colspan="2" style={{height: "8px"}}>

                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <label className="col-form-label pr-2" htmlFor="inp_commission_standalone">
                                                      {intl.msg("reseller_type_standalone")}:
                                                    </label>
                                                  </td>
                                                  <td>
                                                    <div className="d-flex gap-1 align-items-center">
                                                      <div style={{width: "80px"}}>
                                                        <NumericInput id={`inp_commission_standalone`} className="form-control" min={1.00} max={99.00} precision={2} disabled={!canEdit()} value={commissions?.length > maxLevels - 1? commissions[maxLevels] : 0.00} onChange={(v) => handleCommissionsChange(maxLevels, v)}/>
                                                      </div>
                                                      <span className="col-form-label">%</span>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </>
                                            }

                                          </table>
                                          <InfoBlock>
                                            <InfoText validate="valid" disabled={!isEditing()}>{intl.msg("reseller_commissions_helptext_valid")}</InfoText>
                                            <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                                          </InfoBlock>
                                        </FormGroup.Controls>

                                      </FormGroup>
                                    </>
                                  }
                                </>
                              ) : resellerType?.value === "STANDALONE" ? (
                                <>
                                  <FormGroup>
                                    <FormGroup.Label htmlFor="inp_commission" text={intl.msg("reseller_commission")} description={intl.msg("reseller_commission_description")}/>
                                    <FormGroup.Controls>
                                      <div className="d-flex gap-1 align-items-center" style={{width: "100px"}}>
                                        <NumericInput id="inp_commission" className="form-control" min={1.00} max={99.00} precision={2} disabled={!canEdit()} value={commissions && commissions.length > 0 ? commissions[0] : 0.00} onChange={(v) => handleCommissionsChange(0, v)}/>
                                        <span className="col-form-label">%</span>
                                      </div>
                                      <InfoBlock>
                                        <InfoText validate="valid" disabled={!isEditing()}>{intl.msg("reseller_commission_helptext_valid")}</InfoText>
                                        <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                                      </InfoBlock>
                                    </FormGroup.Controls>
                                  </FormGroup>
                                </>
                              ) : (
                                <></>
                              )
                            }

                            {
                              ["MASTER", "STANDALONE"].includes(getResellerType(tenant)) &&
                                <FormGroup>
                                  <FormGroup.Label htmlFor="inp_industry" text={intl.msg("reseller_industry")} description={intl.msg("reseller_industry_description")}/>
                                  <FormGroup.Controls>
                                    <div style={{width: "400px"}}>
                                      <Select
                                        id="inp_industry"
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        value={industry}
                                        options={industryOpts}
                                        defaultValue={findIndustryOpt("PUBLIC_SAFETY")}
                                        onChange={handleIndustryChange}
                                        isDisabled={!canEdit()}
                                      />
                                    </div>
                                    <InfoBlock>
                                      {
                                        !shortIdEqual(industry?.value, tenant.industryId) && (resellerType?.value !== "STANDALONE") &&
                                          <InfoText warning={true} disabled={!isEditing()}>{intl.msg(`reseller_industry_helptext_change_industry_warning`)}</InfoText>
                                          // <InfoText warning={true} disabled={!isEditing()}>{intl.msg(`reseller_industry_helptext_change_w${resellerType?.value === "STANDALONE" ? "o" : ""}_sub_warning`)}</InfoText>
                                      }
                                      <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                                    </InfoBlock>
                                  </FormGroup.Controls>
                                </FormGroup>
                            }
                          </FormGroups>
                        ) : (
                          <></>
                        )
                    ) : (
                      <></>
                    )
                  }

                  <FormGroups title={intl.msg("account_contact_section_title")} description={intl.msg("account_contact_section_description")}>

                    <FormGroup>
                      <FormGroup.Label htmlFor="inp_displayName" text={intl.msg("account_contact_name")} description={intl.msg("account_contact_name_description")}/>
                      <FormGroup.Controls>
                        <input id="inp_displayName" type="text" className="form-control" value={modifyTenant.displayName} disabled={!canEdit()} onChange={handleDisplayNameChange} autoComplete="no"/>
                        <InfoBlock>
                          <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_optional")}</InfoText>
                        </InfoBlock>
                      </FormGroup.Controls>
                    </FormGroup>

                    <FormGroup>
                      <FormGroup.Label htmlFor="inp_contactInfo" text={intl.msg("account_contact_address")} description={intl.msg("account_contact_address_description")}/>
                      <FormGroup.Controls>
                        <textarea id="inp_contactInfo" className="form-control" rows="3" autoComplete="no" value={modifyTenant.contactInfo} disabled={!canEdit()} onChange={handleContactInfoChange} style={{whiteSpace: "pre-line"}}/>
                        <InfoBlock>
                          <InfoText validate="optional" disabled={!isEditing()}>{intl.msg("helptext_optional")}</InfoText>
                        </InfoBlock>
                      </FormGroup.Controls>
                    </FormGroup>

                    <FormGroup>
                      <FormGroup.Label htmlFor="inp_logoUrl" text={intl.msg("account_logo")} description={intl.msg("account_logo_description")}/>
                      <FormGroup.Controls>
                        <ImageDropBox
                          id="inp_logoUrl"
                          image={modifyTenant.logoUrl}
                          imageClassName={"account-logo"}
                          width={100}
                          height={100}
                          onChange={handleLogoChange}
                          onClear={handleLogoClear}
                          disabled={!canEdit()}/>
                        <InfoBlock>
                          <InfoText validate="valid_size" disabled={!isEditing()}>{intl.msg("account_logo_helptext_valid_size")}</InfoText>
                          <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_optional")}</InfoText>
                        </InfoBlock>
                      </FormGroup.Controls>
                    </FormGroup>

                    <FormGroup>
                      <FormGroup.Label htmlFor="inp_homePage" text={intl.msg("account_home_page")} description={intl.msg("account_home_page_description")}/>
                      <FormGroup.Controls>
                        <div className="input-group" style={{gap: "0.5rem"}}>
                          <input id="inp_homePage" type="text" className="form-control" value={modifyTenant.homePage} onChange={handleHomePageChange} onBlur={handleHomePageBlur}/>
                          <div className="input-group-append">
                            <ExternalLinkButton href={modifyTenant.homePage} validate="relaxed"/>
                          </div>
                        </div>
                        <InfoBlock>
                          <InfoText validate="valid" disabled={!isEditing()}>{intl.msg("helptext_web_address_valid")}</InfoText>
                          <InfoText validate="optional" disabled={!isEditing()}>{intl.msg("helptext_optional")}</InfoText>
                        </InfoBlock>
                      </FormGroup.Controls>
                    </FormGroup>

                  </FormGroups>

                  {/*<FormGroup>*/}
                  {/*  <FormGroup.Label htmlFor="inp_clickUrls" text={intl.msg("account_click_urls")} description={intl.msg("account_click_urls_description")}/>*/}
                  {/*  <FormGroup.Controls>*/}
                  {/*    {*/}
                  {/*      clickUrls?.length > 0 &&*/}
                  {/*        <table style={{width: "100%"}}>*/}
                  {/*          <tr>*/}
                  {/*            <td className="pr-2" style={{width: "30%"}}>*/}
                  {/*              <label className="col-form-label">{intl.msg("description")}:</label>*/}
                  {/*            </td>*/}
                  {/*            <td style={{width: "70%"}}>*/}
                  {/*              <label className="col-form-label">{intl.msg("web_address")}:</label>*/}
                  {/*            </td>*/}
                  {/*          </tr>*/}
                  {/*          {*/}
                  {/*            clickUrls?.map((clickUrl, index) => {*/}
                  {/*              return (*/}
                  {/*                <tr key={`inp_clickUrl_${index}`}>*/}
                  {/*                  <td className="d-table-cell pb-2 pr-2" style={{width: "30%"}}>*/}
                  {/*                    <input id={`inp_clickUrls_name_${index}`} type="text" className="form-control" value={clickUrl.name} onChange={(e) => handleClickUrlNameChange(e, index)}/>*/}
                  {/*                  </td>*/}
                  {/*                  <td className="d-table-cell pb-2" style={{width: "70%"}}>*/}
                  {/*                    <UrlInput id={`inp_clickUrls_url_${index}`} value={clickUrl.url} onChange={(e) => handleClickUrlUrlChange(e, index)}/>*/}
                  {/*                  </td>*/}
                  {/*                  <td className="d-table-cell" style={{width: "0%"}}>*/}
                  {/*                    <button className="btn btn-tertiary" type="button" style={{marginBottom: "8px", padding: "4px"}} title={intl.msg("delete")} onClick={(e) => handleClickUrlDelete(e, index)}><i className="fas fa-trash-alt"></i></button>*/}
                  {/*                  </td>*/}
                  {/*                </tr>*/}
                  {/*              )*/}
                  {/*            })*/}
                  {/*          }*/}
                  {/*        </table>*/}
                  {/*    }*/}
                  {/*    <button className="btn btn-secondary" type="button" title="Add Click URL" onClick={handleAddClickUrlClick}><i className="fas fa-plus" style={{marginRight: "4px"}}></i>{intl.msg("add")}</button>*/}
                  {/*    <InfoBlock>*/}
                  {/*      <InfoText validate="valid" disabled={!isEditing()}>{intl.msg("helptext_click_urls_helptext_valid")}</InfoText>*/}
                  {/*      <InfoText validate="optional" disabled={!isEditing()}>{intl.msg("helptext_optional")}</InfoText>*/}
                  {/*    </InfoBlock>*/}
                  {/*  </FormGroup.Controls>*/}
                  {/*</FormGroup>*/}

                  {
                    tenant.isClient() &&

                      <>

                        <FormGroups title={intl.msg("account_billing_section_title")} description={intl.msg("account_billing_section_description")}>

                          <FormGroup>
                            <FormGroup.Label htmlFor="inp_useContactInfo" text={intl.msg("account_billing_use_contact_info")} description={intl.msg("account_billing_use_contact_info_description")}/>
                            <FormGroup.Controls>
                              <ToggleButton
                                id="useContactInfo"
                                checked={useContactInfo}
                                readonly={!canEdit()}
                                onChange={handleUseContactInfoChange}
                                onLabel={intl.msg("yes")}
                                offLabel={intl.msg("no")}
                              />
                              <InfoBlock>
                                <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                              </InfoBlock>
                            </FormGroup.Controls>
                          </FormGroup>

                          {
                            !useContactInfo &&
                              <>
                                <FormGroup>
                                  <FormGroup.Label htmlFor="inp_billingName" text={intl.msg("account_billing_name")} description={intl.msg("account_billing_name_description")}/>
                                  <FormGroup.Controls>
                                    <input id="inp_billingName" type="text" className="form-control" value={modifyTenant.billingInfo?.name} disabled={!canEdit()} onChange={handleBillingNameChange} autoComplete="no"/>
                                    <InfoBlock>
                                      <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                                    </InfoBlock>
                                  </FormGroup.Controls>
                                </FormGroup>

                                <FormGroup>
                                  <FormGroup.Label htmlFor="inp_billingAddress" text={intl.msg("account_billing_address")} description={intl.msg("account_billing_address_description")}/>
                                  <FormGroup.Controls>
                                    <textarea id="inp_billingAddress" className="form-control" rows="3" autoComplete="no" value={modifyTenant.billingInfo?.address} disabled={!canEdit()} onChange={handleBillingAddressChange} style={{whiteSpace: "pre-line"}}/>
                                    <InfoBlock>
                                      <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                                    </InfoBlock>
                                  </FormGroup.Controls>
                                </FormGroup>
                              </>
                          }

                        </FormGroups>

                        <FormGroups>

                          <FormGroup>
                            <FormGroup.Label htmlFor="inp_restrictDomains" text={intl.msg("account_restrict_domains")} description={intl.msg("account_restrict_domains_description")}/>
                            <FormGroup.Controls>
                              <ToggleButton
                                id="inp_restrictDomains"
                                checked={restrictDomains}
                                readonly={!canEdit()}
                                onChange={handleRestrictDomainsChange}
                                onLabel={intl.msg("yes")}
                                offLabel={intl.msg("no")}
                              />
                              <InfoBlock>
                                {
                                  restrictDomains &&
                                  <InfoText validate="valid" disabled={!isEditing()}>{intl.msg("account_restrict_domains_helptext_valid")}</InfoText>
                                }
                              </InfoBlock>
                            </FormGroup.Controls>
                          </FormGroup>

                          {
                            restrictDomains &&
                            <>

                              <FormGroup>
                                <FormGroup.Label htmlFor="inp_allowedDomains" text={intl.msg("account_allowed_domains")} description={intl.msg("account_allowed_domains_description")}/>
                                <FormGroup.Controls>
                                  <div className="input-group">
                                    <TagInput
                                      id="inp_allowedDomains"
                                      className="react-select w-100"
                                      classNamePrefix="react-select"
                                      value={allowedDomains}
                                      isDisabled={!canEdit() || !restrictDomains}
                                      breakKeys={["Enter", " ", ","]}
                                      lowerCase={true}
                                      onChange={handleAllowedDomainsChange}
                                    />
                                  </div>
                                  <InfoBlock>
                                    <InfoText validate="valid" disabled={!isEditing()}>{intl.msg("account_domains_helptext_valid")}</InfoText>
                                  </InfoBlock>
                                </FormGroup.Controls>
                              </FormGroup>

                              <FormGroup>
                                <FormGroup.Label htmlFor="inp_disallowedDomains" text={intl.msg("account_disallowed_domains")} description={intl.msg("account_disallowed_domains_description")}/>
                                <FormGroup.Controls>
                                  <div className="input-group">
                                    <TagInput
                                      id="inp_disallowedDomains"
                                      className="react-select w-100"
                                      classNamePrefix="react-select"
                                      value={disallowedDomains}
                                      isDisabled={!canEdit() || !restrictDomains}
                                      breakKeys={["Enter", " ", ","]}
                                      lowerCase={true}
                                      onChange={handleDisallowedDomainsChange}
                                    />
                                  </div>
                                  <InfoBlock>
                                    <InfoText validate="valid" disabled={!isEditing()}>{intl.msg("account_domains_helptext_valid")}</InfoText>
                                  </InfoBlock>
                                </FormGroup.Controls>
                              </FormGroup>

                            </>
                          }

                        </FormGroups>

                      </>
                  }

                  {
                    !tenant.isSystem() && tenant.accessingAs("SUPERADMIN/*/SYSTEM") &&
                      <FormGroups title={intl.msg("account_system_settings")}>

                        <FormGroup>
                          <FormGroup.Label htmlFor="inp_flags" text={intl.msg("account_system_flags")}  description={intl.msg("account_system_flags_description")}/>
                          <FormGroup.Controls>
                            <AsyncPaginate
                              id="inp_flags"
                              className="react-select"
                              classNamePrefix="react-select"
                              cacheOptions
                              isMulti
                              isClearable
                              value={flags}
                              debounceTimeout={800}
                              defaultOptions={true}
                              loadOptions={loadFlags}
                              onChange={handleFlagsChange}
                              isDisabled={!isEditing()}
                            />
                            <InfoBlock>
                            </InfoBlock>
                          </FormGroup.Controls>
                        </FormGroup>

                      </FormGroups>
                  }

                  {
                    !tenant.isSystem() &&
                      <>

                        {
                          session?.user && session.user.isSystem() && session.user.isSuperAdminOrAbove() &&
                            <FormGroups title={intl.msg("account_adsystem_settings")}>

                              <FormGroup>
                                <FormGroup.Label htmlFor="inp_overrideAdSystemProps" text={intl.msg("account_adsystem_override")} description={intl.msg("account_adsystem_override_description")}/>
                                <FormGroup.Controls>
                                  <ToggleButton
                                    id="inp_overrideAdSystemProps"
                                    checked={overrideAdSystemProps}
                                    readonly={!canEdit()}
                                    onChange={handleOverrideAdSystemPropsChange}
                                    onLabel={intl.msg("yes")}
                                    offLabel={intl.msg("no")}
                                  />
                                  <InfoBlock>
                                    {
                                      tenant.isReseller() &&
                                      <InfoText disabled={!isEditing()}>{intl.msg("account_adsystem_override_helptext_reseller")}</InfoText>
                                    }
                                  </InfoBlock>
                                </FormGroup.Controls>
                              </FormGroup>

                              {
                                overrideAdSystemProps === true &&
                                <>

                                  <FormGroup>
                                    <FormGroup.Label htmlFor="inp_cpms" text={intl.msg("account_adsystem_cpms")} description={intl.msg("account_adsystem_cpms_description")}/>
                                    <FormGroup.Controls>
                                      <table border="0">
                                        <tr>
                                          <td>
                                            <label className="col-form-label pr-2" htmlFor="inp_cpmSolv">
                                              {intl.msg("account_adsystem_cpm_solv")}:
                                            </label>
                                          </td>
                                          <td>
                                            <div className="d-flex gap-1 align-items-center">
                                              <div style={{width: "80px"}}>
                                                <NumericInput id="inp_cpmSolv" className="form-control" min={1.00} max={20.00} precision={2} disabled={!canEdit()} value={cpms && cpms["SOLV"] ? cpms["SOLV"] : DefaultCPMs["SOLV"]} onChange={(v) => handleCpmsChange("SOLV", v)}/>
                                              </div>
                                              {/*<span className="col-form-label">%</span>*/}
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <label className="col-form-label pr-2" htmlFor="inp_cpmImage">
                                              {intl.msg("account_adsystem_cpm_image")}:
                                            </label>
                                          </td>
                                          <td>
                                            <div className="d-flex gap-1 align-items-center">
                                              <div style={{width: "80px"}}>
                                                <NumericInput id="inp_cpmImage" className="form-control" min={1.00} max={20.00} precision={2} disabled={!canEdit()} value={cpms && cpms["IMAGE"] ? cpms["IMAGE"] : DefaultCPMs["IMAGE"]} onChange={(v) => handleCpmsChange("IMAGE", v)}/>
                                              </div>
                                              {/*<span className="col-form-label">%</span>*/}
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <label className="col-form-label pr-2" htmlFor="inp_cpmVide">
                                              {intl.msg("account_adsystem_cpm_video")}:
                                            </label>
                                          </td>
                                          <td>
                                            <div className="d-flex gap-1">
                                              <div style={{width: "80px"}}>
                                                <NumericInput id="inp_cpmVideo" className="form-control" min={1.00} max={20.00} precision={2} disabled={!canEdit()} value={cpms && cpms["VIDEO"] ? cpms["VIDEO"] : DefaultCPMs["VIDEO"]} onChange={(v) => handleCpmsChange("VIDEO", v)}/>
                                              </div>
                                              {/*<span className="col-form-label">%</span>*/}
                                            </div>
                                          </td>
                                        </tr>
                                      </table>
                                      <InfoBlock>
                                        <InfoText validate="valid" disabled={!isEditing()}>{intl.msg("account_adsystem_cpms_helptext_valid", {minValue: tenant.commissions && tenant.commissions.length > 0 ? tenant.commissions.length : 2, maxValue: 5})}</InfoText>
                                        <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                                      </InfoBlock>
                                    </FormGroup.Controls>
                                  </FormGroup>

                                  <FormGroup>
                                    <FormGroup.Label htmlFor="inp_behaviorSegmentSet" text={intl.msg("account_adsystem_amobee_behavior_segment_set")} description={intl.msg("account_adsystem_amobee_behavior_segment_set_description")}/>
                                    <FormGroup.Controls>
                                      <div style={{width: "500px"}}>
                                        <AsyncPaginate
                                          id="inp_behaviorSegmentSet"
                                          className="react-select"
                                          classNamePrefix="react-select"
                                          cacheOptions
                                          value={amobeeBehaviorSegmentSet}
                                          debounceTimeout={800}
                                          defaultOptions={true}
                                          isClearable={true}
                                          placeholder={""}
                                          loadOptions={loadAmobeeBehaviorSegmentSets}
                                          onChange={handleAmobeeBehaviorSegmentSetChange}
                                          isDisabled={!canEdit()}
                                        />
                                      </div>
                                      <InfoBlock>
                                      </InfoBlock>
                                    </FormGroup.Controls>
                                  </FormGroup>

                                </>
                              }

                            </FormGroups>
                        }

                      </>
                  }
                </>
              ) : (
                <></>
              )
            }

          </FormBody>

        </MainContainer>

        <CreditTransactionTopupDialog key={"topup"} tenant={tenant}/>

        <CreditsAdjustDialog key={"extend"} tenant={tenant} currencyCode={tenant.region.currencyCode}/>

        {
          CreditsExpiring(tenant.credits) &&
            <CreditsExtendExpiryDialog key={"extend"} tenant={tenant}/>
        }

        <ImageDialog ref={logoImagePickerDialogRef} id="dlg_logoImagePicker" title="Logo Image" imageInfo={logoImageInfo} setTargetImageData={setLogoUrl} setTouched={setTouched}/>

        <ResellerInviteDialog tenant={tenant} reinvite={tenant && "INVITING" === tenant.status}/>

        <ResellerMoveDialog tenant={tenant}/>

        <ClientInviteDialog tenant={tenant}/>

        <AccountDeleteDialog tenant={tenant}/>

      </>
  )
}