import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Helmet } from "react-helmet"
import { GoogleOAuthProvider } from '@react-oauth/google'
import { QueryClient,  QueryClientProvider } from 'react-query'
import { IntlProvider } from "react-intl"
import { LOCALES } from "./i18n/locales"
import { messages } from "./i18n/messages"
import {useSolv, useTenant} from "./components/SolvProvider"
import { usePrefs } from "./components/PrefUtils"
import SolvRoutes from "./solv/SolvRoutes"
import PetSolvRoutes from "./petsolv/PetSolvRoutes"
import getLogger from "./components/Logging"
import "./App.css"

const log = getLogger("App")

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: -1,
      refetchIntervalInBackground: -1,
    }
  }
})

function App() {

  const {isLoading, brandId, env} = useSolv()

  const {locale} = usePrefs()

  return (
      !isLoading &&
        <GoogleOAuthProvider clientId={env.GOOGLE_CLIENT_ID}>
          <QueryClientProvider client={queryClient}>
            <IntlProvider
              key={locale}
              messages={messages[locale]}
              locale={locale}
              defaultLocale={LOCALES.en}
              defaultRichTextElements={{
                a_www: chunks => <a className="text-link" href="https://www.solv.technology" target="_blank">{chunks}</a>,
                a_contact: chunks => <a className="text-link" href="https://www.solv.technology/contact" target="_blank">{chunks}</a>,
                a_contact_mail: chunks => <a className="text-link" href="mailto:support@solv.technology" target="_blank">{chunks}</a>,
                a_tos: chunks => <a className="text-link" href="https://sassets.solv.technology/legal/terms" target="_blank">{chunks}</a>,
                a_privacy: chunks => <a className="text-link" href="https://sassets.solv.technology/legal/privacy" target="_blank">{chunks}</a>,
                cp_icon: () => <>&copy;</>,
                a_recaptcha: chunks => <a className="text-link" href="https://en.wikipedia.org/wiki/ReCAPTCHA" target="_blank">{chunks}</a>,
                a_signin: chunks => <a className="text-link" href="/signin">{chunks}</a>,
                a_signup: chunks => <a className="text-link" href="/signup">{chunks}</a>,
                a_help: chunks => <a className="text-link" href="https://help.solv.technology" target="_blank">{chunks}</a>,
                a_help_access_level: chunks => <a className="icon-link" title={chunks} href="https://help.solv.technology/article/access-levels-and-permissions" target="_blank">
                  <span className="fa-layers">
                    <i className="far fa-circle" style={{color: "var(--form-control-border-color)"}}
                       data-fa-transform="grow-4"></i>
                    <i className="fas fa-question" data-fa-transform="shrink-6"></i>
                  </span>
                </a>,
                a_help_broadcast_name: chunks => <a className="icon-link" title={chunks} href="https://help.solv.technology/article/broadcast_name" target="_blank">
                  <span className="fa-layers">
                    <i className="far fa-circle" style={{color: "var(--form-control-border-color)"}}  data-fa-transform="grow-4"></i>
                    <i className="fas fa-question" data-fa-transform="shrink-6"></i>
                  </span>
                </a>,
                a_help_broadcast_image: chunks => <a className="icon-link" title={chunks} href="https://help.solv.technology/article/broadcast_image" target="_blank">
                  <span className="fa-layers">
                    <i className="far fa-circle" style={{color: "var(--form-control-border-color)"}}  data-fa-transform="grow-4"></i>
                    <i className="fas fa-question" data-fa-transform="shrink-6"></i>
                  </span>
                </a>,
                a_help_broadcast_locations: chunks => <a className="icon-link" title={chunks} href="https://help.solv.technology/article/broadcast_locations" target="_blank">
                  <span className="fa-layers">
                    <i className="far fa-circle" style={{color: "var(--form-control-border-color)"}}  data-fa-transform="grow-4"></i>
                    <i className="fas fa-question" data-fa-transform="shrink-6"></i>
                  </span>
                </a>,
                a_help_broadcast_budget: chunks => <a className="icon-link" title={chunks} href="https://help.solv.technology/article/broadcast_budget" target="_blank">
                  <span className="fa-layers">
                    <i className="far fa-circle" style={{color: "var(--form-control-border-color)"}}  data-fa-transform="grow-4"></i>
                    <i className="fas fa-question" data-fa-transform="shrink-6"></i>
                  </span>
                </a>,
                a_help_broadcast_duration: chunks => <a className="icon-link" title={chunks} href="https://help.solv.technology/article/broadcast_duration" target="_blank">
                  <span className="fa-layers">
                    <i className="far fa-circle" style={{color: "var(--form-control-border-color)"}}  data-fa-transform="grow-4"></i>
                    <i className="fas fa-question" data-fa-transform="shrink-6"></i>
                  </span>
                </a>,
                a_help_image_format: chunks => <a className="icon-link" title={chunks} href="https://help.solv.technology/article/image-format-specs-and-requirements" target="_blank">
                  <span className="fa-layers">
                    <i className="far fa-circle" style={{color: "var(--form-control-border-color)"}}  data-fa-transform="grow-4"></i>
                    <i className="fas fa-question" data-fa-transform="shrink-6"></i>
                  </span>
                </a>,
                a_help_estimations: chunks => <a className="icon-link" title={chunks} href="https://help.solv.technology/article/populations-views-and-reach" target="_blank">
                  <span className="fa-layers">
                    <i className="far fa-circle" style={{color: "var(--form-control-border-color)"}}  data-fa-transform="grow-4"></i>
                    <i className="fas fa-question" data-fa-transform="shrink-6"></i>
                  </span>
                </a>,
                a_help_markdown: chunks => <a className="text-link" href="https://help.solv.technology/article/formatting-text-with-markdown" target="_blank">{chunks}</a>,
                a_wikipedia_iso_3166_1_alpha_2: chunks => <a className="icon-link" title={chunks} href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2" target="_blank"><i className="far fa-question-circle"></i></a>, a_wikipedia_iso_4217: chunks => <a className="text-link" href="https://en.wikipedia.org/wiki/ISO_4217" target="_blank">{chunks}</a>,
                br: chunks => <br/>,
                b: chunks => <strong>{chunks}</strong>,
                but: chunks => <span className="but">{chunks}</span>,
                em: chunks => <em>{chunks}</em>,
                emd: chunks => <em className="text-danger">{chunks}</em>,
                emw: chunks => <em className="text-warning">{chunks}</em>,
                i: chunks => <i>{chunks}</i>,
                p: chunks => <p>{chunks}</p>,
                h1: chunks => <h1>{chunks}</h1>,
                h2: chunks => <h2>{chunks}</h2>,
                h3: chunks => <h3>{chunks}</h3>,
                h4: chunks => <h4>{chunks}</h4>,
                h5: chunks => <h5>{chunks}</h5>,
                mark: chunks => <mark>{chunks}</mark>,
                code: chunks => <code>{chunks}</code>,
                kbd: chunks => <kbd>{chunks}</kbd>,
                sup: chunks => <sup>{chunks}</sup>,
                small: chunks => <small>{chunks}</small>,
                ul: chunks => <ul style={{display: "flex", flexDirection: "column", gap: "0.5em", marginLeft: "-1.25em", marginBottom: "0"}}>{chunks}</ul>,
                ol: chunks => <ol style={{display: "flex", flexDirection: "column", gap: "0.5em", marginLeft: "-1.25em", marginBottom: "0"}}>{chunks}</ol>,
                li: chunks => <li>{chunks}</li>,
                i_info_circle: chucks => <i className="fas fa-info-circle"></i>,
                sp: chucks => <span className="ml-1"></span>,
                span: chunks => <span>{chunks}</span>,
                warn: chucks => <span style={{color: "orange"}}>{chucks}</span>,
                error: chucks => <span style={{color: "red"}}>{chucks}</span>
              }}>
              {
                "PETSOLV" === brandId ? (
                  <Helmet>
                    <title>PetSOLV</title>
                    <description>PetSOLV</description>
                    <link rel="icon" type="image/png" sizes="32x32" href="/images/petsolv-favicon-32x32.png"/>
                    <link rel="icon" type="image/png" sizes="16x16" href="/images/petsolv-favicon-16x16.png"/>
                    <link rel="apple-touch-icon" type="image/png" sizes="120x120" href="/images/petsolv-favicon-apple-touch-icon.png"/>
                  </Helmet>
                ) : (
                  <Helmet>
                    <title>SOLV</title>
                    <description>SOLV</description>
                    <link rel="icon" type="image/png" sizes="32x32" href="/images/solv-favicon-32x32.png"/>
                    <link rel="icon" type="image/png" sizes="96x96" href="/images/solv-favicon-96x96.png"/>
                    <link rel="icon" type="image/png" sizes="16x16" href="/images/solv-favicon-16x16.png"/>
                    <link rel="apple-touch-icon" href="/images/solv-favicon-96x96.png"/>
                  </Helmet>
                )
              }
              {
                "SOLV" === brandId ? (
                  <SolvRoutes/>
                ) : "PETSOLV" === brandId ? (
                  <PetSolvRoutes/>
                ) : (
                  <></>
                )
              }
          </IntlProvider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
  )
}

export default App;
