import React, {Fragment, useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {useSolv} from "../components/SolvProvider"
import MainMenu from "../MainMenu.js"
import {FormHeader, FormBody, MainContainer, useAlert, ActionButton} from "../components/FormComps.js"
import {useIntlEx} from "../components/IntlUtils"
import {DataTable, useCols} from "../components/TableComps"
import ExportToFileDialog from "../components/ExportToFileDialog";
import getLogger from "../components/Logging.js"
import '../App.css'

const log = getLogger("Locations")

export default function Locations() {

  const {api, session: {user, tenant}, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const [alert, setAlert] = useAlert(null)

  const {cols} = useCols({
    resourceName: "locations",
    specs: [
      {
        field: "locationName",
        label: intl.msg("location_name"),
        filter: "text",
        sort: "ASC",
        align: "left",
      },
      {
        field: "displayName",
        label: intl.msg("location_display_name"),
        filter: "text",
        sort: "",
        align: "left",
      },
      {
        field: "regionId",
        label: intl.msg("region"),
        filter: {
          type: "asyncselect",
          loader: (input, loadedOptions, additional) => {
            log.debug("listRegions: invoked")
            return api.listRegions({view: "BASIC", extraParams: `${input ? `filter=regionName:${encodeURIComponent(input)}&` : ""}sort=regionName:ASC`, cursor: additional?.cursor, limit: 20})
              .then(({data, nextCursor}) => {
                let opt = data.map(c => {
                  return {
                    value: c.regionId,
                    label: c.regionName,
                    pop: c.pop
                  };
                });
                let res = {
                  options: opt,
                }
                if (nextCursor) {
                  res = {
                    ...res,
                    hasMore: true,
                    additional: {
                      cursor: nextCursor
                    }
                  }
                }
                log.debug("loadRegions: res=", res)
                return res
              });
          }
        },
        sort: "",
        align: "left",
      },
      {
        field: "countryCode",
        label: intl.msg("country"),
        filter: {
          type: "asyncselect",
          loader: (input, loadedOptions, additional) => {
            log.debug("listCountries: invoked")
            return api.listCountries({view: "BASIC", extraParams: `${input ? `filter=countryCode:${encodeURIComponent(input)}&` : ""}sort=countryCode:ASC`, cursor: additional?.cursor, limit: 20})
              .then(({data, nextCursor}) => {
                let opt = data.map(c => {
                  return {
                    value: c.countryCode,
                    label: c.countryCode,
                    pop: c.pop
                  };
                });
                let res = {
                  options: opt,
                }
                if (nextCursor) {
                  res = {
                    ...res,
                    hasMore: true,
                    additional: {
                      cursor: nextCursor
                    }
                  }
                }
                log.debug("loadCountries: res=", res)
                return res
              });
          }
        },
        sort: "",
        align: "left",
      },
      {
        field: "pop",
        label: intl.msg("location_pop"),
        filter: null,
        sort: "",
        align: "right",
      },
      {
        field: "status",
        label: intl.msg("status"),
        filter: {
          type: "select",
          options: () => {
            return ([
              {
                value: "ACTIVE",
                label: intl.msg("status_active")
              },
              {
                value: "INACTIVE",
                label: intl.msg("status_inactive")
              },
              {
                value: "PENDING",
                label: intl.msg("status_pending")
              },
            ])
          }
        },
        sort: "",
        align: "left",
      }
    ],
  })

  function handleFetchDataPage({pageParam: cursor}) {
    return api.listLocations({extraParams: cols.stringify(), cursor: cursor})
  }

  function handleRenderDataCol(col, row) {
    let n
    switch (col.field) {
      case "regionId":
        n = <a className="text-link" href={`/sys/regions?filter=regionName:=${row.regionName}`}>{row.regionName}</a>
        break
      case "countryCode":
        n = <a className="text-link" href={`/sys/countries?filter=countryCode:${row.countryCode}`}>{row.countryCode}</a>
        break
      case "locationName":
        n = <span>{row.locationName}</span>
        break
      case "displayName":
        n = <span>{row.displayName}</span>
        break
      case "pop":
        n = <span>{intl.int(row.pop)}</span>
        break
      case "status":
        n = <span>{row.status ? intl.msg(`status_${row.status.toLowerCase()}`) : ""}</span>
        break
    }
    return (
        n
    )
  }

  function handleRowClick(row) {
    window.location = `/sys/locations/${row.locationId}`
  }

  function uploadFile(file) {
    const formData = new FormData()
    formData.append("file", file)
    const url = `/v1/locations/_import`
    log.debug("uploadFile: url=", url)
    return api.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }


  async function handleImportClick(e) {
    log.debug("handleImportClick: invoked")
    if (e.target.files && e.target.files.length > 0) {
      log.debug("handleImportClick: file=", e.target.files[0])
      try {
        setBusy(intl.msg("uploading"))
        const res = await uploadFile(e.target.files[0])
        log.debug("handleImportClick: data=", res)
        // window.location.reload()
      }
      catch (error) {
        log.debug("handleImportClick: error=", JSON.stringify(error))
        handleError(error)
      }
      finally {
        setBusy(null)
      }
    }
  }

  function handleError(error) {
    if (error) {
      log.debug("handleError: error=", JSON.stringify(error))
      if (error.code) {
        switch (error.code) {
          case "BAD_PAYLOAD":
            if (error.msg) {
              setAlert({error: error.msg})
            }
            else {
              setAlert({error: intl.msg("error_failed")})
            }
            break
          case "UNKNOWN_LOCATION":
            log.debug("ERROR: ", error)
            if (error.msg) {
              setAlert({error: error.msg})
            }
            else {
              setAlert({error: intl.msg("error_failed")})
            }
            break
          default:
            setAlert({error: intl.msg("error_failed")})
        }
      }
      else {
        setAlert({error: intl.msg("error_failed")})
      }
    }
  }

  async function handleExport() {
    return await api.exportLocations({extraParams: cols.stringify()})
  }

  return (
    tenant &&
      <>

        <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={"*/*/SYSTEM"}>

          <FormHeader>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("locations_title")}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                {
                  user.isSystem() && user.isAdminOrAbove() &&
                  <>
                    <ActionButton name="add_new" flavor="primary" href={`/sys/locations/_new`}/>
                    <ActionButton name="import" type="file" href={`/sys/locations/_new`} accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onClick={handleImportClick}/>

                    {/*<label className="btn btn-secondary">*/}
                    {/*  <i className="fas fa-upload mr-1"></i>*/}
                    {/*  {intl.msg("import")}*/}
                    {/*  <input id="btn_uploadNew" type="file" accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={handleImportClick}/>*/}
                    {/*</label>*/}

                    {/*<button type="button" className="btn btn-secondary" onClick={openDialogCurried("dlg_locations_export")}>*/}
                    {/*  <i className="fas fa-download mr-1"></i>*/}
                    {/*  {intl.msg("export")}*/}
                    {/*</button>*/}

                  </>
                }

              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>
          <FormBody>
            <DataTable
              tenant={tenant}
                cols={cols}
                resourceName="locations"
                dataKey="locationId"
                onFetchDataPage={handleFetchDataPage}
                onRenderDataCol={handleRenderDataCol}
                onClickDataRow={handleRowClick}
            />
          </FormBody>
        </MainContainer>

        <ExportToFileDialog
          type="locations"
          tenant={tenant}
          fileName={"locations"}
          fileExt={"xlsx"}
          onExport={handleExport}
        />

      </>
  )
}