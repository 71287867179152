import React, { Fragment, useState, useEffect }  from 'react'
import { useParams, useHistory, useLocation }  from 'react-router-dom'
import { useQueryClient } from 'react-query'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { useIntlEx } from "./components/IntlUtils.js"
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {useSolv} from "./components/SolvProvider";
import {UserAndTimeLink, UserLink} from "./components/UserUtils";
import {BroadcastStatusTagLabel} from './components/BroadcastComps.js';
import {FormGroup, useAlert} from "./components/FormComps";
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("BroadcastDetailsDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function BroadcastDetailsDialog(props)  {

  const {api, session, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const [alert, setAlert] = useAlert()

  const [tenant, setTenant] = useState(null)

  const location = useLocation()
  const history = useHistory()
  const [broadcast, setBroadcast] = useState(null)
  const [broadcastName, setBroadcastName] = useState(null)
  const [status, setStatus] = useState(null)
  const [createdBy, setCreatedBy] = useState(null)
  const [createdOn, setCreatedOn] = useState(null)
  const [modifiedBy, setModifiedBy] = useState(null)
  const [modifiedOn, setModifiedOn] = useState(null)
  const [publishedBy, setPublishedBy] = useState(null)
  const [publishedOn, setPublishedOn] = useState(null)

  useEffect(() => {
    if (props) {
      if (props.tenant) {
        setTenant(props.tenant)
      }
      if (props.broadcast) {
        log.debug(">>>Z: broadcast=", broadcast)
        setBroadcast(props.broadcast)
        setBroadcastName(props.broadcast.broadcastName)
        setStatus(props.broadcast.status)
        setCreatedOn(props.broadcast.createdOn)
        setCreatedBy(props.broadcast.createdBy)
        setModifiedOn(props.broadcast.modifiedOn)
        setModifiedBy(props.broadcast.modifiedBy)
        setPublishedOn(props.broadcast.publishedOn)
        setPublishedBy(props.broadcast.publishedBy)
      }
    }
  }, [props])

  function handleOpenDialog() {
    if (!broadcast) {
      return
    }
    // setBusy(intl.msg("loading"))
    // log.debug("BroadcastDetailsDialog: loading...", broadcast.broadcastId)
    // api.getBroadcastDetails(broadcast.tenantId, broadcast.broadcastId)
    //   .then(({data}) => {
    //     log.debug("BroadcastDetailsDialog: res=", data)
    //     setBroadcastName(data.broadcastName)
    //     setStatus(data.status)
    //     setCreatedOn(data.createdOn)
    //     setCreatedBy(data.createdBy)
    //     setModifiedOn(data.modifiedOn)
    //     setModifiedBy(data.modifiedBy)
    //     setPublishedOn(data.publishedOn)
    //     setPublishedBy(data.publishedBy)
    //   })
    //   .catch((error) => {
    //     setAlert({error: "broadcast_error_load_failed"})
    //   })
    //   .finally(function() {
    //     setBusy(null)
    //   })
  }

  function handleCloseDialog() {
    closeDialog("dlg_broadcast_details")
  }

  function getPctDelivered(broadcast) {
    if (broadcast && broadcast.status && broadcast.adViews && broadcast.targetAdViews && ("STARTED" === broadcast.status)) {
      return intl.num(broadcast.adViews / broadcast.targetAdViews * 100)
    }
    else {
      return null
    }
  }

  return ( 

    <ModalDialog
      dialogId="dlg_broadcast_details"
      size="lg"
      keyboard={"true"}
      backdrop={props.backdrop || "static"}
      onOpen={handleOpenDialog}
      onClose={handleCloseDialog}>

      <ModalDialog.Header
        title={intl.msg("broadcast_details_title")}
        alert={alert}/>

      <ModalDialog.Body>

          <FormGroup disabled={true}>
            <FormGroup.Label>
              {intl.msg("broadcast_name")}
            </FormGroup.Label>
            <FormGroup.Control>
              <label className="form-control">
                {broadcastName}
                <div className="ml-2 d-inline-block">
                  <div className="d-flex flex-row" style={{gap: "4px"}}>
                    {
                      broadcast && tenant &&
                      <BroadcastStatusTagLabel broadcast={broadcast} tenant={tenant}/>
                    }
                  </div>
                </div>
              </label>
            </FormGroup.Control>
          </FormGroup>

          <FormGroup disabled={true}>
            <FormGroup.Label>
              {intl.msg("created")}
            </FormGroup.Label>
            <FormGroup.Control>
              <label className="form-control">
                <UserAndTimeLink session={session} tenant={tenant} user={createdBy} timestamp={createdOn}/>
              </label>
            </FormGroup.Control>
          </FormGroup>

          {
            modifiedOn &&
              <FormGroup disabled={true}>
                <FormGroup.Label>
                  {intl.msg("modified")}
                </FormGroup.Label>
                <FormGroup.Control>
                  <label className="form-control">
                    <UserAndTimeLink session={session} tenant={tenant} user={modifiedBy} timestamp={modifiedOn}/>
                  </label>
                </FormGroup.Control>
              </FormGroup>
          }

          {
            publishedOn &&
              <FormGroup disabled={true}>
                <FormGroup.Label>
                  {intl.msg("published")}
                </FormGroup.Label>
                <FormGroup.Control>
                  <label className="form-control">
                    <UserAndTimeLink session={session} tenant={tenant} user={publishedBy} timestamp={publishedOn}/>
                  </label>
                </FormGroup.Control>
              </FormGroup>
          }

        </ModalDialog.Body>

      </ModalDialog>
   )
}