import React, {useState, useEffect, useReducer} from 'react'
import { useParams}  from 'react-router-dom'
import { default as Select } from "react-select"
import {useSolv, useTenant} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils"
import {FormHeader, FormBody, MainContainer, useAlert, FormGroup, ActionButton, FormGroups} from "../components/FormComps.js"
import {validateField, showError, clearAllErrors, InfoBlock, InfoText} from '../components/ValidationUtils.js';
import MainMenu from "../MainMenu"
import {AsyncPaginate} from "react-select-async-paginate";
import {simpleReducer} from "../components/ReactUtils";
import getLogger from "../components/Logging.js"
import '../App.css'
import ToggleButton from "../components/ToggleButton";

const log = getLogger("BankAccount")

export default function BankAccount(props) {

  const {api, session: {user}, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useAlert()

  const [bankAccount, updateBankAccount] = useReducer(simpleReducer, {});
  const [bankAccountEtc, setBankAccountEtc] = useState([])
  const [region, setRegion] = useState(null)
  const [isAdding, setAdding] = useState(null)

  const [touched, setTouched] = useState(false)

  const [status, setStatus] = useState(null)

  useEffect(() => {
    if (params.bankAccountId) {
      if (params.bankAccountId.trim().toLowerCase() === "_new") {
        setAdding(true)
      }
      else {
        setBusy(intl.msg("working"))
        api.getBankAccountById(params.bankAccountId)
          .then(({data: b}) => {
            updateBankAccount(b)
            setBankAccountEtc(b.bankAccountEtc)
            // if (b.bankAccountEtc?.params) {
            //   //
            //   // const etc = []
            //   // Object.entries(b.bankAccountEtc).map((e, i) => {
            //   //   etc.push([e[0] || "", e[1] || ""])
            //   // })
            //   setBankAccountEtc(b.bankAccountEtc.params)
            // }
            api.getRegion(b.regionId)
              .then(({data: r}) => {
                setRegion({value: r.regionId, label: r.regionName})
              })
              .catch((err) => {
                setFatal(err)
              })
          })
          .catch((err) => {
            setFatal(err)
          })
          .finally(() => {
            setBusy(null)
          })
      }
    }
  }, [])

  function handleBankAccountNoChange(e) {
    const v = e.target?.value
    updateBankAccount({bankAccountNo: v})
    setTouched(true)
  }

  function handleBankAccountNameChange(e) {
    const v = e.target?.value
    updateBankAccount({bankAccountName: v})
    setTouched(true)
  }

  function handleBankAccountEtcNameChange(e, index) {
    const v = e.target?.value
    setBankAccountEtc(prev => {
        return prev.map((item, i) =>
          i === index ? [v, item[1]] : item
        )
      }
    )
    setTouched(true)
  }

  function handleBankAccountEtcValueChange(e, index) {
    const v = e.target?.value
    setBankAccountEtc(prev => {
        return prev.map((item, i) =>
          i === index ? [item[0], v] : item
        )
      }
    )
    setTouched(true)
  }

  function handleBankAccountEtcAdd(e) {
    setBankAccountEtc(prev => {
      if (prev?.length > 0) {
        return ([...prev, ["Name", "Text"]])
      }
      else {
        return ([["Name", "Text"]])
      }
    })
    setTouched(true)
  }

  function handleBankAccountEtcDelete(index) {
    setBankAccountEtc(prev => {
      return prev.filter((_, i) => i !== index)
    })
    setTouched(true)
  }

  function handleBankAccountEtcMoveUp(index) {
    if (index <= 0) return;
    setBankAccountEtc((prevItems) => {
      const newItems = [...prevItems];
      [newItems[index], newItems[index - 1]] = [newItems[index - 1], newItems[index]]; // Swap
      return newItems;
    });
    setTouched(true)
  }

  function handleBankAccountEtcMoveDown(index) {
    if (index >= bankAccountEtc?.length - 1) return; // Can't move the last item down
    setBankAccountEtc((prevItems) => {
      const newItems = [...prevItems];
      [newItems[index], newItems[index + 1]] = [newItems[index + 1], newItems[index]]; // Swap
      return newItems;
    });
    setTouched(true)
  }

  function handleRegionChange(v) {
    setRegion(v)
    setTouched(true)
  }

  function handlePrimaryChange(v) {
    updateBankAccount({primary: v})
    setTouched(true)
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_bankAccountNo", bankAccount?.bankAccountNo && bankAccount.bankAccountNo.trim().length > 0 , "required", opt)
    validateField("inp_bankAccountName", bankAccount?.bankAccountName && bankAccount.bankAccountName.trim().length > 0 , "required", opt)

    log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid

  }

  async function handleSaveClick() {

    log.debug("handleSaveClick: invoked" )

    if (validate()) {

      setBusy(intl.msg("saving"))

      try {

        let payload = {
          bankAccountNo: bankAccount.bankAccountNo,
          bankAccountName: bankAccount.bankAccountName,
          bankAccountEtc: bankAccountEtc,
          // bankAccountEtc: bankAccountEtc.reduce((acc, [key, value]) => {
          //   acc[key] = value;
          //   return acc;
          // }, {}),
          primary: bankAccount.primary,
        }

        if (isAdding) {
          payload.brandId = user.brandId
          payload.regionId = region.value
          const {data} = await api.insertBankAccount(payload)
        }
        else {
          payload.brandId = bankAccount.brandId
          payload.regionId = bankAccount.regionId
          const {data} = await api.updateBankAccount(params.bankAccountId, payload)
        }

        window.location = `/sys/bankaccounts`
      }
      catch (error) {
        handleError(error)
      }
      finally {
        setBusy(null)
      }
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }

  }

  function handleCancelClick() {
    window.location = `/sys/bankaccounts`
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
      if (code === "DUPLICATE_KEY") {
        setAlert({error: intl.msg("country_error_duplicate"), field: "inp_regionId", constraint: "unique"})
      }
      else {
        setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function loadRegions(input, loadedOptions, additional) {
    log.debug("loadRegions:", input, loadedOptions, additional)
    return api.listRegions({view: "BASIC", extraParams: `${input ? `filter=regionName:${encodeURIComponent(input)}&` : ""}sort=regionName:ASC`, cursor: additional?.cursor, limit: 20})
      .then(({data, nextCursor}) => {
        log.debug("loadRegions: data=", data)
        let opt = data.map(c => {
          return {
            value: c.regionId,
            label: c.regionName,
          };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug("loadRegions: res=", res)
        return res
      });
  }

  function isEditable() {
    return user.isSystem() && user.isAdminOrAbove()
  }

  return (
    tenant &&
    <>
      <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={"*/*/SYSTEM"}>

        <FormHeader>
          <FormHeader.Toolbar>
            <FormHeader.Toolbar.Title>
              {intl.msg("bank_account_title")} {" - " + (isAdding ? "(new)" : bankAccount?.bankAccountNo)}
            </FormHeader.Toolbar.Title>
            <FormHeader.Toolbar.Controls>
              <ActionButton name="save" flavor="primary" disabled={!touched} onClick={handleSaveClick}/>
              <ActionButton name="cancel" onClick={handleCancelClick}/>
            </FormHeader.Toolbar.Controls>
          </FormHeader.Toolbar>
          <FormHeader.Alert alert={alert}/>
        </FormHeader>

        <FormBody>

          <FormGroup>
            <FormGroup.Label htmlFor="inp_primary" text={intl.msg("bank_account_primary")}/>
            <FormGroup.Controls>
              <ToggleButton
                id="inp_primary"
                checked={bankAccount?.primary}
                onChange={handlePrimaryChange}
                onLabel={intl.msg("yes")}
                offLabel={intl.msg("no")}/>
              <InfoBlock disabled={!isEditable()}>
                <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
              </InfoBlock>
            </FormGroup.Controls>
          </FormGroup>

          <FormGroup>
            <FormGroup.Label htmlFor="inp_bankAccountNo" text={intl.msg("bank_account_no")}/>
            <FormGroup.Controls>
              <input id="inp_bankAccountNo" type="text" className="form-control" value={bankAccount?.bankAccountNo} disabled={!isEditable()} onChange={handleBankAccountNoChange}/>
              <InfoBlock>
                <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
              </InfoBlock>
            </FormGroup.Controls>
          </FormGroup>

          <FormGroup>
            <FormGroup.Label htmlFor="inp_bankAccountName" text={intl.msg("bank_account_name")}/>
            <FormGroup.Controls>
              <input id="inp_bankAccountName" type="text" className="form-control" value={bankAccount?.bankAccountName} disabled={!isEditable()} onChange={handleBankAccountNameChange}/>
              <InfoBlock>
                <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
              </InfoBlock>
            </FormGroup.Controls>
          </FormGroup>

          <FormGroup>
            <FormGroup.Label htmlFor="inp_region" text={intl.msg("location_region")} description={intl.msg("location_region_description")}/>
            <FormGroup.Controls>
              <div style={{maxWidth: "600px"}}>
                <AsyncPaginate
                  id="inp_region"
                  className="react-select"
                  classNamePrefix="react-select"
                  cacheOptions
                  value={region}
                  debounceTimeout={800}
                  defaultOptions={true}
                  loadOptions={loadRegions}
                  onChange={handleRegionChange}
                  isDisabled={!isEditable()}/>
              </div>
              <InfoBlock disabled={!isEditable()}>
                <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
              </InfoBlock>
            </FormGroup.Controls>
          </FormGroup>

          <FormGroups text={intl.msg("additional_info")}>

            {
              bankAccountEtc && bankAccountEtc.length > 0 &&
                <div style={{padding: "var(--form-group-padding)"}}>
                  <table style={{width: "100%"}}>
                    <tr>
                      <td className="pr-2" style={{width: "30%"}}>
                        <label className="col-form-label">{intl.msg("name")}:</label>
                      </td>
                      <td style={{width: "70%"}}>
                        <label className="col-form-label">{intl.msg("value")}:</label>
                      </td>
                      <td></td>
                    </tr>
                    {
                      bankAccountEtc?.map((e, i) =>
                        <tr>
                          <td className="pr-2" style={{width: "30%"}}>
                            <input id={`inp_bankAccountEtcName_${i}`} key={`bankAccountEtcName_${i}`} type="text" className="form-control" value={e[0]} disabled={!isEditable()} onChange={(e) => handleBankAccountEtcNameChange(e, i)}/>
                          </td>
                          <td style={{width: "70%"}}>
                            <input id={`inp_bankAccountEtcValue_${i}`} key={`bankAccountEtcValue_${i}`} type="text" className="form-control" value={e[1]} disabled={!isEditable()} onChange={(e) => handleBankAccountEtcValueChange(e, i)}/>
                          </td>
                          <td>
                            <div className="pl-2 d-flex flex-row gap-1">

                              <ActionButton icon="move_up" disabled={bankAccountEtc?.length === 0 || i === 0} onClick={(e) => handleBankAccountEtcMoveUp(i)}/>
                              <ActionButton icon="move_down" disabled={bankAccountEtc?.length === 0 || i >= bankAccountEtc?.length - 1} onClick={(e) => handleBankAccountEtcMoveDown(i)}/>
                              <ActionButton icon="delete" onClick={(e) => handleBankAccountEtcDelete(i)}/>
                            </div>
                          </td>
                        </tr>
                      )
                    }
                  </table>
                </div>
            }
            <FormGroup className="pt-0">
              <FormGroup.Controls>
                <ActionButton name="add_new" onClick={handleBankAccountEtcAdd}/>
              </FormGroup.Controls>
            </FormGroup>

          </FormGroups>

        </FormBody>
      </MainContainer>

    </>

  )
}
