import React, {Fragment}  from 'react'
import {useParams} from 'react-router-dom'
import {useSolv, useTenant} from "./components/SolvProvider";
import {useIntlEx} from "./components/IntlUtils.js"
import MainMenu from "./MainMenu.js"
import {FormHeader, FormBody, MainContainer, ActionButton, DropdownMenuItem, DropdownMenu} from "./components/FormComps.js"
import {AccountTabs, AccountStatusLabel} from "./components/AccountUtils"
import {UserLink} from "./components/UserUtils";
import {DataTable, useCols} from "./components/TableComps"
import ResellerInviteDialog from "./ResellerInviteDialog";
import {openDialog, openDialogCurried} from "./components/DialogUtils";
import getLogger from "./components/Logging.js"
import './App.css'
import ExportToFileDialog from "./components/ExportToFileDialog";

const log = getLogger("Resellers")

export default function Resellers(props) {

  const params = useParams()

  const {api, session} = useSolv()
  const {intl} = useIntlEx()

  const {tenant} = useTenant(params.tenantId)

  const {cols} = useCols({
    specs: [
      {
        field: "tenantName",
        label: intl.msg("account"),
        filter: "text",
        sort: "ASC",
        align: "left",
        width: "md",
      },
      {
        field: "parentTenantName",
        label: intl.msg("reseller_parent"),
        filter: "text",
        sort: "",
        align: "left",
        width: "md",
        visible: () => (session.user.isSystem() || tenant.parentId)
      },
      {
        field: "ownerDisplayName",
        label: intl.msg("owner"),
        filter: "text",
        sort: "",
        width: "md",
        align: "left"
      },
      {
        field: "regionId",
        label: intl.msg("region"),
        filter: {
          type: "asyncselect",
          loader: (input, loadedOptions, additional) => {
            log.debug("listRegions: invoked")
            return api.listRegions({view: "BASIC", extraParams: `${input ? `filter=regionName:${encodeURIComponent(input)}&` : ""}sort=regionName:ASC`, cursor: additional?.cursor, limit: 20})
              .then(({data, nextCursor}) => {
                let opt = data.map(c => {
                  return {
                    value: c.regionId,
                    label: c.regionName,
                    pop: c.pop
                  };
                });
                let res = {
                  options: opt,
                }
                if (nextCursor) {
                  res = {
                    ...res,
                    hasMore: true,
                    additional: {
                      cursor: nextCursor
                    }
                  }
                }
                log.debug("loadRegions: res=", res)
                return res
              });
          }
        },
        sort: "",
        align: "left",
        width: "md",
      },
      {
        field: "industryId",
        label: intl.msg("industry"),
        filter: {
          type: "asyncselect",
          loader: (input, loadedOptions, additional) => {
            log.debug("listIndustries: invoked")
            return api.listIndustries({view: "BASIC", extraParams: `${input ? `filter=industryName:${encodeURIComponent(input)}&` : ""}sort=industryName:ASC`, cursor: additional?.cursor, limit: 20})
              .then(({data, nextCursor}) => {
                let opt = data.map(c => {
                  return {
                    value: c.industryId,
                    label: c.industryName,
                    pop: c.pop
                  };
                });
                let res = {
                  options: opt,
                }
                if (nextCursor) {
                  res = {
                    ...res,
                    hasMore: true,
                    additional: {
                      cursor: nextCursor
                    }
                  }
                }
                log.debug("loadIndustries: res=", res)
                return res
              });
          }
        },
        sort: "",
        align: "left",
        width: "md",
      },
      {
        field: "status",
        label: intl.msg("status"),
        filter: "select:accountStatus",
        multi: true,
        sort: "",
        align: "left",
        width: "sm",
      },
    ],
    storageItemName: "resellers",
  })

  function handleFetchDataPage({pageParam: cursor}) {
    return api.listResellers({tenantId: tenant.tenantId, extraParams: cols.stringify(), cursor: cursor})
  }

  function handleRenderDataCol(col, row) {
    let n
    switch (col.field) {
      case "tenantName":
        n = <span>{row.tenantName}</span>
        break
      case "parentTenantName":
        n = <div className="d-flex gap-1">
              {
                row.parentId ? (
                  row.parentId.replace(/-/g, "") !== tenant.tenantId.replace(/-/g, "") ? (
                    <a className="text-link" href={`/${row.parentId}`}>{row.parentTenantName}</a>
                  ) : (
                    <>
                      <span>
                        {row.parentTenantName}
                      </span>
                    </>
                  )
                ) : (
                  <></>
                )
              }
            </div>
        break
      case "ownerDisplayName":
        n = <div className="d-flex">
          {
            row.status !== "INVITING" ? (
              <UserLink user={row.owner} session={session} tenant={row}/>
            ) : (
              row.owner.displayName
            )
          }
        </div>
        break
      case "regionId":
        n = <span>{row.regionName}</span>
        break
      case "industryId":
        n = <span>{row.industryName}</span>
        break
      case "status":
        n = AccountStatusLabel(row)
        break
    }
    return (
      n
    )
  }

  function canAdd() {
    return session && (session.user.isSystemUser() || (session.user.isResellerUser() && session.user.hasSubTenants()))
  }

  function canExport() {
    return session && (session.user.isSystemUser() || session.user.isResellerUser())
  }

  function canMove() {
    return tenant.accessingAs("ADMIN/MANAGER/*")
  }

  function handleRowClick(row) {
    window.location = `/${row.tenantId}`
  }

  async function handleExport() {
    return api.exportResellers({tenantId: tenant.tenantId, extraParams: cols.stringify(), limit: -1})
  }

  function FormControls(props) {
    const ctls = []

    if (canAdd()) {
      ctls.push(<ActionButton name="add_new" flavor="primary" onClick={() => openDialog("dlg_reseller_invite")}/>)
    }

    ctls.push(<MoreMenu/>)

    return ctls
  }

  function MoreMenu(props) {

    const ctls = []

    if (canExport()) {
      ctls.push(
        <DropdownMenuItem name="export" onClick={openDialogCurried("dlg_resellers_export")} prefixWithDivider={ctls.length > 0}/>
      )
    }

    return (
      ctls.length > 0 ? (
        <DropdownMenu>
          {ctls}
        </DropdownMenu>
      ) : (
        <></>
      )
    )
  }

  return (
    tenant &&
      <>
        <MainContainer tenant={tenant} menu={MainMenu} allowIf={(tenant.isSystem()) || (tenant.isReseller() && tenant.hasSubTenants())}>
          <FormHeader>
            <AccountTabs tenant={tenant}/>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("resellers")}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                <FormControls/>
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
          </FormHeader>
          <FormBody>
            <DataTable
              tenant={tenant}
              cols={cols}
              resourceName="resellers"
              dataKey="tenantId"
              onFetchDataPage={handleFetchDataPage}
              onRenderDataCol={handleRenderDataCol}
              onClickDataRow={handleRowClick}
            />
          </FormBody>
        </MainContainer>
        <ResellerInviteDialog tenant={tenant}/>
        <ExportToFileDialog
          key="download"
          type="resellers"
          fileName={`solv-resellers`}
          fileExt={"csv"}
          onExport={handleExport}/>
      </>
  )
}