import React, { useEffect, useState }  from 'react'
import { useHistory } from 'react-router-dom'
import getLogger from "./Logging.js"
import {LOCALES} from "../i18n/locales";

const log = getLogger("PrefUtils")

const PREFS_COOKIE_NAME = "SOLV-PREFS"

export function usePrefs() {

  const history = useHistory()
  const [prefs, setPrefs] = useState(null)
  const [locale, setLocale] = useState(null)
  const [theme, setTheme] = useState(null)
  const [timezone, setTimezone] = useState(null)

  useEffect(() => {
    log.debug("Loading prefs")
    let p = localStorage.getItem(PREFS_COOKIE_NAME)
    if (p) {
      try {
        p = JSON.parse(p)
        log.debug("loadPrefs: prefs=", p)
      }
      catch (err) {
        log.debug("Error parsing prefs", err)
        p = null
      }
    }
    if (!p) {
      p = {
        lang: "system",
        theme: "system",
        timezone: "UTC",
      }
    }
    setPrefs(p)
    setLocale(() => {
      return normalizeLocale(p.lang || "system")
    })
    setTheme(p.theme || "system")
    setTimezone(p.timezone || "UTC")
  }, [])

  useEffect(() => {
    if (theme) {
      // remove a previous dynamic theme
      const link = document.getElementById("custom-theme");
      if (link) {
        link.remove()
      }
      let t = theme
      if ("system" === theme) {
        t = (window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light")
      }
      else {
        if (theme.startsWith("https://")) {
          const link = document.createElement('link')
          link.id = "custom-theme"
          link.rel = 'stylesheet'
          link.type = 'text/css'
          link.href = theme
          link.onload = () => console.log(`CSS file loaded: ${theme}`)
          link.onerror = () => console.error(`Failed to load CSS file: ${theme}`)
          document.head.appendChild(link)
        }
      }
      document.documentElement.setAttribute('data-theme', t);
    }
  }, [theme])

  function normalizeLocale(l) {

    let tlocale = l

    if (!l || l === "system") {
      const lang = navigator.languages
        ? navigator.languages[0]
        : (navigator.language || navigator.userLanguage)

      const lang_parts = lang.split("-")
      if (lang_parts.length > 1) {
        // log.debug("lang_parts=", lang_parts)
        if (lang_parts[0] === "en") {
          if (lang_parts[1] === "CA") {
            tlocale = "en"
          }
          else if (lang_parts[1] === "GB") {
            tlocale = "en"
          }
          else if (lang_parts[1] === "US") {
            tlocale = "en"
          }
          else {
            tlocale = "en"
          }
        }
        else if (lang_parts[0] === "zh") {
          if (lang_parts[1] === "CN") {
            tlocale = "zh-CN"
          }
          else if (lang_parts[1] === "HK") {
            tlocale = "zh-TW"
          }
          else {
            tlocale = "zh-CN"
          }
          if (tlocale === "zh-CN") {
            tlocale = "zh-HANS"
          }
          else {
            tlocale = "zh-HANT"
          }
        }
        // log.debug("Set detected lang=", tlocale)
      }
      else {
        tlocale = lang
      }

      if (!tlocale) {
        tlocale = "en"
      }
    }

    if (!(LOCALES.hasOwnProperty(tlocale))) {
      tlocale = "en"
    }

    return tlocale

  }

  const savePrefs = (p, location) => {
    log.debug("savePrefs: prefs=", p)
    localStorage.setItem(PREFS_COOKIE_NAME, JSON.stringify(p))
    setPrefs(p)
    if (location) {
      window.location = location
    }
  }

  const deletePrefs = () => {
    localStorage.removeItem(PREFS_COOKIE_NAME)
    setPrefs(null)
  }

  return {prefs, savePrefs, deletePrefs, locale, theme, timezone}
}