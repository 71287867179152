import {useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import getLogger from "./Logging";

const log = getLogger("UrlUtils")

const VALID_DOMAIN_PAT = /^(((?!-))(xn--|_)?[a-z0-9-]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9][a-z0-9\-]{0,60}|[a-z0-9-]{1,30}\.[a-z]{2,})$/

export function concatUrlComponents(baseUrl, ...componentUrls) {
  let result
  if (baseUrl) {
    result = baseUrl.trim()
    if (result.endsWith("/")) {
      result = result.substring(0, result.length - 1)
    }
    for (let c of componentUrls) {
      if (c.startsWith("/")) {
        result += c
      }
      else {
        result += `/${c}`
      }
    }
  }
  return result
}

export function isValidUrl(v) {
  const s = v.match(VALID_DOMAIN_PAT)
  log.debug(">>>Z: isValidUrl: v=, result=", v, s)
  return s
}

export function urlRemoveLastId(url, id) {
  if (url) {
    const i = url.lastIndexOf(id.replace(/\-/g, ""))
    if (i >= 0) {
      return url.substring(0, i)
    }
  }
  return url
}

const ID_PAT = /^(?:[0-9a-fA-F]{32}|[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/
const ID_OR_ACTION_PAT = /^(?:[0-9a-fA-F]{32}|[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}|_[^_]+)$/

export function parsePathname(location) {
  if (location) {
    let pathname
    if (location.pathname) {
      pathname = location.pathname
    }
    else {
      pathname = location
    }
    const parts = pathname.split('/').filter(i => i !== "")
    // log.debug("parsePathname: parts=", parts)
    if (parts.length === 0) {
      return ({
        tenantId: null,
        path: "/",
        documentId: null,
      })
    }
    if (parts.length === 1) {
      const part0 = parts[0]
      if (part0.length == 0) {
        return ({
          tenantId: null,
          path: "/",
          documentId: null,
        })
      }
      else if (part0.match(ID_PAT)) {
        return ({
          tenantId: part0,
          path: "/",
          documentId: null,
        })
      }
      else {
        return ({
          tenantId: null,
          path: "/" + part0,
          documentId: null,
        })
      }
    }
    else if (parts.length === 2) {
      const part0 = parts[0]
      const part1 = parts[1]
      if (part0.match(ID_PAT)) {
        if (part1.startsWith("_")) {
          return ({
            tenantId: part0,
            path: "/",
            documentId: part1,
          })
        }
        else {
          return ({
            tenantId: part0,
            path: "/" + part1,
            documentId: null,
          })
        }
      }
      else {
        if (part1.match(ID_PAT) || part1.startsWith("_")) {
          return ({
            tenantId: null,
            path: "/" + part0,
            documentId: part1,
          })
        }
        else {
          return ({
            tenantId: null,
            path: "/" + part0 + "/" + part1,
            documentId: null,
          })
        }
      }
    }
    else {
      const part0 = parts[0]
      const partz = parts[parts.length - 1]
      if (part0.match(ID_PAT)) {
        if (partz.match(ID_PAT) || partz.startsWith("_")) {
          return ({
            tenantId: part0,
            path: "/" + parts.slice(1, -1).join("/"),
            documentId: partz
          })
        }
        else {
          return ({
            tenantId: part0,
            path: "/" + parts.slice(1).join("/"),
            documentId: null
          })
        }
      }
      else if (partz.match(ID_PAT)) {
        return ({
          tenantId: null,
          path: "/" + parts.slice(0, -1).join("/"),
          documentId: partz
        })
      }
      else {
        return ({
          tenantId: null,
          path: "/" + parts.join("/"),
          documentId: null
        })
      }
    }
  }
  else {
    return location
  }
}

export function usePathParser(location) {

  const [parts, setParts] = useState(null)

  useEffect(() => {
    if (location) {
      setParts(parsePathname(location))
    }
  }, [])

  return (
    {
      pathParts: parts
    }
  )

}

export function navTo(href) {
  window.location = href
}

export function navToCurried(href) {
  return (event) => {
    window.location = href
  }
}

export const useSearchParams = () => {

  const { search } = useLocation();

  function setSearchParams(p, v) {
    if (v) {
      const pp = new URLSearchParams(window.location.search)
      pp.append(p, v)
      const newUrl = [window.location.pathname, pp.toString()]
        .filter(Boolean)
        .join('?')
      window.history.replaceState(null, '', newUrl)
    }
    else {
      const newUrl = [window.location.pathname, p.toString()]
        .filter(Boolean)
        .join('?')
      window.history.replaceState(null, '', newUrl)
    }
  }

  return [useMemo(() => new URLSearchParams(search), [search]), setSearchParams];

};

