import React, { useState, useEffect }  from 'react'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { TagBox, TagLabel } from "./TagBox.js"
import getLogger from "./Logging.js"
import styles from "./AccountUtils.module.css"
import {useSolv} from "./SolvProvider";
import {useIntlEx} from "./IntlUtils";
import {shortIdEqual} from "./StringUtils";
import {useLocation} from "react-router-dom";
import {uuidRegex} from "./CommonUtils";
import {FormHeader} from "./FormComps";

const log = getLogger("AccountUtils")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export function AccountHeader(props) {
  const {intl} = useIntlEx()
  return (
    <div className="d-flex align-items-center gap-1">
      <div>
        {props.tenant.tenantName}
      </div>
      <div>
        <TagBox>
          {
            !props.tenant.accessingAs("*/MEMBER/*") &&
              <TagLabel label={intl.msg(`account_type_${props.tenant.tenantTypeId.toLowerCase()}`)} tooltip={""} size="lg"/>
          }
          {
            ["INVITING", "ONBOARDING"].includes(props.tenant.status) &&
              <AccountStatusLabel status={props.tenant.status} size="lg"/>
          }
        </TagBox>
      </div>
    </div>
  )
}

export function AccountStatusLabel(props) {
  let status

  if ("string" === typeof(props)) {
    status = props
  }
  else {
    status = props.status || props.tenantStatus
  }
  if (!status) {
    return (<></>)
  }
  let statusLabel = getStatusName(status)
  return (
    <TagLabel label={statusLabel} className={styles[`account-status-color-${statusLabel}`]} tooltip={statusLabel} size={props.size}/>
  )
}

export function AccountTabs(props) {

  const [tenant, setTenant] = useState(null)
  const {intl } = useIntlEx()

  const location = useLocation()

  const [tabs, setTabs] = useState(null)
  const [currentTab, setCurrentTab] = useState(null)

  const cmp = location.pathname.split("/").filter(i => i.trim().length > 0)
  const currentPage = cmp.findLast(i => !i.match(uuidRegex) && !i.startsWith("_"))

  useEffect(() => {
    log.debug("useEffect: invoked: currentPage=", currentPage)
    if (props && props.tenant) {

      const t = props.tenant
      log.debug("useEffect: tenant=", t)

      setTenant(t)

      const tabs = []

      if (!t.accessingAs("*/MEMBER/*")) {

        const tenantId = t.tenantId?.replace(/\-/g, "")

        tabs.push({
          id: "account",
          href: `/${tenantId}`
        })

        if (t.accessingAs("*/MANAGER/*")) {

          if (t.status !== "INVITING") {

            if (t.isReseller()) {
              tabs.push({
                id: "clients",
                href: `/${tenantId}/clients`
              })

              if (t.hasSubTenants()) {
                tabs.push({
                  id: "resellers",
                  href: `/${tenantId}/resellers`
                })
              }
            }

            tabs.push({
              id: "broadcasts",
              href: `/${tenantId}/broadcasts`
            })
          }

          tabs.push({
            id: "members",
            href: `/${tenantId}/members`
          })

          if (t.status !== "INVITING") {
            if (t.isClient()) {
              tabs.push({
                id: "credits",
                href: `/${tenantId}/credits`
              })
            }

            if (t.isReseller() || t.isSystem()) {
              tabs.push({
                id: "refcodes",
                href: `/${tenantId}/refcodes`
              })
            }
          }
        }

        setTabs(tabs)
        setCurrentTab(tabs.find(i => i.id === currentPage) || tabs[0])

      }

    }
  }, [props])

  log.debug("cmp=, currentPage=, currentTab=", cmp, currentPage, currentTab)

  function Tab({tab}) {
    const href = `/${cmp[0]}/${cmp[1]}${tab.href}`
    let title = intl.msg(tab.id)
    return (
      <>
        <FormHeader.Tab id={tab.id} href={tab.href} title={title} active={currentTab.id === tab.id}/>
      </>
    )
  }

  return (
    tenant && tabs && tabs.length > 0 &&
    <FormHeader.Tabs title={<AccountHeader tenant={tenant}/>}>
      {
        // tenant.status !== "INVITING" &&
        tabs.map(tab => {
          return <Tab tab={tab}/>
        })
      }
    </FormHeader.Tabs>
  )
}


export function mkAccountDocUrl(user, tenant, docType, docId) {
  let suffix = `/${docType}${docId ? `/${docId}` : ""}`
  log.debug("mkAccountDocUrl: user=, tenant=", user, tenant)
  if (user && tenant) {
    if (user.tenantId?.replace(/\-/g, "") === tenant.tenantId?.replace(/\-/g, "")) {
      return suffix
    }
    else {
      return `/${tenant.tenantId}${suffix}`
    }
  }
  else {
    return suffix
  }
}

function getStatusName(status) {
  switch (status) {
    case "NEW":
    case "ACTIVE":
    case "BANNED":
    case "BLOCKED":
    case "INACTIVE":
    case "INVITING":
    case "ONBOARDING":
      return status.toLowerCase()
    default:
      if (status?.startsWith("UNUSED")) {
        return "unused"
      }
      else if (status?.startsWith("DORMANT")) {
        return "dormant"
      }
      else {
        return "unknown"
      }
  }
}

export function useAccountStatusName() {

}

export function useAccountStatusOpts() {
  const {intl} = useIntlEx()
  const accountStatusOpts = [
    {
      value: "NEW",
      label: intl.msg("status_account_new"),
    },
    {
      value: "ACTIVE",
      label: intl.msg("status_account_active"),
    },
    {
      value: "INACTIVE",
      label: intl.msg("status_account_inactive"),
    },
    {
      value: "DORMANT",
      label: intl.msg("status_account_dormant"),
    },
    {
      value: "UNUSED",
      label: intl.msg("status_account_unused"),
    },
    {
      value: "BANNED",
      label: intl.msg("status_account_banned"),
    },
    {
      value: "ONBOARDING",
      label: intl.msg("status_account_onboarding"),
    },
    {
      value: "INVITING",
      label: intl.msg("status_account_inviting"),
    },
  ]
  function findAccountStatusOpt(status) {
    const st = status?.toUpperCase()
    if (!st) {
      return null
    }
    if (st.startsWith("DORMANT")) {
      return accountStatusOpts.find(o => o.value === "DORMANT")
    }
    else if (st.startsWith("UNUSED")) {
      return accountStatusOpts.find(o => o.value === "UNUSED")
    }
    else {
      return accountStatusOpts.find(o => o.value === st)
    }
  }
  return ({
    accountStatusOpts,
    findAccountStatusOpt,
  })
}

export function useAccountTypesOpts() {
  const {intl} = useIntlEx()
  const accountTypeOpts = [
    {
      value: "CLIENT",
      label: intl.msg("account_type_client"),
    },
    {
      value: "RESELLER",
      label: intl.msg("account_type_reseller"),
    },
    {
      value: "SYSTEM",
      label: intl.msg("account_type_system"),
    },
  ]
  function findAccountTypeOpt(status) {
    const st = status?.toUpperCase()
    if (!st) {
      return null
    }
    else {
      return accountTypeOpts.find(o => o.value === st)
    }
  }
  return ({
    accountTypeOpts: accountTypeOpts,
    findAccountTypeOpt: findAccountTypeOpt,
  })
}

function concatPaths(p1, p2) {
  let u = ""
  if (p1) {
    if (p1.endsWith("/")) {
      u = p1.substring(0, p1.length - 1)
    }
    else {
      u = p1
    }
  }
  if (p2) {
    if (p2.startsWith("/")) {
      u = u + p2
    }
    else {
      u = u + "/" + p2
    }
  }
  return u
}

export function mkHref({prefix, suffix, tenant, user}) {
  if (user) {
    if (shortIdEqual(user.tenantId, tenant.tenantId)) {
      return concatPaths(prefix, suffix)
    }
    else {
      return concatPaths(prefix, `/${tenant.tenantId.replaceAll("-", "")}${suffix}`)
    }
  }
  else {
    if (tenant && tenant.accessingAs("*/MEMBER/*")) {
      return concatPaths(prefix, suffix)
    }
    else {
      return concatPaths(prefix, `/${tenant.tenantId.replaceAll("-", "")}${suffix}`)
    }
  }
}

export function useTenantPlan(filter) {
  const {intl} = useIntlEx()

  let tenantPlanOpts = [
    {
      value: "BUSINESS_A",
      label: intl.msg("account_plan_business_a")
    },
    {
      value: "BUSINESS_B",
      label: intl.msg("account_plan_business_b")
    },
    {
      value: "DEMO",
      label: intl.msg("account_plan_demo")
    },
    {
      value: "TEST",
      label: intl.msg("account_plan_test")
    },
    {
      value: "TRIAL",
      label: intl.msg("account_plan_trial")
    },
  ]

  if (Array.isArray(filter)) {
    tenantPlanOpts = tenantPlanOpts.filter((c) => filter.includes(c.value))
  }
  else if ("function" === typeof(filter)) {
    tenantPlanOpts = tenantPlanOpts.map(filter).filter(Boolean)
  }

  return ({
    tenantPlanOpts: tenantPlanOpts,
    findTenantPlanOpt: (v, def) => {
      return tenantPlanOpts.find(e => e.value?.toUpperCase() === v)
             || tenantPlanOpts.find(e => e.value?.toUpperCase() === def)
    }
  })

}

export function useIndustry(filter) {
  const {intl} = useIntlEx()

  let industryOpts = [
    {
      value: "PUBLIC_SAFETY",
      label: intl.msg("industry_public_safety")
    },
    {
      value: "SME",
      label: intl.msg("industry_sme")
    },
    {
      value: "REAL_ESTATE",
      label: intl.msg("industry_real_estate")
    },
    {
      value: "OTHER",
      label: intl.msg("industry_other")
    },
  ]

  if (Array.isArray(filter)) {
    industryOpts = industryOpts.filter((c) => filter.includes(c.value))
  }
  else if ("function" === typeof(filter)) {
    industryOpts = industryOpts.map(filter).filter(Boolean)
  }

  return ({
    industryOpts: industryOpts,
    findIndustryOpt: (v, def) => {
      return industryOpts.find(e => e.value?.toUpperCase() === v)
             || industryOpts.find(e => e.value?.toUpperCase() === def)
    }
  })

}

export function useResellerType(filter) {

  const {session} = useSolv()
  const {intl} = useIntlEx()

  let resellerTypeOpts = [
    {
      value: "MASTER",
      label: intl.msg(`reseller_type_master`),
    },
    {
      value: "SUPER",
      label: intl.msg(`reseller_type_super`),
    },
    {
      value: "SUB",
      label: intl.msg(`reseller_type_sub`),
    },
    {
      value: "STANDALONE",
      label: intl.msg(`reseller_type_standalone`),
    }
  ]

  resellerTypeOpts.sort((a, b) => a.label.localeCompare(b.label))

  function getResellerType(tenant) {
    let n = null
    if (tenant && tenant.tenantTypeId === "RESELLER") {
      if (tenant.parentId) {
        if (tenant.hasSubTenants()) {
          n = "SUPER"
        }
        else {
          n = "SUB"
        }
      }
      else if (tenant.hasSubTenants()) {
        n = "MASTER"
      }
      else {
        n = "STANDALONE"
      }
    }
    return n
  }

  function getAvailableResellerTypeOpts(tenant) {
    switch (getResellerType(tenant)) {
      case "MASTER":
        return [
          findResellerTypeOpt("MASTER"),
          findResellerTypeOpt("STANDALONE")
        ]
      case "SUPER":
        return [
          findResellerTypeOpt("MASTER"),
          findResellerTypeOpt("STANDALONE"),
          findResellerTypeOpt("SUPER"),
        ]
      case "SUB":
        return [
          findResellerTypeOpt("STANDALONE"),
          findResellerTypeOpt("SUB"),
        ]
      default:
        return [
          findResellerTypeOpt("MASTER"),
          findResellerTypeOpt("STANDALONE"),
        ]
    }
  }

  function getInvitableResellerTypeOpts(tenant) {
    switch (tenant?.tenantTypeId) {
      case "SYSTEM":
        return [
          findResellerTypeOpt("MASTER"),
          findResellerTypeOpt("STANDALONE")
        ]
      default:
        return []
    }
  }

  if (Array.isArray(filter)) {
    resellerTypeOpts = resellerTypeOpts.filter((c) => filter.includes(c.value))
  }
  else if ("function" === typeof(filter)) {
    resellerTypeOpts = resellerTypeOpts.map(filter).filter(Boolean)
  }

  function findResellerTypeOpt(v, def) {
    return resellerTypeOpts.find(e => e.value?.toUpperCase() === v)
           || resellerTypeOpts.find(e => e.value?.toUpperCase() === def)
  }

  return {
    getResellerType,
    resellerTypeOpts,
    getAvailableResellerTypeOpts,
    getInvitableResellerTypeOpts,
    findResellerTypeOpt
  }

}

export function hasChildTenants(tenant) {
  return (tenant?.maxDepth ? tenant?.maxDepth > (tenant?.parentPath ? (tenant?.parentPath.split(".").length - 1) : 0) : false)
}

export const PATH_PAT = /^\/?(?:([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}|[a-f0-9]{32}))\/([^_]+)(?:\/([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}|[a-f0-9]{32}|_[a-zA-Z0-9_]+))?$/;

export const PATH_START_PAT = /^\/([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}|[a-f0-9]{32})/;

export const PATH_END_PAT = /\/([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}|[a-f0-9]{32}|_[a-zA-Z0-9_]+)$/;

export const TENANT_PATH_PAT = /^\/(?:(?:[0-9a-fA-F]{8}(?:-[0-9a-fA-F]{4}){3}-[0-9a-fA-F]{12})|[0-9a-fA-F]{32})/;

export function parsePath(path) {
  const match = path.match(PATH_PAT)
  log.debug(`parsePath: path=${path}, match=${match}`)
  return ({
    tenantId: match[1] ? match[1].replace(/-/g, '') : null,
    documentPath: match[2] ? match[2].replace(/\/$/, '') : null,
    documentId: match[3] ? match[3].replace(/_/g, '') : null
  })
}

export function EnsureAccessible(props) {

  const {setFatal} = useSolv()
  const [tenant, setTenant] = useState(null)

  useEffect(() => {
    if (props.tenant) {
      setTenant(props.tenant)
    }
  }, [props.tenant])

  useEffect(() => {
    if (tenant) {
      if (props.accessingAs) {
        if (!tenant.accessingAs(props.accessingAs)) {
          setFatal("DENIED_ACCESS")
        }
      }
    }
  }, [tenant])

}

export function TenantLink(props) {
  const {intl} = useIntlEx()
  const tenantId = props?.tenant && props.tenant.tenantId ? props.tenant.tenantId : props?.tenantId ? props.tenantId : null
  const tenantName = props?.tenant && props.tenant.tenantName ? props.tenant.tenantName : props?.tenantName ? props.tenantName : null
  if (tenantId && tenantName) {
    return (
      <a className="text-link" href={`/${tenantId.replace(/\-/g, "")}${props.suffix ? props.suffix : ""}`}>
        {tenantName}
      </a>
    )
  }
  else {
    return (<></>)
  }
}

export function navigateTo(props) {
  window.location = mkHref(props)
}